import "./AffiliateLayout.css";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import { useAccordionButton } from "react-bootstrap/AccordionButton";

const BankPage = () => {
  const [accordionOpen, setAccordionOpen] = useState(false);

  const columns = [
    {
      name: "SI.No",
      selector: (row) => parseInt(row.id),
      sortable: true,
      width: "100px",
      wrap: true,
    },
    {
      name: "Bank Name",
      selector: (row) => row.bank,
      sortable: true,
      wrap: true,
    },
    {
      name: "Icon ",
      selector: (row) => row.icon,
      sortable: true,
      wrap: true,
    },
    {
      name: "Created Date",
      selector: (row) => row.date,
      sortable: true,
      wrap: true,
    },
    {
      name: "Action",
      selector: (row) =>
        // <span className="d-flex gap-2 align-items-center">
        //   <a href={`/company/edit/${row.companyId}`} target="_blank">
        //     <img
        //       src="/build/edit.svg"
        //       alt="edit"
        //       width={24}
        //       height={24}
        //       priority
        //     />
        //   </a>
        //   {row.status === "ACTIVE" ? (
        //     <label className="switch">
        //       <input type="checkbox" checked />
        //       <span className="slider round"></span>
        //     </label>
        //   ) : (
        //     <label className="switch">
        //       <input type="checkbox" />
        //       <span className="slider round"></span>
        //     </label>
        //   )}
        // </span>
        row.action,
      sortable: true,
      wrap: true,
    },
  ];

  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      setAccordionOpen(!accordionOpen)
    );
    return (
      <button
        type="button"
        className="admin_filter_btn"
        // style={{ backgroundColor: "pink" }}
        onClick={decoratedOnClick}
      >
        {children}
        {accordionOpen ? (
          <img
            src="/build/img/Icons/down_vector.svg"
            alt="zex pr wire Logo"
            width={16}
            height={16}
          />
        ) : (
          <img
            src="/build/img/Icons/down_vector.svg"
            alt="zex pr wire Logo"
            width={16}
            height={16}
            className="filter_up_arrow"
          />
        )}
      </button>
    );
  }
  return (
    <>
      <div className="affiliate_pages_layout">
        <div className="affiliate_page_heading bank_heading">
          <h4 className="bank1"> Manage Bank </h4>
          <Link to="/admin/affiliate_bank/add" className="bank2">
            <button className="new_affiliate_btn  bank_btn ">
              {" "}
              ADD NEW BANK{" "}
            </button>
          </Link>
        </div>
        <Accordion className="mb-4">
          <Card>
            <Card.Header className="d-md-flex justify-content-between align-items-center">
              <div className="staff_search">
                <label>
                  Search:
                  <input
                    type="search"
                    placeholder=""
                    aria-controls="datatable"
                    // onChange={filterData}
                  ></input>
                </label>
              </div>
              <div className="d-md-flex">
                <CustomToggle as={Button} variant="link" eventKey="0">
                  Filters
                </CustomToggle>
              </div>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Form>
                  <div className="d-flex gap-4 flex-wrap">
                    <Form.Group
                      controlId="filterStatus"
                      className="staff_filter_options"
                    >
                      <Form.Label>Status</Form.Label>
                      <Form.Control
                        as="select"
                        name="status"
                        // value={filters.status}
                        // onChange={handleFilterChange}
                      >
                        <option value="">All</option>
                        <option value="ACTIVE">Approved</option>
                        <option value="INACTIVE">Disapprove</option>
                      </Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filtername"
                      className="staff_filter_options"
                    >
                      <Form.Label>Bank Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search Bank Name"
                        name="name"
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filterIcon"
                      className="staff_filter_options"
                    >
                      <Form.Label>Icon</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search Icon"
                        name="icon"
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filterDate"
                      className="staff_filter_options"
                    >
                      <Form.Label>Created Date</Form.Label>
                      <Form.Control
                        type="date"
                        placeholder="Search Date"
                        name="date"
                      ></Form.Control>
                    </Form.Group>
                  </div>
                  <div className="mt-3 d-flex gap-3">
                    <Button variant="secondary" /*onClick={clearFilters}*/>
                      Clear
                    </Button>
                    <Button variant="primary" /*onClick={applyFilters} */>
                      Apply
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <DataTable
          columns={columns}
          //   data={tempData}
          data={[{}]}
          pagination
          defaultSortFieldId={1}
          responsive={true}
          defaultSortAsc={true}
          className="application_datatable data-table-wrapper"
        />
      </div>
    </>
  );
};

export default BankPage;
