import Navbar from "../../components/NavbarProfile/NavbarProfile";
import Sidebar from "../../components/SidebarNew/Sidebar";
import CompanyUpdate from "../../components/ManageCompany/CompanyUpdate";
import Footer from "../../components/Layouts/Footer";
import "../ManageCompany/Company.css";

const CompanyEdit = () => {
  //   const activeSide = 27;
  //   const activeEventKey = "8";
  return (
    <>
      <div className="row gx-0 manage_company_layout">
        <Sidebar />
        <div
          className={`main col d-flex flex-column`}
          style={{ overflow: "auto" }}
        >
          <Navbar />
          <div className="flex-grow-1">
            <CompanyUpdate />
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default CompanyEdit;
