import "./Login.css";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Instance from "../../Utils/Axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { showToast } from "../../Utils/index";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export default function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [userError, setUserError] = useState("");
  const [passError, setPassError] = useState("");
  const [show, setShow] = useState(false);
  const [otpError, setOtpError] = useState("");
  const [otpMessage, setOtpMessage] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();

  const loginAdmin = (e) => {
    e.preventDefault();
    if (!username) {
      setUserError("The Username field is required.");
      return;
    }
    if (!password) {
      setPassError("The Password field is required.");
      return;
    }

    Instance.post("/siteadmin/staff/login", {
      userName: username,
      password: password,
    })
      .then((response) => {
        localStorage.setItem("adminToken", response.data.adminToken);
        showToast("Login successful!", "success", "success");
        setTimeout(() => {
          navigate("/admin/staff");
        }, 2000);
        console.log("Login successful:", response);
      })
      .catch((error) => {
        showToast("Login failed!", "error", "error");
        setError("Either Username/Password is incorrect");
        console.log("Login unsucceful:", error);
      });
  };

  const sendOtp = () => {
    if (!username) {
      setOtpError("This field is required.");
      return;
    }
    Instance.post("/siteadmin/staff/login", { userName: username })
      .then((response) => {
        setOtpMessage("OTP sent successfully!");
        showToast("OTP is sent", "success");
        handleClose();
        console.log("checking username", response);
      })
      .catch((error) => {
        showToast("The username does not match", "error");
      });
  };

  const authMe = () => {
    const adminToken = localStorage.getItem("adminToken");
    // if (!adminToken) {
    //   setError("Authentication token not found.");
    //   return;
    // }

    axios
      .post(
        "https://pradmin.webandapi.com//siteadmin/get",
        {
          adminToken: adminToken,
        },
        {
          headers: {
            Authorization: `Bearer ${adminToken}`,
          },
        }
      )
      .then((response) => {
        if (response.data.resCode === "200") {
          setTimeout(() => {
            window.location.href = "/admin/staff";
            navigate("/staff");
          }, 1000);
          console.log("AuthMe successful:", response);
        } else {
          // showToast("Authentication failed!", "error", "error");
          // setError("Authentication failed. Please login again.");
          console.log("AuthMe unsuccessful:", response);
        }
      })
      .catch((error) => {
        showToast("Authentication failed!", "error", "error");
        setError("Authentication failed. Please login again.");
        console.log("AuthMe error:", error);
      });
  };

  useEffect(() => {
    authMe();
  }, []);
  return (
    <>
      <div className="admin_login_layout">
        <div className={`signin_container`}>
          <h1>Welcome to Zexprwire</h1>
          {error && <p className="error_message">{error}</p>}
          <form className={`signin_wrapper`}>
            <input
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            {userError && !username && <p>{userError}</p>}
            <input
              size="password"
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {passError && !password && <p>{passError}</p>}
            <button className={` signin_btn`} onClick={loginAdmin}>
              Log In
            </button>
            <p className={`mb-0 forgot`} onClick={handleShow}>
              Forgot Password?
            </p>
            <Modal
              show={show}
              onHide={handleClose}
              className="custom_modal_admin"
            >
              <Modal.Header closeButton>
                <Modal.Title>Change Password</Modal.Title>
              </Modal.Header>
              <Modal.Body className="custom_modal_admin_body">
                <p>Username</p>
                <input
                  type="text"
                  placeholder="Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
                {otpError && <p className="text-danger mt-2">{otpError}</p>}
                {otpMessage && (
                  <p className="text-success mt-2">{otpMessage}</p>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button variant="primary" onClick={sendOtp}>
                  Send OTP
                </Button>
              </Modal.Footer>
            </Modal>
          </form>
        </div>
        <ToastContainer theme="colored" />
      </div>
    </>
  );
}
