import "./PaymentLayout.css";
import { Link, useNavigate } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import { useEffect, useState } from "react";
import Select from "react-select";
import Instance from "../../Utils/Axios";
import { showToast } from "../../Utils/index";

const PaymentAddUser = () => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [userList, setUserList] = useState([]);
  const [userString, setUserString] = useState("");
  const [userObj, setUserObj] = useState([]);
  const [checkUser, setCheckUser] = useState(false);
  const [selectedOptions2, setSelectedOptions2] = useState([]);

  const [error, setError] = useState("");

  const fetchUserList = () => {
    const token = localStorage.getItem("adminToken");
    console.log("Fetched token from localStorage:", token);
    Instance.post("/siteadmin/user/list", {
      adminToken: token,
      status: "",
      fromDate: "",
      toDate: "",
      email: "",
      userId: "",
      country: "",
      state: "",
      city: "",
      mobileNumber: "",
      parentId: "",
    })
      .then((response) => {
        // setUserList(response?.data?.message);

        setError("");
        console.log("check user  data", response?.data?.message);
        const userOptions = [];
        // console.log("check Gallery data", response?.data?.message);

        (response?.data?.message).map((val) => {
          userOptions.push({
            value: val.userId,
            label: val.firstName,
          });
        });

        setUserList(userOptions);
      })
      .catch((error) => {
        setError("Failed to fetch the data");
        console.error("Error fetching user list:", error);
      });
  };
  useEffect(() => {
    fetchUserList();
  }, []);

  const options = [
    { value: "user1", label: "user1" },
    { value: "user2", label: "user2" },
    { value: "user3", label: "user3" },
    { value: "user4", label: "user4" },
    { value: "user5", label: "user5" },
    { value: "user6", label: "user6" },
  ];

  const handleMultiUserSelect = (obj) => {
    setUserObj(obj);
    const tempArr = obj.map((ar) => ar.value);
    var str = "";
    tempArr.map((val, indx) => {
      if (indx <= tempArr.length - 2) {
        str = str + val + "|";
      }
      if (indx == tempArr.length - 1) {
        str = str + val;
      }
    });

    setUserString(str);
  };

  const handleSelectAllChange2 = (e) => {
    setCheckUser(!checkUser);
    if (e.target.checked) {
      setSelectedOptions2(userList);
    } else {
      setSelectedOptions2([]);
    }
  };
  return (
    <>
      <div className="payments_pages_layout">
        <div className="payments_page_heading">
          <h4> Manage Payment Method </h4>

          <div className="admin_payments_btns">
            <Link to="/admin/payments">
              <span style={{ cursor: "pointer" }}>
                <div className="backbutton"></div>
              </span>
            </Link>
          </div>
        </div>
        <div className="add_package_details">
          <Form>
            <Row>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Select User</Form.Label>
                <Select
                  value={checkUser ? userList : userObj}
                  options={userList}
                  onChange={(cl) => handleMultiUserSelect(cl)}
                  placeholder="Select Distribution Options"
                  isMulti
                  closeMenuOnSelect={false}
                />
                <Form.Check
                  type="checkbox"
                  label="Select All User"
                  onChange={handleSelectAllChange2}
                  checked={selectedOptions2.length === userList.length}
                  className="mt-3"
                />
              </Col>
            </Row>

            <button className="add_package_submit_btn" type="submit">
              Submit
            </button>
          </Form>
        </div>
      </div>
    </>
  );
};

export default PaymentAddUser;
