"use client";
import "../Dashboard/Dashboard.css";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Instance from "../../Utils/Axios";
import { showToast } from "../../Utils/index";
import { List } from "react-bootstrap-icons";
import Sidebar from "../SidebarNew/Sidebar";
import axios, { Axios } from "axios";
import "./Navbar.css";

export default function Navbar({ name }) {
  const [showResMenu, setShowResMenu] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const handleLogout = async () => {
    const token = localStorage.getItem("adminToken");
    console.log("Fetched token from localStorage for logout:", token);

    try {
      const response = await axios.post(
        "https://pradmin.webandapi.com/siteadmin/staff/logout",
        {
          adminToken: token,
        }
      );
      console.log("logout response", response.data);
      showToast("Logout Succesfully", "success", "success");
      setTimeout(() => {
        navigate("/");
      }, 1000);
    } catch (error) {
      console.error("Error logging out:", error);
      showToast("Logout Failed", "error", "error");
    }
  };

  const toggleSearch = () => {
    setIsOpen(!isOpen);
  };

  const handleShow = () => {
    setShow(!show);
    console.log(show);
  };

  return (
    <>
      <navbar className={`dash_navbar`}>
        <div className={` nav_btn_container`}>
          {/* <div className={` d-none d-lg-flex nav_btns`}>
            <div className={`search_btn`}>
              <input placeholder="Search here" />
              <img
                className={""}
                src="/build/img/search.svg"
                alt="zex pr wire Logo"
                width={24}
                height={24}
                priority
              />
            </div>
          </div> */}

          {/* <div className={`d-lg-none position-relative search_btn_mb`}>
            <div className={``} onClick={toggleSearch}>
              <img
                src="/build/img/search.svg"
                alt="Search Icon"
                width={24}
                height={24}
              />
            </div>
            {isOpen && (
              <div className={`search_input_mb`}>
                <input placeholder="Search here" />
              </div>
            )}
          </div> */}

          <div className={`nav_profile `}>
            <div className="admin_nav_btns">
              <button className={`navbar_btn`}>
                <img
                  className={"nav_bell_img"}
                  src="/build/bell.svg"
                  alt="bell"
                  width={20}
                  height={20}
                />
              </button>
              <button className={`navbar_btn`}>
                <img
                  className={"nav_msg_img"}
                  src="/build/message.svg"
                  alt="bell"
                  width={20}
                  height={20}
                />
              </button>
              <button className={`navbar_btn`} onClick={handleLogout}>
                <img
                  className={"nav_exit_img"}
                  src="/build/exit.svg"
                  alt="bell"
                  width={20}
                  height={20}
                />
              </button>
            </div>
            {/* <div className="resposive_search d-lg-none d-flex align-item-center">
              <img
                className={""}
                src="/build/img/search.svg"
                alt="zex pr wire Logo"
                width={24}
                height={24}
                priority
              />
            </div> */}
            <Link to="/view_profile">
              <div className="d-flex gap-3">
                <div className="nav_admin_detail">
                  <h5>Henry Jr.</h5>
                  <p>Admin</p>
                </div>
                <div className="d-lg-flex gap-4 align-items-center">
                  <img
                    className={"nav_profile_img"}
                    src="/build/img/profile_img.svg"
                    alt="zex pr wire Logo"
                    width={50}
                    height={50}
                    priority
                  />
                  <Dropdown className="admin_lang d-none">
                    <Dropdown.Toggle id="dropdown-basic">Eng</Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1" active>
                        Action
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-2">
                        Another action
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-3">
                        Something else
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item href="#/action-4">
                        Separated link
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                {/* <div
                className=" d-lg-none nav-responsive "
                style={{ cursor: "pointer" }}
                onClick={() => setShowResMenu(true)}
              >
                <img
                  className={""}
                  src="/build/img/hamburger.svg"
                  alt="hamburger"
                  width={30}
                  height={30}
                  priority
                />
              </div> */}
              </div>
            </Link>
          </div>
          {/* <div className={` ${showResMenu ? "responsive_menu" : "d-none"} `}>
            <div className=" res-profile bg-white d-flex justify-content-between align-items-center mb-2  ">
              <Link to="/profile">
                <img
                  className={""}
                  src="/build/img/profile_img.svg"
                  alt="zex pr wire Logo"
                  width={50}
                  height={50}
                  priority
                />
              </Link>
              <img
                className={"pointer"}
                style={{ cursor: "pointer" }}
                src="/build/img/close-menu.svg"
                alt="hamburger"
                width={30}
                height={30}
                onClick={() => setShowResMenu(false)}
                priority
              />
            </div>
            <div className="menu_item p-3 ">Buy Credits </div>
            <div className="menu_item p-3 ">
              <Link to="/submit-pr">Submit PR</Link>
            </div>
            <div className="menu_item p-3 ">Settings</div>
            <div className="menu_item p-3 ">
              <div onClick={handleLogout}>Logout</div>
            </div>
          </div> */}
        </div>
      </navbar>
    </>
  );
}
