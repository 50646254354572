import React from "react";
import Navbar from "../../components/NavbarProfile/NavbarProfile";
import Sidebar from "../../components/SidebarNew/Sidebar";
import Footer from "../../components/Layouts/Footer";
import "./PrUserHistory.css";
import UserHistory from "../../components/Analytics/UserHistory";
import UserPrHistory from "../../components/UserManagement/UserPrHistory";

const PrUserHistory = () => {
  const activeEventKey = "18";
  const activeSide = 8;
  return (
    <>
      <div className="row gx-0 pr_user_history">
        <Sidebar activeSide={activeSide} activeEventKey={activeEventKey} />
        <div
          className={`main col d-flex flex-column`}
          style={{ overflow: "auto" }}
        >
          <Navbar />
          <div className="flex-grow-1">
            <UserPrHistory />
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default PrUserHistory;
