import React, { useEffect } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useState, useRef, useMemo } from "react";
import Instance from "../../Utils/Axios";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { showToast } from "../../Utils/index";
import Select from "react-select";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import DataTable from "react-data-table-component";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import parse from "html-react-parser";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import axios from "axios";

const PressReleaseEdit = () => {
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const { id } = useParams();
  const [userList, setUserList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [companyObj, setCompanyObj] = useState({});
  const [countryList, setCountryList] = useState([]);
  const [galleryList, setGalleryList] = useState([]);

  const [selectedImg, setSelectedImg] = useState(null);
  const [imageString, setImageString] = useState("");

  const [Username, setUsername] = useState("");
  const [historyData, setHistoryData] = useState([]);
  const [tempData, setTempData] = useState([]);
  const [accordionOpen, setAccordionOpen] = useState(false);

  const navigate = useNavigate();
  // const [dateTime, setDateTime] = useState(new Date());
  // const [startDate, setStartDate] = useState(new Date());
  const [count, setCount] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [countStatus, setCountStatus] = useState(0);
  const [butt, setButt] = useState(1);
  const [dateTime, setDateTime] = useState(null);
  const [statusData, setStatusData] = useState([]);
  const [action, setAction] = useState("off");
  const [imageData, setImageData] = useState(null);
  const [formData, setFormData] = useState({
    id: id,
    userId: "",
    packageId: "",
    title: "",
    description: "",
    showContactDetails: "",
    addNote: "",
    image: "",
    status: "",
    categoryId: 2,
    company: 3513,
    packageName: "",
    actionDate: "",
    statusDate: "",
    statusNote: "",
  });
  const [error, setError] = useState("");
  const [editorData, setEditorData] = useState(null);

  const fetchPrHistory = async () => {
    try {
      const token = localStorage.getItem("adminToken");
      const response = await Instance.post(`/siteadmin/pressrelease/history`, {
        adminToken: token,
        id: id,
      });
      // console.log("Response from user details API:", response?.data);
      setHistoryData(response?.data?.message);
      setTempData(response?.data?.message);

      setError("");
    } catch (error) {
      setError("Failed to fetch PR History details");
      // console.error("Error fetching user details for edit:", error);
    }
  };
  useEffect(() => {
    fetchPrHistory();
  }, [id]);

  const filterData = (e) => {
    let filterText = e.target.value;
    if (!filterText) {
      setTempData([...historyData]);
    } else {
      const filteredItems = historyData.filter(
        (item) =>
          (item.status &&
            item.status.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.user &&
            item.user.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.status &&
            item.status.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.createdOn &&
            item.createdOn.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.note &&
            item.note.toLowerCase().includes(filterText.toLowerCase()))
      );
      setTempData([...filteredItems]);
    }
  };
  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    console.log(data);
    setEditorData(data);
  };

  const addNewGallery = (request) => {
    // e.preventDefault();
    // console.log(
    //   "request is ==========================",
    //   JSON.stringify(request)
    // );

    // data = JSON.stringify(request);
    alert("Image Data");
    const token = localStorage.getItem("adminToken");

    Instance.post("/siteadmin/gallery/create", {
      adminToken: token,
      ...{
        name: "texting_image",
        documentUrl: request?.uploaded_image,
        userId: 10,
        // userId: userList?.userProfile?.userId,
      },
    })
      .then((response) => {
        if (response.data.resCode === "100") {
          showToast("Already a member", "error", "error");
          setError(response.data.resMessage);
          console.log("Image added failed:", response.data);
        } else {
          console.log("Image added successfully:", response.data);
          showToast("Image added successfully", "success", "success");
          setError("");
          // setTimeout(() => {
          //   navigate("/admin/company");
          // }, 2000);
        }
      })
      .catch((error) => {
        showToast("Error", "error", "error");
        setError("Failed to add Image");
        console.error("Error adding Image:", error);
      });
  };

  const handleFileChangeLogo = (data) => {
    const token = localStorage.getItem("adminToken");

    const formDataToSubmit = new FormData();
    formDataToSubmit.append("adminToken", token);
    formDataToSubmit.append("fileUpload", data);

    axios
      .post(
        "https://pradmin.webandapi.com//siteadmin/upload/encodedDocument",
        formDataToSubmit,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        if (response?.data?.resCode == "200") {
          // setFormData((prevData) => ({
          //   ...prevData,
          //   logo: response?.data?.documentId,
          // }));

        }
      })
      .catch((error) => {
        setError("Failed to fetch the data");
        console.error("Error fetching network list:", error);
      });
    // console.log(file);
  };

  const uploadAdapter = (loader) => {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const formData = new FormData();
          loader.file.then((file) => {
            formData.append("fileUpload", file);

            axios
              .post("https://pradmin.webandapi.com/siteadmin/upload/document", formData, {
                headers: {
                  "content-type": "multipart/form-data",
                },
              })
              .then((response) => {
                if (response.data.resCode === "200") {
                  resolve({
                    default: response.data.documentUrl,
                  });
                } else {
                  reject("Image upload failed");
                }
              })
              .catch((error) => {
                reject(error.message);
              });
          });
        });
      },
    };
  };

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  const fetchCompanyList = () => {
    const token = localStorage.getItem("adminToken");
    console.log("Fetched token from localStorage:", token);
    Instance.post("/siteadmin/user/company/getList", {
      adminToken: token,
      status: "active",
    })
      .then((response) => {
        setCompanyList(response?.data?.message);

        setError("");
        // console.log("check Company  data", response?.data?.message);
      })
      .catch((error) => {
        setError("Failed to fetch the  Company data");
        // console.error("Error fetching Company list:", error);
      });
  };
  useEffect(() => {
    fetchCompanyList();
  }, []);

  const fetchCountryList = () => {
    const token = localStorage.getItem("adminToken");
    console.log("Fetched token from localStorage:", token);
    Instance.post("/siteadmin/common/countryList", {
      adminToken: token,
      status: "",
    })
      .then((response) => {
        setCountryList(response?.data?.message);

        setError("");
        // console.log("check country  data", response?.data?.message);
      })

      .catch((error) => {
        setError("Failed to fetch the country data");
        console.error("Error fetching country list:", error);
      });
  };
  useEffect(() => {
    fetchCountryList();
  }, []);
  const fetchGalleryList = () => {
    const token = localStorage.getItem("adminToken");
    console.log("Fetched token from localStorage:", token);
    Instance.post("/siteadmin/gallery/getList", {
      adminToken: token,
    })
      .then((response) => {
        // setGalleryList(response?.data?.message);

        const options = [];
        // console.log("check Gallery data", response?.data?.message);

        (response?.data?.message).map((val) => {
          options.push({
            value: val.galleryId,
            label: val.name,
            url: val.documentUrl,
          });
        });

        setGalleryList(options);
      })

      .catch((error) => {
        setError("Failed to fetch the Gallery data");
        console.error("Error fetching Gallery list:", error);
      });
  };
  useEffect(() => {
    fetchGalleryList();
  }, []);

  useEffect(() => {
    const fetchEditorialDetails = async () => {
      try {
        const token = localStorage.getItem("adminToken");
        const response = await Instance.post(
          `/siteadmin/pressrelease/details`,
          {
            adminToken: token,
            id: id,
          }
        );
        // console.log(
        //   "Response from editorial details API:",
        //   response?.data?.message
        // );

        setStatusData([response?.data?.message]);
        setFormData({
          id: id,
          userId: response?.data?.message?.userId,
          packageId: response?.data?.message?.packageId,
          title: response?.data?.message?.title,
          description: response?.data?.message?.description,
          showContactDetails: response?.data?.message?.showContactDetails,
          addNote: response?.data?.message?.addNote,
          image: response?.data?.message?.image,
          status: response?.data?.message?.status,
          categoryId: response?.data?.message?.countryId,
          company: response?.data?.message?.companyId,
          packageName: response?.data?.message?.packageName,
          actionDate: response?.data?.message?.actionDate,
          statusDate: response?.data?.message?.statusDate,
          statusNote: response?.data?.message?.statusNote,
        });

        setContent(response?.data?.message?.description);
        setEditorData(response?.data?.message?.description);
        setDateTime(response?.data?.message?.actionDate);

        let arr = response?.data?.message?.imageDetails.map((obj) => {
          return obj.id;
        });
        var mainArr = [];
        galleryList.map((obj) => {
          if (arr.includes(obj.value)) {
            mainArr.push(obj);
          }
        });

        setSelectedImg(mainArr);
        // console.log("main-----------------", mainArr);

        // console.log("obj-----------------", arr);

        var companyDetail = companyList.find(
          (obj) => obj.companyId === response?.data?.message?.companyId
        );

        setCompanyObj(companyDetail || {});
        setError("");
      } catch (error) {
        setError("Failed to fetch editorial details");
        console.error("Error fetching editorial details for edit:", error);
      }
    };

    fetchEditorialDetails();
  }, [id, companyList]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleComapnyChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    var companyDetail = companyList.find(
      (obj) => obj.companyId === e.target.value
    );

    setCompanyObj(companyDetail);
  };

  const handleCountryChange = (e) => {
    setCompanyObj({
      ...companyObj,
      [e.target.name]: e.target.value,
    });

  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const token = localStorage.getItem("adminToken");
        const response = await Instance.post(`/siteadmin/user/userDetails`, {
          adminToken: token,
          userId: id,
        });
        // console.log("Response from user details API:", response?.data);
        setUserList(response?.data);

        setError("");
      } catch (error) {
        setError("Failed to fetch user details");
        // console.error("Error fetching user details for edit:", error);
      }
    };

    fetchUserDetails();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (showStatus == false) {
      setShowStatus(true);
      return;
    } else {
      formData.description = editorData;
      formData.image = imageString;

      if (countStatus === 1) {
        formData.status = "PUBLISHED";
      } else if (countStatus === 2) {
        formData.status = "PENDING";
      } else if (countStatus === 3) {
        formData.status = "REJECT";
      } else {
        formData.status = "OPEN";
      }

      try {
        const token = localStorage.getItem("adminToken");
        const response = await Instance.post(`/siteadmin/pressrelease/update`, {
          id: id,
          userId: formData.userId,
          packageId: formData.packageId,
          title: formData.title,
          description: formData.description,
          showContactDetails: formData.showContactDetails,
          addNote: formData.addNote,
          image: formData.image,
          status: formData.status,
          categoryId: parseInt(formData.categoryId),
          company: parseInt(formData.company),
          actionDate: formData.actionDate,
          statusDate: formData.statusDate,
          statusNote: formData.statusNote,

          adminToken: token,
        });
        if (response.data.resCode === "200") {
          showToast("Updated Succesfully!", "success", "success");
          setTimeout(() => {
            navigate("/admin/editorial");
          }, 1000);
        } else {
          setError(response.data.resMessage);
        }
      } catch (error) {
        setError("Failed to update Editorial details");
        console.error("Error updating Editorial details:", error);
      }
    }
  };

  if (!formData) {
    return <p>Loading</p>;
  }

  const handleMultiSelect = (obj) => {
    setSelectedImg(obj);
    const tempArr = obj.map((ar) => ar.value);
    var str = "";
    tempArr.map((val, indx) => {
      if (indx <= tempArr.length - 2) {
        str = str + val + "|";
      }
      if (indx == tempArr.length - 1) {
        str = str + val;
      }
    });

    setImageString(str);
  };

  const config = {
    Placeholder: "start typing ..",
  };

  const columns = [
    {
      name: "PR Status",
      selector: (row) => row.status,
      sortable: true,
      wrap: true,
    },
    {
      name: "Status Note",
      selector: (row) => row.note,
      sortable: true,
      wrap: true,
    },
    {
      name: "Status Date",
      selector: (row) => row.createdOn,
      sortable: true,
      wrap: true,
    },
    {
      name: "who's did action",
      selector: (row) => row.user,
      sortable: true,
      wrap: true,
    },
  ];

  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      setAccordionOpen(!accordionOpen)
    );

    return (
      <button
        type="button"
        className="admin_filter_btn"
        // style={{ backgroundColor: "pink" }}
        onClick={decoratedOnClick}
      >
        {children}
        {accordionOpen ? (
          <img
            src="/build/img/Icons/down_vector.svg"
            alt="zex pr wire Logo"
            width={16}
            height={16}
          />
        ) : (
          <img
            src="/build/img/Icons/down_vector.svg"
            alt="zex pr wire Logo"
            width={16}
            height={16}
            className="filter_up_arrow"
          />
        )}
      </button>
    );
  }

  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(historyData[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  function downloadCSV(array) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = "export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  // console.log("selected image is ================", selectedOption);
  const copyUrlToClipboard = (url_value) => {
    const url = url_value;
    navigator.clipboard
      .writeText(url)
      .then(() => {
        showToast("copied url !", "success", "success", `${url}`);
      })
      .catch((err) => {
        showToast("Failed to copy URL !", "error", "error");
      });
  };
  const handleDateTimeChange = (date) => {
    setDateTime(date);
    console.log(date);
  };

  return (
    <>
      {count === false ? (
        <div className="admin_editorial_layout">
          <div className="page_heading">
            <h4>Edit Press Release</h4>
            <Link to="/admin/editorial">
              <span style={{ cursor: "pointer" }}>
                <div className="backbutton"></div>
              </span>
            </Link>
          </div>
          <div className="add_new_editorial">
            <Form onSubmit={handleSubmit}>
              <Row
                className="  mb-4  rounded flex align-items-center"
                style={{
                  backgroundColor: "#595fde",
                  color: "white",
                  fontSize: "1.25rem",
                }}
              >
                <Col className="col-xl-4 col-lg-4 col-md-4 col-sm-12 text-center pt-2 ">
                  <p>Username: {userList?.userProfile?.firstName}</p>
                </Col>

                <Col className="col-xl-4 col-lg-4 col-md-4 col-sm-12 text-center pt-2 ">
                  <p>{userList?.userProfile?.companyName}</p>
                </Col>
                <Col className="col-xl-4 col-lg-4 col-md-4 col-sm-12 text-center pt-2 ">
                  <p>Status: {formData.status}</p>
                </Col>
              </Row>

              <Row>
                <Col className="custom_col_add_editorial mb-4">
                  <Form.Label>Title of the release</Form.Label>
                  <Form.Control
                    type="text"
                    name="title"
                    value={formData.title}
                    onChange={handleChange}
                    // placeholder="Badr Jafar awarded Honorary Fellowship by London Business School"
                    required
                  />
                </Col>
              </Row>

              <Row>
                <Col className="custom_col_add_editorial mb-4">
                  <Form.Label>Press Release body</Form.Label>

                  <div className="CKEditorComponent">
                    <CKEditor
                      editor={ClassicEditor}
                      data={editorData}
                      config={{
                        extraPlugins: [uploadPlugin],
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setEditorData(data);
                      }}
                    />
                    {/* <div>
                    <h3>Content</h3>
                    <div dangerouslySetInnerHTML={{ __html: editorData }} />
                  </div> */}
                  </div>

                </Col>
              </Row>
              <Row>
                <Col className="col-12 custom_col_add_editorial mb-4">
                  <Form.Label>Insert Image & Copy Image URL</Form.Label>

                  {/* <Form.Control
                  as="select"
                  name="image"
                  value={formData.image}
                  onChange={handleChange}
                >
                  {galleryList.map((val) => {
                    return (
                      <>
                        <option value={val.name}>{val.name}</option>
                      </>
                    );
                  })}
                </Form.Control> */}
                  <Select
                    isSearchable
                    value={selectedImg}
                    onChange={(e) => handleMultiSelect(e)}
                    options={galleryList}
                    isMulti
                  />
                </Col>
              </Row>

              <Row>
                <Col className="col-12">
                  {/* <Table striped bordered hover></Table> */}
                  {selectedImg ? (
                    <Table responsive bordered>
                      <thead>
                        <tr>
                          <th scope="col">Image Name</th>
                          <th scope="col">Image</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedImg.map((val) => {
                          return (
                            <>
                              <tr>
                                <td scope="row">{val.label}</td>
                                <td>
                                  <img
                                    src={val.url}
                                    width="100"
                                    height="100"
                                    alt="img"
                                  />
                                </td>
                              </tr>
                            </>
                          );
                        })}
                        {/* <tr>
                      <td scope="row">Badr.jpeg</td>
                      <td>
                        <img
                          src="/build/Image04.png"
                          width="100"
                          height="100"
                          alt="img"
                        />
                      </td>
                    </tr> */}
                      </tbody>
                    </Table>
                  ) : null}
                </Col>
              </Row>

              <Row>
                {selectedImg ? (
                  <Col className="col-12 custom_col_add_editorial my-4">
                    <Table responsive>
                      <thead>
                        <tr>
                          <th scope="col">Image Name</th>
                          <th scope="col">Image</th>
                          <th scope="col">URL</th>
                          <th scope="col"> Size</th>
                          <th scope="col"> Copy</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedImg?.map((val) => {
                          return (
                            <tr>
                              <td scope="row">{val.label}</td>
                              <td>
                                <img
                                  // src="/build/Image05.png"
                                  src={val.url}
                                  width="100"
                                  height="100"
                                  alt="img"
                                />
                              </td>
                              <td>{val.url}</td>
                              <td>136.96 KB</td>
                              <td>
                                {" "}
                                <button
                                  className="btn btn-primary"
                                  onClick={() => copyUrlToClipboard(val.url)}
                                  type="button"
                                >
                                  Copy URL
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Col>
                ) : null}
                <Col className="custom_col_add_editorial  mb-4">
                  <Form.Group>
                    <Form.Label>Package</Form.Label>
                    <Form.Control
                      type="text"
                      name="package"
                      value={formData.packageName}
                    // placeholder="WL - Yahoo Premium with AP NEWS, Etrendystock & Digital Journal"
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs="12" className="custom_col_add_editorial mb-4">
                  <Form.Label>Select Company</Form.Label>
                  <Form.Control
                    as="select"
                    name="company"
                    value={formData.company}
                    onChange={handleComapnyChange}
                  >
                    <option value="">Select Company</option>
                    {companyList.map((val) => {
                      return (
                        <>
                          <option value={val.companyId}>{val.name}</option>
                        </>
                      );
                    })}
                  </Form.Control>
                </Col>
              </Row>
              <Row
                className="text-center pt-2 "
                style={{ color: "#2e61f2", fontWeight: "700" }}
              >
                <Col>OR</Col>
              </Row>
              <Row className="pb-2" style={{ fontSize: "40px" }}>
                <Col>
                  <Form.Label>Sender Details / Company Details </Form.Label>
                </Col>
              </Row>
              <Row>
                <Col className="custom_col_add_editorial mb-4">
                  <Form.Label>Contact Person Name :-</Form.Label>
                  <Form.Control
                    type="text"
                    name="contactPerson"
                    value={companyObj.contactPerson}
                    // onChange={handleChange}
                    // placeholder="john Smith"
                    required
                  ></Form.Control>
                </Col>
                <Col className="custom_col_add_editorial mb-4">
                  <Form.Label>Company Name :-</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={companyObj.name}
                    // onChange={handleChange}

                    placeholder="ZXPREWIRE*"
                    required
                  ></Form.Control>
                </Col>
              </Row>
              <Row>
                <Col className="custom_col_add_editorial mb-4">
                  <Form.Label>Email :-</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={companyObj.email}
                    onChange={handleChange}
                    placeholder="info@zexprwire.com*"
                    required
                  ></Form.Control>
                </Col>
                <Col className="custom_col_add_editorial mb-4">
                  <Form.Label>Phone :-</Form.Label>
                  <Form.Control
                    type="number"
                    name="mobile"
                    value={companyObj.mobile}
                    // onChange={handleChange}
                    placeholder="1-545-XXXXX"
                    required
                  />
                </Col>
              </Row>
              <Row>
                <Col className="custom_col_add_editorial mb-4">
                  <Form.Label>Company Website :-</Form.Label>
                  <Form.Control
                    type="text"
                    name="website"
                    value={companyObj.website}
                    // onChange={handleChange}

                    placeholder="http://www.google.com"
                    required
                  ></Form.Control>
                </Col>
                <Col className="custom_col_add_editorial mb-4">
                  <Form.Label>Company Address :-</Form.Label>
                  <Form.Control
                    type="text"
                    name="address"
                    value={companyObj.address}
                    // onChange={handleChange}

                    placeholder="1A-LANE.."
                    required
                  ></Form.Control>
                </Col>
              </Row>
              <Row>
                <Col className="custom_col_add_editorial mb-4">
                  <Form.Label> State</Form.Label>
                  <Form.Control
                    type="text"
                    name="state"
                    value={companyObj.state}
                    onChange={handleCountryChange}
                    placeholder="Sharjah"
                    required
                  ></Form.Control>
                </Col>
                <Col className="custom_col_add_editorial mb-4">
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    type="text"
                    name="city"
                    value={companyObj.city}
                    onChange={handleCountryChange}
                    placeholder="Sharjah"
                    required
                  ></Form.Control>
                </Col>
              </Row>
              <Row>
                <Col className="col-6 custom_col_add_editorial mb-4">
                  <Form.Label>Country</Form.Label>
                  <Form.Control
                    as="select"
                    name="country"
                    value={companyObj.country}
                    onChange={(e) => handleCountryChange(e)}
                  >
                    {countryList.map((val) => {
                      return (
                        <>
                          <option value={val.countryName}>
                            {val.countryName}
                          </option>
                        </>
                      );
                    })}
                  </Form.Control>
                </Col>
              </Row>
              <Row>
                <Col className="col-6 custom_col_add_editorial mb-4">
                  <Form.Label>Select date & time</Form.Label>*{" "}
                  <Form.Control
                    type="datetime-local"
                    id="datetime"
                    name="actionDate"
                    value={formData.actionDate}
                    onChange={handleChange}
                    // onChange={(e) => console.log(e.target.value)}
                    required
                  ></Form.Control>
                  {/* <br></br>
                  <DatePicker
                    format="MM/DD/YYYY HH:mm:ss"
                    value={dateTime}
                    name="actionDate"
                    plugins={[
                      <TimePicker
                        position="bottom"
                        hStep={2}
                        mStep={3}
                        sStep={4}
                      />,
                    ]}
                    onChange={handleDateTimeChange}
                    className="rmdp-container"
                  /> */}
                </Col>
                <Col className="custom_col_add_editorial mb-4">
                  <Form.Label>
                    Do you want to show contact details on Press Release?
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="showContactDetails"
                    value={formData.showContactDetails}
                    onChange={handleChange}
                  >
                    <option value="Do you want to show contact details on Press Release?">
                      {" "}
                      Do you want to show contact details on Press Release?
                    </option>
                    <option value="YES">Yes</option>
                    <option value="NO">No</option>
                  </Form.Control>
                  <p
                    className="py-2"
                    style={{
                      fontSize: "15px",
                      fontWeight: "500",
                      color: "green",
                      fontFamily: "'Nunito Sans', sans-serif",
                    }}
                  >
                    [ This means if you select "NO" then your contact details
                    will not be visible in our newsroom, but it will be
                    syndicated and visible on other news Platforms ]
                  </p>
                </Col>
              </Row>

              <Row className="mb-4">
                <Col className="col-12">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>See note written by customer.</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      name="addNote"
                      value={formData.addNote}
                    // onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col className="col-12">
                  <Form.Check // prettier-ignore
                    type="radio"
                    id={`action`}
                    label={`Action Required`}
                    onChange={(e) => console.log(e.target.value)}
                  />
                </Col>
              </Row>

              {showStatus === true ? (
                <Row>
                  <Col className="col-12">
                    <Card>
                      <Card.Body>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          <Form.Label style={{ color: "blue" }}>
                            Note for status
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={2}
                            name="statusNote"
                            value={formData.statusNote}
                            onChange={handleChange}
                            placeholder="Enter Note"
                            required
                          />
                        </Form.Group>
                        <h6 style={{ color: "red" }} className="mt-0cd ">
                          * Please enter note
                        </h6>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          <Form.Label style={{ color: "blue" }}>
                            Date for status
                          </Form.Label>
                          <Form.Control
                            type="datetime-local"
                            id="datetime"
                            name="statusDate"
                            value={formData.statusDate}
                            onChange={handleChange}
                            // onChange={(e) => console.log(e.target.value)}
                            required
                          ></Form.Control>
                          {/* <br></br>
                          <DatePicker
                            name="statusDate"
                            value={formData.statusDate}
                            // onChange={handleChange}
                            // onChange={(e) => console.log(e)}
                            format="MM/DD/YYYY HH:mm:ss"
                            plugins={[
                              <TimePicker
                                position="bottom"
                                hStep={2}
                                mStep={3}
                                sStep={4}
                              />,
                            ]}
                            className="rmdp-container container"
                          /> */}
                        </Form.Group>
                        <h6 style={{ color: "red" }} className="mt-0">
                          * Please enter date & time
                        </h6>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              ) : null}

              <Row className="my-5 text-center">
                {countStatus === 1 || countStatus === 0 ? (
                  <Col className=" col-xl-3 col-lg-6 col-md-6 col-sm-6">
                    <button
                      className="add_editorial_submit_btn"
                      style={{ width: "200px" }}
                      // onClick={() => handleStatus(1)}
                      // type={countStatus === 1 ? "submit" : "button"}
                      onClick={() => setCountStatus(1)}
                      type="submit"
                    >
                      Publish
                    </button>
                  </Col>
                ) : null}

                {countStatus === 2 || countStatus === 0 ? (
                  <Col className="col-xl-3 col-lg-6 col-md-6 col-sm-6 ">
                    <button
                      className="add_editorial_submit_btn"
                      style={{ width: "200px" }}
                      // onClick={() => handleStatus(2)}
                      // type={countStatus === 2 ? "submit" : "button"}
                      onClick={() => setCountStatus(2)}
                      type="submit"
                    // type="button"
                    >
                      Save Pending
                    </button>
                  </Col>
                ) : null}

                <Col className="col-xl-3 col-lg-6 col-md-6 col-sm-6 ">
                  <button
                    className="add_editorial_submit_btn"
                    style={{ width: "200px" }}
                    // onClick={() => handleStatus(3)}
                    // type={countStatus === 3 ? "submit" : "button"}
                    // type="button"
                    onClick={() => setCountStatus(3)}
                    type="submit"
                  >
                    Reject
                  </button>
                </Col>

                <Col className="col-xl-3 col-lg-3 col-md-6 col-sm-6 ">
                  <button
                    className="add_editorial_submit_btn"
                    // type="button"
                    type="button"
                    style={{ width: "200px" }}
                    onClick={() => setCount(!count)}
                  >
                    Preview
                  </button>
                </Col>
              </Row>

              <Card className="shadow ">
                <Card.Body>
                  <Card.Title
                    className="p-2 rounded "
                    style={{
                      backgroundColor: "#595fde",
                      color: "white",
                      fontSize: "1.25rem",
                    }}
                  >
                    Press Release History :- Badr Jafar awarded Honorary Fe...
                  </Card.Title>

                  <Card.Header className="d-md-flex justify-content-between align-items-center">
                    <div className="editorial_search">
                      <label>
                        Search:
                        <input
                          type="search"
                          placeholder=""
                          aria-controls="datatable"
                          onChange={filterData}
                        ></input>
                      </label>
                    </div>
                    <div className="d-md-flex">
                      <button
                        onClick={(e) => downloadCSV(historyData)}
                        className="btn btn-export mx-3"
                      >
                        <img
                          src="/build/img/Icons/export.svg"
                          alt="zex pr wire Logo"
                          width={25}
                          height={25}
                        />
                      </button>
                    </div>
                  </Card.Header>

                  <DataTable
                    className="data-table-wrapper"
                    columns={columns}
                    data={tempData}
                    pagination
                    defaultSortFieldId={1}
                    responsive={true}
                    defaultSortAsc={true}
                  />
                </Card.Body>
              </Card>
            </Form>
            {/* {error && <p className="error_message">{error}</p>} */}
          </div>
        </div>
      ) : (
        <div className="admin_editorial_layout">
          <div className="page_heading">
            <h4>Preview Press Release</h4>
            <Link onClick={() => setCount(!count)}>
              <span style={{ cursor: "pointer" }}>
                <div className="backbutton"></div>
              </span>
            </Link>
          </div>
          {/* formData.userId = selectedUser.value; formData.description =
      editorData; formData.company = company; formData.image =
      selectedOption.value; formData.categoryId = categoryString; */}
          <div className="add_new_editorial">
            <Card.Text className="mb-4">
              <div>
                <div>
                  <div style={{ fontSize: "20px", fontWeight: "600" }}>
                    {" "}
                    {formData?.title}{" "}
                  </div>
                </div>
              </div>
            </Card.Text>
            <hr />

            <Card.Text className="mb-4">
              <div>
                <div>
                  <div style={{ fontSize: "20px" }}> {parse(editorData)} </div>
                </div>
              </div>
            </Card.Text>

            <Card style={{ backgroundColor: "#80808014" }}>
              <Card.Body>
                <Card.Text>
                  <div>
                    <div>
                      <div style={{ fontSize: "16px", fontWeight: "700" }}>
                        {" "}
                        Media Contact
                      </div>
                    </div>
                  </div>
                </Card.Text>
                <Card.Text>
                  <div>
                    <div>
                      <div style={{ fontSize: "16px" }}>
                        <span style={{ fontWeight: "700" }}>
                          Company Name :-
                        </span>
                        <span> {companyObj.name}</span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <div style={{ fontSize: "16px" }}>
                        {" "}
                        <span style={{ fontWeight: "700" }}> Email Id :- </span>
                        <span>{companyObj.email}</span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <div style={{ fontSize: "16px" }}>
                        {" "}
                        <span style={{ fontWeight: "700" }}>
                          Company Website :-
                        </span>
                        <span>{companyObj.website}</span>
                      </div>
                    </div>
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </div>
      )}
    </>
  );
};

export default PressReleaseEdit;
