"use client";
import { Table, Pagination, Dropdown, Modal, Button } from "react-bootstrap";
import { useState } from "react";
import "./PRmanager.css";

export default function PRlayout({ onShowMyProfile }) {
  const [show, setShow] = useState(false);
  const [showViewDetailsModal, setShowViewDetailsModal] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCloseViewDetailsModal = () => setShowViewDetailsModal(false);
  const handleShowViewDetailsModal = () => setShowViewDetailsModal(true);
  return (
    <>
      <div className="pr_menu">
        <div className="all_pr">
          <img
            className={"pr_img mb-0"}
            src="/Newspaper.svg"
            alt="zex pr wire Logo"
            width={48}
            height={48}
            priority
          />
          <p className="pr_num ">10</p>
          <p className="pr_text mb-0">All PR</p>
        </div>
        <div className="published_pr">
          <img
            className={"pr_img mb-0"}
            src="/Package.svg"
            alt="zex pr wire Logo"
            width={48}
            height={48}
            priority
          />
          <p className="pr_num ">5</p>
          <p className="pr_text mb-0">Published</p>
        </div>
        <div className="pending_pr">
          <img
            className={"pr_img mb-0"}
            src="/Pending.svg"
            alt="zex pr wire Logo"
            width={48}
            height={48}
            priority
          />
          <p className="pr_num ">2</p>
          <p className="pr_text mb-0">Pending</p>
        </div>
        <div className="drafts_pr">
          <img
            className={"pr_img mb-0"}
            src="/Drafts.svg"
            alt="zex pr wire Logo"
            width={48}
            height={48}
            priority
          />
          <p className="pr_num ">1</p>
          <p className="pr_text mb-0">Drafts</p>
        </div>
        <div className="rejected_pr">
          <img
            className={"pr_img mb-0"}
            src="/Rejected.svg"
            alt="zex pr wire Logo"
            width={48}
            height={48}
            priority
          />
          <p className="pr_num ">2</p>
          <p className="pr_text mb-0">Rejected</p>
        </div>
        <div className="reports_pr">
          <img
            className={"pr_img mb-0"}
            src="/Reports.svg"
            alt="zex pr wire Logo"
            width={48}
            height={48}
            priority
          />
          <p className="pr_num ">0</p>
          <p className="pr_text mb-0">Reports</p>
        </div>
      </div>
      <div className="my_press">
        <h4 className="my_press_text">My Press Releases</h4>
        <div className="my_press_btns">
          <button className="my_view_btn">View all</button>
          <button className="my_add_btn">
            <img
              className={"pr_add_img mb-0"}
              src="/build/img/Icons/Vector.png"
              alt="zex pr wire Logo"
              width={13}
              height={13}
              priority
            />
            Add new
          </button>
        </div>
      </div>
      <div className="my_pr_tables">
        <Table hover className="mb-0">
          <thead className="pr_tables_head">
            <tr>
              <th>
                PACKAGE NAME
                <img
                  className={"pr_add_img mb-0"}
                  src="/build/img/down_arrow.svg"
                  alt="zex pr wire Logo"
                  width={26}
                  height={18}
                  priority
                />
              </th>
              <th>
                TITLE
                <img
                  className={"pr_add_img mb-0"}
                  src="/build/img/down_arrow.svg"
                  alt="zex pr wire Logo"
                  width={26}
                  height={18}
                  priority
                />
              </th>
              <th>
                COMPANY NAME
                <img
                  className={"pr_add_img mb-0"}
                  src="/build/img/down_arrow.svg"
                  alt="zex pr wire Logo"
                  width={26}
                  height={18}
                  priority
                />
              </th>
              <th>
                STATUS
                <img
                  className={"pr_add_img mb-0"}
                  src="/build/img/down_arrow.svg"
                  alt="zex pr wire Logo"
                  width={26}
                  height={18}
                  priority
                />
              </th>
              <th>
                ACTIONS
                <img
                  className={"pr_add_img mb-0"}
                  src="/build/img/down_arrow.svg"
                  alt="zex pr wire Logo"
                  width={26}
                  height={18}
                  priority
                />
              </th>
              <th>
                NOTE
                <img
                  className={"pr_add_img mb-0"}
                  src="/build/img/down_arrow.svg"
                  alt="zex pr wire Logo"
                  width={26}
                  height={18}
                  priority
                />
              </th>
              <th>
                REPORT
                <img
                  className={"pr_add_img mb-0"}
                  src="/build/img/down_arrow.svg"
                  alt="zex pr wire Logo"
                  width={26}
                  height={18}
                  priority
                />
              </th>
            </tr>
          </thead>
          <tbody className="pr_tables_body">
            <tr>
              <td>Etrendy</td>
              <td>Darlene Robertson</td>
              <td>Acme Corporation</td>
              <td>
                <button className="draft_btn">Draft</button>
              </td>
              <td>
                <img
                  className={"pr_add_img mb-0"}
                  src="/build/img/Icons/Edit.svg"
                  alt="zex pr wire Logo"
                  width={24}
                  height={24}
                  priority
                />
                <img
                  className={"pr_add_img mb-0"}
                  src="/build/img/Icons/Delete.svg"
                  alt="zex pr wire Logo"
                  width={24}
                  height={24}
                  priority
                />
              </td>
              <td> </td>
              <td>
                <button className="in_process_btn">In Process</button>
              </td>
            </tr>
            <tr>
              <td>Marketwatch</td>
              <td>Cody Fisher</td>
              <td>Wayne Enterprises</td>
              <td>
                <button className="pending_btn">Pending</button>
              </td>
              <td>
              <img
                  className={"pr_add_img mb-0"}
                  src="/build/img/Icons/Edit.svg"
                  alt="zex pr wire Logo"
                  width={24}
                  height={24}
                  priority
                />
                <img
                  className={"pr_add_img mb-0"}
                  src="/build/img/Icons/Delete.svg"
                  alt="zex pr wire Logo"
                  width={24}
                  height={24}
                  priority
                />
              </td>
              <td className="pr_note" onClick={handleShow}>
                Action Required
              </td>
              <Modal show={show} onHide={handleClose} centered>
                <Modal.Body>
                  <div>
                    <h4 className="pr_modal_head">Action Required</h4>
                  </div>
                  <div>
                    <h6 className="pr_modal_note">Note</h6>
                    <p className="pr_modal_content">
                      In most states, the legal limit in blood alcohol to not be
                      considered DUI is 500 to 1,000 mg/L. Therefore, this is
                      way below a level considered to be intoxication.
                    </p>
                    <div className="pr_modal_date">
                      <span>12-06-2022</span>
                      <span>12.30 am</span>
                    </div>
                  </div>
                  <div className="pr_modal_btn">
                    <Button
                      className="modal_cancel_btn"
                      variant="secondary"
                      onClick={handleClose}
                    >
                      Close
                    </Button>
                    <Button
                      className="modal_save_btn"
                      variant="primary"
                      onClick={onShowMyProfile}
                    >
                      Make Changes
                    </Button>
                  </div>
                </Modal.Body>
              </Modal>
              <td>
                <button className="pr_download_btn">Download</button>
              </td>
            </tr>
            <tr>
              <td>Google</td>
              <td>Guy Hawkins</td>
              <td>Sterling Cooper</td>
              <td>
                <button className="rejected_btn">Rejected</button>
              </td>
              <td>
              <img
                  className={"pr_add_img mb-0"}
                  src="/build/img/Icons/Edit.svg"
                  alt="zex pr wire Logo"
                  width={24}
                  height={24}
                  priority
                />
                <img
                  className={"pr_add_img mb-0"}
                  src="/build/img/Icons/Delete.svg"
                  alt="zex pr wire Logo"
                  width={24}
                  height={24}
                  priority
                />
              </td>
              <td className="pr_note" onClick={handleShowViewDetailsModal}>
                View Details
              </td>
              <Modal
                show={showViewDetailsModal}
                onHide={handleCloseViewDetailsModal}
                centered
              >
                <Modal.Body>
                  <div>
                    <h4 className="pr_modal_head">View Details</h4>
                  </div>
                  <div>
                    <h6 className="pr_modal_note">Reason for Rejection</h6>
                    <p className="pr_modal_content">
                      In most states, the legal limit in blood alcohol to not be
                      considered DUI is 500 to 1,000 mg/L. Therefore, this is
                      way below a level considered to be intoxication.
                    </p>
                    <div className="pr_modal_date">
                      <span>12-06-2022</span>
                      <span>12.30 am</span>
                    </div>
                  </div>
                  <div className="pr_modal_btn">
                    <Button
                      className="modal_cancel_btn"
                      variant="secondary"
                      onClick={handleCloseViewDetailsModal}
                    >
                      Close
                    </Button>
                  </div>
                </Modal.Body>
              </Modal>
              <td>
                <button className="pr_download_btn">Download</button>
              </td>
            </tr>
            <tr>
              <td>Yahoo</td>
              <td>Guy Hawkins</td>
              <td>Sterling Cooper</td>
              <td>
                <button className="published_btn">Published</button>
              </td>
              <td>
              <img
                  className={"pr_add_img mb-0"}
                  src="/build/img/Icons/Edit.svg"
                  alt="zex pr wire Logo"
                  width={24}
                  height={24}
                  priority
                />
                <img
                  className={"pr_add_img mb-0"}
                  src="/build/img/Icons/Delete.svg"
                  alt="zex pr wire Logo"
                  width={24}
                  height={24}
                  priority
                />
              </td>
              <td className="pr_note"> </td>
              <td>
                <button className="pr_download_btn">Download</button>
              </td>
            </tr>
          </tbody>
          <caption>
            <div className="pr_rows_table">
              <div className="pr_dropdown">
                <h4 className="mb-0">Rows per page</h4>
                <Dropdown className="pr_rows_dropdown">
                  <Dropdown.Toggle variant="light" id="dropdown-basic">
                    10
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">1</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">2</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">3</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">4</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">5</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="pr_pagination">
                <Pagination className="mb-0">
                  <Pagination.Item>{1}</Pagination.Item>
                  <Pagination.Item>{2}</Pagination.Item>
                  <Pagination.Item>{3}</Pagination.Item>
                  <Pagination.Ellipsis />
                  <Pagination.Item>{10}</Pagination.Item>
                  <Pagination.Item>{11}</Pagination.Item>
                  <Pagination.Item>{12}</Pagination.Item>
                </Pagination>
              </div>
              <div className="pr_next_page">
                <h4 className="mb-0">Go to page</h4>
                <img
                  className={"pr_next_img mb-0"}
                  src="/build/img/Select.svg"
                  alt="zex pr wire Logo"
                  width={76}
                  height={32}
                  priority
                />
              </div>
            </div>
          </caption>
        </Table>
      </div>
    </>
  );
}
