import React from "react";
import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link, useParams, useNavigate } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Instance from "../../Utils/Axios";
import { showToast } from "../../Utils/index";

const EditUser = () => {
  const [show, setShow] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [tempData, setTempData] = useState([]);
  const [user, setUser] = useState({
    userId: "",
    firstName: "",
    lastName: "",
    mobileNumber: "",
    mobile: "",
    email: "",
    country: "",
    isdCode: "",
    address: "",
    pwd: "",
    status: "",
  });
  const [error, setError] = useState("");
  const { email } = useParams();
  const { userId } = useParams();
  const navigate = useNavigate();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (email) {
      const fetchUserDetails = async () => {
        try {
          const token = localStorage.getItem("adminToken");
          const response = await Instance.post(`/siteadmin/user/userDetails`, {
            adminToken: token,
            email: email,
          });

          console.log(
            "Response from userDetail API:",
            response.data.userProfile
          );
          if (response.data.userProfile) {
            const { userProfile } = response.data;
            setUser({
              userId: userProfile.userId,
              firstName: userProfile.firstName,
              lastName: userProfile.lastName,
              email: userProfile.email,
              mobile: userProfile.mobile,
              country: userProfile.country,
              isdCode: userProfile.isdCode,
              pwd: userProfile.pwd || "",
              address: userProfile.address || "",
              last_login_date: userProfile.last_login_date,
              userType: userProfile.userType,
              mobileNumber: userProfile.userType,
              status: userProfile.status,
              doj: userProfile.doj,
              currency: userProfile.currency,
              dob: userProfile.dob,
              imageUrl: userProfile.imageUrl,
              parentId: userProfile.parentId,
              status: userProfile.status,
            });
            setError("");
          } else {
            setError("User details not found");
          }
        } catch (error) {
          setError("Failed to fetch user details");
          console.error("Error fetching user details for edit:", error);
        }
      };

      fetchUserDetails();
    } else {
      setError("No email provided");
    }
  }, [email]);

  const fetchCountryList = () => {
    const token = localStorage.getItem("adminToken");
    console.log("Fetched token from localStorage:", token);
    Instance.post("/siteadmin/common/countryList", {
      adminToken: token,
      countryName: "",
      countryCode: "",
      isdCode: "",
      currency: "",
    })
      .then((response) => {
        setCountryList(response?.data?.message || []);
        setTempData(response?.data?.message || []);
        setError("");
        console.log("Country list data:", response?.data?.message);
      })
      .catch((error) => {
        setError("Failed to fetch the data");
        console.error("Error fetching country list:", error);
      });
  };

  useEffect(() => {
    fetchCountryList();
  }, []);

  const handleChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const updateUser = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("adminToken");

      const formDataToSubmit = {
        adminToken: token,
        userId: user.userId,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        pwd: user.pwd,
        address: user.address,
        country: user.country,
        mobile: user.mobile,
        isdCode: user.isdCode,
        status: user.status,
        type: user.type,
        currency: user.currency,
        imageUrl: user.imageUrl,
        parentId: user.parentId,
        dateOfJoining: user.dateOfJoining,
        dateOfBirth: user.dateOfBirth,
        status: user.status,
      };

      const response = await Instance.post(
        "/siteadmin/user/updateUser",
        formDataToSubmit
      );

      console.log("Response from update API:", response.data);

      if (response.data.resCode === "200") {
        showToast("User updated successfully", "success", "success");
        setTimeout(() => {
          navigate("/admin/user");
        }, 2000);
        setError("");
      } else {
        showToast("Error updating user", "error", "error");
        setError(response.data.resMessage || "Error updating user");
        console.log("User update failed:", response.data);
      }
    } catch (error) {
      showToast("Error", "error", "error");
      setError("Failed to update user");
      console.error("Error updating user:", error);
    }
  };

  return (
    <>
      <div className="user_manage_layout">
        <div className="page_heading page_head">
          <h4 style={{ marginTop: "-20px" }}> Edit User </h4>
          <Link to={"/admin/user"}>
            <span style={{ cursor: "pointer" }}>
              <div className="backbutton" style={{ marginTop: "-20px" }}></div>
            </span>
          </Link>
        </div>

        <div className="add_new_staff">
          <Form onSubmit={updateUser}>
            <Row>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  name="firstName"
                  value={user.firstName}
                  onChange={handleChange}
                />
              </Col>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  name="lastName"
                  value={user.lastName}
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <Row>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={user.email}
                  onChange={handleChange}
                />
              </Col>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  name="pwd"
                  value={user.pwd}
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <Row>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Country code</Form.Label>
                {/* <span>
                  <img src="/build/plus.svg" height={18} width={18} alt="icon" onClick={handleShow}
                    style={{marginLeft: "3px" }}></img>
                </span> */}
                <Form.Select
                  type="text"
                  name="country"
                  value={user.country}
                  onChange={handleChange}
                >
                  <option value="" selected hidden>
                    Please select
                  </option>
                  {countryList.map((country) => (
                    <option key={country.country} value={country.country}>
                      {country.isdCode}
                    </option>
                  ))}
                </Form.Select>
              </Col>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  type="number"
                  name="mobile"
                  value={user.mobile}
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <Row>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  type="text"
                  name="address"
                  value={user.address}
                  onChange={handleChange}
                />
              </Col>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Country</Form.Label>
                <Form.Select
                  type="text"
                  name="country"
                  value={user.country}
                  onChange={handleChange}
                >
                  <option value="" selected hidden>
                    Please select
                  </option>
                  {countryList.map((country) => (
                    <option key={country.isdCode} value={country.isdCode}>
                      {country.countryName}
                    </option>
                  ))}
                </Form.Select>
              </Col>
            </Row>
            <Row>
              <Col xs="6" className="custom_col_add_staff mb-4">
                <Form.Label>Status</Form.Label>
                <Form.Select
                  name="status"
                  value={user.status}
                  onChange={handleChange}
                >
                  <option value="ACTIVE">Active</option>
                  <option value="PENDING">Pending</option>
                </Form.Select>
              </Col>
            </Row>

            <Row>
              <h4>Company Info (Billing Information)</h4>
            </Row>
            <br></br>
            <Row>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Company Name</Form.Label>
                <Form.Control
                  type="text"
                  name="companyName"
                  onChange={handleChange}
                />
              </Col>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Company Address</Form.Label>
                <Form.Control
                  as="textarea"
                  type="text"
                  name="companyaddress"
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <Row>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Company Country</Form.Label>
                <Form.Control
                  type="text"
                  name="companyCountry"
                  onChange={handleChange}
                />
              </Col>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Company Website</Form.Label>
                <Form.Control
                  type="text"
                  name="companyWebsite"
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <Row>
              <Col xs="6" className="custom_col_add_staff mb-4">
                <Form.Label>Other Details</Form.Label>
                <Form.Control
                  as="textarea"
                  type="text"
                  name="otherDetails"
                  onChange={handleChange}
                />
              </Col>
            </Row>

            {/* <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Add New Country Code</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Label>Country Name</Form.Label>
                <Form.Control
                  type="text"
                  name="newCountryName"
                />
                <Form.Label>Country Code</Form.Label>
                <Form.Control
                  type="number"
                  name="newCountryCode"
                />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button variant="primary" onClick={handleClose}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </Modal> */}

            <button className="add_staff_submit_btn" type="submit">
              Update User
            </button>
          </Form>
          {error && <p className="error">{error}</p>}
        </div>
      </div>
    </>
  );
};

export default EditUser;
