import React from "react";
import "./Editorial.css";
import { Table, Pagination, Dropdown, DropdownButton } from "react-bootstrap";
import { useState, useEffect, useMemo } from "react";
import Instance from "../../Utils/Axios";
import ReactPaginate from "react-paginate";
import { Link, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import EditorialRoom from "./EditorialRoom";
import EditorialCardData from "./EdtorialCardData";

const PressReleasePendingView = () => {
  const [editorialList, setEditorialList] = useState([]);
  const [error, setError] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [tempData, setTempData] = useState([]);
  const [accordionOpen, setAccordionOpen] = useState(false);
  const { id } = useParams();

  const [record, setRecord] = useState(EditorialRoom);
  const [pendingData, setPendingData] = useState([]);

  const fetchEditorialDetails = async () => {
    try {
      const token = localStorage.getItem("adminToken");
      const response = await Instance.post(`/siteadmin/pressrelease/history`, {
        adminToken: token,
        id: id,
      });
      console.log(
        "Response from pending history details API:",
        response?.data?.message
      );

      setPendingData(response?.data?.message);

      setError("");
    } catch (error) {
      setError("Failed to fetch editorial details");
      console.error("Error fetching editorial details for edit:", error);
    }
  };

  useEffect(() => {
    fetchEditorialDetails();
  }, id);

  const handleFilter = (event) => {
    const newData = record.filter((row) => {
      return;
      row.User.toLowerCase().includes(event.target.value.toLowerCase());
    });

    setRecord(newData);
  };

  const columns = [
    {
      name: "Release Number",
      selector: (row) => parseInt(row.id),
      sortable: true,
      // width: "160px",
      wrap: true,
    },
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
      wrap: true,
    },

    {
      name: "User Name",
      selector: (row) => row.user,
      //   sortable: true,
      // width: "140px",
      wrap: true,
    },

    {
      name: "Status",
      selector: (row) => row.status,
      wrap: true,
    },
    {
      name: "Comment",
      selector: (row) => row.note,
      wrap: true,
    },
    {
      name: "Date & Time",
      selector: (row) => row.createdOn,
      // width: "140px",
      wrap: true,
    },
    {
      name: "who's action on this page",
      selector: (row) => row.action_by,
      width: "220px",
      wrap: true,
    },
  ];

  const data = [
    {
      ReleaseNumber: 10460,
      Title: "Badr Jafar awarded Honorary Fe...",
      User: " Etrendystock & Digital",

      Status: "Pending ",
      Comment: "abc",
      Date: "17 march",
      Action: "consistancy",
    },
  ];

  const filterData = (e) => {
    let filterText = e.target.value;
    if (!filterText) {
      setTempData([...editorialList]);
    } else {
      const filteredItems = editorialList.filter(
        (item) =>
          item.userName &&
          item.userName.toLowerCase().includes(filterText.toLowerCase())
        // ||
        // (item.email &&
        //   item.email.toLowerCase().includes(filterText.toLowerCase())) ||
        // (item.id &&
        //   item.id.toLowerCase().includes(filterText.toLowerCase())) ||
        // (item.mobile &&
        //   item.mobile.toLowerCase().includes(filterText.toLowerCase()))
      );
      setTempData([...filteredItems]);
    }
  };

  return (
    <>
      <div className="admin_editorial_layout">
        <div className="page_heading">
          <h4> Pending Press Release [History] </h4>
          <Link to="/admin/editorial/pending_press_release">
            <span style={{ cursor: "pointer" }}>
              <div className="backbutton"></div>
            </span>
          </Link>
        </div>

        <div className="row gap-3 flex justify-content-center my-3">
          {EditorialCardData.map((val) => {
            return (
              <>
                {/* <Card  className='col-xl-2 col-lg-2 col-md-2 col-sm-2' style={{ width: '11rem' }}>
       
                    <div className='text-center'>
                      <p className="pt-2" style={{color:"#868e96",fontWeight:"600"}}> {val.name}</p>
                      <p style={{color:"#868e96",fontWeight:"600"}}> {val.number}</p>

                      </div>
   
                </Card> */}
              </>
            );
          })}
        </div>

        <DataTable
          className="data-table-wrapper"
          columns={columns}
          data={pendingData}
          // pagination
          defaultSortFieldId={1}
          responsive={true}
          defaultSortAsc={true}
          //  actions={actionsMemo}
        />
      </div>
    </>
  );
};

export default PressReleasePendingView;
