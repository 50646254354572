import "./VendorLayout.css";
import { Link, useParams, useNavigate } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Alert } from "react-bootstrap";
import { useEffect, useState } from "react";
import Instance from "../../Utils/Axios";
import { showToast } from "../../Utils/index";

const VendorUpdate = () => {
  const [vendor, setVendor] = useState([]);
  const { vendorId } = useParams();
  const [error, setError] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchVendorDetails = () => {
      const token = localStorage.getItem("adminToken");
      Instance.post("/siteadmin/vendor/details", {
        adminToken: token,
        vendorId: vendorId,
      })
        .then((response) => {
          setVendor(response?.data?.message);
          setError("");
        })
        .catch((error) => {
          setError("Cannot fetch Vendor Details");
          console.error("cannot fetch vendor details", error);
        });
    };
    fetchVendorDetails();
  }, [vendorId]);

  const handleChange = (e) => {
    setVendor({
      ...vendor,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const token = localStorage.getItem("adminToken");
    Instance.post("/siteadmin/vendor/update", {
      adminToken: token,
      vendorId: vendorId,
      name: vendor.name,
      url: vendor.url,
      credits: vendor.credits,
      status: vendor.status,
    })
      .then((response) => {
        if (response.data.resCode === "200") {
          showToast("Updated Succesfully!", "success", "success");
          setTimeout(() => {
            navigate("/admin/vendor");
          }, 1000);
        } else {
          setError(response.data.resMessage);
        }
      })
      .catch((error) => {
        setError("Failed to update vendor details");
        console.error("Error updating vendor details:", error);
      });
    if (!vendor) {
      return <p>Loading</p>;
    }
  };
  return (
    <>
      <div className="vendor_pages_layout">
        <div className="vendor_page_heading">
          <h4> Edit Vendor </h4>
          <div className="admin_vendor_btns">
            <Link to="/admin/vendor">
              <span style={{ cursor: "pointer" }}>
                <div className="backbutton"></div>
              </span>
            </Link>
          </div>
        </div>
        <div className="add_vendor_details">
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Distribution Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={vendor.name}
                  onChange={handleChange}
                  required
                />
              </Col>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Credits</Form.Label>
                <Form.Control
                  type="number"
                  name="credits"
                  value={vendor.credits}
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <Row>
              <Col xs="6" className="custom_col_add_staff mb-4">
                <Form.Label>Website</Form.Label>
                <Form.Control
                  type="type"
                  name="url"
                  value={vendor.url}
                  onChange={handleChange}
                />
              </Col>
              <Col xs="6" className="custom_col_add_staff mb-4">
                <Form.Label>Status</Form.Label>
                <Form.Select
                  name="status"
                  value={vendor.status}
                  onChange={handleChange}
                >
                  <option value="ACTIVE">Active</option>
                  <option value="INACTIVE">Inactive</option>
                </Form.Select>
              </Col>
            </Row>
            <button
              className="add_vendor_submit_btn  btn btn-primary"
              type="submit"
            >
              Submit
            </button>
          </Form>
          {error && (
            <Alert variant="danger" className="mt-4">
              {error}
            </Alert>
          )}
        </div>
      </div>
    </>
  );
};

export default VendorUpdate;
