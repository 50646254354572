import Header from "../../components/Layouts/Header";
import Layout from "../../components/Layouts/Layout";
import Footer from "../../components/Layouts/Footer";
import "../Login/Login.css";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Instance from "../../Utils/Axios";
import { showToast } from '../../Utils/index'

export default function SignUp() {
  const [title, setTitle] = useState("mr");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("");
  const [isdCode, setIsdCode] = useState("+91");
  const [address, setAddress] = useState("");
  const [pwd, setPwd] = useState("");
  const [confirmPwd, setConfirmPwd] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const navigate = useNavigate();

  const handleSignUp = () => {
    setLoading(true);
    Instance.post("/user/createUser", {
      title,
      firstName,
      lastName,
      mobileNumber,
      email,
      country,
      isdCode,
      address,
      pwd,
      confirmPwd,
    })
      .then((response) => {
        setLoading(false);
        showToast("Registration successful!", 'success', 'success');
        setTimeout(() => {
          navigate("/");
        }, 2000)
      })
      .catch((error) => {
        setLoading(false);
        setError("Error registering user. Please try again.");
        console.error("Error registering user:", error);
      });
  }
  return (
    <>
      <Header />
      <Layout>
        <div className={`col-6 signup_container`}>
          <h1>Sign Up</h1>
          <div className={`signup_wrapper`}>
            <div className={`name_container`}>
              <Form.Control
                type="text"
                placeholder="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <Form.Control
                type="text"
                placeholder="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <Form.Control
              type="tel"
              placeholder="Phone Number"
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
            />
            <Form.Control
              type="text"
              placeholder="Address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
            <div className="select_container">
              <Form.Select
                placeholder="Select Country"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
              >
                <option>Select Country</option>
                <option value="India">India</option>
                <option value="USA">USA</option>
                <option value="UK">UK</option>
              </Form.Select>
            </div>
            <Form.Control
              type="email"
              placeholder="Email Id"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <div className={` password_container `}>
              <Form.Control
                type="password"
                placeholder="Password"
                value={pwd}
                onChange={(e) => setPwd(e.target.value)}
              />
              <img
                className={` logo visible_two `}
                src="/build/eye.svg"
                alt="Show Password"
                width={24}
                height={24}
              />
              <Form.Control
                type="password"
                placeholder="Confirm Password"
                value={confirmPwd}
                onChange={(e) => setConfirmPwd(e.target.value)}
              />
              <img
                className={` logo visible_three `}
                src="/build/eye.svg"
                alt="Show Password"
                width={24}
                height={24}
              />
            </div>
            {successMessage && <p className="text-success">{successMessage}</p>}
            {error && <p className="text-danger">{error}</p>}
            <button
              className={`signin_btn`}
              onClick={handleSignUp}
              disabled={loading}
            >
              {loading ? "Processing..." : "Sign Up"}
            </button>
            <p className={`mb-0 text-center  or`}>OR</p>
            <div className="d-flex justify-content-between">
              <div className="d-flex gap-2 auth_btn">
                <img
                  className={"logo"}
                  src="/build/Google_Logo.svg"
                  alt="Google Logo"
                  width={24}
                  height={24}
                />
                <p>Sign in with Google</p>
              </div>
              <div className="d-flex gap-2 auth_btn auth_btn_two">
                <img
                  className={"logo"}
                  src="/build/Google_Logo.svg"
                  alt="Apple Logo"
                  width={24}
                  height={24}
                />
                <p>Sign in with Apple</p>
              </div>
            </div>
          </div>
        </div>
      </Layout>
      <Footer />
    </>
  );

};