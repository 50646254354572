import "./TemplateLayout.css";
import { useState, useEffect } from "react";
import Instance from "../../Utils/Axios";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import { showToast } from "../../Utils/index";
import Table from "react-bootstrap/Table";
import AddTemplate from "./AddTemplate";

const TemplateDetail = () => {
  const [templateList, setTemplateList] = useState([]);
  const [filteredTemplates, setFilteredTemplates] = useState([]);
  const [error, setError] = useState("");
  const [show, setShow] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [filters, setFilters] = useState({ status: "" });
  const [accordionOpen, setAccordionOpen] = useState(false);
  const [count, setCount] = useState(0);
  const [copyObject, setCopyObject] = useState({});
  const [isChecked, setIsChecked] = useState(null);

  const [formData, setFormData] = useState({
    templateName: "",
    templateDesc: "",
    status: "",
  });

  const handleClose = () => setShow(false);
  const handleShow = (templateId) => {
    setSelectedTemplateId(templateId);
    setShow(true);
  };

  useEffect(() => {
    fetchTemplateList();
  }, []);

  const fetchTemplateList = () => {
    const token = localStorage.getItem("adminToken");
    Instance.post("/siteadmin/template/getList", {
      adminToken: token,
      status: "",
    })
      .then((response) => {
        setTemplateList(response?.data?.message);
        setFilteredTemplates(response?.data?.message);
        setError("");
        console.log("Template List:", response?.data?.message);
      })
      .catch((error) => {
        setError("Failed to fetch template data");
        console.error("Error fetching templates:", error);
      });
  };

  const deleteTemplate = () => {
    const updatedList = templateList.filter(
      (item) => item.templateId !== selectedTemplateId
    );
    setTemplateList(updatedList);
    setFilteredTemplates(updatedList);
    handleClose();
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const applyFilters = () => {
    const token = localStorage.getItem("adminToken");
    Instance.post("/siteadmin/template/getList", {
      adminToken: token,
      status: filters.status,
    })
      .then((response) => {
        setFilteredTemplates(response?.data?.message);
        setError();
        console.log("Filtered template data:", response?.data?.message);
      })
      .catch((error) => {
        setError("Failed to apply filters");
        console.error("Error applying filter", error);
      });
  };

  const clearFilters = () => {
    const token = localStorage.getItem("adminToken");
    Instance.post("/siteadmin/template/getList", {
      adminToken: token,
      status: "",
    })
      .then((response) => {
        setFilters({ status: "" });
        setFilteredTemplates(response?.data?.message);
        setTemplateList(response?.data?.message);
        setError("");
      })
      .catch((error) => {
        setError("Failed to clear filters");
        console.error("error clearing the filters", error);
      });
  };

  const handleSubmit = async (status, id) => {
    // e.preventDefault();
    try {
      const token = localStorage.getItem("adminToken");
      const response = await Instance.post(`/siteadmin/template/update`, {
        adminToken: token,
        templateId: id,
        status: status,
      });
      // console.log("Response from updateCoupon API:", response.data);
      if (response.data.resCode === "200") {
        fetchTemplateList();
        showToast(" Status Updated Succesfully!", "success", "success");
        // setTimeout(() => {
        //   // navigate("/category_press_release");
        //   window.location.reload();
        // }, 1000);
      } else {
        setError(response.data.resMessage);
      }
    } catch (error) {
      setError("Failed to update template details");
      console.error("Error updating template details:", error);
    }
  };

  const handleChecked = (event, status, id) => {
    const confirmed = window.confirm(
      `Are you sure you want to ${
        status === "active" ? "inactive" : "active"
      } status?`
    );

    if (status === "active" && confirmed === true) {
      handleSubmit("inactive", id);
    } else if (
      (status === "inactive" || status === "deactive" || status === "") &&
      confirmed === true
    ) {
      handleSubmit("active", id);
    }

    // else if (status === "DEACTIVE" && confirmed === true) {
    //   handleSubmit("ACTIVE", id);
    // }

    console.log("Switch value:", event.target.checked, status, id);
  };

  const handleTable = (row_data) => {
    setCount(1);
    if (row_data.status === "active") {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
    setCopyObject(row_data);
    setFormData(row_data);
    console.log(row_data);
  };

  const columns = [
    {
      name: "SI No",
      selector: (row) => parseInt(row.templateId),
      sortable: true,
      wrap: true,
    },
    {
      name: "Template Name",
      selector: (row) => row.templateName,
      sortable: true,
      wrap: true,
    },
    {
      name: "Description",
      selector: (row) => row.templateDesc,
      sortable: true,
      wrap: true,
    },
    {
      name: "Status",
      cell: (row) => (
        <Form>
          <Form.Check
            type="switch"
            id="custom-switch"
            checked={row?.status === "active"}
            onChange={(e) => handleChecked(e, row.status, row.templateId)}
          />
        </Form>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <span className="d-flex gap-1">
          <a href={`/admin/template/edit/${row.templateId}`} target="_blank">
            <img
              src="/build/edit.svg"
              alt="edit"
              width={24}
              height={24}
              priority
            />
          </a>
          <a
            // onClick={() => console.log(row)}
            onClick={() => handleTable(row)}
          >
            <img src="/build/copy.svg" alt="copy" width={24} height={24} />
          </a>
        </span>
      ),
    },
  ];

  const addNewTemplate = (e) => {
    e.preventDefault();

    if (formData?.templateName === copyObject?.templateName) {
      showToast(
        "Template Already Exist change Template Name !",
        "error",
        "error"
      );
    } else {
      const token = localStorage.getItem("adminToken");
      if (isChecked === true) {
        formData.status = "active";
      } else {
        formData.status = "inactive";
      }

      Instance.post("/siteadmin/template/create", {
        adminToken: token,
        ...formData,
      })
        .then((response) => {
          if (response.data.resCode === "100") {
            showToast("Already a member", "error", "error");
            setError(response.data.resMessage);
            // console.log("Template added failed:", response.data);
          } else {
            console.log("Template added successfully:", response.data);
            showToast("Template added successfully", "success", "success");
            setCount(0);
            setError("");
            fetchTemplateList();

            // setTimeout(() => {
            //   navigate("/admin/company");
            // }, 2000);
          }
        })
        .catch((error) => {
          showToast("Error", "error", "error");
          setError("Failed to add Template");
          console.error("Error adding Template:", error);
        });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const columns_copy = [
    {
      name: "SI No",
      selector: (row) => 0,
      sortable: true,
      wrap: true,
    },
    {
      name: "Template Name",
      selector: (row) => (
        <>
          <Form.Control
            type="text"
            name="templateName"
            value={formData ? formData.templateName : null}
            // value={row.templateName}
            onChange={handleChange}
            required
          />
        </>
      ),
      sortable: true,
      wrap: true,
    },
    {
      name: "Description",
      selector: (row) => (
        <>
          <Form.Control
            type="text"
            name="templateDesc"
            value={formData ? formData.templateDesc : null}
            onChange={handleChange}
            required
          />
        </>
      ),

      sortable: true,
      wrap: true,
    },
    {
      name: "Status",
      cell: (row) => (
        <Form>
          <Form.Check
            type="switch"
            id="custom-switch"
            // checked={true}
            checked={isChecked}
            onChange={(event) => setIsChecked(event.target.checked)}
            // onChange={(event) => console.log(event.target.checked)}
            // onChange={(e) => handleChecked(e, row.status, row.templateId)}
          />
        </Form>
      ),
      wrap: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <span className="d-flex gap-1 align-items-center">
          <a
            onClick={
              formData.templateName.length > 0 &&
              formData.templateDesc.length > 0
                ? addNewTemplate
                : null
            }
            className="btn  btn-sm rounded"
            style={{ backgroundColor: "#595fde", color: "#fff" }}
          >
            Add
          </a>
          <a onClick={() => setCount(0)}>
            <img
              src="/build/delete.svg"
              alt="edit"
              width={24}
              height={24}
              priority
            />
          </a>
        </span>
      ),
      wrap: true,
    },
  ];

  const handleSearch = (e) => {
    let filterText = e.target.value.toLowerCase();
    const filteredItems = templateList.filter(
      (item) =>
        item.templateName.toLowerCase().includes(filterText) ||
        item.templateId.toLowerCase().includes(filterText) ||
        item.templateDesc.toLowerCase().includes(filterText)
    );
    setFilteredTemplates(filteredItems);
  };

  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      setAccordionOpen(!accordionOpen)
    );

    return (
      <button
        type="button"
        className="admin_filter_btn"
        // style={{ backgroundColor: "pink" }}
        onClick={decoratedOnClick}
      >
        {children}
        {accordionOpen ? (
          <img
            src="/build/img/Icons/down_vector.svg"
            alt="zex pr wire Logo"
            width={16}
            height={16}
          />
        ) : (
          <img
            src="/build/img/Icons/down_vector.svg"
            alt="zex pr wire Logo"
            width={16}
            height={16}
            className="filter_up_arrow"
          />
        )}
      </button>
    );
  }

  // console.log("---------------formdata is ------------------", formData);
  return (
    <>
      <div className="template_pages_layout">
        <div className="template_page_heading">
          <h4> Templates </h4>
          <div className="admin_template_btns">
            <div className="search_template">
              <input
                placeholder="Search"
                type="search"
                aria-controls="datatable"
                onChange={handleSearch}
              />
              <img
                className={""}
                src="/build/img/search.svg"
                alt="zex pr wire Logo"
                width={24}
                height={24}
                priority
              />
            </div>
            <Link to="/admin/template/add">
              <button className="new_template_btn"> Create </button>
            </Link>
          </div>
        </div>
        <Accordion className="mb-4">
          <Card>
            <Card.Header>
              <CustomToggle as={Button} variant="link" eventKey="0">
                Filter
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Form>
                  <div className="d-flex">
                    <Form.Group
                      controlId="filterStatus"
                      className="template_filter_options"
                    >
                      <Form.Label>Status</Form.Label>
                      <Form.Control
                        as="select"
                        name="status"
                        value={filters.status}
                        onChange={handleFilterChange}
                        className=""
                      >
                        <option value="">All</option>
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                      </Form.Control>
                    </Form.Group>
                  </div>
                  <div className="mt-3 d-flex gap-3">
                    <Button
                      variant="secondary"
                      className="clear"
                      onClick={clearFilters}
                    >
                      Clear
                    </Button>
                    <Button
                      variant="primary"
                      className="apply"
                      onClick={applyFilters}
                    >
                      Apply
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>

        {/* <Table responsive>
          <thead>
            <tr>
              <th>#</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Username</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Mark</td>
              <td>Otto</td>
              <td>@mdo</td>
            </tr>
          </tbody>
        </Table> */}

        {count === 1 ? (
          <DataTable
            className="data-table-wrapper"
            columns={columns_copy}
            data={[copyObject]}
            // pagination
            responsive={true}
            defaultSortFieldId={1}
            defaultSortAsc={true}
            // className="pb-4"
          />
        ) : null}
        <DataTable
          className="data-table-wrapper"
          columns={columns}
          data={filteredTemplates}
          pagination
          responsive={true}
          defaultSortFieldId={1}
          defaultSortAsc={true}
        />
        {error && <p className="error_message">{error}</p>}
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <div className="custom_template_modal_body">
            <h5>Are you sure?</h5>
            <p>Are you sure! You want to delete?</p>
            <div className="d-flex justify-content-end gap-4">
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                variant="danger"
                onClick={deleteTemplate}
                style={{ cursor: "pointer" }}
              >
                Ok
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TemplateDetail;
