import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Instance from "./Axios";
import { showToast } from "./index";
import axios from "axios";

const ProtectedRoute = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      const adminToken = localStorage.getItem("adminToken");

      // if (!adminToken) {
      //   showToast("You need to log in first", "error");
      //   setLoading(false);
      //   return;
      // }

      try {
        const response = await axios.post(
          "https://pradmin.webandapi.com//siteadmin/get",
          {
            adminToken: adminToken,
          },
          {
            headers: {
              Authorization: `Bearer ${adminToken}`,
            },
          }
        );

        if (response.data.resCode === "200") {
          setIsAuthenticated(true);
        } else {
          showToast("You need to log in first", "error");
        }
      } catch (error) {
        showToast("You need to log in first", "error");
      } finally {
        setLoading(false);
      }
    };
    // checkAuth();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return (
      <>
        <Navigate to="/" />
        <ToastContainer theme="colored" />
      </>
    );
  }

  return (
    <>
      {children}
      <ToastContainer theme="colored" />
    </>
  );
};

export default ProtectedRoute;
