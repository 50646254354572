import "./ProfileLayout.css";
import FormLayout from "./FormLayout";

export default function ProfileEdit({ onCancelClick, onSaveChangeClick }) {
  return (
    <>
      <div className="content_container">
        <div className="edit_container">
          <h5>Profile Picture</h5>
          <div className="upload">
            <img
              className={"profile_picture"}
              src="/ProfilePicture.svg"
              alt="zex pr wire Logo"
              width={170}
              height={170}
              priority
            />
            <div className="browse_image">
              <img
                className={"add_picture"}
                src="/Addimage.svg"
                alt="zex pr wire Logo"
                width={150}
                height={68}
                priority
              />
              <h6>
                Click to upload{" "}
                <span className="light_text">
                  or drag and drop PNG or JPG here.
                </span>
                Max size 2MB.
              </h6>
              <button className="browse_btn">Browse</button>
            </div>
          </div>
        </div>
        <FormLayout />
      </div>
      <div className="change_buttons">
        <button className="cancel_btn mb-4" onClick={onCancelClick}>
          Cancel
        </button>
        <button className="save_btn mb-4" onClick={onSaveChangeClick}>
          Save Changes
        </button>
      </div>
    </>
  );
}
