import "./UserManage.css";
import DataTable from "react-data-table-component";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Instance from "../../Utils/Axios";
import { useEffect, useState, useCallback } from "react";
import axios from "axios";

const UserDeletePage = () => {
  const [accordionOpen, setAccordionOpen] = useState(false);
  const [error, setError] = useState("");

  const [userList, setUserList] = useState([]);
  const [tempData, setTempData] = useState([]);
  const [filters, setFilters] = useState({
    status: "PENDING",
    firstName: "",
    email: "",
    mobileNumber: "",
    userId: "",
  });
  const columns = [
    {
      name: "User Id",
      selector: (row) => parseInt(row.userId),
      sortable: true,
      // width: "80px",
      wrap: true,
    },
    {
      name: "Name",
      cell: (row) => row.firstName,
      sortable: true,
      wrap: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      wrap: true,
    },
    {
      name: "Phone",
      selector: (row) => row.mobileNumber,
      sortable: true,
      wrap: true,
    },
    {
      name: "Status",
      cell: (row) => "Deleted" || row.status,
      wrap: true,
    },
  ];

  const fetchUserList = () => {
    const token = localStorage.getItem("adminToken");
    console.log("Fetched token from localStorage:", token);
    Instance.post("/siteadmin/user/list", {
      adminToken: token,
      status: "PENDING",
      fromDate: "",
      toDate: "",
      email: "",
      userId: "",
      country: "",
      state: "",
      city: "",
      mobileNumber: "",
      parentId: "",
    })
      .then((response) => {
        setUserList(response?.data?.message);
        setTempData(response?.data?.message);
        setError("");
        console.log("check data", response?.data?.message);
      })
      .catch((error) => {
        setError("Failed to fetch the data");
        console.error("Error fetching User list:", error);
      });
  };
  useEffect(() => {
    fetchUserList();
  }, []);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const applyFilters = () => {
    const token = localStorage.getItem("adminToken");
    Instance.post("/siteadmin/user/list", {
      adminToken: token,
      // status: filters.status,
      status: "PENDING",
      firstName: filters.firstName,
      email: filters.email,
      mobileNumber: filters.mobileNumber,
      userId: filters.userId,
    })
      .then((response) => {
        setTempData(response?.data?.message);
        setError("");
        console.log("Filtered data:", response?.data?.message);
      })
      .catch((error) => {
        setError("Failed to apply filters");
        console.error("Error applying filters:", error);
      });
  };

  const clearFilters = () => {
    const token = localStorage.getItem("adminToken");
    Instance.post("/siteadmin/user/list", {
      adminToken: token,
      // status: filters.status,
      status: "PENDING",
      firstName: "",
      email: "",
      mobileNumber: "",
      userId: "",
    })
      .then((response) => {
        setFilters({
          status: "PENDING",
          firstName: "",
          email: "",
          mobileNumber: "",
          userId: "",
        });
        setUserList(response?.data?.message);
        setTempData(response?.data?.message);
        setError("");
        console.log("Cleared filters. Updated data:", response?.data?.message);
      })
      .catch((error) => {
        setError("Failed to clear filters");
        console.error("Error clearing filters:", error);
      });
  };

  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = ["userId", "firstName", "email", "mobileNumber", "status"];

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  function downloadCSV(array) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = "deleted_users.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      setAccordionOpen(!accordionOpen)
    );
    return (
      <button
        type="button"
        className="admin_filter_btn"
        // style={{ backgroundColor: "pink" }}
        onClick={decoratedOnClick}
      >
        {children}
        {accordionOpen ? (
          <img
            src="/build/img/Icons/down_vector.svg"
            alt="zex pr wire Logo"
            width={16}
            height={16}
          />
        ) : (
          <img
            src="/build/img/Icons/down_vector.svg"
            alt="zex pr wire Logo"
            width={16}
            height={16}
            className="filter_up_arrow"
          />
        )}
      </button>
    );
  }

  const filterData = (e) => {
    let filterText = e.target.value;
    if (!filterText) {
      setTempData([...userList]);
    } else {
      const filteredItems = userList.filter(
        (item) =>
          (item.firstName &&
            item.firstName.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.mobileNumber &&
            item.mobileNumber
              .toLowerCase()
              .includes(filterText.toLowerCase())) ||
          (item.mobileNumber &&
            item.mobileNumber
              .toLowerCase()
              .includes(filterText.toLowerCase())) ||
          (item.email &&
            item.email.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.userId &&
            item.userId.toLowerCase().includes(filterText.toLowerCase()))
      );
      setTempData([...filteredItems]);
    }
  };

  return (
    <>
      <div className="user_manage_layout">
        <div className="page_heading_user" style={{ marginTop: "-20px" }}>
          <h4> Manage Deleted User </h4>
        </div>
        <Accordion className="mb-4">
          <Card>
            <Card.Header className="d-md-flex justify-content-between align-items-center">
              <div className="country_search ">
                <label>
                  Search:
                  <input
                    type="search"
                    placeholder=""
                    aria-controls="datatable"
                    onChange={filterData}
                  ></input>
                </label>
              </div>
              <div className="d-flex">
                <button
                  onClick={(e) => downloadCSV(userList)}
                  className="btn btn-export mx-3"
                >
                  <img
                    src="/build/img/Icons/export.svg"
                    alt="zex pr wire Logo"
                    width={25}
                    height={25}
                  />
                </button>
                <CustomToggle as={Button} variant="link" eventKey="0">
                  Filters
                </CustomToggle>
              </div>
            </Card.Header>

            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Form>
                  <div className="d-flex gap-4 flex-wrap">
                    <Form.Group
                      controlId="filterUsername"
                      className="country_filter_options"
                    >
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search Name"
                        name="name"
                        value={filters.firstName}
                        onChange={handleFilterChange}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filterName"
                      className="country_filter_options"
                    >
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search Email"
                        name="email"
                        value={filters.email}
                        onChange={handleFilterChange}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filterName"
                      className="country_filter_options"
                    >
                      <Form.Label>Phone</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Search Phone Number"
                        name="mobileNumber"
                        value={filters.mobileNumber}
                        onChange={handleFilterChange}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filterName"
                      className="country_filter_options"
                    >
                      <Form.Label>User ID</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Search User ID"
                        name="userId"
                        value={filters.userId}
                        onChange={handleFilterChange}
                      ></Form.Control>
                    </Form.Group>
                  </div>
                  <div className="mt-3 d-flex gap-3">
                    <Button variant="secondary" onClick={clearFilters}>
                      Clear
                    </Button>
                    <Button variant="primary" onClick={applyFilters}>
                      Apply
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <DataTable
          className="data-table-wrapper"
          columns={columns}
          // data={filteredTemplates}
          data={tempData}
          pagination
          responsive={true}
          defaultSortFieldId={1}
          defaultSortAsc={true}
        />
      </div>
    </>
  );
};

export default UserDeletePage;
