import "../Login/Login.css";
import Header from "../../components/Layouts/Header";
import Layout from "../../components/Layouts/Layout";
import Footer from "../../components/Layouts/Footer";
import Form from "react-bootstrap/Form";
import { useState, useEffect } from "react";
import Instance from "../../Utils/Axios";
import { Link, useNavigate } from "react-router-dom";
import { showToast } from '../../Utils/index'

export default function Forgot() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [otp, setOtp] = useState("");
  const [apiEmail, setApiEmail] = useState("");
  const navigate = useNavigate();

  const handleForgotPassword = () => {
    setLoading(true);
    Instance.post("/user/forgotPassword", {
      email: email,
    })
      .then((response) => {
        setOtp(response.data.otp);
        setApiEmail(email);
        setLoading(false);
        setSuccessMessage(response.data.resMessage);
        showToast("Reset OTP Sent successful!", 'success', 'success');
        setTimeout(() => {
          navigate("/newpassword?email=" + email);
        }, 3000)
      })
      .catch((error) => {
        setLoading(false);
        setError("Error sending reset link. Please try again.");
        console.error("Error sending reset link:", error);
      });
  };

  return (
    <>
      <Header />
      <Layout>
        <>
          <div className={`col-6 forgot_container`}>
            <div className={`forgot_wrapper`}>
              <h1>Forgot Password</h1>
              <Form.Control
                type="email"
                placeholder="Enter your Email Id"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {successMessage && <p className="text-success">{successMessage}</p>}
              {error && <p className="text-danger">{error}</p>}
              <button
                className={`signin_btn`}
                onClick={handleForgotPassword}
                disabled={loading}
              >
                {loading ? "Sending..." : "Get Reset Link"}
              </button>
              <p className={`mb-0 text-center or`}>OR</p>
              <div className={`d-flex justify-content-between`}>
                <div className={`d-flex gap-2 auth_btn`}>
                  <img
                    className={"logo"}
                    src="/build/Google_Logo.svg"
                    alt="zex pr wire Logo"
                    width={24}
                    height={24}
                    priority
                  />
                  <p>Sign in with Google</p>
                </div>
                <div className={`d-flex gap-2 auth_btn auth_btn_two`}>
                  <img
                    className={"logo"}
                    src="/build/Google_Logo.svg"
                    alt="zex pr wire Logo"
                    width={24}
                    height={24}
                    priority
                  />
                  <p>Sign in with Apple</p>
                </div>
              </div>
            </div>
          </div>
        </>
      </Layout>
      <Footer />
    </>
  );
}
