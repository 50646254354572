import Navbar from "../../components/NavbarProfile/NavbarProfile";
import Sidebar from "../../components/SidebarNew/Sidebar";
import NetworkPage from "../../components/Networks/NetworkPage";
import Footer from "../../components/Layouts/Footer";
import "./Network.css";

const Networks = () => {
  const activeSide = 16;
  const activeEventKey = "3";
  return (
    <>
      <div className="row gx-0 admin_network_content">
        <Sidebar activeSide={activeSide}  activeEventKey={activeEventKey} />
        <div
          className={`main col d-flex flex-column`}
          style={{ overflow: "auto" }}
        >
          <Navbar />
          <div className="flex-grow-1">
            <NetworkPage />
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Networks;
