import { useEffect, useState } from "react";
import "../../Pages/Login/Login.css";
import Instance from "../../Utils/Axios";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

const Layout = ({ children }) => {
  const [footerData, setFooterData] = useState(null);
  const [highlightData, setHighlightData] = useState({});
  const [faqData, setFaqData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    Instance.get("/site/footer")
      .then((response) => {
        setFooterData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setError("Error fetching footer data");
        setLoading(false);
        console.error("Error fetching footer data:", error);
      });
  }, []);

  useEffect(() => {
    Instance.get("/site/highlights")
      .then((response) => {
        setHighlightData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setError("Error fetching highlight data");
        setLoading(false);
        console.error("Error fetching highlight data:", error);
      });
  }, []);
  useEffect(() => {
    Instance.get("/site/faq")
      .then((response) => {
        setFaqData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setError("Error fetching highlight data");
        setLoading(false);
        console.error("Error fetching highlight data:", error);
      });
  }, []);

  if (loading) return <div>Loading Data</div>;
  if (error) return <div>{error}</div>;

  if (!footerData) {
    return <div>No footer data found</div>;
  }

  const { social, email } = footerData;

  return (
    <>
      <div className={`main_content`}>
        <div className={`row main_container`}>
          {children}

          <img
            className={` logo  vector`}
            src="/build/Vector.svg"
            alt="zex pr wire Logo"
            width={250}
            height={300}
            priority
          />
          <img
            className={` logo vector_two`}
            src="Vector2.svg"
            alt="zex pr wire Logo"
            width={300}
            height={350}
            priority
          />

          <div
            className={`col-6 d-flex justify-content-center align-items-center flex-coloumn`}
          >
            <div className={`features`}>
              {highlightData.highlights &&
                highlightData.highlights.desc.map((item, index) => (
                  <div key={index}>
                    {index === 0 && (
                      <div className={"feature_one"}>
                        <h5>{item.title}</h5>
                        <p>{parse(item.desc)}</p>
                      </div>
                    )}
                    {index === 1 && (
                      <div className={"feature_two"}>
                        <img
                          className={`logo ${item.iconClass}`}
                          src={item.iconUrl}
                          alt="zex pr wire Logo"
                          width={24}
                          height={24}
                          priority
                        />
                        <h5>{item.title}</h5>
                        <p>{parse(item.desc)}</p>
                      </div>
                    )}
                    {index === 2 && (
                      <div className={"feature_three"}>
                        <h5>
                          <span>{item.title}</span>
                        </h5>
                        <p>{parse(item.desc)}</p>
                      </div>
                    )}
                    {index === 3 && (
                      <div className={"feature_four"}>
                        <img
                          className={`logo ${item.iconClass}`}
                          src={item.iconUrl}
                          alt="zex pr wire Logo"
                          width={24}
                          height={24}
                          priority
                        />
                        <h5>{item.title}</h5>
                        <p>{parse(item.desc)}</p>
                      </div>
                    )}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <div className={` info_wrapper`}>
        <div className={`info_container`}>
          <div className="col-5">
            <img
              className={"logo"}
              src="/build/Logo.svg"
              alt="zex pr wire Logo"
              width={110}
              height={50}
              priority
            />
            <p className={`main_text`}>
              ZEX PR Wire offers massive Press Release, News, Authored Articles,
              Editorial services, geared at generating massive exposure for your
              unique project, business, or concept.
            </p>
            <p className={`secondary_text`}>
              ZEX PR WIRE is a premium press release distribution provides
              end-to- end solutions to produce, optimize and target content and
              then distribute and measure results. Combining the world's largest
              multi-channel, multi-cultural content distribution and
              optimization network with comprehensive workflow tools and
              platforms, ZEX PR WIRE serves tens of thousands of clients from
              offices in the Americas, Europe, Middle East, Africa and
              Asia-Pacific regions.
            </p>
            <div className={`row mt-5 options`}>
              <div className={`col-5 links_container`}>
                <h2>Quick links</h2>
                <ul className="ps-0">
                  {footerData.quickLinks.map((item, index) => (
                    <li key={index}>
                      <Link
                        to={item.rUrl}
                        target={item.target}
                        className="custom_link"
                      >
                        {item.label}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col-5 submit_pr_container">
                <h2>Submit PR</h2>
                <ul className="ps-0">
                  {footerData.submitPR.map((item, index) => (
                    <li key={index}>
                      <Link
                        to={item.rUrl}
                        target={item.target}
                        className="custom_link"
                      >
                        {item.label}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="col-5">
            <h4>FAQs</h4>
            <div className={"faq_content"}>

              {faqData?.faq?.desc?.map((faqItem, index) => (
                <div
                  key={index}
                  className={`d-flex justify-content-between question`}
                >
                  <p>{faqItem.title}</p>
                  <img
                    className={"logo"}
                    src={faqItem.iconUrl || "/arrownw.svg"}
                    alt="FAQ Icon"
                    width={15}
                    height={15}
                  />
                </div>
              ))}
            </div>
            <div className={`subs_box`}>
              <h5>Subscribe to our Newsletter</h5>
              <div className={`row subs_inner`}>
                <input
                  className={`col-8 subs_input`}
                  placeholder="Enter your email"
                />
                <div className={`col-4 subs_text`}>Subscribe</div>
              </div>
            </div>
            <div className={"contact_detail"}>
              <h5>Email</h5>
              <p>{email}</p>
              <img
                className={`logo `}
                src="/build/Social Media Icon.svg"
                alt="zex pr wire Logo"
                width={160}
                height={91}
                priority
              />
            </div>
            <div>
              <img
                className={` logo help_btn`}
                src="/build/Help.svg"
                alt="zex pr wire Logo"
                width={91}
                height={91}
                priority
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;
