import "./ContactLayout.css";

export default function ContactLayout() {
  return (
    <>
      <div className="contact_layout">
        <div className="contact_content">
          <div className="contact_text">
            <p className="contact_heading">
              Drop by anytime, we endeavor to answer all inquiries within 24
              hours on business days.
            </p>
            <p className="contact_heading_2">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo
              consectetur felis fermentum eget turpis quis.
            </p>
          </div>
          <div className="contact_card">
            <p className="card_text_1">Contact & Reach Us!</p>
            <p className="card_text_2">We are excited to work with you!</p>
            <form className="contact_form">
              <input type="text" placeholder="Your Name" />
              <input type="email" placeholder="Your Email" />
              <input type="number" placeholder="Your Phone Number" />
              <input type="text" placeholder="Website" />
              <input type="text" placeholder="Your Requirements" />
              <button className="contact_form_btn">Send Message</button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
