import "./VendorLayout.css";
import { Link, useNavigate } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useState } from "react";
import Instance from "../../Utils/Axios";
import { showToast } from "../../Utils/index";

const AddVendor = () => {
  const navigate = useNavigate();
  const [vendorCreate, setVendorCreate] = useState({
    name: "",
    credits: "",
    url: "",
  });
  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setVendorCreate({
      ...vendorCreate,
      [name]: value,
    });
  };

  const handleAddVendor = (e) => {
    e.preventDefault();
    const token = localStorage.getItem("adminToken");
    Instance.post("/siteadmin/vendor/create", {
      adminToken: token,
      ...vendorCreate,
    })
      .then((response) => {
        if (response.data.resCode === "100") {
          showToast("Already a member", "error", "error");
          setError(response.data.resMessage);
          console.log("Vendor registration failed:", response.data);
        } else {
          console.log("Vendor registered successfully:", response.data);
          showToast("Vendor registered successfully", "success", "success");
          setError("");
          setTimeout(() => {
            navigate("/admin/vendor");
          }, 1000);
        }
      })
      .catch((error) => {
        showToast("Error", "error", "error");
        setError("Failed to register vendor");
        console.error("Error registering vendor:", error);
      });
  };
  return (
    <>
      <div className="vendor_pages_layout">
        <div className="vendor_page_heading">
          <h4> Add New Vendor </h4>
          <div className="admin_vendor_btns">
            <Link to="/admin/vendor">
              <span style={{ cursor: "pointer" }}>
                <div className="backbutton"></div>
              </span>
            </Link>
          </div>
        </div>
        <div className="add_vendor_details">
          <Form onSubmit={handleAddVendor}>
            <Row>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Vendor Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={vendorCreate.userName}
                  onChange={handleChange}
                  required
                />
              </Col>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Credits</Form.Label>
                <Form.Control
                  type="number"
                  name="credits"
                  value={vendorCreate.credits}
                  onChange={handleChange}
                  required
                />
              </Col>
            </Row>
            <Row>
              <Col xs="6" className="custom_col_add_staff mb-4">
                <Form.Label>Website</Form.Label>
                <Form.Control
                  type="type"
                  name="url"
                  value={vendorCreate.url}
                  onChange={handleChange}
                  required
                />
              </Col>
            </Row>
            <button
              className="add_vendor_submit_btn btn  btn-primary"
              type="submit"
            >
              Submit
            </button>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddVendor;
