import React from "react";
import { Col, FormGroup, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { useState, useEffect } from "react";
import Instance from "../../Utils/Axios";
import { showToast } from "../../Utils/index";

const PressReleaseAddCategory = () => {
  const navigate = useNavigate();
  const [categoryCreate, setCategoryCreate] = useState({
    name: "",
  });
  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCategoryCreate({
      ...categoryCreate,
      [name]: value,
    });
  };

  const handleAddCategory = (e) => {
    e.preventDefault();
    const token = localStorage.getItem("adminToken");
    Instance.post("/siteadmin/category/create", {
      adminToken: token,
      ...categoryCreate,
    })
      .then((response) => {
        if (response.data.resCode === "100") {
          showToast("Already a member", "error", "error");
          setError(response.data.resMessage);
          console.log("Category addition failed:", response.data);
        } else {
          console.log("Category added successfully:", response.data);
          showToast("Category added successfully", "success", "success");
          setError("");
          setTimeout(() => {
            navigate("/admin/editorial/category_press_release");
          }, 1000);
        }
      })
      .catch((error) => {
        showToast("Error", "error", "error");
        setError("Failed to add Category");
        console.error("Error adding Category:", error);
      });
  };

  return (
    <>
      <div className="admin_editorial_layout">
        <div className="page_heading">
          <h4>Add New Category</h4>
          <Link to="/admin/editorial/category_press_release">
            <span style={{ cursor: "pointer" }}>
              <div className="backbutton"></div>
            </span>
          </Link>
        </div>
        <div className="add_new_editorial">
          <Form onSubmit={handleAddCategory}>
            <Row>
              <Col className="col-10 custom_col_add_editorial mb-4">
                <Form.Label>Category Name</Form.Label>
                <Form.Control
                  type="text"
                  class="form-control"
                  name="name"
                  value={categoryCreate.name}
                  onChange={handleChange}
                />
              </Col>
            </Row>

            <Row className="pb-5 ">
              <Col className=" ">
                <button
                  className="add_editorial_submit_btn"
                  type="submit"
                  style={{ width: "200px" }}
                >
                  Submit
                </button>
              </Col>
            </Row>
          </Form>
          {/* {error && <p className="error_message">{error}</p>} */}
        </div>
      </div>
    </>
  );
};
export default PressReleaseAddCategory;
