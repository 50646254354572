import "./UserManage.css";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Instance from "../../Utils/Axios";
import { showToast } from "../../Utils/index";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";

const AddUser = () => {
  const navigate = useNavigate();
  const [countryList, setCountryList] = useState([]);
  const [tempData, setTempData] = useState([]);
  const [error, setError] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [formData, setFormData] = useState({
    title: "mr",
    firstName: "",
    lastName: "",
    mobileNumber: "",
    email: "",
    country: "",
    isdCode: "",
    address: "",
    pwd: "",
    confirmPwd: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "firstName" && value.length < 3) {
      setFirstNameError(true);
    } else {
      setFirstNameError(false);
    }
  };

  const fetchCountryList = () => {
    const token = localStorage.getItem("adminToken");
    console.log("Fetched token from localStorage:");
    Instance.post("/siteadmin/common/countryList", {
      adminToken: token,
      countryName: "",
      countryCode: "",
      isdCode: "",
      currency: "",
    })
      .then((response) => {
        setCountryList(response?.data?.message);
        setTempData(response?.data?.message);
        setError("");
        console.log("check data", response?.data?.message);
      })
      .catch((error) => {
        setError("Failed to fetch the data");
        console.error("Error fetching country list:", error);
      });
  };

  useEffect(() => {
    fetchCountryList();
  }, []);

  const addNewUser = (e) => {
    e.preventDefault();

    console.log(formData);
    alert("hiiiiiii");
    const token = localStorage.getItem("adminToken");

    Instance.post("/siteadmin/user/createUser", {
      adminToken: token,
      ...formData,
    })
      .then((response) => {
        if (response.data.resCode === "100") {
          showToast("Already a member", "error", "error");
          setError(response.data.resMessage);
          console.log("User added failed:", response.data);
        } else {
          console.log("User added successfully:", response.data);
          showToast("User added successfully", "success", "success");
          setError("");
          setTimeout(() => {
            navigate("/admin/user");
          }, 2000);
        }
      })
      .catch((error) => {
        showToast("Error", "error", "error");
        setError("Failed to add user");
        console.error("Error adding user:", error);
      });
  };

  return (
    <>
      <div className="user_manage_layout">
        <div className="page_heading page_head ">
          <h4 style={{ marginTop: "-30px" }}> Add New User </h4>
          <Link to={"/admin/user"}>
            <span style={{ cursor: "pointer" }}>
              <div style={{ marginTop: "-30px" }} className="backbutton"></div>
            </span>
          </Link>
        </div>

        <div className="add_new_staff" style={{ marginTop: "-20px" }}>
          <Form onSubmit={addNewUser}>
            <Row>
              <Col xs="6" className="custom_col_add_staff mb-4">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  as="select"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                >
                  <option value="" selected hidden>
                    Select Title
                  </option>
                  <option value="Mr">Mr</option>
                  <option value="Mrs">Mrs</option>
                </Form.Control>
              </Col>
              <Col xs="6" className="custom_col_add_staff mb-4">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                />
                {firstNameError && (
                  <div style={{ color: "red", marginTop: 5 }}>
                    First name must be at least 3 characters long
                  </div>
                )}
              </Col>
            </Row>
            <Row>
              <Col xs="6" className="custom_col_add_staff mb-4">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                />
              </Col>
              <Col xs="6" className="custom_col_add_staff mb-4">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  type="number"
                  name="mobileNumber"
                  value={formData.mobileNumber}
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <Row>
              <Col xs="6" className="custom_col_add_staff mb-4">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </Col>
              <Col xs="6" className="custom_col_add_staff mb-4">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  type="text"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                />
              </Col>
            </Row>

            <Row>
              <Col xs="6" className="custom_col_add_staff mb-4">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  name="pwd"
                  value={formData.pwd}
                  onChange={handleChange}
                  required
                />
              </Col>
              <Col xs="6" className="custom_col_add_staff mb-4">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  type="password"
                  name="confirmPwd"
                  value={formData.confirmPwd}
                  onChange={handleChange}
                  required
                />
                {passwordError && (
                  <div style={{ color: "red", marginTop: 5 }}>
                    Passwords do not match
                  </div>
                )}
              </Col>
            </Row>

            <Row>
              <Col xs="6" className="custom_col_add_staff mb-4">
                <Form.Label>Country</Form.Label>
                <Form.Select
                  type="text"
                  name="country"
                  value={formData.country}
                  onChange={handleChange}
                >
                  <option value="" selected hidden>
                    Please select
                  </option>
                  {countryList.map((country) => (
                    <option key={country.isdCode} value={country.isdCode}>
                      {country.countryName}
                    </option>
                  ))}
                </Form.Select>
              </Col>

              <Col xs="6" className="custom_col_add_staff mb-4">
                <Form.Label>Country Code</Form.Label>
                <Form.Select
                  as="select"
                  name="isdCode"
                  value={formData.isdCode}
                  onChange={handleChange}
                >
                  <option value="" selected hidden>
                    Please select
                  </option>
                  {countryList.map((country) => (
                    <option key={country.isdCode} value={country.isdCode}>
                      {country.isdCode}
                    </option>
                  ))}
                </Form.Select>
              </Col>
            </Row>

            <button className="add_staff_submit_btn" type="submit">
              Submit
            </button>
          </Form>
          {error && <p className="error_message">{error}</p>}
        </div>
      </div>
    </>
  );
};

export default AddUser;
