import React, { useEffect, useState } from "react";
import { Accordion, Button, Card, Form } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Instance from "../../Utils/Axios";
import { showToast } from "../../Utils/index";
import { Link, useNavigate } from "react-router-dom";

const Active = () => {
  const [id, setId] = useState(1);
  const [accordionOpen, setAccordionOpen] = useState(false);
  const navigate = useNavigate();

  const [supportList, setSupportList] = useState([]);
  const [tempData, setTempData] = useState([]);
  const [error, setError] = useState("");

  const [filters, setFilters] = useState({
    userName: "",
    mobileNumber: "",
    description: "",
    createdOn: "",
    status: "",
    supportId: "",
  });
  const handleSubmit = async (id) => {
    // e.preventDefault();
    try {
      const token = localStorage.getItem("adminToken");
      const response = await Instance.post(`/siteadmin/user/support/update`, {
        adminToken: token,
        supportId: id,
        status: "Resolved",
      });

      if (response.data.resCode === "200") {
        showToast("Updated Succesfully!", "success", "success");
        fetchSupportList();
        //   setTimeout(() => {
        //     navigate("/manage_support");
        //   }, 1000);
      } else {
        setError(response.data.resMessage);
      }
    } catch (error) {
      setError("Failed to update support details");
      console.error("Error updating support details:", error);
    }
  };

  const updateQuery = (id) => {
    const ans = window.confirm(`Are You Sure Close Query ?  `);

    if (ans === true) {
      handleSubmit(id);
    }
  };

  const columns = [
    {
      name: "Ticket No",
      selector: (row) => row.supportId,
      sortable: true,
      wrap: true,
    },
    {
      name: "Contact Name",
      selector: (row) => row.userName,
      sortable: true,
      wrap: true,
    },

    {
      name: "Phone",
      selector: (row) => row.mobileNumber,
      sortable: true,
      wrap: true,
    },
    {
      name: "Query",
      selector: (row) => row.description,
      sortable: true,
      wrap: true,
    },
    {
      name: "Date",
      selector: (row) => row.createdOn,
      sortable: true,
      wrap: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      wrap: true,
    },

    {
      name: "Actions",
      //  selector: row => row.mobile,
      cell: (row) => (
        <>
          <Link to={`/admin/support/view_chat/${row.supportId}`}>
            <img
              src="/build/watch.svg"
              alt="edit"
              width={24}
              height={24}
              priority
              className="me-2"
            />
          </Link>

          <a href={`#`}>
            <img
              src="/build/notification.png"
              alt="edit"
              width={24}
              height={24}
              priority
              className="me-2"
            />
          </a>

          <a href={`#`} onClick={() => updateQuery(row.supportId)}>
            <img
              src="/build/Closequery.png"
              alt="edit"
              width={24}
              height={24}
              priority
              className="me-2"
            />
          </a>
        </>
      ),
    },
  ];

  const fetchSupportList = () => {
    const token = localStorage.getItem("adminToken");
    console.log("Fetched token from localStorage:", token);
    Instance.post("/siteadmin/user/support/getList", {
      adminToken: token,
      status: "OPEN",
    })
      .then((response) => {
        setSupportList(response?.data?.message?.tickets);
        setTempData(response?.data?.message?.tickets);
        setError("");
        // console.log("check data", response?.data?.message);
      })
      .catch((error) => {
        setError("Failed to fetch the data");
        console.error("Error fetching User list:", error);
      });
  };
  useEffect(() => {
    fetchSupportList();
  }, []);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const applyFilters = () => {
    const token = localStorage.getItem("adminToken");
    Instance.post("/siteadmin/user/support/getList", {
      adminToken: token,
      userName: filters.userName,
      mobileNumber: filters.mobileNumber,
      description: filters.description,
      createdOn: filters.createdOn,
      status: filters.status,
      supportId: filters.supportId,
    })
      .then((response) => {
        setTempData(response?.data?.message?.tickets);
        setError("");
        // console.log("Filtered data:", response?.data?.message);
      })
      .catch((error) => {
        setError("Failed to apply filters");
        console.error("Error applying filters:", error);
      });
  };

  const clearFilters = () => {
    const token = localStorage.getItem("adminToken");
    Instance.post("/siteadmin/user/support/getList", {
      adminToken: token,
      // status: filters.status,
      userName: "",
      mobileNumber: "",
      description: "",
      createdOn: "",
      status: "",
      supportId: "",
    })
      .then((response) => {
        setFilters({
          userName: "",
          mobileNumber: "",
          description: "",
          createdOn: "",
          status: "",
          supportId: "",
        });
        setSupportList(response?.data?.message?.tickets);
        setTempData(response?.data?.message?.tickets);
        setError("");
        // console.log("Cleared filters. Updated data:", response?.data?.message);
      })
      .catch((error) => {
        setError("Failed to clear filters");
        console.error("Error clearing filters:", error);
      });
  };

  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(supportList[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  function downloadCSV(array) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = "export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      setAccordionOpen(!accordionOpen)
    );
    return (
      <button
        type="button"
        className="admin_filter_btn"
        // style={{ backgroundColor: "pink" }}
        onClick={decoratedOnClick}
      >
        {children}
        {accordionOpen ? (
          <img
            src="/build/img/Icons/down_vector.svg"
            alt="zex pr wire Logo"
            width={16}
            height={16}
          />
        ) : (
          <img
            src="/build/img/Icons/down_vector.svg"
            alt="zex pr wire Logo"
            width={16}
            height={16}
            className="filter_up_arrow"
          />
        )}
      </button>
    );
  }

  const filterData = (e) => {
    let filterText = e.target.value;
    if (!filterText) {
      setTempData([...supportList]);
    } else {
      const filteredItems = supportList.filter(
        (item) =>
          (item.userName &&
            item.userName.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.mobileNumber &&
            item.mobileNumber
              .toLowerCase()
              .includes(filterText.toLowerCase())) ||
          (item.description &&
            item.description
              .toLowerCase()
              .includes(filterText.toLowerCase())) ||
          (item.createdOn &&
            item.createdOn.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.status &&
            item.status.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.supportId &&
            item.supportId.toLowerCase().includes(filterText.toLowerCase()))
      );
      setTempData([...filteredItems]);
    }
  };

  const data = [
    {
      No: " 154",
      Ticket: "1",
      Name: "saurabh singla",
      Phone: "	9999898989 ",
      Query: "Press Release Submission",
      Date: "2021-04-15",
      Status: "Cleared",
    },
    {
      No: " 153",
      Ticket: "2",
      Name: "Akshay Chavan",
      Phone: "	9637101313 ",
      Query: "Press Release Submission",
      Date: "2021-04-15",
      Status: "Cleared",
    },
  ];
  return (
    <>
      <Accordion className="my-4">
        <Card>
          <Card.Header className="d-md-flex justify-content-between align-items-center">
            <div className="support_search">
              <label>
                Search:
                <input
                  type="search"
                  placeholder=""
                  aria-controls="datatable"
                  onChange={filterData}
                ></input>
              </label>
            </div>
            <div className="d-md-flex">
              <button
                onClick={(e) => downloadCSV(supportList)}
                className="btn btn-export mx-3"
              >
                <img
                  src="/build/img/Icons/export.svg"
                  alt="zex pr wire Logo"
                  width={25}
                  height={25}
                />
              </button>
              <CustomToggle as={Button} variant="link" eventKey="0">
                Filters
              </CustomToggle>
            </div>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <Form>
                <div className="d-flex gap-4 flex-wrap">
                  <Form.Group
                    controlId="filterStatus"
                    className="support_filter_options"
                  >
                    <Form.Label>Status</Form.Label>
                    <Form.Control
                      as="select"
                      name="status"
                      value={filters.status}
                      onChange={handleFilterChange}
                    >
                      <option value=""></option>
                      <option value="OPEN">Open</option>
                      <option value="Resolved">Resolved</option>
                    </Form.Control>
                  </Form.Group>

                  <Form.Group
                    controlId="filterUsername"
                    className="support_filter_options"
                  >
                    <Form.Label>Contact Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Search Contact Name"
                      name="userName"
                      value={filters.userName}
                      onChange={handleFilterChange}
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group
                    controlId="filterUsername"
                    className="support_filter_options"
                  >
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Search Phone Number"
                      name="mobileNumber"
                      value={filters.mobileNumber}
                      onChange={handleFilterChange}
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group
                    controlId="filterName"
                    className="support_filter_options"
                  >
                    <Form.Label>Ticket No</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Ticket No"
                      name="supportId"
                      value={filters.supportId}
                      onChange={handleFilterChange}
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group
                    controlId="filterEmail"
                    className="support_filter_options"
                  >
                    <Form.Label> Date</Form.Label>
                    <Form.Control
                      type="datetime-local"
                      id="datetime"
                      placeholder="Search Date"
                      name="createdOn"
                      value={filters.createdOn}
                      onChange={handleFilterChange}
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group
                    controlId="filterEmail"
                    className="support_filter_options"
                  >
                    <Form.Label>Query</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Search Query"
                      name="description"
                      value={filters.description}
                      onChange={handleFilterChange}
                    ></Form.Control>
                  </Form.Group>
                </div>
                <div className="mt-3 d-flex gap-3">
                  <Button variant="secondary" onClick={clearFilters}>
                    Clear
                  </Button>
                  <Button variant="primary" onClick={applyFilters}>
                    Apply
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>

      <DataTable
        className="data-table-wrapper"
        columns={columns}
        data={tempData}
        pagination
        defaultSortFieldId={1}
        responsive={true}
        defaultSortAsc={true}
      />
    </>
  );
};

export default Active;
