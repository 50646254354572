import React from "react";
import Navbar from "../../components/NavbarProfile/NavbarProfile";
import Sidebar from "../../components/SidebarNew/Sidebar";
import Footer from "../../components/Layouts/Footer";
import "./SupportManagement.css";
import ManageSupport from "../../components/Support/ManageSupport";

const SupportManagement = () => {
  const activeSide = 24;
  return (
    <>
      <div className="row gx-0 support_manage_content ">
        <Sidebar activeSide={activeSide} />
        <div
          className={`main col d-flex flex-column`}
          style={{ overflow: "auto" }}
        >
          <Navbar />
          <div className="flex-grow-1">
            <ManageSupport />
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default SupportManagement;
