import "./UserManage.css";
import { Table, Dropdown, DropdownButton } from "react-bootstrap";
import { useState, useEffect } from "react";
import Instance from "../../Utils/Axios";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import { Link, useNavigate } from "react-router-dom";
import { showToast } from "../../Utils/index";

const UserManage = () => {
  const [userData, setUserData] = useState([]);
  const [userList, setUserList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [tempData, setTempData] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [filteredTemplates, setFilteredTemplates] = useState([]);
  const [error, setError] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [isChecked, setIsChecked] = useState(null);
  const navigate = useNavigate();

  const [filters, setFilters] = useState({
    status: "",
    email: "",
    userId: "",
    mobileNumber: "",
    country: "",
    state: "",
    city: "",
  });
  const [accordionOpen, setAccordionOpen] = useState(false);

  const fetchUserList = () => {
    const token = localStorage.getItem("adminToken");
    Instance.post("/siteadmin/user/list", {
      adminToken: token,
      status: "",
      fromDate: "",
      toDate: "",
      email: "",
      userId: "",
      country: "",
      state: "",
      city: "",
      mobileNumber: "",
      parentId: "",
    })
      .then((response) => {
        setUserData(response?.data);
        setUserList(response?.data?.message);
        setFilteredTemplates(response?.data?.message);

        // console.log("status is ==========", response?.data?.message);

        // if (response?.data?.message?.status === "ACTIVE") {
        //   isChecked(true);
        // } else if (response?.data?.message?.status === "DEACTIVE") {
        //   isChecked(false);
        // }

        setError("");
        console.log("User list fetched successfully:", response?.data?.message);
      })
      .catch((error) => {
        setError("Failed to fetch user list");
        console.error("Error fetching user list:", error);
      });
  };

  useEffect(() => {
    fetchUserList();
  }, []);

  const fetchCountryList = () => {
    const token = localStorage.getItem("adminToken");
    console.log("Fetched token from localStorage:");
    Instance.post("/siteadmin/common/countryList", {
      adminToken: token,
      countryName: "",
      countryCode: "",
      isdCode: "",
      currency: "",
    })
      .then((response) => {
        setCountryList(response?.data?.message);
        setTempData(response?.data?.message);
        setError("");
        console.log("check data", response?.data?.message);
      })
      .catch((error) => {
        setError("Failed to fetch the data");
        console.error("Error fetching country list:", error);
      });
  };

  useEffect(() => {
    fetchCountryList();
  }, []);

  const fetchStateList = (code) => {
    const token = localStorage.getItem("adminToken");
    // console.log("Fetched token from localStorage:");
    Instance.post("/siteadmin/common/getStates", {
      adminToken: token,
      code: code,
    })
      .then((response) => {
        setStateList(response?.data?.message);
        setTempData(response?.data?.message);
        setError("");
        console.log("check data", response?.data?.message);
      })
      .catch((error) => {
        setError("Failed to fetch the data");
        // console.error("Error fetching country list:", error);
      });
  };
  // useEffect(() => {
  //   fetchStateList();
  // }, []);

  console.log("---------", stateList);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const handleApplyFilters = () => {
    const token = localStorage.getItem("adminToken");
    Instance.post("/siteadmin/user/list", {
      adminToken: token,
      status: filters.status,
      email: filters.email,
      userId: filters.userId,
      mobileNumber: filters.mobileNumber,
      country: filters.country,
      state: filters.state,
      city: filters.city,
    })
      .then((response) => {
        setFilteredTemplates(response?.data?.message);
        setError("");
        console.log("Filtered data:", response?.data?.message);
      })
      .catch((error) => {
        setError("Failed to apply filters");
        console.error("Error applying filters:", error);
      });
  };

  const handleClearFilters = () => {
    const token = localStorage.getItem("adminToken");
    Instance.post("/siteadmin/user/list", {
      adminToken: token,
      status: "",
      email: "",
      userId: "",
      mobileNumber: "",
      country: "",
      state: "",
      city: "",
    })
      .then((response) => {
        setFilters({
          status: "",
          email: "",
          userId: "",
          mobileNumber: "",
          country: "",
          state: "",
          city: "",
        });
        setUserList(response?.data?.message);
        setFilteredTemplates(response?.data?.message);
        setError("");
        console.log("Cleared filters. Updated data:", response?.data?.message);
      })
      .catch((error) => {
        setError("Failed to clear filters");
        console.error("Error clearing filters:", error);
      });
  };

  const NameCell = ({ row }) => (
    <div className="d-flex flex-column gap-1">
      <div>{row.firstName}</div>
      <div>{row.lastName}</div>
    </div>
  );

  const handleSubmit = async (status, id) => {
    // e.preventDefault();
    try {
      const token = localStorage.getItem("adminToken");
      const response = await Instance.post(`/siteadmin/user/updateUser`, {
        adminToken: token,
        userId: id,
        status: status,
      });
      // console.log("Response from updateCoupon API:", response.data);
      if (response.data.resCode === "200") {
        fetchUserList();
        showToast("Updated Succesfully!", "success", "success");
        // setTimeout(() => {
        //   // navigate("/user");
        //   window.location.reload();
        // }, 1000);
      } else {
        setError(response.data.resMessage);
      }
    } catch (error) {
      setError("Failed to update user details");
      console.error("Error updating user details:", error);
    }
  };

  const handleToggleStatus = (row) => {
    const confirmed = window.confirm(
      `Are you sure you want to ${row?.status === "ACTIVE" ? "deactivate" : "activate"
      } status?`
    );
    console.log(confirmed, row?.userId);

    if (row?.status === "ACTIVE" && confirmed === true) {
      handleSubmit("DEACTIVE", row?.userId);
    } else if (row?.status === "DEACTIVE" && confirmed === true) {
      handleSubmit("ACTIVE", row?.userID);
    }
  };

  const handleChecked = (event, status, id) => {
    const confirmed = window.confirm(
      `Are you sure you want to ${status === "ACTIVE" ? "deactivate" : "activate"
      } status?`
    );

    if (status === "ACTIVE" && confirmed === true) {
      handleSubmit("DEACTIVE", id);
    } else if (
      (status === "DEACTIVE" || status === "PENDING") &&
      confirmed === true
    ) {
      handleSubmit("ACTIVE", id);
    }

    // else if (status === "DEACTIVE" && confirmed === true) {
    //   handleSubmit("ACTIVE", id);
    // }

    console.log("Switch value:", event.target.checked, status, id);
  };
  const columns = [
    {
      name: "Sr.No.",
      selector: (row) => parseInt(row.userId),
      sortable: true,
      width: "100px",
      wrap: "true",
    },
    {
      name: "Name",
      cell: (row) => (
        <Link to={`/admin/user/history/${row.userId}`}>
          <NameCell row={row} />,
        </Link>
      ),
      sortable: true,
      width: "100px",
      wrap: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      width: "180px",
      wrap: true,
    },
    {
      name: "Email address verification",
      selector: (row) => (
        <div className={row.isEmailVerified ? "thumb" : "downthumb"}></div>
      ),
      width: "210px",
      wrap: true,
    },
    { name: "Phone", selector: (row) => row.mobileNumber, sortable: true },
    {
      name: "Status",
      cell: (row) => (
        <>
          {/* {console.log(row.status)} */}
          {/* {`${row.status}` === "ACTIVE"
            ? setIsChecked(true)
            : setIsChecked(false)} */}
          <Form>
            <Form.Check
              type="switch"
              id="custom-switch"
              checked={row?.status === "ACTIVE"}
              // checked={isChecked}
              // onChange={(e) => console.log(e.target.checked, row.userId)}
              onChange={(e) => handleChecked(e, row.status, row.userId)}
            />
          </Form>
        </>
      ),
    },
    {
      name: "Increase PR",
      cell: (row) => (
        <Link to={`/admin/user/edit_pr_view/${row.userId}`}>
          <img src="/build/plus.svg" alt="pr" width={18} height={20} />
        </Link>
      ),
      width: "140px",
    },
    {
      name: "PR History",
      cell: (row) => (
        <Link to={`/admin/user/editPr_history_view/${row.userId}`}>
          <div className="pr-history"></div>,
        </Link>
      ),
      width: "140px",
    },
    {
      name: "Edit",
      cell: (row) => (
        <span className="d-flex gap-1">
          <Link to={`/admin/edit_user/${row.email}`}>
            <img
              src="/build/edit.svg"
              alt="edit"
              width={24}
              height={24}
              priority
            />
          </Link>
        </span>
      ),
      width: "80px",
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "10px",
        paddingRight: "10px",
      },
    },
    cells: {
      style: {
        padding: "10px",
        // paddingRight: "8px",
      },
    },
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  const handleItemsPerPageChange = (value) => {
    setItemsPerPage(value);
    setCurrentPage(0);
  };

  const offset = currentPage * itemsPerPage;
  const currentItems = filteredTemplates.slice(offset, offset + itemsPerPage);
  const pageCount = Math.max(Math.ceil(userList.length / itemsPerPage), 1);

  const handleSearch = (e) => {
    let filterText = e.target.value.toLowerCase();
    const filteredItems = userList.filter((item) => {
      return (
        (item.firstName && item.firstName.toLowerCase().includes(filterText)) ||
        (item.lastName && item.lastName.toLowerCase().includes(filterText)) ||
        (item.mobileNumber &&
          item.mobileNumber.toLowerCase().includes(filterText)) ||
        (item.userId && item.userId.toLowerCase().includes(filterText)) ||
        (item.email && item.email.toLowerCase().includes(filterText)) ||
        (item.countryName &&
          item.countryName.toLowerCase().includes(filterText)) ||
        (item.state && item.state.toLowerCase().includes(filterText)) ||
        (item.city && item.city.toLowerCase().includes(filterText)) ||
        (item.status && item.status.toLowerCase().includes(filterText))
      );
    });
    setFilteredTemplates(filteredItems);
  };

  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = ["userId", "firstName", "email", "mobileNumber", "status"];

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  function downloadCSV(array) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = "user_details.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      setAccordionOpen(!accordionOpen)
    );

    return (
      <button
        type="button"
        className="admin_filter_btn"
        // style={{ backgroundColor: "pink" }}
        onClick={decoratedOnClick}
      >
        {children}
        {accordionOpen ? (
          <img
            src="/build/img/Icons/down_vector.svg"
            alt="zex pr wire Logo"
            width={16}
            height={16}
          />
        ) : (
          <img
            src="/build/img/Icons/down_vector.svg"
            alt="zex pr wire Logo"
            width={16}
            height={16}
            className="filter_up_arrow"
          />
        )}
      </button>
    );
  }

  return (
    <>
      <div className="user_manage_layout">
        <div className="page_heading_user" style={{ marginTop: "-20px" }}>
          <h4> Manage User </h4>
          <div>
            {/* <img
              src="/build/download.png"
              alt="download"
              height="45px "
              width="45px"
            />
            &nbsp; &nbsp; */}
            <button className="refresh_btn" onClick={handleRefresh}>
              Refresh
            </button>
          </div>
        </div>
        <div
          className="total_users_details row  "
          style={{ marginTop: "-10px" }}
        >
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12  mb-3  ">
            <div className=" user_count   user_one  ">
              <div>
                <h4>{userData.total}</h4>
                <p>Total Users</p>
              </div>
              <div>
                {/* <img
                ttp://104.211.152.128/zv-3/assets_admin/new-images/User-svg.png"
                src="/build/user.svg"
                alt="user-icon"
                width={64}
                height={64}
              /> */}
                <div className="user-image"></div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-3">
            <div className="  add_new_user d-flex  ">
              <div>
                <h4>ADD</h4>
                <p>New User</p>
              </div>
              <span>
                <Link to={"/admin/user/add"} className="imgg">
                  {/* <img
                  // src="http://104.211.152.128/zv-3/assets_admin/new-images/Adduser-svg.png"
                  src="/build/user-icon.svg"
                  alt="user-icon"
                  width={64}
                  height={64}
                /> */}
                  <div className="adduser-image"></div>
                </Link>
              </span>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-3    ">
            <div className="  user_information_box  ">
              <h4>Information</h4>
              <p>Total no of active users this month : {userData.active}</p>
              <p>Total PR Submitted : {userData?.prSubmitted}</p>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end">
          {/* <div className="admin_staff_entries">
            <p>Show</p>
            <DropdownButton
              id="dropdown-basic-button"
              title={itemsPerPage}
              className="custom_staff_dropdown"
              onSelect={handleItemsPerPageChange}
            >
              <Dropdown.Item eventKey={10}>10</Dropdown.Item>
              <Dropdown.Item eventKey={25}>25</Dropdown.Item>
              <Dropdown.Item eventKey={50}>50</Dropdown.Item>
              <Dropdown.Item eventKey={100}>100</Dropdown.Item>
            </DropdownButton>
            <p>entries</p>
          </div> */}
        </div>
        <Accordion className="mb-3 mt-3">
          <Card>
            <Card.Header className="d-md-flex justify-content-between align-items-center">
              <div className="staff_search">
                <label>
                  Search:
                  <input
                    type="search"
                    placeholder=""
                    aria-controls="datatable"
                    onChange={handleSearch}
                  ></input>
                </label>
              </div>
              <div className="d-sm-flex">
                <button
                  onClick={(e) => downloadCSV(userList)}
                  className="btn btn-export mx-3"
                >
                  <img
                    src="/build/img/Icons/export.svg"
                    alt="zex pr wire Logo"
                    width={25}
                    height={25}
                  />
                </button>
                <CustomToggle as={Button} variant="link" eventKey="0">
                  Filters
                </CustomToggle>
              </div>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Form>
                  <div className="d-flex gap-4 flex-wrap">
                    <Form.Group
                      controlId="filterStatus"
                      className="template_filter_options"
                    >
                      <Form.Label>Status</Form.Label>
                      <Form.Control
                        as="select"
                        name="status"
                        value={filters.status}
                        onChange={handleFilterChange}
                        className=""
                      >
                        <option value="">All</option>
                        <option value="ACTIVE">ACTIVE</option>
                        <option value="PENDING">PENDING</option>
                      </Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filterEmail"
                      className="template_filter_options"
                    >
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search Email"
                        name="email"
                        value={filters.email}
                        onChange={handleFilterChange}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filteruserId"
                      className="template_filter_options"
                    >
                      <Form.Label>User ID</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search User Id"
                        name="userId"
                        value={filters.userId}
                        onChange={handleFilterChange}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filtermobile"
                      className="template_filter_options"
                    >
                      <Form.Label>Mobile Number</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search Number"
                        name="mobileNumber"
                        value={filters.mobileNumber}
                        onChange={handleFilterChange}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filtercountry"
                      className="template_filter_options"
                    >
                      <Form.Label>Country</Form.Label>
                      <Form.Select
                        type="text"
                        name="country"
                        value={filters.country}
                        onChange={(e) => {
                          fetchStateList(e.target.value);

                          handleFilterChange(e);
                        }}
                      >
                        <option value="" selected hidden>
                          Please select
                        </option>
                        {countryList.map((country) => (
                          <option
                            key={country.countryCode}
                            value={country.countryCode}
                          >
                            {country.countryName}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                    <Form.Group
                      controlId="filterStatus"
                      className="template_filter_options"
                    >
                      <Form.Label>State</Form.Label>
                      <Form.Control
                        as="select"
                        name="state"
                        value={filters.state}
                        onChange={handleFilterChange}
                        className=""
                      >
                        <option value="" selected hidden>
                          Please select
                        </option>
                        {stateList.map((state) => (
                          <option key={state.id} value={state.id}>
                            {state.value}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filtermobile"
                      className="template_filter_options"
                    >
                      <Form.Label>City</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search City"
                        name="city"
                        value={filters.city}
                        onChange={handleFilterChange}
                      ></Form.Control>
                    </Form.Group>
                  </div>
                  <div className="mt-3 d-flex gap-3">
                    <Button variant="secondary" onClick={handleClearFilters}>
                      Clear
                    </Button>
                    <Button variant="primary" onClick={handleApplyFilters}>
                      Apply
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <div className="user_list_table data-table-wrapper">
          <DataTable
            columns={columns}
            data={filteredTemplates}
            pagination
            responsive={true}
            defaultSortFieldId={1}
            defaultSortAsc={true}
            className="user_data_table  "
            style={{ overflow: "wrap" }}
            customStyles={customStyles}
          />
          {/* <Table className="mb-0" striped bordered hover>
            <thead className="user_list_table_head">
              <tr>
                <th>
                  Sr.No.
                  <img
                    className="user_table_sort_img"
                    src="/build/sort.svg"
                    alt="sort"
                    width={10}
                    height={15}
                    priority
                  />
                </th>
                <th>
                  Name
                  <img
                    className="user_table_sort_img"
                    src="/build/sort.svg"
                    alt="sort"
                    width={10}
                    height={15}
                    priority
                  />
                </th>
                <th>
                  Email
                  <img
                    className="user_table_sort_img"
                    src="/build/sort.svg"
                    alt="sort"
                    width={10}
                    height={15}
                    priority
                  />
                </th>
                <th>
                  Email address verification
                  <img
                    className="user_table_sort_img"
                    src="/build/sort.svg"
                    alt="sort"
                    width={10}
                    height={15}
                    priority
                  />
                </th>
                <th>
                  Phone
                  <img
                    className="user_table_sort_img"
                    src="/build/sort.svg"
                    alt="sort"
                    width={10}
                    height={15}
                    priority
                  />
                </th>
                <th>
                  Active / Deactive
                  <img
                    className="user_table_sort_img"
                    src="/build/sort.svg"
                    alt="sort"
                    width={10}
                    height={15}
                    priority
                  />
                </th>
                <th>
                  Increase PR
                  <img
                    className="user_table_sort_img"
                    src="/build/sort.svg"
                    alt="sort"
                    width={10}
                    height={15}
                    priority
                  />
                </th>
                <th>
                  PR History
                  <img
                    className="user_table_sort_img"
                    src="/build/sort.svg"
                    alt="sort"
                    width={10}
                    height={15}
                    priority
                  />
                </th>
                <th>
                  Edit
                  <img
                    className="user_table_sort_img"
                    src="/build/sort.svg"
                    alt="sort"
                    width={10}
                    height={15}
                    priority
                  />
                </th>
              </tr>
            </thead>
            <tbody className="user_list_table_body">
              {currentItems.map((user, index) => (
                <tr key={user.id}>
                  <td>{offset + index + 1}</td>
                  <td>
                    {user.firstName} {user.lastName}
                  </td>
                  <td>{user.email}</td>
                  <td>
                    <img
                      src="http://104.211.152.128/zv-3/assets_admin/images/Group%20434.svg"
                      alt="verified"
                      width={30}
                      height={30}
                    />
                  </td>
                  <td>{user.mobile}</td>
                  <td>
                    {user.status === "ACTIVE" ? (
                      <label className="switch">
                        <input type="checkbox" checked />
                        <span className="slider round"></span>
                      </label>
                    ) : (
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider round"></span>
                      </label>
                    )}
                  </td>
                  <td>
                    <img
                      src="http://104.211.152.128/zv-3/assets_admin/new-images/Add.png"
                      alt="pr"
                      width={18}
                      height={20}
                    />
                  </td>
                  <td>
                    <img
                      src="http://104.211.152.128/zv-3/assets_admin/new-images/history.png"
                      alt="history"
                      width={18}
                      height={20}
                    />
                  </td>
                  <td className="d-flex gap-2 align-items-center">
                    <img
                      src="/build/edit.svg"
                      alt="edit"
                      width={24}
                      height={24}
                      priority
                    />
                    <img
                      src="/build/delete.svg"
                      alt="delete"
                      width={24}
                      height={24}
                      priority
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table> */}
          {error && <p className="error_message">{error}</p>}
        </div>
        {/* <div className="d-flex justify-content-between mt-2">
          <p>
            Showing {offset + 1} to {offset + currentItems.length} of{" "}
            {userList.length} entries
          </p>
          <div>
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              previousClassName={"custom_previous_user"}
              nextClassName={"custom_next_user"}
              pageClassName={"custom_page_user"}
              containerClassName={"pagination_user"}
              activeClassName={"active_user"}
            />
          </div>
        </div> */}
      </div>
    </>
  );
};
export default UserManage;
