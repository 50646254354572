"use client";
import {
  Affiliatesvg,
  Companysvg,
  Conatactussvg,
  Dashboardsvg,
  Gallerysvg,
  Newsroomsvg,
  PaymentHistorysvg,
  Pricingsvg,
  Prmanage,
  Supportsvg,
} from "../../../public/svgs/svg";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Sidebar.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Accordion from "react-bootstrap/Accordion";
import axios from "axios";
import { showToast } from "../../Utils";

export default function Sidebar({ activeSide, activeEventKey }) {
  const [activeTab, setActiveTab] = useState(activeSide);
  const [showFullSidebar, setShowFullSidebar] = useState(true);
  const navigate = useNavigate();

  const handleLogout = async () => {
    const token = localStorage.getItem("adminToken");
    console.log("Fetched token from localStorage for logout:", token);

    try {
      const response = await axios.post(
        "https://pradmin.webandapi.com/siteadmin/staff/logout",
        {
          adminToken: token,
        }
      );
      console.log("logout response", response.data);
      showToast("Logout Succesfully", "success", "success");
      setTimeout(() => {
        navigate("/");
      }, 1000);
    } catch (error) {
      console.error("Error logging out:", error);
      showToast("Logout Failed", "error", "error");
    }
  };

  return (
    <sidebar
      className="sidebar col"
      style={{ backgroundImage: "url('/build/img/sidebarLines.svg')" }}
    >
      <div className={`d-none d-lg-flex logo_wrapper`}>
        <img
          className={""}
          src="/build/img/imglogowhite.svg"
          alt="zex pr wire Logo"
          width={110}
          height={50}
          priority
        />
      </div>
      <div
        className={` d-lg-none logo_wrapper cursor-pointer`}
        style={{ cursor: "pointer" }}
        onClick={() => setShowFullSidebar(!showFullSidebar)}
      >
        <img
          className={""}
          src="/build/img/imglogowhite.svg"
          alt="zex pr wire Logo"
          width={showFullSidebar ? 110 : 50}
          height={showFullSidebar ? 50 : 30}
          priority
        />
      </div>
      <div className="sidebar-accordion ps-0 nav_items_container">
        <Accordion defaultActiveKey={activeEventKey}>
          {!showFullSidebar ? (
            <div className="ps-4">
              <img
                src="/build/user.svg"
                alt="user-icon"
                width={19}
                height={19}
              />
            </div>
          ) : (
            <Accordion.Item eventKey="1">
              <Accordion.Header className="nav_item">
                <div className="d-flex">
                  <img
                    src="/build/user.svg"
                    alt="user-icon"
                    width={19}
                    height={19}
                  />
                  <p className="sidebarname">Manage users</p>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                {/* <div className="linee" style={{ height: "80px" }}></div> */}
                <div className="item_subcategories">
                  <li
                    className={`cursor-pointer nav_item${activeTab === 1 ? "_active" : ""
                      }`}
                    onClick={() => setActiveTab(2)}
                  >
                    <Link
                      to="/admin/user"
                      className={`${showFullSidebar ? "" : "d-none"
                        }  d-lg-inline`}
                      style={{ paddingLeft: "10px", paddingRight: "93px" }}
                    >
                      Manage Users
                    </Link>
                  </li>
                  <li
                    className={`cursor-pointer nav_item${activeTab === 2 ? "_active" : ""
                      }`}
                    onClick={() => setActiveTab(2)}
                  >
                    <Link
                      to="/admin/user_delete"
                      className={`${showFullSidebar ? "" : "d-none"
                        }   d-lg-inline`}
                      style={{ paddingLeft: "10px", paddingRight: "102px" }}
                    >
                      Deleted User
                    </Link>
                  </li>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          )}

          {!showFullSidebar ? (
            <div className="ps-4 mt-4">
              <img
                src="/build/editorial.svg"
                alt="editorial-icon"
                width={19}
                height={19}
              />
            </div>
          ) : (
            <Accordion.Item eventKey="2">
              <Accordion.Header className="nav_item">
                <div className="d-flex">
                  <img
                    src="/build/editorial.svg"
                    alt="editorial-icon"
                    width={19}
                    height={19}
                  />

                  <p className="sidebarname">Editorial Room</p>

                  <div></div>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                {/* <div className="linee" style={{ height: "400px" }}></div> */}
                <div className="item_subcategories">
                  <li
                    className={`cursor-pointer nav_item${activeTab === 3 ? "_active" : ""
                      }`}
                    onClick={() => setActiveTab(2)}
                  >
                    <Link
                      to="/admin/editorial"
                      className={`${showFullSidebar ? "" : "d-none"
                        } d-lg-inline`}
                      style={{ paddingLeft: "10px", paddingRight: "90px" }}
                    >
                      Editorial Room
                    </Link>
                  </li>
                  <li
                    className={`cursor-pointer nav_item${activeTab === 4 ? "_active" : ""
                      }`}
                    onClick={() => setActiveTab(2)}
                  >
                    <Link
                      to="/admin/editorial/all_press_release"
                      className={`${showFullSidebar ? "" : "d-none"
                        } d-lg-inline`}
                      style={{ paddingLeft: "10px", paddingRight: "115px" }}
                    >
                      All Release
                    </Link>
                  </li>
                  <li
                    className={`cursor-pointer nav_item${activeTab === 5 ? "_active" : ""
                      }`}
                    onClick={() => setActiveTab(2)}
                  >
                    <Link
                      to="/admin/editorial/draft_press_release"
                      className={`${showFullSidebar ? "" : "d-none"
                        } d-lg-inline`}
                      style={{ paddingLeft: "10px", paddingRight: "151px" }}
                    >
                      Draft
                    </Link>
                  </li>
                  <li
                    className={`cursor-pointer nav_item${activeTab === 6 ? "_active" : ""
                      }`}
                    onClick={() => setActiveTab(2)}
                  >
                    <Link
                      to="/admin/editorial/pending_press_release"
                      className={`${showFullSidebar ? "" : "d-none"
                        } d-lg-inline`}
                      style={{ paddingLeft: "10px", paddingRight: "131px" }}
                    >
                      Pending
                    </Link>
                  </li>
                  <li
                    className={`cursor-pointer nav_item${activeTab === 7 ? "_active" : ""
                      }`}
                    onClick={() => setActiveTab(2)}
                  >
                    <Link
                      to="/admin/editorial/action_press_release"
                      className={`${showFullSidebar ? "" : "d-none"
                        } d-lg-inline`}
                      style={{ paddingLeft: "10px", paddingRight: "81px" }}
                    >
                      Action Required
                    </Link>
                  </li>
                  <li
                    className={`cursor-pointer nav_item${activeTab === 8 ? "_active" : ""
                      }`}
                    onClick={() => setActiveTab(2)}
                  >
                    <Link
                      to="/admin/editorial/publish_press_release"
                      className={`${showFullSidebar ? "" : "d-none"
                        } d-lg-inline`}
                      style={{ paddingLeft: "10px", paddingRight: "121px" }}
                    >
                      Published
                    </Link>
                  </li>
                  <li
                    className={`cursor-pointer nav_item${activeTab === 9 ? "_active" : ""
                      }`}
                    onClick={() => setActiveTab(2)}
                  >
                    <Link
                      to="/admin/editorial/rejected_press_release"
                      className={`${showFullSidebar ? "" : "d-none"
                        } d-lg-inline`}
                      style={{ paddingLeft: "10px", paddingRight: "129px" }}
                    >
                      Rejected
                    </Link>
                  </li>
                  <li
                    className={`cursor-pointer nav_item${activeTab === 10 ? "_active" : ""
                      }`}
                    onClick={() => setActiveTab(2)}
                  >
                    <Link
                      to="/admin/editorial/delete_press_release"
                      className={`${showFullSidebar ? "" : "d-none"
                        } d-lg-inline`}
                      style={{ paddingLeft: "10px", paddingRight: "113px" }}
                    >
                      Deleted PR
                    </Link>
                  </li>
                  <li
                    className={`cursor-pointer nav_item${activeTab === 11 ? "_active" : ""
                      }`}
                    onClick={() => setActiveTab(2)}
                  >
                    <Link
                      to="/admin/editorial/category_press_release"
                      className={`${showFullSidebar ? "" : "d-none"
                        } d-lg-inline`}
                      style={{ paddingLeft: "10px", paddingRight: "70px" }}
                    >
                      Manage Category
                    </Link>
                  </li>
                  <li
                    className={`cursor-pointer nav_item${activeTab === 12 ? "_active" : ""
                      }`}
                    onClick={() => setActiveTab(2)}
                  >
                    <Link
                      to="/admin/editorial/gallery_press_release"
                      className={`${showFullSidebar ? "" : "d-none"
                        } d-lg-inline`}
                      style={{ paddingLeft: "10px", paddingRight: "83px" }}
                    >
                      Manage Gallery
                    </Link>
                  </li>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          )}
          {!showFullSidebar ? (
            <div className="ps-4 mt-4">
              <img
                src="/build/analytics.svg"
                alt="analytics-icon"
                width={19}
                height={19}
              />
            </div>
          ) : (
            <Accordion.Item eventKey="3">
              <Accordion.Header className="nav_item">
                <div className="d-flex">
                  <img
                    src="/build/analytics.svg"
                    alt="analytics-icon"
                    width={19}
                    height={19}
                  />
                  <p className="sidebarname">Automatic Report System</p>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                {/* <div className="linee" style={{ height: "160px" }}></div> */}
                <div className="item_subcategories">
                  <li
                    className={`cursor-pointer nav_item${activeTab === 13 ? "_active" : ""
                      }`}
                    onClick={() => setActiveTab(13)}
                  >
                    <Link
                      to="/admin/report"
                      className={`${showFullSidebar ? "" : "d-none"
                        } d-lg-inline`}
                      style={{ paddingLeft: "10px", paddingRight: "94px" }}
                    >
                      Report Center
                    </Link>
                  </li>
                  <li
                    className={`cursor-pointer nav_item${activeTab === 14 ? "_active" : ""
                      }`}
                    onClick={() => setActiveTab(2)}
                  >
                    <Link
                      to="/admin/publication"
                      className={`${showFullSidebar ? "" : "d-none"
                        } d-lg-inline`}
                      style={{ paddingLeft: "10px", paddingRight: "106px" }}
                    >
                      Publications
                    </Link>
                  </li>
                  <li
                    className={`cursor-pointer nav_item${activeTab === 15 ? "_active" : ""
                      }`}
                    onClick={() => setActiveTab(2)}
                  >
                    <Link
                      to="/admin/template"
                      className={`${showFullSidebar ? "" : "d-none"
                        } d-lg-inline`}
                      style={{ paddingLeft: "10px", paddingRight: "120px" }}
                    >
                      Templates
                    </Link>
                  </li>
                  <li
                    className={`cursor-pointer nav_item${activeTab === 16 ? "_active" : ""
                      }`}
                    onClick={() => setActiveTab(2)}
                  >
                    <Link
                      to="/admin/network"
                      className={`${showFullSidebar ? "" : "d-none"
                        } d-lg-inline`}
                      style={{ paddingLeft: "10px", paddingRight: "123px" }}
                    >
                      Networks
                    </Link>
                  </li>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          )}
          {!showFullSidebar ? (
            <Link>
              <div className="ps-4 mt-4">
                <img
                  src="/build/package-admin.svg"
                  alt="user-icon"
                  width={19}
                  height={19}
                />
              </div>
            </Link>
          ) : (
            <Accordion.Item eventKey="4">
              <Accordion.Header className="nav_item">
                <div className="d-flex">
                  <img
                    src="/build/package-admin.svg"
                    alt="user-icon"
                    width={19}
                    height={19}
                  />
                  <p className="sidebarname">Manage Packages</p>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                {/* <div className="linee" style={{ height: "280px" }}></div> */}
                <div className="item_subcategories">
                  <li
                    className={`cursor-pointer nav_item${activeTab === 17 ? "_active" : ""
                      }`}
                    onClick={() => setActiveTab(2)}
                  >
                    <Link
                      to="/admin/package"
                      className={`${showFullSidebar ? "" : "d-none"
                        } d-lg-inline`}
                      style={{ paddingLeft: "10px", paddingRight: "125px" }}
                    >
                      Packages
                    </Link>
                  </li>

                  <li
                    className={`cursor-pointer nav_item${activeTab === 18 ? "_active" : ""
                      }`}
                    onClick={() => setActiveTab(2)}
                  >
                    <Link
                      to="/admin/online_package"
                      className={`${showFullSidebar ? "" : "d-none"
                        } d-lg-inline`}
                      style={{ paddingLeft: "10px", paddingRight: "86px" }}
                    >
                      Online Package
                    </Link>
                  </li>
                  <li
                    className={`cursor-pointer nav_item${activeTab === 19 ? "_active" : ""
                      }`}
                    onClick={() => setActiveTab(2)}
                  >
                    <Link
                      to="/admin/offline_package"
                      className={`${showFullSidebar ? "" : "d-none"
                        } d-lg-inline`}
                      style={{ paddingLeft: "10px", paddingRight: "84px" }}
                    >
                      Offline Package
                    </Link>
                  </li>
                  <li
                    className={`cursor-pointer nav_item${activeTab === 20 ? "_active" : ""
                      }`}
                    onClick={() => setActiveTab(2)}
                  >
                    <Link
                      to="/admin/reseller_package"
                      className={`${showFullSidebar ? "" : "d-none"
                        } d-lg-inline`}
                      style={{ paddingLeft: "10px", paddingRight: "78px" }}
                    >
                      Reseller Package
                    </Link>
                  </li>
                  <li
                    className={`cursor-pointer nav_item${activeTab === 21 ? "_active" : ""
                      }`}
                    onClick={() => setActiveTab(2)}
                  >
                    <Link
                      to="/admin/crypto_package"
                      className={`${showFullSidebar ? "" : "d-none"
                        } d-lg-inline`}
                      style={{ paddingLeft: "10px", paddingRight: "84px" }}
                    >
                      Crypto Package
                    </Link>
                  </li>
                  <li
                    className={`cursor-pointer nav_item${activeTab === 22 ? "_active" : ""
                      }`}
                    onClick={() => setActiveTab(2)}
                  >
                    <Link
                      to="/admin/indian_package"
                      className={`${showFullSidebar ? "" : "d-none"
                        } d-lg-inline`}
                      style={{ paddingLeft: "10px", paddingRight: "67px" }}
                    >
                      Indian PR Package
                    </Link>
                  </li>
                  <li
                    className={`cursor-pointer nav_item${activeTab === 23 ? "_active" : ""
                      }`}
                    onClick={() => setActiveTab(2)}
                  >
                    <Link
                      to="/admin/whitelabel_package"
                      className={`${showFullSidebar ? "" : "d-none"
                        } d-lg-inline`}
                      style={{ paddingLeft: "10px", paddingRight: "31px" }}
                    >
                      White Label PR Package
                    </Link>
                  </li>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          )}
          {!showFullSidebar ? (
            <Link>
              <div className="ps-4 mt-4">
                <img
                  src="/build/headphones.svg"
                  alt="user-icon"
                  width={19}
                  height={19}
                />
              </div>
            </Link>
          ) : (
            <Link to="/admin/manage_support">
              <Accordion.Item eventKey="5">
                <Accordion.Header
                  className={`noicon cursor-pointer nav_item${activeTab === 24 ? "_activeTab" : ""
                    }`}
                >
                  <div
                    // className={`d-flex cursor-pointer nav_item${
                    //   activeTab === 24 ? "_activeTab" : ""
                    // }`}
                    className="d-flex"
                  >
                    <img
                      src="/build/headphones.svg"
                      alt="user-icon"
                      width={19}
                      height={19}
                    />

                    <p className="sidebarname">Support</p>

                    <div></div>
                  </div>
                </Accordion.Header>
              </Accordion.Item>
            </Link>
          )}
          {!showFullSidebar ? (
            <Link to={"/admin/staff"}>
              <div className="ps-4 mt-4">
                <img
                  src="/build/staff.svg"
                  alt="staff-icon"
                  width={19}
                  height={19}
                />
              </div>
            </Link>
          ) : (
            <Link to={"/admin/staff"}>
              <Accordion.Item eventKey="6">
                <Accordion.Header
                  className={`noicon cursor-pointer nav_item${activeTab === 25 ? "_activeTab" : ""
                    }`}
                >
                  <div className="d-flex">
                    <img
                      src="/build/staff.svg"
                      alt="staff-icon"
                      width={19}
                      height={19}
                    />
                    <p className="sidebarname">Staff Management</p>
                  </div>
                </Accordion.Header>
              </Accordion.Item>
            </Link>
          )}
          {!showFullSidebar ? (
            <Link to={"/admin/vendor"}>
              <div className="ps-4 mt-4">
                <img
                  src="/build/vendor.svg"
                  alt="vendor-icon"
                  width={19}
                  height={19}
                />
              </div>
            </Link>
          ) : (
            <Link to={"/admin/vendor"}>
              <Accordion.Item eventKey="7">
                <Accordion.Header
                  className={`noicon cursor-pointer nav_item${activeTab === 26 ? "_activeTab" : ""
                    }`}
                >
                  <div className="d-flex">
                    <img
                      src="/build/vendor.svg"
                      alt="vendor-icon"
                      width={19}
                      height={19}
                    />
                    <p className="sidebarname">Vendor Management</p>
                  </div>
                </Accordion.Header>
              </Accordion.Item>
            </Link>
          )}
          {!showFullSidebar ? (
            <Link to={"/admin/company"}>
              <div className="ps-4 mt-4">
                <img
                  src="/build/company.svg"
                  alt="company-icon"
                  width={19}
                  height={19}
                />
              </div>
            </Link>
          ) : (
            <Link to={"/admin/company"}>
              <Accordion.Item eventKey="8">
                <Accordion.Header
                  className={`noicon cursor-pointer nav_item${activeTab === 27 ? "_activeTab" : ""
                    }`}
                >
                  <div className="d-flex">
                    <img
                      src="/build/company.svg"
                      alt="company-icon"
                      width={19}
                      height={19}
                    />
                    <p className="sidebarname">Manage Company</p>
                  </div>
                </Accordion.Header>
              </Accordion.Item>
            </Link>
          )}
          {!showFullSidebar ? (
            <div className="ps-4 mt-4">
              <img
                src="/build/coupon.svg"
                alt="coupon-icon"
                width={19}
                height={19}
              />
            </div>
          ) : (
            <Link to="/admin/coupons">
              <Accordion.Item eventKey="9">
                <Accordion.Header
                  className={`noicon cursor-pointer nav_item${activeTab === 28 ? "_activeTab" : ""
                    }`}
                >
                  <div className="d-flex">
                    <img
                      src="/build/coupon.svg"
                      alt="coupon-icon"
                      width={19}
                      height={19}
                    />
                    <p className="sidebarname">Manage Coupons</p>
                  </div>
                </Accordion.Header>
              </Accordion.Item>
            </Link>
          )}
          {!showFullSidebar ? (
            <Link to="/admin/country">
              <div className="ps-4 mt-4">
                <img
                  src="/build/earth.svg"
                  alt="earth-icon"
                  width={19}
                  height={19}
                />
              </div>
            </Link>
          ) : (
            <Link to="/admin/country">
              <Accordion.Item eventKey="10">
                <Accordion.Header
                  className={`noicon cursor-pointer nav_item${activeTab === 29 ? "_activeTab" : ""
                    }`}
                >
                  <div className="d-flex">
                    <img
                      src="/build/earth.svg"
                      alt="earth-icon"
                      width={19}
                      height={19}
                    />

                    <p className="sidebarname">Manage Country</p>
                  </div>
                </Accordion.Header>
              </Accordion.Item>
            </Link>
          )}
          {!showFullSidebar ? (
            <div className="ps-4 mt-4">
              <img
                src="/build/payment.svg"
                // src="/build/payment.svg"
                alt="user-icon"
                width={19}
                height={19}
              />
            </div>
          ) : (
            <Accordion.Item eventKey="11">
              <Accordion.Header className="nav_item">
                <div className="d-flex">
                  <img
                    src="/build/payment.svg"
                    // src="/build/payment.svg"
                    alt="user-icon"
                    width={19}
                    height={19}
                  />
                  <p className="sidebarname">Manage Payments</p>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                {/* <div className="linee" style={{ height: "80px" }}></div> */}
                <div className="item_subcategories">
                  <li
                    className={`cursor-pointer nav_item${activeTab === 30 ? "_active" : ""
                      }`}
                    onClick={() => setActiveTab(2)}
                  >
                    <Link
                      to="/admin/payments"
                      className={`${showFullSidebar ? "" : "d-none"
                        } d-lg-inline`}
                      style={{ paddingLeft: "10px", paddingRight: "18px" }}
                    >
                      Manage Payment Method
                    </Link>
                  </li>
                  <li
                    className={`cursor-pointer nav_item${activeTab === 31 ? "_active" : ""
                      }`}
                    onClick={() => setActiveTab(2)}
                  >
                    <Link
                      to="/admin/payments_history"
                      className={`${showFullSidebar ? "" : "d-none"
                        } d-lg-inline`}
                      style={{ paddingLeft: "10px", paddingRight: "23px" }}
                    >
                      Manage Payment History
                    </Link>
                  </li>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          )}
          {!showFullSidebar ? (
            <div className="ps-4 mt-4">
              <img
                src="/build/newsletter.svg"
                // src="/build/payment.svg"
                alt="user-icon"
                width={19}
                height={19}
              />
            </div>
          ) : (
            <Link to="/admin/newsletter">
              <Accordion.Item eventKey="12">
                <Accordion.Header
                  className={`noicon cursor-pointer nav_item${activeTab === 32 ? "_activeTab" : ""
                    }`}
                >
                  <div className="d-flex">
                    <img
                      src="/build/newsletter.svg"
                      // src="/build/payment.svg"
                      alt="user-icon"
                      width={19}
                      height={19}
                    />

                    <p className="sidebarname">Manage Newsletter</p>
                  </div>
                </Accordion.Header>
              </Accordion.Item>
            </Link>
          )}
          {!showFullSidebar ? (
            <div className="ps-4 mt-4">
              <img
                src="/build/affiliate-center.svg"
                // src="/build/payment.svg"
                alt="user-icon"
                width={19}
                height={19}
              />
            </div>
          ) : (
            <Accordion.Item eventKey="13">
              <Accordion.Header className="nav_item">
                <div className="d-flex">
                  <img
                    src="/build/affiliate-center.svg"
                    // src="/build/payment.svg"
                    alt="user-icon"
                    width={19}
                    height={19}
                  />

                  <p className="sidebarname">Affiliate Center</p>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                {/* <div className="linee" style={{ height: "200px" }}></div> */}
                <div className="item_subcategories">
                  <li
                    className={`cursor-pointer nav_item${activeTab === 33 ? "_active" : ""
                      }`}
                    onClick={() => setActiveTab(2)}
                  >
                    <Link
                      to="/admin/application_center"
                      className={`${showFullSidebar ? "" : "d-none"
                        } d-lg-inline`}
                      style={{ paddingLeft: "10px", paddingRight: "65px" }}
                    >
                      Application Center
                    </Link>
                  </li>
                  <li
                    className={`cursor-pointer nav_item${activeTab === 34 ? "_active" : ""
                      }`}
                    onClick={() => setActiveTab(2)}
                  >
                    <Link
                      to="/admin/affiliate_user"
                      className={`${showFullSidebar ? "" : "d-none"
                        } d-lg-inline`}
                      style={{ paddingLeft: "10px", paddingRight: "150px" }}
                    >
                      Users
                    </Link>
                  </li>
                  <li
                    className={`cursor-pointer nav_item${activeTab === 35 ? "_active" : ""
                      }`}
                    onClick={() => setActiveTab(2)}
                  >
                    <Link
                      to="/admin/affiliate_payment"
                      className={`${showFullSidebar ? "" : "d-none"
                        } d-lg-inline`}
                      style={{ paddingLeft: "10px", paddingRight: "128px" }}
                    >
                      Payment
                    </Link>
                  </li>
                  <li
                    className={`cursor-pointer nav_item${activeTab === 36 ? "_active" : ""
                      }`}
                    onClick={() => setActiveTab(2)}
                  >
                    <Link
                      to="/admin/affiliate_sources"
                      className={`${showFullSidebar ? "" : "d-none"
                        } d-lg-inline`}
                      style={{ paddingLeft: "10px", paddingRight: "134px" }}
                    >
                      Sources
                    </Link>
                  </li>
                  <li
                    className={`cursor-pointer nav_item${activeTab === 37 ? "_active" : ""
                      }`}
                    onClick={() => setActiveTab(2)}
                  >
                    <Link
                      to="/admin/affiliate_bank"
                      className={`${showFullSidebar ? "" : "d-none"
                        } d-lg-inline`}
                      style={{ paddingLeft: "10px", paddingRight: "152px" }}
                    >
                      Bank
                    </Link>
                  </li>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          )}
          {!showFullSidebar ? (
            <div className="ps-4 mt-4">
              <img
                src="/build/analytics.svg"
                // src="/build/payment.svg"
                alt="user-icon"
                width={19}
                height={19}
              />
            </div>
          ) : (
            <Accordion.Item eventKey="14">
              <Accordion.Header className="nav_item">
                <div className="d-flex">
                  <img
                    src="/build/analytics.svg"
                    // src="/build/payment.svg"
                    alt="user-icon"
                    width={19}
                    height={19}
                  />
                  <p className="sidebarname">Analytics</p>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                {/* <div className="linee" style={{ height: "200px" }}></div> */}
                <div className="item_subcategories">
                  <li
                    className={`cursor-pointer nav_item${activeTab === 38 ? "_active" : ""
                      }`}
                    onClick={() => setActiveTab(2)}
                  >
                    <Link
                      to="/admin/analytics"
                      className={`${showFullSidebar ? "" : "d-none"
                        } d-lg-inline`}
                      style={{ paddingLeft: "10px", paddingRight: "124px" }}
                    >
                      Overview
                    </Link>
                  </li>
                  <li
                    className={`cursor-pointer nav_item${activeTab === 39 ? "_active" : ""
                      }`}
                    onClick={() => setActiveTab(2)}
                  >
                    <Link
                      to="/admin/PR"
                      className={`${showFullSidebar ? "" : "d-none"
                        } d-lg-inline`}
                      style={{ paddingLeft: "10px", paddingRight: "166px" }}
                    >
                      PR
                    </Link>
                  </li>
                  <li
                    className={`cursor-pointer nav_item${activeTab === 40 ? "_active" : ""
                      }`}
                    onClick={() => setActiveTab(2)}
                  >
                    <Link
                      to="/admin/analytics_sales"
                      className={`${showFullSidebar ? "" : "d-none"
                        } d-lg-inline`}
                      style={{ paddingLeft: "10px", paddingRight: "151px" }}
                    >
                      Sales
                    </Link>
                  </li>
                  <li
                    className={`cursor-pointer nav_item${activeTab === 41 ? "_active" : ""
                      }`}
                    onClick={() => setActiveTab(2)}
                  >
                    <Link
                      to="/admin/analytics_report"
                      className={`${showFullSidebar ? "" : "d-none"
                        } d-lg-inline`}
                      style={{ paddingLeft: "10px", paddingRight: "140px" }}
                    >
                      Reports
                    </Link>
                  </li>
                  <li
                    className={`cursor-pointer nav_item${activeTab === 42 ? "_active" : ""
                      }`}
                    onClick={() => setActiveTab(2)}
                  >
                    <Link
                      to="/admin/analytics_user"
                      className={`${showFullSidebar ? "" : "d-none"
                        } d-lg-inline`}
                      style={{ paddingLeft: "10px", paddingRight: "149px" }}
                    >
                      Users
                    </Link>
                  </li>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          )}
          {!showFullSidebar ? (
            <div className="ps-4 mt-4">
              <img
                src="/build/invoice.svg"
                // src="/build/payment.svg"
                alt="user-icon"
                width={19}
                height={19}
              />
            </div>
          ) : (
            <Accordion.Item eventKey="15">
              <Accordion.Header className="nav_item">
                <div className="d-flex">
                  <img
                    src="/build/invoice.svg"
                    // src="/build/payment.svg"
                    alt="user-icon"
                    width={19}
                    height={19}
                  />

                  <p className="sidebarname">Invoice Management</p>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                {/* <div className="linee" style={{ height: "80px" }}></div> */}
                <div className="item_subcategories">
                  <li
                    className={`cursor-pointer nav_item${activeTab === 43 ? "_active" : ""
                      }`}
                    onClick={() => setActiveTab(2)}
                  >
                    <Link
                      to="/admin/manage_invoice_company"
                      className={`${showFullSidebar ? "" : "d-none"
                        } d-lg-inline`}
                      style={{ paddingLeft: "10px", paddingRight: "28px" }}
                    >
                      Manage Invoice Address
                    </Link>
                  </li>
                  <li
                    className={`cursor-pointer nav_item${activeTab === 44 ? "_active" : ""
                      }`}
                    onClick={() => setActiveTab(2)}
                  >
                    <Link
                      to="/admin/manage_invoice"
                      className={`${showFullSidebar ? "" : "d-none"
                        } d-lg-inline`}
                      style={{ paddingLeft: "10px", paddingRight: "83px" }}
                    >
                      Manage Invoice
                    </Link>
                  </li>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          )}
          {!showFullSidebar ? (
            <div className="ps-4 mt-4">
              <img
                src="/build/login.svg"
                // src="/build/payment.svg"
                alt="user-icon"
                width={19}
                height={19}
              />
            </div>
          ) : (
            <Link to="/admin/loginlog">
              <Accordion.Item eventKey="16">
                <Accordion.Header
                  className={`noicon cursor-pointer nav_item${activeTab === 45 ? "_activeTab" : ""
                    }`}
                >
                  <div className="d-flex">
                    <img
                      src="/build/login.svg"
                      // src="/build/payment.svg"
                      alt="user-icon"
                      width={19}
                      height={19}
                    />

                    <p className="sidebarname">Login Log</p>
                  </div>
                </Accordion.Header>
              </Accordion.Item>
            </Link>
          )}
          {!showFullSidebar ? (
            <div className="ps-4 mt-4">
              <img
                src="/build/logout.svg"
                // src="/build/payment.svg"
                alt="user-icon"
                width={19}
                height={19}
              />
            </div>
          ) : (
            <Accordion.Item eventKey="17">
              <Accordion.Header className="noicon">
                <div className="d-flex " onClick={handleLogout}>
                  <img
                    src="/build/logout.svg"
                    // src="/build/payment.svg"
                    alt="user-icon"
                    width={19}
                    height={19}
                  />

                  <p className="sidebarname">Logout</p>
                </div>
              </Accordion.Header>
            </Accordion.Item>
          )}
        </Accordion>
      </div>

      {/* <div
        className={`help_box ${showFullSidebar ? "" : "d-none"} d-lg-block `}
        style={{
          position: "relative",
        }}
      >
        <div style={{ position: "absolute", left: "0", bottom: "0" }}>
          <img src="/build/img/lines.svg" alt="" />
        </div>
        <p className={`help_first`}>Need help using the product?</p>
        <p className={`help_second`}>
          Click on the button below to get detailed informational videos on
          youtube.
        </p>
        <div className="d-flex justify-content-center">
          <button className={`learn_btn`}>
            <span>Learn More</span>
          </button>
        </div>
      </div> */}
      <ToastContainer theme="colored" />
    </sidebar>
  );
}
