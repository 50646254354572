import "./GalleryLayout.css";
import { useState } from "react";
import { Button, Accordion, Offcanvas, Modal, Form } from "react-bootstrap";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import ListView from "./ListView";
import GridView from "./GridView";

export default function GalleryLayout() {
  const [show, setShow] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [filtersApplied, setFiltersApplied] = useState(0);
  const [gridView, setGridView] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showPreview, setShowPreview] = useState(false);

  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [numSelectedCheckboxes, setNumSelectedCheckboxes] = useState(0);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const hadnleShowUpload = () => {
    setShowUpload(true);
  };
  const hadnleNotShowUpload = () => {
    setShowUpload(false);
  };

  const handleShowDelete = () => {
    setShowDelete(true);
  };
  const hadnleNotShowDelete = () => {
    setShowDelete(false);
  };

  const handleShowPreview = () => {
    setShowPreview(true);
  };
  const handleNotShowPreview = () => {
    setShowPreview(false);
  };

  const handleListView = () => {
    setGridView(false);
  };
  const handleGridView = () => setGridView(true);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const calculateFiltersApplied = () => {
    let count = 0;
    if (selectedDate) count++;
    setFiltersApplied(count);
  };
  const handleApplyFilters = () => {
    calculateFiltersApplied();
    handleClose();
  };

  const handleClearFilters = () => {
    setSelectedDate(null);
    setFiltersApplied(0);
  };

  const updateSelectedCheckboxes = (checkedState) => {
    const numSelected = checkedState.filter((checked) => checked).length;
    setNumSelectedCheckboxes(numSelected);
  };

  return (
    <>
      <div className="gallery_layout">
        <div className="all_images">
          <div className="gallery_options_btns">
            <div>
              <button
                className="list_view_btn"
                onClick={handleListView}
                style={{
                  backgroundColor: gridView === false ? "#585EDB" : "#5D6B82",
                }}
              >
                <img
                  className=""
                  src="/build/img/Icons/Grid.svg"
                  alt="zex pr wire Logo"
                  width={16}
                  height={16}
                  priority
                />
              </button>
              <button
                className="grid_view_btn"
                onClick={handleGridView}
                style={{
                  backgroundColor: gridView === true ? "#585EDB" : "#5D6B82",
                }}
              >
                <img
                  className=""
                  src="/build/img/Icons/List.svg"
                  alt="zex pr wire Logo"
                  width={16}
                  height={16}
                  priority
                />
              </button>

              <Button
                variant="primary"
                className="Payment_filter_btn"
                onClick={handleShow}
              >
                Filter
                <img
                  className="filter_img"
                  src="/build/img/Icons/down_vector.svg"
                  alt="zex pr wire Logo"
                  width={16}
                  height={16}
                  priority
                />
              </Button>
              <Offcanvas show={show} onHide={handleClose}>
                <Offcanvas.Header
                  closeButton
                  className="filter_offcanvas_header"
                >
                  <Offcanvas.Title className="filter_offcanvas_title">
                    {filtersApplied} filters applied
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="filter_offcanavas_body">
                  <Accordion alwaysOpen flush>
                    <Accordion.Item eventKey="0" className="accordian_item">
                      <Accordion.Header className="filter_accordian">
                        Date Range
                      </Accordion.Header>
                      <Accordion.Body style={{ backgroundColor: "#FAFBFB" }}>
                        <div>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateCalendar
                              value={selectedDate}
                              onChange={handleDateChange}
                            />
                          </LocalizationProvider>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <div className="mt-4 mb-4 filter_btns">
                    <button
                      className="filter_clear_btn"
                      onClick={handleClearFilters}
                    >
                      Clear
                    </button>
                    <button
                      className="filter_apply_btn"
                      onClick={handleApplyFilters}
                    >
                      Apply
                    </button>
                  </div>
                </Offcanvas.Body>
              </Offcanvas>
            </div>
            <div>
              <button className="upload_gallery_btn" onClick={hadnleShowUpload}>
                <img
                  className="upload_gallery_img"
                  src="/build/img/Icons/Upload.svg"
                  alt="zex pr wire Logo"
                  width={16}
                  height={16}
                  priority
                />
                Upload
              </button>
              <Modal
                show={showUpload}
                onHide={hadnleNotShowUpload}
                centered
                className="custom_modal custom_modal_upload"
              >
                <Modal.Body className="p-0">
                  <div className="m-auto">
                    <img
                      className={"mb-3"}
                      src="/Addimageframe.svg"
                      alt="zex pr wire Logo"
                      width={150}
                      height={150}
                      priority
                    />
                    <h5 className="submit_modal_head">Add Image</h5>
                  </div>
                  <div className="modal_gallery_inputs">
                    <Form.Control placeholder="Attach Files" className="mb-3" />
                    <Form.Label>Image Title</Form.Label>
                    <Form.Control
                      placeholder="Enter text here"
                      className="mb-3"
                    />
                    <Form.Label>Alternate Text</Form.Label>
                    <Form.Control placeholder="Enter text here" />
                  </div>
                  <div className="pr_modal_btn">
                    <Button
                      className="modal_gallery_btn1"
                      onClick={hadnleNotShowUpload}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="modal_gallery_btn2"
                      onClick={hadnleNotShowUpload}
                    >
                      Upload
                    </Button>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          </div>

          {gridView === false ? (
            <ListView
              setSelectedPhoto={setSelectedPhoto}
              updateSelectedCheckboxes={updateSelectedCheckboxes}
            />
          ) : (
            <GridView
              setSelectedPhoto={setSelectedPhoto}
              updateSelectedCheckboxes={updateSelectedCheckboxes}
            />
          )}
        </div>
        <div className="image_description">
          {!selectedPhoto && !numSelectedCheckboxes && (
            <>
              <div className="no_image">No Image to display </div>
            </>
          )}
          {selectedPhoto && numSelectedCheckboxes === 1 && (
            <>
              <h4 className="image_name">Big Ben </h4>
              <img
                className="image_display"
                src={selectedPhoto}
                alt="zex pr wire Logo"
                width={400}
                height={300}
                priority
              />

              <div className="image_detail">
                <p className="mb-4 image_detail_input">URL</p>
                <p className="mb-4 image_detail_output">
                  {" "}
                  www.googleimages.com
                </p>
                <p className="mb-4 image_detail_input"> Date Uploaded</p>
                <p className="mb-4 image_detail_output">June 7, 2020</p>
                <p className="mb-0 image_detail_input">Size</p>
                <p className="mb-0 image_detail_output">24 mb</p>
              </div>
              <div className="gallery_details_btns">
                <button
                  className="image_preview_btn"
                  onClick={handleShowPreview}
                >
                  Preview
                </button>
                <Modal
                  show={showPreview}
                  onHide={handleNotShowPreview}
                  centered
                  className="custom_modal_preview"
                >
                  <Modal.Body className="p-0">
                    <div className="">
                      <img
                        className={"gallery_preview_img"}
                        src={selectedPhoto}
                        alt="zex pr wire Logo"
                        width={550}
                        height={300}
                        priority
                      />
                    </div>
                    <div className="gallery_details_btns m-auto">
                      <button
                        className="image_preview_btn"
                        onClick={handleNotShowPreview}
                      >
                        Back
                      </button>
                      <button className="image_url_btn">Copy URL</button>
                      <button
                        className="image_delete_btn"
                        onClick={() => {
                          handleShowDelete();
                          handleNotShowPreview();
                        }}
                      >
                        <img
                          className={"image_delete_img"}
                          src="/build/img/Icons/Delete.png"
                          alt="zex pr wire Logo"
                          width={16}
                          height={16}
                          priority
                        />
                        Delete
                      </button>
                    </div>
                  </Modal.Body>
                </Modal>
                <button className="image_url_btn">Copy URL</button>
                <button className="image_delete_btn" onClick={handleShowDelete}>
                  <img
                    className={"image_delete_img"}
                    src="/build/img/Icons/Delete.png"
                    alt="zex pr wire Logo"
                    width={16}
                    height={16}
                    priority
                  />
                  Delete
                </button>
                <Modal
                  show={showDelete}
                  onHide={hadnleNotShowDelete}
                  centered
                  className="custom_modal"
                >
                  <Modal.Body>
                    <div className="submit_modal_img">
                      <img
                        className={"submit_done_img"}
                        src="/build/imageDelete.svg"
                        alt="zex pr wire Logo"
                        width={140}
                        height={140}
                        priority
                      />
                    </div>
                    <div>
                      <h5 className="submit_modal_head">Delete Image</h5>
                      <p className="submit_modal_content">
                        Are you sure you want to delete this image?
                      </p>
                    </div>
                    <div className="pr_modal_btn">
                      <Button
                        className="modal_gallery_btn1"
                        onClick={hadnleNotShowDelete}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="image_delete_btn"
                        onClick={hadnleNotShowDelete}
                      >
                        <img
                          className={"image_delete_img"}
                          src="/build/img/Icons/Delete.png"
                          alt="zex pr wire Logo"
                          width={16}
                          height={16}
                          priority
                        />
                        Delete
                      </Button>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
            </>
          )}
          {selectedPhoto && numSelectedCheckboxes > 1 && (
            <>
              <div>
                <h4 className="image_name">Multiple Images Selected</h4>
                <div className="multiple_images">
                  <img
                    className="image_display"
                    src={selectedPhoto}
                    alt="zex pr wire Logo"
                    width={400}
                    height={300}
                    style={{ filter: "blur(10px)" }}
                    priority
                  />
                  <div className="select_image_number">
                    {numSelectedCheckboxes + "+"}
                  </div>
                </div>
              </div>
              <div className="gallery_details_btns">
                <button className="image_preview_btn">Preview</button>
                <button className="image_delete_btn" onClick={handleShowDelete}>
                  <img
                    className={"image_delete_img"}
                    src="/build/img/Icons/Delete.png"
                    alt="zex pr wire Logo"
                    width={16}
                    height={16}
                    priority
                  />
                  Delete
                </button>
                <Modal
                  show={showDelete}
                  onHide={hadnleNotShowDelete}
                  centered
                  className="custom_modal"
                >
                  <Modal.Body>
                    <div className="submit_modal_img">
                      <img
                        className={"submit_done_img"}
                        src="/build/imageDelete.svg"
                        alt="zex pr wire Logo"
                        width={140}
                        height={140}
                        priority
                      />
                    </div>
                    <div>
                      <h5 className="submit_modal_head">Delete Image</h5>
                      <p className="submit_modal_content">
                        Are you sure you want to delete multiple images?
                      </p>
                    </div>
                    <div className="pr_modal_btn">
                      <Button
                        className="modal_gallery_btn1"
                        onClick={hadnleNotShowDelete}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="image_delete_btn"
                        onClick={hadnleNotShowDelete}
                      >
                        <img
                          className={"image_delete_img"}
                          src="/build/img/Icons/Delete.png"
                          alt="zex pr wire Logo"
                          width={16}
                          height={16}
                          priority
                        />
                        Delete
                      </Button>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
