import "./ManageCoupons.css";
import { Link, useNavigate } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useEffect, useState } from "react";
import Select from "react-select";
import Instance from "../../Utils/Axios";
import { showToast } from "../../Utils/index";

const AddCoupon = () => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOptions2, setSelectedOptions2] = useState([]);
  const [error, setError] = useState("");
  const [packageList, setPackageList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [userString, setUserString] = useState("");
  const [packageString, setPackageString] = useState("");
  const [checkPcakge, setCheckPackage] = useState(false);
  const [checkUser, setCheckUser] = useState(false);
  const [userObj, setUserObj] = useState([]);
  const [packageObj, setPackageObj] = useState([]);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    code: "",
    usageLimit: "",
    discountType: "",
    discount: "",
    redeemRestrictions: "YES",
    source: "Test2",
    userId: "",
    packageId: "",
    name: "",
    status: "PENDING",
    startDate: "",
    expiryDate: "",
  });

  const fetchUserList = () => {
    const token = localStorage.getItem("adminToken");
    console.log("Fetched token from localStorage:", token);
    Instance.post("/siteadmin/user/list", {
      adminToken: token,
      status: "",
      fromDate: "",
      toDate: "",
      email: "",
      userId: "",
      country: "",
      state: "",
      city: "",
      mobileNumber: "",
      parentId: "",
    })
      .then((response) => {
        // setUserList(response?.data?.message);

        setError("");
        console.log("check user  data", response?.data?.message);
        const userOptions = [];
        // console.log("check Gallery data", response?.data?.message);

        (response?.data?.message).map((val) => {
          userOptions.push({
            value: val.userId,
            label: val.firstName,
          });
        });

        setUserList(userOptions);
      })
      .catch((error) => {
        setError("Failed to fetch the data");
        console.error("Error fetching user list:", error);
      });
  };
  useEffect(() => {
    fetchUserList();
  }, []);

  const fetchPackageList = () => {
    const token = localStorage.getItem("adminToken");
    console.log("Fetched token from localStorage:", token);
    Instance.post("/siteadmin/package/getList", {
      adminToken: token,
      status: "",
    })
      .then((response) => {
        const PackageOptions = [];

        (response?.data?.message).map((val) => {
          PackageOptions.push({
            value: val.packageId,
            label: val.name,
          });
        });

        setPackageList(PackageOptions);

        setError("");
        console.log("check Package  data", response?.data?.message);
      })
      .catch((error) => {
        setError("Failed to fetch the  package data");
        console.error("Error fetching package list:", error);
      });
  };
  useEffect(() => {
    fetchPackageList();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const addNewCoupon = (e) => {
    e.preventDefault();
    formData.packageId = packageString;
    formData.userId = userString;
    const token = localStorage.getItem("adminToken");

    // console.log(formData);
    // alert("hii");

    Instance.post("/siteadmin/coupons/create", {
      adminToken: token,
      ...formData,
    })
      .then((response) => {
        if (response.data.resCode === "100") {
          showToast("Already a member", "error", "error");
          setError(response.data.resMessage);
          console.log("Coupon added failed:", response.data);
        } else {
          console.log("Coupon added successfully:", response.data);
          showToast("Coupon added successfully", "success", "success");
          setError("");
          setTimeout(() => {
            navigate("/admin/coupons");
          }, 2000);
        }
      })
      .catch((error) => {
        showToast("Error", "error", "error");
        setError("Failed to add user");
        console.error("Error adding user:", error);
      });
  };

  const handleMultiSelect = (obj) => {
    setPackageObj(obj);
    const tempArr = obj.map((ar) => ar.value);
    var str = "";
    tempArr.map((val, indx) => {
      if (indx <= tempArr.length - 2) {
        str = str + val + "|";
      }
      if (indx == tempArr.length - 1) {
        str = str + val;
      }
    });

    setPackageString(str);
  };

  const handleMultiUserSelect = (obj) => {
    setUserObj(obj);
    const tempArr = obj.map((ar) => ar.value);
    var str = "";
    tempArr.map((val, indx) => {
      if (indx <= tempArr.length - 2) {
        str = str + val + "|";
      }
      if (indx == tempArr.length - 1) {
        str = str + val;
      }
    });

    setUserString(str);
  };

  const handleSelectAllChange = (e) => {
    setCheckPackage(!checkPcakge);
    if (e.target.checked) {
      setSelectedOptions(packageList);
    } else {
      setSelectedOptions([]);
    }
  };

  const handleSelectAllChange2 = (e) => {
    setCheckUser(!checkUser);
    if (e.target.checked) {
      setSelectedOptions2(userList);
    } else {
      setSelectedOptions2([]);
    }
  };

  // console.log("selected all package is ", selectedOptions);
  // console.log("selected all users is ", selectedOptions2);
  return (
    <>
      <div className="coupon_pages_layout">
        <div className="coupon_page_heading">
          <h4> Add New Coupon </h4>
          <div className="admin_coupon_btns">
            <Link to="/admin/coupons">
              <span style={{ cursor: "pointer" }}>
                <div className="backbutton"></div>
              </span>
            </Link>
          </div>
        </div>
        <div className="add_coupon_detail">
          <Form onSubmit={addNewCoupon}>
            <Row>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Coupon Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </Col>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Coupon Code</Form.Label>
                <Form.Control
                  type="text"
                  name="code"
                  value={formData.code}
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <Row>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Discount Type</Form.Label>
                <Form.Select
                  name="discountType"
                  value={formData.discountType}
                  onChange={handleChange}
                  required
                >
                  <option value=""></option>
                  <option value="percentage">%</option>
                  <option value="flat">Flat</option>
                </Form.Select>
              </Col>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Discount Value</Form.Label>
                <Form.Control
                  type="text"
                  name="discount"
                  value={formData.discount}
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <Row>
              <Col xs="6" className="custom_col_add_staff mb-4">
                <Form.Label>Number of times the coupon can be used</Form.Label>
                <Form.Control
                  type="number"
                  name="usageLimit"
                  value={formData.usageLimit}
                  onChange={handleChange}
                />
              </Col>
              <Col xs="3" className="custom_col_add_staff mb-4">
                <Form.Label>Start Date</Form.Label>
                <Form.Control
                  type="datetime-local"
                  id="datetime"
                  name="startDate"
                  value={formData.startDate}
                  onChange={handleChange}
                />
              </Col>
              <Col xs="3" className="custom_col_add_staff mb-4">
                <Form.Label>End Date</Form.Label>
                <Form.Control
                  type="datetime-local"
                  id="datetime"
                  name="expiryDate"
                  value={formData.expiryDate}
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <Row>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Select Package</Form.Label>
                <Select
                  value={checkPcakge ? packageList : packageObj}
                  options={packageList}
                  onChange={(cl) => handleMultiSelect(cl)}
                  placeholder="Select Distribution Options"
                  isMulti
                  closeMenuOnSelect={false}
                />
                <Form.Check
                  type="checkbox"
                  label="Select All Packages"
                  onChange={handleSelectAllChange}
                  checked={selectedOptions.length === packageList.length}
                  className="mt-3"
                />
              </Col>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Select User</Form.Label>
                <Select
                  value={checkUser ? userList : userObj}
                  options={userList}
                  onChange={(cl) => handleMultiUserSelect(cl)}
                  placeholder="Select Distribution Options"
                  isMulti
                  closeMenuOnSelect={false}
                />
                <Form.Check
                  type="checkbox"
                  label="Select All User"
                  onChange={handleSelectAllChange2}
                  checked={selectedOptions2.length === userList.length}
                  className="mt-3"
                />
              </Col>
            </Row>
            <button className="add_coupon_submit_btn" type="submit">
              Submit
            </button>
          </Form>
        </div>
      </div>
    </>
  );
};
export default AddCoupon;
