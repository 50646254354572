import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

const PressReleasePreview = () => {
  const location = useLocation();
  const { data } = location.state || {};

  // const [arr, setArr] = useState([`${data}`]);

  console.log(data);

  return (
    <>
      <div className="admin_editorial_layout">
        <div className="page_heading">
          <h4>Preview Press Release</h4>
          <Link to="/editorial/add_press_release">
            <span style={{ cursor: "pointer" }}>
              <div className="backbutton"></div>
            </span>
          </Link>
        </div>

        <div className="add_new_editorial">
          {/* {JSON.stringify(data)} */}
          {/* {data.status} */}
          {Object.entries(data).map(([key, value]) => {
            {
              if (typeof value === "object" && value !== null) {
                return (
                  <div key={key}>
                    <strong>{key}:</strong>
                    {/* <div style={{ marginLeft: "20px" }}> {key["mobile"]}</div> */}
                  </div>
                );
              } else {
                return (
                  <div key={key} className="my-3">
                    <div>{key}:</div> {value}
                  </div>
                );
              }
            }
          })}

          <div>{data.company.name}</div>
        </div>
      </div>
    </>
  );
};

export default PressReleasePreview;
