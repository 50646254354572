import Navbar from "../../components/NavbarProfile/NavbarProfile";
import Sidebar from "../../components/SidebarNew/Sidebar";
import AddBank from "../../components/AffiliateCenter/AddBank";
import Footer from "../../components/Layouts/Footer";
import "../AffiliateCenter/AffiliateCenter.css";

const BankCreate = () => {
  const activeSide = 37;
  const activeEventKey = "13";

  return (
    <>
      <div className="row gx-0 admin_affiliate_layout">
        <Sidebar activeSide={activeSide} activeEventKey={activeEventKey} />
        <div
          className={`main col d-flex flex-column`}
          style={{ overflow: "auto" }}
        >
          <Navbar />
          <div className="flex-grow-1">
            <AddBank />
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default BankCreate;
