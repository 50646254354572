import "./PackageLayout.css";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useState, useEffect } from "react";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Instance from "../../Utils/Axios";
import PackageList from "./PackageList";

const OnlinePackagePage = () => {
  const [accordionOpen, setAccordionOpen] = useState(false);
  const [packageList, setPackageList] = useState([]);
  const [filteredPackage, setFilteredPackage] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  const [filters, setFilters] = useState({
    name: "",
    type: "",
    price: "",
    pressReleaseCount: "",
    subType: "",
    validity: "",
    subType: "",
  });

  const [error, setError] = useState("");

  useEffect(() => {
    const fetchPackageDetails = () => {
      const token = localStorage.getItem("adminToken");
      Instance.post("/siteadmin/package/getList", {
        adminToken: token,

        type: "ONLINE",
      })
        .then((response) => {
          setPackageList(response.data.message);
          console.log(response.data.message);

          setFilteredPackage(response.data.message);
          setError("");
        })
        .catch((error) => {
          setError("Error fetching the package details");
          console.error("Error fetching the package details ", error);
        });
    };
    fetchPackageDetails();
  }, []);

  const fetchVendorList = () => {
    const token = localStorage.getItem("adminToken");
    console.log("Fetched token from localStorage:", token);
    Instance.post("/siteadmin/vendor/getList", {
      adminToken: token,
      type: "ONLINE",
    })
      .then((response) => {
        setVendorList(response?.data?.message);
        // setTempData(response?.data?.message);
        setError("");
        //console.log("check  vendor data", response?.data?.message);
        // console.log("check  temp data", tempData);
      })
      .catch((error) => {
        setError("Failed to fetch the data");
        console.error("Error fetching vendor list:", error);
      });
  };
  useEffect(() => {
    fetchVendorList();
  }, []);

  const options = [];

  const data = vendorList.map((val) => {
    options.push({
      value: val.name,
      label: val.name,
    });
  });

  const columns = [
    {
      name: "Package Name",
      selector: (row) => row.name,
      sortable: true,
      wrap: true,
    },
    {
      name: "Price",
      selector: (row) => row.price,
      sortable: true,
      wrap: true,
    },
    {
      name: "Credits",
      selector: (row) => row.pressReleaseCount,
      sortable: true,
      wrap: true,
    },
    {
      name: "Package Type",
      selector: (row) => row.type,
      sortable: true,
      wrap: true,
    },
    {
      name: "Package Validity",
      selector: (row) => row.validity,
      sortable: true,
      wrap: true,
    },
    {
      name: "Distribution",
      selector: (row) => row.vendorId,
      sortable: true,
      wrap: true,
    },

    {
      name: "Normal/Reseller/CryptoIndian PR/White Label PR Package",
      selector: (row) => row.subType,
      sortable: true,
      wrap: true,
    },
  ];

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const applyFilters = () => {
    const token = localStorage.getItem("adminToken");
    Instance.post("/siteadmin/package/getList", {
      adminToken: token,
      // status: filters.status,
      name: filters.name,
      type: filters.type,
      price: filters.price,
      pressReleaseCount: filters.pressReleaseCount,
      subType: filters.subType,
      validity: filters.validity,
      subType: filters.subType,
    })
      .then((response) => {
        setFilteredPackage(response?.data?.message);
        setError("");
        console.log("Filtered data:", response?.data?.message);
      })
      .catch((error) => {
        setError("Failed to apply filters");
        console.error("Error applying filters:", error);
      });
  };

  const clearFilters = () => {
    const token = localStorage.getItem("adminToken");
    Instance.post("/siteadmin/package/getList", {
      adminToken: token,
      // status: filters.status,
      name: "",
      type: "",
      price: "",
      pressReleaseCount: "",
      subType: "",
      validity: "",
      subType: "",
    })
      .then((response) => {
        setFilters({
          name: "",
          type: "",
          price: "",
          pressReleaseCount: "",
          subType: "",
          validity: "",
          subType: "",
        });
        setPackageList(response?.data?.message);
        setFilteredPackage(response?.data?.message);
        setError("");
        console.log("Cleared filters. Updated data:", response?.data?.message);
      })
      .catch((error) => {
        setError("Failed to clear filters");
        console.error("Error clearing filters:", error);
      });
  };

  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(setFilteredPackage[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  function downloadCSV(array) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = "export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      setAccordionOpen(!accordionOpen)
    );
    return (
      <button
        type="button"
        className="admin_filter_btn"
        // style={{ backgroundColor: "pink" }}
        onClick={decoratedOnClick}
      >
        {children}
        {accordionOpen ? (
          <img
            src="/build/img/Icons/down_vector.svg"
            alt="zex pr wire Logo"
            width={16}
            height={16}
          />
        ) : (
          <img
            src="/build/img/Icons/down_vector.svg"
            alt="zex pr wire Logo"
            width={16}
            height={16}
            className="filter_up_arrow"
          />
        )}
      </button>
    );
  }

  const filterData = (e) => {
    let filterText = e.target.value;
    if (!filterText) {
      setFilteredPackage([...packageList]);
    } else {
      const filteredItems = packageList.filter(
        (item) =>
          (item.name &&
            item.name.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.type &&
            item.type.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.price && item.price.includes(filterText.toLowerCase())) ||
          (item.pressReleaseCount &&
            item.pressReleaseCount.includes(filterText.toLowerCase())) ||
          (item.subType &&
            item.subType.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.validity && item.validity.includes(filterText.toLowerCase()))
      );
      setFilteredPackage([...filteredItems]);
    }
  };

  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      setAccordionOpen(!accordionOpen)
    );
    return (
      <button
        type="button"
        className="admin_filter_btn"
        // style={{ backgroundColor: "pink" }}
        onClick={decoratedOnClick}
      >
        {children}
        {accordionOpen ? (
          <img
            src="/build/img/Icons/down_vector.svg"
            alt="zex pr wire Logo"
            width={16}
            height={16}
          />
        ) : (
          <img
            src="/build/img/Icons/down_vector.svg"
            alt="zex pr wire Logo"
            width={16}
            height={16}
            className="filter_up_arrow"
          />
        )}
      </button>
    );
  }
  return (
    <>
      <div className="package_pages_layout">
        <div className="package_page_heading online_pack">
          <h4 className="addpack online_pack1"> Online Package </h4>
          <div className="admin_package_btns online_pack1">
            <Link to="/admin/package/add" className=" ">
              <button className="new_package_btn  new_online_pack">
                {" "}
                Add New Package{" "}
              </button>
            </Link>
          </div>
        </div>
        <Accordion className="mb-4">
          <Card>
            <Card.Header className="d-md-flex justify-content-between align-items-center">
              <div className="staff_search">
                <label>
                  Search:
                  <input
                    type="search"
                    placeholder=""
                    aria-controls="datatable"
                    onChange={filterData}
                  ></input>
                </label>
              </div>
              <div className="d-md-flex">
                <CustomToggle as={Button} variant="link" eventKey="0">
                  Filters
                </CustomToggle>
              </div>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Form>
                  <div className="d-flex gap-4 flex-wrap">
                    <Form.Group
                      controlId="filterName"
                      className="staff_filter_options"
                    >
                      <Form.Label>Package Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search Package Name"
                        name="name"
                        value={filters.name}
                        onChange={handleFilterChange}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filterprice"
                      className="staff_filter_options"
                    >
                      <Form.Label>Package Price</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search Package Price"
                        name="price"
                        value={filters.price}
                        onChange={handleFilterChange}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filterCredits"
                      className="staff_filter_options"
                    >
                      <Form.Label>Credits</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Search Credits"
                        name="pressReleaseCount"
                        value={filters.pressReleaseCount}
                        onChange={handleFilterChange}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filterType"
                      className="staff_filter_options"
                    >
                      <Form.Label>Package Type</Form.Label>
                      <Form.Control
                        as="select"
                        name="type"
                        value={filters.type}
                        onChange={handleFilterChange}
                      >
                        <option value="">All</option>
                        <option value="OFFLINE">Offline</option>
                        <option value="ONLINE">Online</option>
                      </Form.Control>
                    </Form.Group>

                    <Form.Group
                      controlId="filterType"
                      className="staff_filter_options"
                    >
                      <Form.Label>Package Validity</Form.Label>
                      <Form.Control
                        type="number"
                        name="validity"
                        value={filters.validity}
                        onChange={handleFilterChange}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filterType"
                      className="staff_filter_options"
                    >
                      <Form.Label>Package subType</Form.Label>
                      <Form.Control
                        as="select"
                        name="subType"
                        value={filters.subType}
                        onChange={handleFilterChange}
                      >
                        <option value="">All</option>
                        <option value="NORMAL">Normal</option>
                        <option value="RESELLER">Reseller</option>
                        <option value="CRYPTO">Crypto</option>
                        <option value="INDIAN">Indian PR</option>
                        <option value="WHITELABEL">White Label</option>
                      </Form.Control>
                    </Form.Group>
                  </div>
                  <div className="mt-3 d-flex gap-3">
                    <Button variant="secondary" onClick={clearFilters}>
                      Clear
                    </Button>
                    <Button variant="primary" onClick={applyFilters}>
                      Apply
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <DataTable
          className="data-table-wrapper"
          columns={columns}
          //   data={tempData}
          data={filteredPackage}
          pagination
          defaultSortFieldId={1}
          responsive={true}
          defaultSortAsc={true}
        />
      </div>
    </>
  );
};
export default OnlinePackagePage;
