import Sidebar from "../../components/SidebarNew/Sidebar";
import Navbar from "../../components/NavbarProfile/NavbarProfile";
import SupportLayout from "../../components/SupportLayout/SupportLayout";
import NewTicket from "../../components/SupportLayout/NewTicket";
import "./Support.css";
import { useState } from "react";

export default function Support() {
  const activeSide = 9;

  const [newQuery, setNewQuery] = useState(true);

  const handleNewQuery = () => {
    setNewQuery(false);
  };
  const handleTicketRaised = () => {
    setNewQuery(true);
  };

  return (
    <>
      <div className="row gx-0 support_content">
        <Sidebar activeSide={activeSide} />
        <div className={`main col`}>
          <Navbar name={"Support"} />
          {newQuery ? (
            <SupportLayout onShowNewQuery={handleNewQuery} />
          ) : (
            <NewTicket onShowTickets={handleTicketRaised} />
          )}
        </div>
      </div>
    </>
  );
}
