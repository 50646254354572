import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import "./ProfileLayout.css";

export default function BillingProfile() {
  return (
    <>
      <div className="profile_container">
        <div className="rectangle_one"></div>
        <img
          className={"blank_photo"}
          src="/Profile_Pic.svg"
          alt="zex pr wire Logo"
          width={154}
          height={154}
          priority
        />
      </div>
      <div className="content_container">
        <Form>
          <Row className="custom-row mb-4">
            <Form.Group as={Col} className="custom-col">
              <Form.Label>First Name</Form.Label>
              <Form.Control type="text" placeholder="First Name" />
            </Form.Group>
            <Form.Group as={Col} className="custom-col">
              <Form.Label>Last Name</Form.Label>
              <Form.Control type="text" placeholder="Last Name" />
            </Form.Group>
          </Row>
          <Row className="custom-row">
            <Col xs={6} className="custom-col mb-4">
              <Form.Label>Company Name</Form.Label>
              <Form.Control type="text" placeholder="wadewaren@fakemail.com" />
            </Col>
            <Col xs={2} className="custom-col mb-4">
              <Form.Label>Country Code</Form.Label>
              <Form.Control type="text" placeholder="IND | +91" />
            </Col>
            <Col xs={4} className="custom-col mb-4">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control type="number" placeholder="9876543210" />
            </Col>
          </Row>
          <Row className="custom-row mb-4">
            <Form.Group as={Col} className="custom-col">
              <Form.Label>Company Address </Form.Label>
              <Form.Control
                type="text"
                placeholder=" 20 Gardener Rd India 208652"
              />
            </Form.Group>
            <Form.Group as={Col} className="custom-col">
              <Form.Label>Country</Form.Label>
              <Form.Control type="text" placeholder=" India" />
            </Form.Group>
          </Row>
          <Row className="custom-row mb-4">
            <Form.Group as={Col} className="custom-col">
              <Form.Label>Company Email</Form.Label>
              <Form.Control type="email" placeholder=" Acme Corporation" />
            </Form.Group>
            <Form.Group as={Col} className="custom-col">
              <Form.Label>Company Website</Form.Label>
              <Form.Control type="text" placeholder="www.acmecorporation.com" />
            </Form.Group>
          </Row>
          <Row className="custom-row mb-4">
            <Form.Group as={Col} className="custom-col">
              <Form.Label>Other Details</Form.Label>
              <Form.Control type="text" placeholder="Lorem Ipsum Lorem Ipsum" />
            </Form.Group>
          </Row>
        </Form>
      </div>
    </>
  );
}
