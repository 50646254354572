import { Table, Pagination, Dropdown, DropdownButton } from "react-bootstrap";
import { useState, useEffect, useMemo } from "react";
import Instance from "../../Utils/Axios";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { showToast } from "../../Utils/index";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import "./Publication.css";

const PublicationManage = () => {
  const [PublicationList, setPublicationList] = useState([]);
  const [error, setError] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [tempData, setTempData] = useState([]);
  const [show, setShow] = useState(false);
  const [filters, setFilters] = useState({ status: "", audience: "" });
  const [selectedPublicationId, setSelectedPublicationId] = useState(null);
  const [accordionOpen, setAccordionOpen] = useState(false);
  const itemsPerPage = 10;

  const handleClose = () => setShow(false);
  const handleShow = (publicationId) => {
    setSelectedPublicationId(publicationId);
    setShow(true);
  };

  const deletePublication = () => {
    const updatedList = PublicationList.filter(
      (item) => item.publicationId !== selectedPublicationId
    );
    setPublicationList(updatedList);
    setTempData(updatedList);
    handleClose();
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const applyFilters = () => {
    let filteredData = [...PublicationList];

    // Filter by status
    filteredData = filteredData.filter((item) => {
      return filters.status ? item.status === filters.status : true;
    });

    if (filters.audience) {
      const [min, max] = filters.audience.split("-").map(Number);
      filteredData = filteredData.filter((item) => {
        const audience = parseInt(item.audience);
        return audience >= min && audience <= max;
      });
    }

    setTempData(filteredData);
  };

  const clearFilters = () => {
    setFilters({ status: "", audience: "" });
    setTempData(PublicationList);
  };

  const fetchPublicationList = () => {
    const token = localStorage.getItem("adminToken");
    console.log("Fetched token from localStorage:", token);
    Instance.post("/siteadmin/publication/getList", {
      adminToken: token,
      status: "",
      publicationName: "",
      publicationUrl: "",
      region: "",
      audience: "",
      networkName: "",
      logo: "",
    })
      .then((response) => {
        setPublicationList(response?.data?.message);
        setTempData(response?.data?.message);
        setError("");
        console.log("check data", response?.data?.message);
      })
      .catch((error) => {
        setError("Failed to fetch the data");
        console.error("Error fetching publication list:", error);
      });
  };
  useEffect(() => {
    fetchPublicationList();
  }, []);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  const offset = currentPage * itemsPerPage;
  const currentItems = PublicationList.slice(offset, offset + itemsPerPage);
  const pageCount = Math.max(Math.ceil(tempData.length / itemsPerPage), 1);

  console.log(currentPage);

  const handleSubmit = async (status, id) => {
    // e.preventDefault();
    try {
      const token = localStorage.getItem("adminToken");
      const response = await Instance.post(`/siteadmin/publication/update`, {
        adminToken: token,
        publicationId: id,
        status: status,
      });
      // console.log("Response from updateCoupon API:", response.data);
      if (response.data.resCode === "200") {
        fetchPublicationList();
        showToast(" Status Updated Succesfully!", "success", "success");

        // setTimeout(() => {
        //   // navigate("/category_press_release");
        //   window.location.reload();
        // }, 1000);
      } else {
        setError(response.data.resMessage);
      }
    } catch (error) {
      setError("Failed to update Publication details");
      console.error("Error updating Publication details:", error);
    }
  };

  const handleChecked = (event, status, id) => {
    const confirmed = window.confirm(
      `Are you sure you want to ${
        status === "active" ? "inactive" : "active"
      } status?`
    );

    if (status === "active" && confirmed === true) {
      handleSubmit("inactive", id);
    } else if (
      (status === "inactive" || status === "deactive" || status === "") &&
      confirmed === true
    ) {
      handleSubmit("active", id);
    }

    // else if (status === "DEACTIVE" && confirmed === true) {
    //   handleSubmit("ACTIVE", id);
    // }

    console.log("Switch value:", event.target.checked, status, id);
  };

  const columns = [
    {
      name: "SI.No.",
      cell: (row, index) => offset + index + 1,
      sortable: true,
      width: "90px",
      wrap: true,
    },
    {
      name: "Publication ID",
      selector: (row) => parseInt(row.publicationId),
      sortable: true,
      width: "160px",
      wrap: true,
    },
    {
      name: "Publication Name",
      selector: (row) => row.publicationName,
      sortable: true,
      width: "180px",
      wrap: true,
    },
    {
      name: "Region/Language",
      selector: (row) => row.region,
      sortable: true,
      // width: "160px",
      wrap: true,
    },
    {
      name: "Est Audience",
      selector: (row) => row.audience,
      sortable: true,
      wrap: true,
      width: "140px",
    },
    {
      name: "Publication URL",
      selector: (row) => row.publicationUrl,
      sortable: true,
      wrap: true,
      width: "160px",
    },
    {
      name: "Network Name",
      selector: (row) => row.networkName,
      sortable: true,
      wrap: true,
      width: "160px",
    },
    {
      name: "Status",
      cell: (row) => (
        <Form>
          <Form.Check
            type="switch"
            id="custom-switch"
            checked={row?.status === "active"}
            onChange={(e) => handleChecked(e, row.status, row.publicationId)}
          />
        </Form>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <Link to={`/admin/publication/edit/${row.publicationId}`}>
            <img
              src="/build/edit.svg"
              alt="edit"
              width={24}
              height={24}
              priority
            />
          </Link>
        </>
      ),
      width: "90px",
      wrap: true,
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        // fontSize: "18px",
      },
    },
  };

  const filterData = (e) => {
    let filterText = e.target.value;
    if (!filterText) {
      setTempData([...PublicationList]);
    } else {
      const filteredItems = PublicationList.filter(
        (item) =>
          (item.publicationId &&
            item.publicationId
              .toLowerCase()
              .includes(filterText.toLowerCase())) ||
          (item.publicationName &&
            item.publicationName
              .toLowerCase()
              .includes(filterText.toLowerCase())) ||
          (item.region &&
            item.region.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.audience &&
            item.audience.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.publicationUrl &&
            item.publicationUrl
              .toLowerCase()
              .includes(filterText.toLowerCase())) ||
          (item.networkId &&
            item.networkId.toLowerCase().includes(filterText.toLowerCase()))
      );
      setTempData([...filteredItems]);
    }
  };
  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(PublicationList[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  function downloadCSV(array) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = "export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      setAccordionOpen(!accordionOpen)
    );

    return (
      <button
        type="button"
        className="admin_filter_btn"
        onClick={decoratedOnClick}
      >
        {children}
        {accordionOpen ? (
          <img
            src="/build/img/Icons/down_vector.svg"
            alt="zex pr wire Logo"
            width={22}
            height={22}
          />
        ) : (
          <img
            src="/build/img/Icons/down_vector.svg"
            alt="zex pr wire Logo"
            width={22}
            height={22}
            className="filter_up_arrow"
          />
        )}
      </button>
    );
  }

  return (
    <>
      <div className="admin_staff_layout">
        <div className="page_heading">
          <h4> Publications </h4>
          <Link to={"/admin/publication/addnewpublication"}>
            <button className="addnew"> ADD NEW </button>
          </Link>
        </div>

        <Accordion className="mb-4">
          <Card>
            <Card.Header className="d-md-flex justify-content-between align-items-center">
              <div className="staff_search">
                <label>
                  Search:
                  <input
                    type="search"
                    placeholder=""
                    aria-controls="datatable"
                    onChange={filterData}
                  ></input>
                </label>
              </div>
              <div className="d-md-flex">
                <button
                  onClick={(e) => downloadCSV(PublicationList)}
                  className="btn btn-export mx-3"
                >
                  <img
                    src="/build/img/Icons/export.svg"
                    alt="zex pr wire Logo"
                    width={25}
                    height={25}
                  />
                </button>
                <CustomToggle as={Button} variant="link" eventKey="0">
                  Filters
                </CustomToggle>
              </div>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Form>
                  <div className="d-flex gap-3">
                    <Form.Group
                      controlId="filterStatus"
                      className="template_filter_options"
                    >
                      <Form.Label>Status</Form.Label>
                      <Form.Control
                        as="select"
                        name="status"
                        value={filters.status}
                        onChange={handleFilterChange}
                        className=""
                      >
                        <option value="">All</option>
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                      </Form.Control>
                    </Form.Group>
                    {/* <Form.Group controlId="filterStatuss"  className="template_filter_options">
                      <Form.Label>Estimated Audience</Form.Label>
                      <Form.Control
                        as="select"
                        name="audience"
                        value={filters.audience}
                        onChange={handleFilterChange}
                        className=""
                      >
                        <option value="">All</option>
                        <option value="0-100">0-100</option>
                        <option value="101-200">101-200</option>
                        <option value="201-300">201-300</option>
                        <option value="301-400">301-400</option>
                        <option value="401-500">401-500</option>
                        <option value="501-600">501-600</option>
                        <option value="601-700">601-700</option>
                        <option value="701-800">701-800</option>
                        <option value="801-900">801-900</option>
                        <option value="901-1000">901-1000</option>
                        <option value="1000-200000">More than 1000</option>
                      </Form.Control>
                    </Form.Group> */}
                  </div>
                  <div className="mt-3 d-flex gap-3">
                    <Button
                      variant="secondary"
                      className="clear"
                      onClick={clearFilters}
                    >
                      Clear
                    </Button>
                    <Button
                      variant="primary"
                      className="apply"
                      onClick={applyFilters}
                    >
                      Apply
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>

        <DataTable
          className="data-table-wrapper"
          columns={columns}
          data={tempData}
          pagination
          defaultSortFieldId={1}
          responsive={true}
          defaultSortAsc={true}
          customStyles={customStyles}
          //  actions={actionsMemo}
        />
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <div className="custom_template_modal_body">
            <h5>Are you sure?</h5>
            <p>Are you sure! You want to delete?</p>
            <div className="d-flex justify-content-end gap-4">
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                variant="danger"
                onClick={deletePublication}
                style={{ cursor: "pointer" }}
              >
                Ok
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PublicationManage;
