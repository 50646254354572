import "./UserLayout.css";
import { Link, useParams } from "react-router-dom";
import { Table } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useEffect, useState } from "react";
import Instance from "../../Utils/Axios";

const UserHistory = () => {
  const { userId } = useParams();
  const [error, setError] = useState("");
  const [UserHistory, setUserHistory] = useState([]);
  const [UserPaymentHistory, setUserPaymentHistory] = useState([]);
  const [prHistory, setPrHistory] = useState([]);
  const [tempData, setTempData] = useState([]);
  const [prData, setPrData] = useState([]);
  const [directPr, setDirectPr] = useState([]);
  //   console.log("user history page");
  const fetchUserDetails = async () => {
    try {
      const token = localStorage.getItem("adminToken");
      const response = await Instance.post(`/siteadmin/user/userDetails`, {
        adminToken: token,
        userId: userId,
      });
      //   console.log("Response from User API:", response.data);
      setUserHistory([response?.data?.userProfile]);

      setError("");
    } catch (error) {
      setError("Failed to fetch User details");
      console.error("Error fetching User details for edit:", error);
    }
  };
  useEffect(() => {
    fetchUserDetails();
  }, [userId]);

  const fetchPaymentHistory = async () => {
    try {
      const token = localStorage.getItem("adminToken");
      const response = await Instance.post(`/siteadmin/user/paymentHistory`, {
        adminToken: token,
        userId: userId,
      });
      //   console.log("Response from User API:", response.data);
      setUserPaymentHistory(response?.data?.message);
      setTempData(response?.data?.message);

      setError("");
    } catch (error) {
      setError("Failed to fetch User Payment details");
      console.error("Error fetching User Payment details for edit:", error);
    }
  };
  useEffect(() => {
    fetchPaymentHistory();
  }, [userId]);

  const fetchDirectPrList = async () => {
    try {
      const token = localStorage.getItem("adminToken");
      const response = await Instance.post(`/siteadmin/user/directPRList`, {
        adminToken: token,
        userId: userId,
      });
      //   console.log("Response from User API:", response.data);
      setDirectPr(response?.data?.message);

      setError("");
    } catch (error) {
      setError("Failed to fetch Direct Pr details");
      console.error("Error fetching Direct Pr details:", error);
    }
  };
  useEffect(() => {
    fetchDirectPrList();
  }, [userId]);

  const fetchPrHistory = async () => {
    try {
      const token = localStorage.getItem("adminToken");
      const response = await Instance.post(
        `/siteadmin/user/pressreleasehistory`,
        {
          adminToken: token,
          userId: userId,
        }
      );
      //   console.log("Response from User API:", response.data);
      setPrHistory(response?.data?.message);
      setPrData(response?.data?.message);

      setError("");
    } catch (error) {
      setError("Failed to fetch Pr History");
      console.error("Error fetching Pr History:", error);
    }
  };
  useEffect(() => {
    fetchPrHistory();
  }, [userId]);

  const columns1 = [
    {
      name: "Name",
      selector: (row) => row.firstName,
      sortable: true,
      // width: "360px",
      wrap: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      wrap: true,
    },
    {
      name: "Phone",
      selector: (row) => row.mobile,
      sortable: true,
      // width: "110px",
      wrap: true,
    },
    {
      name: "Date of Signup (DOJ)",
      selector: (row) => row.doj,
      sortable: true,
      //  width: "200px",
      wrap: true,
    },
    {
      name: "Last Login",
      selector: (row) => row.last_login_date,
      sortable: true,
      // width: "130px",
      wrap: true,
    },
    {
      name: "Spending Amount",
      selector: (row) => row.spendAmount,
      sortable: true,
      //  width: "160px",
      wrap: true,
    },
  ];
  const columns = [
    {
      name: "Package Name",
      selector: (row) => row.packageName,
      sortable: true,
      // width: "360px",
      wrap: true,
    },
    {
      name: "Price",
      selector: (row) => row.amount,
      sortable: true,
      wrap: true,
    },
    {
      name: "PR limit",
      selector: (row) => row.prLimit,
      sortable: true,
      // width: "110px",
      wrap: true,
    },
    {
      name: "How paid (reason)",
      selector: (row) => row.remarks,
      sortable: true,
      // width: "190px",
      wrap: true,
    },
    {
      name: "Date Time",
      selector: (row) => row.createdOn,
      sortable: true,
      // width: "130px",
      wrap: true,
    },
    {
      name: "Remaining Credits",
      selector: (row) => row.remainingPR,
      sortable: true,
      // width: "190px",
      wrap: true,
    },
    {
      name: "Expiry Dates",
      selector: (row) => row.endDate,
      sortable: true,
      // width: "180px",
      wrap: true,
    },
  ];

  const columns2 = [
    {
      name: "Package Name",
      selector: (row) => row.packageName,
      sortable: true,
      // width: "360px",
      wrap: true,
    },
    {
      name: "Price",
      selector: (row) => row.price,
      sortable: true,
      wrap: true,
    },
    {
      name: "PR limit",
      selector: (row) => row.prLimit,
      sortable: true,
      width: "110px",
      wrap: true,
    },
    {
      name: "Date Time",
      selector: (row) => row.createdOn,
      sortable: true,
      width: "130px",
      wrap: true,
    },
    {
      name: "Remaining Credits",
      selector: (row) => row.remainingPR,
      sortable: true,
      width: "190px",
      wrap: true,
    },
    {
      name: "Decrease PR",
      selector: (row) => (
        <>
          <Link to={`/admin/user/decreasePr/${row.id}/${row.userId}`}>
            <img
              src="/build/decrease.png"
              alt="decrese img"
              height="20"
              width="20"
            />
          </Link>
        </>
      ),
      sortable: true,
      width: "140px",
      wrap: true,
    },
    {
      name: "Decrease History",
      selector: (row) => row.decreaseHistory,
      sortable: true,
      width: "190px",
      wrap: true,
    },
  ];

  const columns3 = [
    {
      name: "Release Id",
      selector: (row) => row.id,
      sortable: true,
      wrap: true,
    },
    {
      name: "PR Status",
      selector: (row) => row.status,
      sortable: true,
      wrap: true,
    },
    {
      name: "Name of the release",
      selector: (row) => row.title,
      sortable: true,
      width: "360px",
      wrap: true,
    },
    {
      name: "Package",
      selector: (row) => row.packageName,
      sortable: true,
      wrap: true,
    },
    {
      name: "Date & Time",
      selector: (row) => row.updatedOn,
      sortable: true,
      wrap: true,
    },
  ];

  // const filterData = (e) => {
  //   let filterText = e.target.value;
  //   if (!filterText) {
  //     setTempData([...UserHistory]);
  //   } else {
  //     const filteredItems = UserHistory.filter(
  //       (item) =>
  //         (item.firstName &&
  //           item.firstName.toLowerCase().includes(filterText.toLowerCase())) ||
  //         (item.email &&
  //           item.email.toLowerCase().includes(filterText.toLowerCase())) ||
  //         (item.mobile &&
  //           item.mobile.toLowerCase().includes(filterText.toLowerCase())) ||
  //         (item.doj &&
  //           item.doj.toLowerCase().includes(filterText.toLowerCase())) ||
  //         (item.last_login_date &&
  //           item.last_login_date
  //             .toLowerCase()
  //             .includes(filterText.toLowerCase())) ||
  //         (item.spendAmount &&
  //           item.spendAmount.includes(filterText.toLowerCase()))
  //     );
  //     setTempData([...filteredItems]);
  //   }
  // };
  const filterData1 = (e) => {
    let filterText = e.target.value;
    if (!filterText) {
      setTempData([...UserPaymentHistory]);
    } else {
      const filteredItems = UserPaymentHistory.filter(
        (item) =>
          (item.packageName &&
            item.packageName
              .toLowerCase()
              .includes(filterText.toLowerCase())) ||
          (item.amount &&
            item.amount.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.prLimit &&
            item.prLimit.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.remarks &&
            item.remarks.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.createdOn &&
            item.createdOn.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.credits && item.credits.includes(filterText.toLowerCase())) ||
          (item.endDate && item.endDate.includes(filterText.toLowerCase()))
      );
      setTempData([...filteredItems]);
    }
  };
  const filterData3 = (e) => {
    let filterText = e.target.value;
    if (!filterText) {
      setPrData([...prHistory]);
    } else {
      const filteredItems = prHistory.filter(
        (item) =>
          (item.id &&
            item.id.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.status &&
            item.status.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.title &&
            item.title.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.packageName &&
            item.packageName
              .toLowerCase()
              .includes(filterText.toLowerCase())) ||
          (item.updatedOn &&
            item.updatedOn.toLowerCase().includes(filterText.toLowerCase()))
      );
      setPrData([...filteredItems]);
    }
  };
  return (
    <>
      <div className="affiliate_pages_layout">
        <div className="affiliate_page_heading">
          <h4>View User History</h4>
          <Link to={"/admin/user"}>
            <span style={{ cursor: "pointer" }}>
              <div className="backbutton"></div>
            </span>
          </Link>
        </div>
        <div className="table_box">
          <h4>User History</h4>

          <div className="admin_user_table">
            <DataTable
              columns={columns1}
              //   data={tempData}
              data={UserHistory}
              pagination
              defaultSortFieldId={1}
              responsive={true}
              defaultSortAsc={true}
              className="application_datatable mt-4 data-table-wrapper"
            />
          </div>
        </div>
        <div className="table_box">
          <h4>Payment History</h4>
          <div className="staff_search mt-4">
            <label>
              Search:
              <input
                type="search"
                placeholder=""
                aria-controls="datatable"
                onChange={filterData1}
              ></input>
            </label>
          </div>
          <DataTable
            columns={columns}
            //   data={tempData}
            data={tempData}
            pagination
            defaultSortFieldId={1}
            responsive={true}
            defaultSortAsc={true}
            className="application_datatable mt-4 data-table-wrapper"
          />
        </div>
        <div className="table_box">
          <h4>Decrease PR</h4>
          <div className="staff_search mt-4">
            <label>
              Search:
              <input
                type="search"
                placeholder=""
                aria-controls="datatable"
                // onChange={filterData}
              ></input>
            </label>
          </div>
          <DataTable
            columns={columns2}
            data={directPr}
            pagination
            defaultSortFieldId={1}
            responsive={true}
            defaultSortAsc={true}
            className="application_datatable mt-4 data-table-wrapper"
          />
        </div>
        <div className="table_box">
          <h4>Press Release History</h4>
          <div className="staff_search mt-4">
            <label>
              Search:
              <input
                type="search"
                placeholder=""
                aria-controls="datatable"
                onChange={filterData3}
              ></input>
            </label>
          </div>
          <DataTable
            columns={columns3}
            data={prData}
            pagination
            defaultSortFieldId={1}
            responsive={true}
            defaultSortAsc={true}
            className="application_datatable mt-4 data-table-wrapper"
          />
        </div>
      </div>
    </>
  );
};

export default UserHistory;
