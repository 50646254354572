"use client";
import "./Dashboard.css";
import Navbar from "../NavbarProfile/NavbarProfile";
import { Form, Pagination, Table } from "react-bootstrap";
import Sidebar from "../SidebarNew/Sidebar";
import Fillbutton from "../FillButtonNew/Fillbutton";
import { Arrowsvg } from "../../../public/svgs/svg";
import { Link } from "react-router-dom";

export default function Dashboard2() {
  const activeSide = 1;
  return (
    <div className="dashboard_container row gx-0">
      <Sidebar activeSide={activeSide} />
      <div className={`main col`}>
        <Navbar name={"Dashboard"} />
        <div className={`row gx-0`}>
          <div className={` left_main col-12 col-md-8`}>
            <div className="package_info">
              <div className="articles_info">
                <img
                  className={"d_article_img mb-0"}
                  src="/build/img/Newspaper.svg"
                  alt="zex pr wire Logo"
                  width={48}
                  height={48}
                  priority
                />
                <p className="d_article_num ">0</p>
                <p className="d_article_text mb-0">Articles Published</p>
              </div>
              <div className="package_owned">
                <img
                  className={"d_article_img mb-0"}
                  src="/build/img/Package.svg"
                  alt="zex pr wire Logo"
                  width={48}
                  height={48}
                  priority
                />
                <p className="d_article_num ">0</p>
                <p className="d_article_text mb-0">Packages Owned</p>
              </div>
              <div className="pr_status_container">
                {true ? (
                  <>
                    <div className="dash_expire_info ">
                      <div className="dash_expire_child">
                        <p className="dash_expire_title mb-0">
                          Package expiring on
                        </p>
                        <p className="dash_expire_date mb-0">12 Mar 2024</p>
                        <p className="dash_expire_time mb-0">
                          About to expire in a 3 days
                        </p>
                      </div>
                      <div className="dash_expire_child">
                        <p className="dash_expire_title mb-0">
                          Package expiring on
                        </p>
                        <p className="dash_expire_date mb-0">16 Mar 2024</p>
                        <p className="dash_expire_time mb-0">
                          About to expire in a week
                        </p>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between pr_text_wrapper">
                      <p className="dash_prstatus_text mb-0">PR Status</p>
                      <div className="dash_viewall mb-0">View all</div>
                    </div>
                  </>
                ) : (
                  <div
                    className="d-flex justify-content-center align-items-center m-auto"
                    style={{ height: "100%" }}
                  >
                    <div>
                      <p className="first_package_text">
                        Let’s buy your first package
                      </p>
                      <Fillbutton
                        text="Buy Now"
                        className="mx-auto first_pack_btn"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="package_details_text">
              <p className="dash_pack_text mb-0">Package Details</p>
              <p className="dash_pack_view mb-0">View all</p>
            </div>
            <div className="table-responsive">
              <Table className="table_container">
                <thead className="t_head_wrapper">
                  <tr className="t_head_row">
                    <th className="t_head_item">NAME</th>
                    <th className="t_head_item">PR LIMIT</th>
                    <th className="t_head_item">USER PR</th>
                    <th className="t_head_item">CREDITS LEFT</th>
                    <th className="t_head_item">EXPIRY</th>
                    <th className="t_head_item">BUY AGAIN</th>
                  </tr>
                </thead>
                {true ? (
                  <tbody>
                    <tr className="align-middle">
                      <td className="t_item">Boomerang</td>
                      <td className="t_item">11</td>
                      <td className="t_item">11</td>
                      <td className="t_item">0</td>
                      <td className="t_item">Aug 27,2019</td>
                      <td>
                        <Fillbutton
                          text="Buy Now"
                          className="mx-auto small_btn"
                        />
                      </td>
                    </tr>
                    <tr className="align-middle">
                      <td className="t_item">Coin Market Cap</td>
                      <td className="t_item">5</td>
                      <td className="t_item">5</td>
                      <td className="t_item">0</td>
                      <td className="t_item">Mar 02, 2019</td>
                      <td>
                        <Fillbutton
                          text="Buy Now"
                          className="mx-auto small_btn"
                        />
                      </td>
                    </tr>
                    <tr className="align-middle">
                      <td className="t_item">Yahoo Finance</td>
                      <td className="t_item">3</td>
                      <td className="t_item">0</td>
                      <td className="t_item">3</td>
                      <td className="t_item">Jun 24, 2024</td>
                      <td></td>
                    </tr>
                    <tr className="align-middle">
                      <td className="t_item">AP News</td>
                      <td className="t_item">4</td>
                      <td className="t_item">4</td>
                      <td className="t_item">2</td>
                      <td className="t_item">Dec 18, 2024</td>
                      <td></td>
                    </tr>
                    <tr className="align-middle">
                      <td className="t_item">Hackernoon</td>
                      <td className="t_item">11</td>
                      <td className="t_item">6</td>
                      <td className="t_item">5</td>
                      <td className="t_item">Aug 27,2024</td>
                      <td></td>
                    </tr>
                    <tr className="align-middle">
                      <td className="t_item">Coin Desk</td>
                      <td className="t_item">5</td>
                      <td className="t_item">5</td>
                      <td className="t_item">0</td>
                      <td className="t_item">Mar 02, 2019</td>
                      <td>
                        {" "}
                        <Fillbutton
                          text="Buy Now"
                          className="mx-auto small_btn"
                        />
                      </td>
                    </tr>
                    <tr className="align-middle">
                      <td className="t_item">CoinTelegraph</td>
                      <td className="t_item">3</td>
                      <td className="t_item">0</td>
                      <td className="t_item">3</td>
                      <td className="t_item">Jun 24, 2024</td>
                      <td></td>
                    </tr>
                    <tr className="align-middle">
                      <td className="t_item">Bitcoin.com</td>
                      <td className="t_item">4</td>
                      <td className="t_item">2</td>
                      <td className="t_item">2</td>
                      <td className="t_item">Dec 18, 2024</td>
                      <td></td>
                    </tr>
                    <tr className="align-middle">
                      <td className="t_item">News BTC</td>
                      <td className="t_item">3</td>
                      <td className="t_item">0</td>
                      <td className="t_item">3</td>
                      <td className="t_item">Jun 24, 2024</td>
                      <td></td>
                    </tr>
                    <tr className="align-middle">
                      <td className="t_item">Binance Feed</td>
                      <td className="t_item">4</td>
                      <td className="t_item">2</td>
                      <td className="t_item">2</td>
                      <td className="t_item">Dec 18, 2024</td>
                      <td></td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    <tr className=" ">
                      <td className="px-3 pt-3" colSpan={5}>
                        Buy your first PR package
                      </td>
                      <td>
                        <Fillbutton
                          text="Buy Now"
                          className="mx-auto small_btn"
                        />
                      </td>
                    </tr>
                  </tbody>
                )}
              </Table>
              <div className="table_footer">
                <div className="rows_per_page">
                  <p className="mb-0">Rows per page</p>
                  <Form.Select
                    aria-label="Default select example"
                    bsPrefix="dash_select"
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="1">4</option>
                    <option value="2">5</option>
                    <option value="3">6</option>
                    <option value="1">7</option>
                    <option value="2">8</option>
                    <option value="3">9</option>
                    <option selected>10</option>
                  </Form.Select>
                </div>
                <div className="rows_page">
                  <Pagination
                    className="mb-0 ps-0 "
                    bsPrefix="custom_pagination"
                  >
                    <Pagination.Item>{1}</Pagination.Item>
                    <Pagination.Item>{2}</Pagination.Item>
                    <Pagination.Item className="d-none d-lg-block">
                      {3}
                    </Pagination.Item>
                    <Pagination.Ellipsis />

                    <Pagination.Item className="d-none d-lg-block">
                      {10}
                    </Pagination.Item>
                    <Pagination.Item>{11}</Pagination.Item>
                    <Pagination.Item>{12}</Pagination.Item>
                  </Pagination>
                </div>
                <div className="go_to_page">
                  <p className="mb-0">Go to page</p>
                  <input />
                  <Arrowsvg rotate="0deg" />
                </div>
              </div>
            </div>
          </div>
          <div className={`col-12  col-md-4 right_main`}>
            <p className="mb-0 right_main_title">My Last Pr</p>
            {true ? (
              <div className="lastpr_main_cont">
                <div className="d-flex gap-2">
                  <img
                    className={""}
                    src="/build/img/notify.svg"
                    alt="zex pr wire Logo"
                    width={48}
                    height={48}
                    priority
                  />
                  <div className="lastpr_info">
                    <p className="lastpr_text">
                      World Blockchain Summit Set the Stage for a Decentralized
                      Future with Ground-breaking Insights and Collaborations
                    </p>
                    <p className="lastpr_time mb-0">Aug 30, 2023, 4:00 PM</p>
                  </div>
                </div>
                <div className="d-flex gap-2">
                  <img
                    className={""}
                    src="/build/img/notify.svg"
                    alt="zex pr wire Logo"
                    width={48}
                    height={48}
                    priority
                  />
                  <div className="lastpr_info">
                    <p className="lastpr_text">
                      ZEX PR WIRE Join Forces with Blockchain Life to be the
                      Official PR Partner for the 10th Edition of Blockchain
                      Life 2023 in Dubai
                    </p>
                    <p className="lastpr_time mb-0 ">Aug 30, 2023, 4:00 PM</p>
                  </div>
                </div>
                <div className="d-flex gap-2">
                  <img
                    className={""}
                    src="/build/img/notify.svg"
                    alt="zex pr wire Logo"
                    width={48}
                    height={48}
                    priority
                  />
                  <div className="lastpr_info">
                    <p className="lastpr_text">
                      UAE’s Future Blockchain Summit to address all things
                      Blockchain in Dubai from tomorrow
                    </p>
                    <p className="lastpr_time mb-0">Aug 30, 2023, 4:00 PM</p>
                  </div>
                </div>
                <div className="d-flex gap-2">
                  <img
                    className={""}
                    src="/build/img/notify.svg"
                    alt="zex pr wire Logo"
                    width={48}
                    height={48}
                    priority
                  />
                  <div className="lastpr_info">
                    <p className="lastpr_text">
                      ZEX PR WIRE World Best PR NewsWire now joins Token2049 To
                      Exhibit at  Singapore Edition Sept 2023
                    </p>
                    <p className="lastpr_time mb-0">Aug 30, 2023, 4:00 PM</p>
                  </div>
                </div>
                <div className="d-flex gap-2">
                  <img
                    className={""}
                    src="/build/img/notify.svg"
                    alt="zex pr wire Logo"
                    width={48}
                    height={48}
                    priority
                  />
                  <div className="lastpr_info">
                    <p className="lastpr_text">
                      Middle East & North Africa’s Fintech community to gather
                      tomorrow at Fintech Surge 2022
                    </p>
                    <p className="lastpr_time mb-0">Aug 30, 2023, 4:00 PM</p>
                  </div>
                </div>
              </div>
            ) : (
              <div className="d-flex justify-content-center my-4">
                <div>
                  <p className="submit_first_pr">Let’s Submit your first PR</p>
                  <Fillbutton
                    text="Submit Now"
                    className="mx-auto first_pack_btn"
                  />
                </div>
              </div>
            )}
            <div className="notification_info">
              <div className=" notification_title">
                <p className="notification_text">Notification</p>
                <p className="notification_viewall">
                  <Link to="/notification">View all</Link>
                </p>
              </div>
              {true ? (
                <div>
                  <div className="notification_actions">
                    <div className="color_box"></div>
                    <div className="notification_actions_info">
                      <p className="notification_action mb-2">
                        PR ID: 7284 - Rejected
                      </p>
                      <p className="notification_action_desc mb-2">
                        Make some small changes to submit again
                      </p>
                      <p className="notification_action_time mb-4">
                        1 Hours Ago
                      </p>
                    </div>
                  </div>
                  <div className="notification_actions">
                    <div
                      className="color_box"
                      style={{ background: "#5DCC5C" }}
                    ></div>
                    <div className="notification_actions_info">
                      <p className="notification_action mb-2">
                        PR ID: 7279 - Published
                      </p>
                      <p className="notification_action_desc mb-2">
                        Your PR ‘Adobe Calls BS on Marketing Buzzword’ is online
                      </p>
                      <p className="notification_action_time">1 Hours Ago</p>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="d-flex gap-2">
                  <div
                    className="color_box"
                    style={{ backgroundColor: "#595FDE" }}
                  ></div>
                  <div className="notification_actions_info">
                    <p className="notification_action">No Notifications yet</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
