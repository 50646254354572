import "./NetworkPages.css";
import { useState, useRef, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Instance from "../../Utils/Axios";
import { showToast } from "../../Utils/index";
import { useParams, Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const NetworkUpdate = () => {
  const { networkId } = useParams();
  const fileInputRef = useRef(null);
  const logoInputRef = useRef(null);
  const [uploadFile, setUploadFile] = useState(null);
  const [uploadUrl, setUploadUrl] = useState(null);
  const [dataUpdate, setDataUpdate] = useState({
    networkName: "",
    region: "",
    audience: "",
    bulkPublicationsUrl: "",
    status: "",
  });
  const [logoPreview, setLogoPreview] = useState(null);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const fetchNetworkDetails = async () => {
    try {
      const token = localStorage.getItem("adminToken");
      const response = await Instance.post("/siteadmin/network/details", {
        adminToken: token,
        networkId: networkId,
      });
      setDataUpdate(response?.data);
      setUploadFile(response?.data?.logo);
      if (response.data.logo) {
        setLogoPreview(response.data.logo);
        console.logo("check log", response.data.logo);
        // setUploadFile(response.data.logo);
      }
    } catch (error) {
      console.error("Error fetching network details for edit:", error);
    }
  };
  useEffect(() => {
    fetchNetworkDetails();
  }, [networkId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDataUpdate((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleLogoUploadClick = () => {
    logoInputRef.current.click();
  };

  const handleUploadChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      setUploadFile(file?.name);
      console.log(`Selected file: ${file.name}`);
    }

    if (!file) {
      showToast("Please Select Image", "error", "error");
    } else {
      const formDataToSubmit = new FormData();
      const token = localStorage.getItem("adminToken");
      formDataToSubmit.append("adminToken", token);
      formDataToSubmit.append("fileUpload", file);

      axios
        .post(
          "https://pradmin.webandapi.com//siteadmin/upload/document",
          formDataToSubmit,
          {
            headers: {
              "content-type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          if (response?.data?.resCode == "200") {
            // handleAddGallery(response?.data?.documentUrl);

            console.log("file url : ", response?.data?.documentUrl);
            setUploadUrl(response?.data?.documentUrl);
            // showToast("logo Inserted !", "success", "success");
          }
        })
        .catch((error) => {
          setError("Failed to fetch the data");
          console.error("Error fetching network list:", error);
        });
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setDataUpdate((prevData) => ({
      ...prevData,
      bulkPublicationsUrl: file,
    }));
  };

  const handleLogoChange = (event) => {
    const file = event.target.files[0];
    setDataUpdate((prevData) => ({
      ...prevData,
      logo: file,
    }));
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setLogoPreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setLogoPreview(null);
    }
  };

  const updateNetwork = (e) => {
    e.preventDefault();
    const token = localStorage.getItem("adminToken");

    const formDataToSubmit = new FormData();
    formDataToSubmit.append("adminToken", token);
    formDataToSubmit.append("networkName", dataUpdate.networkName);
    formDataToSubmit.append("networkId", networkId);
    formDataToSubmit.append("region", dataUpdate.region);
    formDataToSubmit.append("audience", dataUpdate.audience);
    formDataToSubmit.append("status", dataUpdate.status);
    formDataToSubmit.append("logo", uploadUrl);

    // if (dataUpdate.logo) {
    //   formDataToSubmit.append("logo", dataUpdate.logo);
    // }

    Instance.post("/siteadmin/network/update", formDataToSubmit)
      .then((response) => {
        if (response.data.resCode === "200") {
          showToast("Network updated successfully", "success", "success");
          setTimeout(() => {
            navigate("/admin/network");
          }, 2000);
          setError("");
        } else {
          showToast("Error updating network", "error", "error");
          setError(response.data.resMessage);
          console.log("Network update failed:", response.data);
        }
      })
      .catch((error) => {
        showToast("Error", "error", "error");
        setError("Failed to update network");
        console.error("Error updating network:", error);
      });
  };

  console.log("file name is ===========", uploadFile);
  return (
    <>
      <div className="admin_network_layout">
        <div className="network_page_heading">
          <h4>Edit Report Network</h4>
          <div className="admin_network_btns">
            <Link to={"/admin/network"}>
              <span style={{ cursor: "pointer" }}>
                <div className="backbutton"></div>
              </span>
            </Link>
          </div>
        </div>
        <div className="add_network_table">
          <Form onSubmit={updateNetwork}>
            <Row>
              <Col xs="6" className="custom_col_add_staff mb-4">
                <Form.Label>Network Name</Form.Label>
                <Form.Control
                  type="text"
                  name="networkName"
                  value={dataUpdate.networkName}
                  onChange={handleChange}
                  required
                />
              </Col>
              <Col xs="6" className="custom_col_add_staff mb-4">
                <Form.Label>Logo</Form.Label>
                <div className="d-flex gap-4">
                  <Form.Control
                    type="text"
                    // value={logoPreview ? logoPreview : ""}
                    value={uploadFile}
                    readOnly
                  />
                  <button
                    className="network_upload_btn"
                    type="button"
                    onClick={handleLogoUploadClick}
                  >
                    <img
                      src="/build/upload.svg"
                      alt="upload"
                      width={20}
                      height={20}
                    />
                  </button>
                  <input
                    type="file"
                    ref={logoInputRef}
                    style={{ display: "none" }}
                    accept="image/*"
                    onChange={handleLogoChange}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Region / Language</Form.Label>
                <Form.Control
                  type="text"
                  name="region"
                  value={dataUpdate.region}
                  onChange={handleChange}
                  required
                />
              </Col>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Estimated Audience</Form.Label>
                <Form.Control
                  type="text"
                  name="audience"
                  value={dataUpdate.audience}
                  onChange={handleChange}
                  required
                />
              </Col>
            </Row>
            <Row>
              <Col xs="6" className="custom_col_add_staff mb-4">
                <Form.Label>Upload Network CSV</Form.Label>
                <div className="d-flex gap-4">
                  <Form.Control
                    type="text"
                    // value={
                    //   dataUpdate.bulkPublicationsUrl
                    //     ? dataUpdate.bulkPublicationsUrl.name
                    //     : ""
                    // }
                    value={uploadFile}
                    readOnly
                  />
                  <button
                    className="network_upload_btn"
                    type="button"
                    onClick={handleFileUploadClick}
                  >
                    <img
                      src="/build/upload.svg"
                      alt="upload"
                      width={20}
                      height={20}
                    />
                  </button>
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    accept=".csv"
                    // onChange={handleFileChange}
                    onChange={handleUploadChange}
                  />
                </div>
                <p className="network_table_dwonload_text">
                  Download Sample CSV
                </p>
              </Col>
              <Col className="custom_col_add_staff mb-4">
                <div className="d-flex gap-4 flex-column">
                  <div>
                    <Form.Label>Status</Form.Label>
                    <Form.Select
                      name="status"
                      value={dataUpdate.status}
                      onChange={handleChange}
                    >
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </Form.Select>
                  </div>
                  <div>
                    {logoPreview ? (
                      <img
                        src={logoPreview}
                        alt="Logo Preview"
                        className="preview-logo"
                      />
                    ) : (
                      <span>No image uploaded</span>
                    )}{" "}
                  </div>
                </div>
              </Col>
            </Row>
            <div>
              <button className="add_network_add_btn" type="submit">
                Update
              </button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default NetworkUpdate;
