import "./AnalyticsLayout.css";
import { useState } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";

import { Col } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import Package from "./Sales/Pakage";
import Customer from "./Sales/Customer";
import Payment from "./Sales/Payment";

const AnalyticsSales = () => {
  const [id, setId] = useState(1);

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [showDatePicker, setShowDatePicker] = useState(false);

  const formatDate = (date) => {
    return date.toLocaleDateString("en-US", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  const formattedDateRange = `${formatDate(
    dateRange[0].startDate
  )} - ${formatDate(dateRange[0].endDate)}`;

  const handleApplyDateRange = () => {
    setShowDatePicker(false);
  };

  const Total = [3473, 3477];
  const New = [49, 0];
  const Active = [49, 0];
  const Delete = [0, 0];
  const xLabels = ["June", "July"];

  return (
    <>
      <div className="affiliate_pages_layout">
        <div className="affiliate_page_heading">
          <h4> Analytics - Sales</h4>
          <div className="date-picker-container">
            {showDatePicker ? (
              <div className="date-picker-overlay">
                <DateRangePicker
                  ranges={dateRange}
                  onChange={(ranges) => setDateRange([ranges.selection])}
                  onClose={() => setShowDatePicker(false)}
                />
                <button
                  className="apply_date_btn"
                  onClick={handleApplyDateRange}
                >
                  Apply
                </button>
              </div>
            ) : (
              <div
                style={{ textDecoration: "underline" }}
                onClick={() => setShowDatePicker(true)}
              >
                {formattedDateRange}
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6   ">
            <div className="card_info" style={{ backgroundColor: "#465aff" }}>
              <div className="card_detail">
                <h5>1</h5>
                <span>TOTAL NO OF SALES</span>
              </div>
              <div className="card_img">
                <span>
                  <img src="/build/total-sales.svg" width={36} height={40} />
                </span>
                <p>
                  -97.92% <FontAwesomeIcon icon={faCircleInfo} />
                </p>
              </div>
              {/* <img className="card_arrow" src="/build/downarrow.svg" /> */}
            </div>
          </div>

          <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6   ">
            <div className="card_info" style={{ backgroundColor: "#ff326e" }}>
              <div className="card_detail">
                <h5>$0</h5>
                <span>TOTAL SALES(USD)</span>
              </div>
              <div className="card_img">
                <span>
                  <img
                    src="/build/total-sales-USD.svg"
                    width={36}
                    height={40}
                  />
                </span>
                <p>
                  -2385 USD% <FontAwesomeIcon icon={faCircleInfo} />
                </p>
              </div>
              <img className="card_arrow" src="/build/downarrow.svg" />
            </div>
          </div>

          <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6   ">
            <div className="card_info" style={{ backgroundColor: "#fa6d42" }}>
              <div className="card_detail">
                <h5> -97.20%</h5>
                <span>SALES % GROWTH</span>
              </div>
              <div className="card_img">
                <span>
                  <img src="/build/sales-growth.svg" width={34} height={38} />
                </span>
                <p>
                  -97.92% <FontAwesomeIcon icon={faCircleInfo} />
                </p>
              </div>
              <img className="card_arrow" src="/build/downarrow.svg" />
            </div>
          </div>

          <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6   ">
            <div className="card_info" style={{ backgroundColor: "#5cc858" }}>
              <div className="card_detail">
                <h5>-100%</h5>
                <span>SALES % GROWTH (USD)</span>
              </div>
              <div className="card_img">
                <span>
                  <img
                    src="/build/sales-Growth-USD.svg"
                    width={34}
                    height={38}
                  />
                </span>
                <p>
                  -100% <FontAwesomeIcon icon={faCircleInfo} />
                </p>
              </div>
              <img className="card_arrow" src="/build/downarrow.svg" />
            </div>
          </div>
        </div>

        <div className="third_div  mt-5" style={{ overflow: "auto" }}>
          <div>
            <Link
              className="  info-btn "
              onClick={() => setId(1)}
              //   style={{ fo }}
            >
              Package
            </Link>
            {id === 1 ? (
              <hr className="info-btn" style={{ opacity: "unset" }}></hr>
            ) : null}
          </div>
          <div>
            <Link
              className=" info-btn "
              onClick={() => setId(2)}

              //   style={{ backgroundColor: id === 2 ? "#fff" : "#eeeeee" }}
            >
              Customer
            </Link>
            {id === 2 ? (
              <hr className="info-btn" style={{ opacity: "unset" }}></hr>
            ) : null}
          </div>
          <div>
            <Link
              className=" info-btn "
              onClick={() => setId(3)}
              //   style={{ backgroundColor: id === 3 ? "#fff" : "#eeeeee" }}
            >
              Payment method
            </Link>
            {id === 3 ? (
              <hr className="info-btn" style={{ opacity: "unset" }}></hr>
            ) : null}
          </div>
        </div>

        {id === 1 ? <Package /> : id === 2 ? <Customer /> : <Payment />}
      </div>
    </>
  );
};

export default AnalyticsSales;
