import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./ManageSupport.css";

import Active from "./Active";
import Closed from "./Closed";
import All from "./All";

const ManageSupport = () => {
  const [id, setId] = useState(1);

  return (
    <>
      <div className="admin_support_layout">
        <div className="page_heading">
          <h4>Manage Query </h4>

          <div className="second_div  ">
            <Link to="#">
              <button
                className="btn btn-new_1"
                onClick={() => window.location.reload()}
              >
                {" "}
                Refresh{" "}
              </button>
            </Link>
            &nbsp; &nbsp;
            <Link to="#" onClick={() => window.location.reload()}>
              <span style={{ cursor: "pointer" }}>
                <div className="backbutton"></div>
              </span>
            </Link>
          </div>
        </div>

        <div className="third_div btn-primary">
          <div>
            <Link
              className="btn text-primary btn_tabs "
              onClick={() => setId(1)}
              style={{ backgroundColor: id === 1 ? "#fff" : "#eeeeee" }}
            >
              Active
            </Link>
          </div>
          <div>
            <Link
              className="btn text-primary btn_tabs"
              onClick={() => setId(2)}
              style={{ backgroundColor: id === 2 ? "#fff" : "#eeeeee" }}
            >
              Closed
            </Link>
          </div>
          <div>
            <Link
              className="btn text-primary btn_tabs"
              onClick={() => setId(3)}
              style={{ backgroundColor: id === 3 ? "#fff" : "#eeeeee" }}
            >
              All
            </Link>
          </div>
        </div>

        {id === 1 ? (
          <>
            <Active />
          </>
        ) : id === 2 ? (
          <Closed />
        ) : (
          <All />
        )}
      </div>
    </>
  );
};

export default ManageSupport;
