import "./Report.css"
import Navbar from "../../components/NavbarProfile/NavbarProfile";
import Sidebar from "../../components/SidebarNew/Sidebar";
import ReportPage from "../../components/Report/Report";
import Footer from "../../components/Layouts/Footer";

const Report = () => {
  const activeSide = 13;
  const activeEventKey = "3";

  return (
    <>
      <div className="row gx-0 admin_report_layout">
        <Sidebar activeSide={activeSide} activeEventKey={activeEventKey} />
        <div
          className={`main col d-flex flex-column`}
          style={{ overflow: "auto" }}
        >
          <Navbar />
          <div className="flex-grow-1">
            <ReportPage />
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Report;
