import { useState } from "react";
import "./SecondaryNav.css";
import MyProfile from "../ProfileLayout/MyProfile";
import BillingProfile from "../ProfileLayout/BillingProfile";
import ProfileEdit from "../ProfileLayout/ProfileEdit";
import AfterEdit from "../ProfileLayout/AfterEdit";

export default function SecondaryNavbar() {
  const [active, setActive] = useState(1);
  const [activeTab, setActiveTab] = useState(1);
  const [editMode, setEditMode] = useState(false);

  const handleTabClick = (tabIndex) => {
    setActive(tabIndex);
    setActiveTab(tabIndex);
    setEditMode(false);
  };
  const handleEditClick = () => {
    setEditMode(true);
  };
  const handleSaveChangeClick = () => {
    setActive(3);
    setActiveTab(1);
  };
  const handleSaveClick = () => {
    setEditMode(false);
  };
  const handleCancelClick = () => {
    setActive(1);
    setEditMode(false);
  };

  return (
    <>
      <secondary>
        <div className="secondary_nav">
          <div className="tabs">
            <div
              className={`path_one ${activeTab === 1 ? "path_active" : ""}`}
              onClick={() => handleTabClick(1)}
            >
              My Profile
            </div>
            <div
              className={`path_two ${activeTab === 2 ? "path_active" : ""}`}
              onClick={() => handleTabClick(2)}
            >
              Billing Profile
            </div>
          </div>
          {!editMode && (
            <button className="edit_btn" onClick={handleEditClick}>
              <img
                className={""}
                src="/build/img/Edit.svg"
                alt="zex pr wire Logo"
                width={14}
                height={14}
                priority
              />
              Edit
            </button>
          )}
          {editMode && active === 2 && (
            <button className="save_btn" onClick={handleSaveClick}>
              Save
            </button>
          )}
        </div>
        <img
          className={"group_img"}
          src="/build/img/Group.png"
          alt="zex pr wire Logo"
          width={620}
          height={68}
          priority
        />
      </secondary>
      <div>
        {editMode && active === 1 ? (
          <ProfileEdit
            onCancelClick={handleCancelClick}
            onSaveChangeClick={handleSaveChangeClick}
          />
        ) : active === 1 ? (
          <MyProfile />
        ) : active === 3 ? (
          <AfterEdit />
        ) : (
          <BillingProfile />
        )}
      </div>
    </>
  );
}
