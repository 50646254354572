import "./Footer.css"

const Footer = () => {
  return (
    <>
      <div className="admin_footer">
        Copyright 2024 © ZEXPRWIRE. All rights reserved. Privacy Policy | Terms
        and Conditions
      </div>
    </>
  );
};

export default Footer;
