import Navbar from "../../components/NavbarProfile/NavbarProfile";
import Sidebar from "../../components/SidebarNew/Sidebar";
import NetworkUpdate from "../../components/Networks/NetworkUpdate";
import Footer from "../../components/Layouts/Footer";
import "./NE.css";

const NetworkEdit = () => {
  const activeSide = 16;
  const activeEventKey = "3";
  return (
    <>
      <div className="row gx-0 network_edit_layout">
        <Sidebar activeSide={activeSide}  activeEventKey={activeEventKey} />
        <div
          className={`main col d-flex flex-column`}
          style={{ overflow: "auto" }}
        >
          <Navbar />
          <div className="flex-grow-1">
            <NetworkUpdate />
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default NetworkEdit;
