import "./CompanyLayout.css";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Button from "react-bootstrap/Button";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Instance from "../../Utils/Axios";
import { showToast } from "../../Utils/index";
const CompanyPage = () => {
  const [accordionOpen, setAccordionOpen] = useState(false);
  const [tempData, setTempData] = useState([]);

  const [companyList, setCompanyList] = useState([]);
  const [filters, setFilters] = useState({
    //status: "",
    name: "",
    contactPerson: "",
    mobile: "",
    email: "",

    status: "",
    userName: "",
  });

  const [error, setError] = useState("");

  const fetchCompanyList = () => {
    const token = localStorage.getItem("adminToken");
    console.log("Fetched token from localStorage:", token);
    Instance.post("/siteadmin/user/company/getList", {
      adminToken: token,
      companyId: "",
      status: "",
      name: "",
      contactPerson: "",
      mobile: "",
      email: "",
      userName: "",
    })
      .then((response) => {
        setCompanyList(response?.data?.message);
        setTempData(response?.data?.message);
        setError("");
        console.log("check data", response?.data?.message);
      })
      .catch((error) => {
        setError("Failed to fetch the data");
        console.error("Error fetching company list:", error);
      });
  };
  useEffect(() => {
    fetchCompanyList();
  }, []);

  const filterData = (e) => {
    let filterText = e.target.value;
    // console.log(filterText);

    if (!filterText) {
      setTempData([...companyList]);
    } else {
      const filteredItems = companyList.filter(
        (item) =>
          (item.name &&
            item.name.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.contactPerson &&
            item.contactPerson
              .toLowerCase()
              .includes(filterText.toLowerCase())) ||
          (item.mobile &&
            item.mobile.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.email &&
            item.email.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.userName &&
            item.userName.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.status &&
            item.status.toLowerCase().includes(filterText.toLowerCase()))
      );
      setTempData([...filteredItems]);
    }
  };

  const handleSubmit = async (status, id) => {
    // e.preventDefault();
    try {
      const token = localStorage.getItem("adminToken");
      const response = await Instance.post(`/siteadmin/user/company/update`, {
        adminToken: token,
        companyId: id,
        status: status,
      });

      if (response.data.resCode === "200") {
        fetchCompanyList();
        showToast("Updated Succesfully!", "success", "success");
        // setTimeout(() => {
        //   window.location.reload();
        // }, 1000);
      } else {
        setError(response.data.resMessage);
      }
    } catch (error) {
      setError("Failed to update company details");
      console.error("Error updating comapny details:", error);
    }
  };

  const handleChecked = (event, status, id) => {
    const confirmed = window.confirm(
      `Are you sure you want to ${status === "ACTIVE" ? "inactive" : "active"
      } status?`
    );

    if (status === "ACTIVE" && confirmed === true) {
      handleSubmit("INACTIVE", id);
    } else if (status === "INACTIVE" && confirmed === true) {
      handleSubmit("ACTIVE", id);
    }

    // else if (status === "DEACTIVE" && confirmed === true) {
    //   handleSubmit("ACTIVE", id);
    // }

    console.log("Switch value:", event.target.checked, status, id);
  };

  const columns = [
    {
      name: "Company Name",
      selector: (row) => row.name,
      // sortable: true,
      wrap: true,
    },
    {
      name: "Contact Person",
      selector: (row) => row.contactPerson,
      // sortable: true,
      wrap: true,
    },
    {
      name: "Phone",
      selector: (row) => row.mobile,
      // sortable: true,
      wrap: true,
    },
    {
      name: "email",
      selector: (row) => row.email,
      // sortable: true,
      wrap: true,
    },
    {
      name: "Created By",
      cell: (row) => row.userName,
      wrap: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <span className="d-flex gap-2 align-items-center">
          <Form>
            <Form.Check
              type="switch"
              id="custom-switch"
              checked={row?.status === "ACTIVE"}
              onChange={(e) => handleChecked(e, row.status, row.companyId)}
            />
          </Form>
          <a href={`/admin/company/edit/${row.companyId}`} target="_blank">
            <img
              src="/build/edit.svg"
              alt="edit"
              width={24}
              height={24}
              priority
            />
          </a>
        </span>
      ),
    },
  ];

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const applyFilters = () => {
    const token = localStorage.getItem("adminToken");
    Instance.post("/siteadmin/user/company/getList", {
      adminToken: token,
      // status: filters.status,

      name: filters.name,
      contactPerson: filters.contactPerson,
      mobile: filters.mobile,
      email: filters.email,

      status: filters.status,
      userName: filters.userName,
    })
      .then((response) => {
        setTempData(response?.data?.message);
        setError("");
        console.log("Filtered data:", response?.data?.message);
      })
      .catch((error) => {
        setError("Failed to apply filters");
        console.error("Error applying filters:", error);
      });
  };

  const clearFilters = () => {
    const token = localStorage.getItem("adminToken");
    Instance.post("/siteadmin/user/company/getList", {
      adminToken: token,
      // status: filters.status,
      name: "",
      contactPerson: "",
      mobile: "",
      email: "",
      status: "",
      userName: "",
    })
      .then((response) => {
        setFilters({
          name: "",
          contactPerson: "",
          mobile: "",
          email: "",

          status: "",
          userName: "",
        });
        setCompanyList(response?.data?.message);
        setTempData(response?.data?.message);
        setError("");
        console.log("Cleared filters. Updated data:", response?.data?.message);
      })
      .catch((error) => {
        setError("Failed to clear filters");
        console.error("Error clearing filters:", error);
      });
  };

  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      setAccordionOpen(!accordionOpen)
    );

    return (
      <button
        type="button"
        className="admin_filter_btn"
        // style={{ backgroundColor: "pink" }}
        onClick={decoratedOnClick}
      >
        {children}
        {accordionOpen ? (
          <img
            src="/build/img/Icons/down_vector.svg"
            alt="zex pr wire Logo"
            width={16}
            height={16}
          />
        ) : (
          <img
            src="/build/img/Icons/down_vector.svg"
            alt="zex pr wire Logo"
            width={16}
            height={16}
            className="filter_up_arrow"
          />
        )}
      </button>
    );
  }

  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = ["name", "contactPerson", "mobile", "email", "userName", "status"];

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  function downloadCSV(array) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = "Company_list.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }
  return (
    <>
      <div className="company_pages_layout">
        <div
          className="company_page_heading {
"
        >
          <h4 className="comp"> Manage Company </h4>
          <Link to="/admin/company/add" className="comp">
            <button className="btn btn-new add_company">
              {" "}
              ADD NEW COMPANY{" "}
            </button>{" "}
          </Link>
        </div>

        <Accordion className="mb-4">
          <Card>
            <Card.Header className="d-md-flex justify-content-between align-items-center">
              <div className="country_search ">
                <label>
                  Search:
                  <input
                    type="search"
                    aria-controls="datatable"
                    onChange={(e) => filterData(e)}
                  />
                </label>
              </div>
              <div className="d-flex">
                <button
                  onClick={(e) => downloadCSV(companyList)}
                  className="btn btn-export mx-3"
                >
                  <img
                    src="/build/img/Icons/export.svg"
                    alt="zex pr wire Logo"
                    width={25}
                    height={25}
                  />
                </button>
                <CustomToggle as={Button} variant="link" eventKey="0">
                  Filters
                </CustomToggle>
              </div>
            </Card.Header>

            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Form>
                  <div className="d-flex gap-4 flex-wrap">
                    <Form.Group
                      controlId="name"
                      className="country_filter_options"
                    >
                      <Form.Label>Company Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search Company Name"
                        name="name"
                        value={filters.name}
                        onChange={handleFilterChange}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="contactPerson"
                      className="country_filter_options"
                    >
                      <Form.Label>Contact Person</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search Contact Person"
                        name="contactPerson"
                        value={filters.contactPerson}
                        onChange={handleFilterChange}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="mobile"
                      className="country_filter_options"
                    >
                      <Form.Label>Phone</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search number"
                        name="mobile"
                        value={filters.mobile}
                        onChange={handleFilterChange}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="email"
                      className="country_filter_options"
                    >
                      <Form.Label>Eamil</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search email"
                        name="email"
                        value={filters.email}
                        onChange={handleFilterChange}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="createdBy"
                      className="country_filter_options"
                    >
                      <Form.Label>Created By</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search Created By"
                        name="userName"
                        value={filters.userName}
                        onChange={handleFilterChange}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="createdBy"
                      className="country_filter_options"
                    >
                      <Form.Label>Status</Form.Label>
                      <Form.Control
                        as="select"
                        name="status"
                        value={filters.status}
                        onChange={handleFilterChange}
                      >
                        <option value="">ALL</option>
                        <option value="ACTIVE">ACTIVE</option>
                        <option value="INACTIVE">INACTIVE</option>
                      </Form.Control>
                    </Form.Group>
                  </div>
                  <div className="mt-3 d-flex gap-3">
                    <Button variant="secondary" onClick={clearFilters}>
                      Clear
                    </Button>
                    <Button variant="primary" onClick={applyFilters}>
                      Apply
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>

        <DataTable
          className="data-table-wrapper"
          columns={columns}
          //   data={row}
          data={tempData}
          pagination
          responsive={true}
          defaultSortFieldId={1}
          defaultSortAsc={true}
        />
      </div>
    </>
  );
};

export default CompanyPage;
