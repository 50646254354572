import React from "react";
import Navbar from "../../components/NavbarProfile/NavbarProfile";
import Sidebar from "../../components/SidebarNew/Sidebar";
import Footer from "../../components/Layouts/Footer";
import "./EditInvoice.css";
import InvoiceEdit from "../../components/InvoiceManagement/InvoiceEdit";

const EditInvoice = () => {
  const activeSide = 15;
  const activeEventKey = "2";
  return (
    <>
      <div className="row gx-0 edit_invoice_content ">
        <Sidebar activeSide={activeSide} activeEventKey={activeEventKey} />
        <div
          className={`main col d-flex flex-column`}
          style={{ overflow: "auto" }}
        >
          <Navbar />
          <div className="flex-grow-1">
            <InvoiceEdit />
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default EditInvoice;
