import React from "react";
import Navbar from "../../components/NavbarProfile/NavbarProfile";
import Sidebar from "../../components/SidebarNew/Sidebar";
import Footer from "../../components/Layouts/Footer";
import "./DeletePressRelease.css";
import PressReleaseDelete from "../../components/EditorialManagment/PressReleaseDelete";

const DeletePressRelease = () => {
  const activeEventKey = "2";
  const activeSide = 10;
  return (
    <>
      <div className="row gx-0 delete_press_content ">
        <Sidebar activeSide={activeSide} activeEventKey={activeEventKey} />
        <div
          className={`main col d-flex flex-column`}
          style={{ overflow: "auto" }}
        >
          <Navbar />
          <div className="flex-grow-1">
            <PressReleaseDelete />
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default DeletePressRelease;
