import React from "react";
import "./Editorial.css";
import { Table, Pagination, Dropdown, DropdownButton } from "react-bootstrap";
import { useState, useEffect, useMemo } from "react";
import Instance from "../../Utils/Axios";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import EditorialRoom from "./EditorialRoom";
import EditorialCardData from "./EdtorialCardData";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import { Select } from 'antd';
import { showToast } from "../../Utils";

const PressReleasePublish = () => {
  const [error, setError] = useState("");
  const [editorialList, setEditorialList] = useState([]);
  const [tempData, setTempData] = useState([]);
  const [accordionOpen, setAccordionOpen] = useState(false);
  // const cardData = EditorialCardData();
  const [record, setRecord] = useState(EditorialRoom);

  console.log(EditorialRoom);

  const handleFilter = (event) => {
    const newData = record.filter((row) => {
      return;
      row.User.toLowerCase().includes(event.target.value.toLowerCase());
    });

    setRecord(newData);
  };

  const handleSubmit = async (id) => {
    // e.preventDefault();
    try {
      const token = localStorage.getItem("adminToken");
      const response = await Instance.post(`/siteadmin/pressrelease/update`, {
        adminToken: token,
        id: id,
        isDeleted: "YES",
      });

      if (response.data.resCode === "200") {
        fetchEditorialList();
        showToast("Deleted PR Succesfully!", "success", "success");
        // setTimeout(() => {
        //   window.location.reload();
        // }, 1000);
      } else {
        setError(response.data.resMessage);
      }
    } catch (error) {
      setError("Failed to update Editorial details");
      console.error("Error updating Editorial details:", error);
    }
  };

  const handleDelete = (id) => {
    const ans = window.confirm("Are you sure , want to delete the PR?");
    if (ans === true) {
      handleSubmit(id);
    }
  };

  const [counData, setCountData] = useState([]);
  const [count, setCount] = useState(0);
  const [filters, setFilters] = useState({
    id: "",
    title: "",
    createdOn: "",
    status: "",
    companyName: "",
    packageName: "",
    userName: "",
  });

  const columns = [
    {
      name: "Release ID",
      selector: (row) => parseInt(row.id),
      sortable: true,
      wrap: true,
    },
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
      wrap: true,
    },
    {
      name: "Published Date",
      selector: (row) => row.createdOn,
      sortable: true,
      wrap: true,
      width: "160px",
    },

    {
      name: "Package Name",
      selector: (row) => row.packageName,
      sortable: true,
      wrap: true,
      width: "160px",
    },
    {
      name: "User",
      selector: (row) => row.userName,
      sortable: true,
      wrap: true,
    },
    {
      name: "Company",
      selector: (row) => row.companyName,
      sortable: true,
      wrap: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      wrap: true,
    },
    {
      name: "Report Uploded",
      selector: (row) => row.reportStatus,
      wrap: true,
      width: "160px",
    },
    {
      name: "Actions",
      //  selector: row => row.mobile,
      cell: (row) => (
        <>
          <a>
            <img
              src="/build/delete.svg"
              alt="delete"
              width={24}
              height={24}
              priority
              className="me-2"
              onClick={() => handleDelete(row.id)}
              style={{ cursor: "pointer" }}
            />
          </a>
          <a
            href={`/admin/editorial/publish/upload_press_release`}
            target="_blank"
          >
            <img
              src="/build/upload_icon.png"
              alt="view"
              width={24}
              height={24}
              priority
              className="me-2"
            />
          </a>
        </>
      ),
      wrap: true,
    },
  ];

  const fetchEditorialList = () => {
    const token = localStorage.getItem("adminToken");
    console.log("Fetched token from localStorage:", token);
    Instance.post("/siteadmin/pressrelease/list", {
      adminToken: token,

      status: "PUBLISHED",
    })
      .then((response) => {
        setEditorialList(response?.data?.message);
        setTempData(response?.data?.message);
        console.log("this is the state data ", response?.data?.stats);
        const arr = [];
        arr.push(response?.data?.stats?.All);
        arr.push(response?.data?.stats?.PUBLISHED?.Published);
        arr.push(response?.data?.stats?.PENDING?.Pending);
        arr.push(response?.data?.stats?.DRAFT?.Drafts);
        arr.push(response?.data?.stats?.OPEN?.["Action Required"]);
        arr.push(response?.data?.stats?.REJECT?.["Rejected"]);
        // setCountData(...counData, response?.data?.stats?.REPORT?.Reports);

        setCountData(...counData, arr);
        // console.log("open value", counData);

        setError("");
        console.log("check editorial  data", response?.data?.message);
      })
      .catch((error) => {
        setError("Failed to fetch the data");
        console.error("Error fetching editorial list:", error);
      });
  };
  useEffect(() => {
    fetchEditorialList();
  }, []);

  const filterData = (e) => {
    let filterText = e.target.value;
    if (!filterText) {
      setTempData([...editorialList]);
    } else {
      const filteredItems = editorialList.filter(
        (item) =>
          (item.title &&
            item.title.toLowerCase().includes(filterText.toLowerCase())) ||
          // (item.createdOn &&
          //   item.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.status &&
            item.status.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.id &&
            item.id.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.companyName &&
            item.companyName
              .toLowerCase()
              .includes(filterText.toLowerCase())) ||
          (item.packageName &&
            item.packageName
              .toLowerCase()
              .includes(filterText.toLowerCase())) ||
          (item.userName &&
            item.userName.toLowerCase().includes(filterText.toLowerCase()))
      );
      setTempData([...filteredItems]);
    }
  };
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const applyFilters = () => {
    const token = localStorage.getItem("adminToken");
    Instance.post("/siteadmin/pressrelease/list", {
      adminToken: token,
      // status: filters.status,

      id: filters.id,
      title: filters.title,
      createdOn: filters.createdOn,
      status: filters.status,
      companyName: filters.companyName,
      packageName: filters.packageName,
      userName: filters.userName,
    })
      .then((response) => {
        setTempData(response?.data?.message);
        setError("");
        console.log("Filtered data:", response?.data?.message);
      })
      .catch((error) => {
        setError("Failed to apply filters");
        console.error("Error applying filters:", error);
      });
  };

  const clearFilters = () => {
    const token = localStorage.getItem("adminToken");
    Instance.post("/siteadmin/pressrelease/list", {
      adminToken: token,
      id: "",
      title: "",
      createdOn: "",
      status: "",
      companyName: "",
      packageName: "",
      userName: "",
    })
      .then((response) => {
        setFilters({
          id: "",
          title: "",
          createdOn: "",
          status: "",
          companyName: "",
          packageName: "",
          userName: "",
        });
        setEditorialList(response?.data?.message);
        setTempData(response?.data?.message);
        setError("");
        console.log("Cleared filters. Updated data:", response?.data?.message);
      })
      .catch((error) => {
        setError("Failed to clear filters");
        console.error("Error clearing filters:", error);
      });
  };

  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      setAccordionOpen(!accordionOpen)
    );

    return (
      <button
        type="button"
        className="admin_filter_btn"
        // style={{ backgroundColor: "pink" }}
        onClick={decoratedOnClick}
      >
        {children}
        {accordionOpen ? (
          <img
            src="/build/img/Icons/down_vector.svg"
            alt="zex pr wire Logo"
            width={16}
            height={16}
          />
        ) : (
          <img
            src="/build/img/Icons/down_vector.svg"
            alt="zex pr wire Logo"
            width={16}
            height={16}
            className="filter_up_arrow"
          />
        )}
      </button>
    );
  }

  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = ["id", "title", "updatedOn", "packageName", "userName", "companyName", "status", "reportStatus" ];

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  function downloadCSV(array) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = "Published_Press_Release.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  return (
    <>
      <div className="admin_editorial_layout">
        <div className="page_heading">
          <h4>Published Press Release </h4>
          <Link to="/admin/editorial">
            <span style={{ cursor: "pointer" }}>
              <div className="backbutton"></div>
            </span>
          </Link>
        </div>

        <Row className="my-3">
          {EditorialCardData.map((val, indx) => {
            return (
              <>
                <Col className="col-xl-2 col-lg-4 col-md-4 col-sm-4  col-12  py-2">
                  <Card
                    className="    box_card"
                  // style={{ width: "11rem" }}
                  >
                    <Link to={val.link}>
                      <div className="text-center">
                        <p
                          className="pt-2"
                          style={{ color: "#868e96", fontWeight: "600" }}
                        >
                          {" "}
                          {val.name}
                        </p>
                        <p style={{ color: "#868e96", fontWeight: "600" }}>
                          {" "}
                          {counData[indx]}
                        </p>
                      </div>
                    </Link>
                  </Card>
                </Col>
              </>
            );
          })}

          {/* </div> */}
        </Row>

        <Accordion className="mb-4">
          <Card>
            <Card.Header className="d-md-flex justify-content-between align-items-center">
              <div className="editorial_search">
                <label>
                  Search:
                  <input
                    type="search"
                    placeholder=""
                    aria-controls="datatable"
                    onChange={filterData}
                  ></input>
                </label>
              </div>
              <div className="d-md-flex">
                <button
                  onClick={(e) => downloadCSV(editorialList)}
                  className="btn btn-export mx-3"
                >
                  <img
                    src="/build/img/Icons/export.svg"
                    alt="zex pr wire Logo"
                    width={25}
                    height={25}
                  />
                </button>
                <CustomToggle as={Button} variant="link" eventKey="0">
                  Filters
                </CustomToggle>
              </div>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Form>
                  <div className="d-flex gap-4 flex-wrap">
                    <Form.Group
                      controlId="filterStatus"
                      className="editorial_filter_options"
                    >
                      <Form.Label>Status</Form.Label>
                      <Form.Control
                        as="select"
                        name="status"
                        value={filters.status}
                        onChange={handleFilterChange}
                      >
                        <option value="All">All</option>
                        <option value="OPEN">OPEN</option>
                        <option value="DRAFT">DRAFT</option>
                        <option value="PENDING">PENDING</option>
                        <option value="REPORT">REPORT</option>
                        <option value="PUBLISHED">PUBLISHED</option>
                        <option value="REJECT">REJECT</option>
                      </Form.Control>
                    </Form.Group>

                    <Form.Group
                      controlId="filterUsername"
                      className="editorial_filter_options"
                    >
                      <Form.Label>User</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search Username"
                        name="userName"
                        value={filters.userName}
                        onChange={handleFilterChange}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filterUsername"
                      className="editorial_filter_options"
                    >
                      <Form.Label>Release ID</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Search Release Id"
                        name="id"
                        value={filters.id}
                        onChange={handleFilterChange}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filterName"
                      className="editorial_filter_options"
                    >
                      <Form.Label>Title</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search Title"
                        name="title"
                        value={filters.title}
                        onChange={handleFilterChange}
                      ></Form.Control>
                    </Form.Group>

                    <Form.Group
                      controlId="filterEmail"
                      className="editorial_filter_options"
                    >
                      <Form.Label>Package Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search Package Name"
                        name="packageName"
                        value={filters.packageName}
                        onChange={handleFilterChange}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filterEmail"
                      className="editorial_filter_options"
                    >
                      <Form.Label>Company</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search Company"
                        name="companyName"
                        value={filters.companyName}
                        onChange={handleFilterChange}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filterEmail"
                      className="editorial_filter_options"
                    >
                      <Form.Label>Published Date</Form.Label>
                      <Form.Control
                        type="datetime-local"
                        id="datetime"
                        placeholder="Search Published Date"
                        name="createdOn"
                        value={filters.createdOn}
                        onChange={handleFilterChange}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filterDate"
                      className="editorial_filter_options"
                    >
                      <Form.Label>Report Uploded</Form.Label>
                      <Form.Control
                        as="select"
                        name="Report"
                      // value={filters.Report}
                      // onChange={handleFilterChange}
                      >
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </Form.Control>
                    </Form.Group>
                  </div>
                  <div className="mt-3 d-flex gap-3">
                    <Button variant="secondary" onClick={clearFilters}>
                      Clear
                    </Button>
                    <Button variant="primary" onClick={applyFilters}>
                      Apply
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>

        <DataTable
          className="data-table-wrapper"
          columns={columns}
          data={tempData}
          pagination
          defaultSortFieldId={1}
          responsive={true}
          defaultSortAsc={true}
        //  actions={actionsMemo}
        />
      </div>
    </>
  );
};

export default PressReleasePublish;
