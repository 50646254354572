import "./PackageLayout.css";
import { Link, useNavigate } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import { useEffect, useState } from "react";
import Select from "react-select";
import Instance from "../../Utils/Axios";
import { showToast } from "../../Utils/index";

const AddPackage = () => {
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const [error, setError] = useState("");
  const [showTable, setShowTable] = useState(false);
  const [vendorList, setVendorList] = useState([]);
  const [tempData, setTempData] = useState([]);
  const [PackageData, setPackageData] = useState([]);
  const [currentFormData, setCurrentFormData] = useState({
    checkedOption: "",
    text: "",
  });
  const [descOption, setDescOption] = useState("");
  const [description, setDescription] = useState("");
  const [descArr, setDescArr] = useState([]);
  const [distribution, setDistribution] = useState([]);
  const [vendorString, setVendorString] = useState("");

  // =============vendor api call========================

  const fetchVendorList = () => {
    const token = localStorage.getItem("adminToken");
    Instance.post("/siteadmin/vendor/getList", {
      adminToken: token,
      vendorId: "",
      credits: "",
      status: "",
      url: "",
      name: "",
      createdOn: "",
    })
      .then((response) => {
        setVendorList(response?.data?.message);
        var tempArr = [];
        response?.data?.message.map((dist) =>
          tempArr.push({
            label: dist.name,
            value: dist.vendorId,
          })
        );
        setDistribution(tempArr);
        setError("");
      })
      .catch((error) => {
        setError("Failed to fetch the data");
      });
  };
  useEffect(() => {
    fetchVendorList();
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    vendorId: vendorString,
    price: "",
    pressReleaseCount: "",
    badge: "",
    type: "",
    subType: "NORMAL",
    validity: "",
    description: PackageData,
    // adminToken: "{{adminToken}}",
    // name: "",
    // vendorId: "34",
    // price: 1000,
    // pressReleaseCount: "",
    // badge: "",
    // type: "",
    // subType: "",
    // validity: "",
    // description: [
    //   {
    //     text: "",
    //     checkedOption: "",
    //   },
    // ],
  });

  const addNewPackage = (e) => {
    e.preventDefault();
    formData.description = PackageData;
    formData.vendorId = vendorString;
    console.log(formData, "dataaaaaaaa");
    // alert("hii");
    const token = localStorage.getItem("adminToken");

    Instance.post("/siteadmin/package/create", {
      adminToken: token,
      ...formData,
    })
      .then((response) => {
        if (response.data.resCode === "100") {
          showToast("Already a member", "error", "error");
          setError(response.data.resMessage);
        } else {
          showToast("User added successfully", "success", "success");
          setError("");
          setTimeout(() => {
            navigate("/admin/package");
          }, 2000);
        }
      })
      .catch((error) => {
        showToast("Error", "error", "error");
        setError("Failed to add package");
      });
  };

  const handleAddDesc = () => {
    var tempArr = [...descArr];
    tempArr.push({
      text: descOption,
      description: description,
    });
    setDescArr(tempArr);
  };

  // const options = [];

  // const data = tempData.map((val) => {
  //   console.log(val, "oooooooooooo");
  //   options.push({
  //     value: val.name,
  //     label: val.name,
  //   });
  // });
  const handlePackageChange = (e) => {
    const { name, value } = e.target;
    setCurrentFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleButtonClick = (e) => {
    e.preventDefault();
    const { text, checkedOption } = currentFormData;
    if (text && checkedOption) {
      setPackageData((prevData) => [...prevData, { text, checkedOption }]);
      setShowTable(true);
    } else {
      showToast("Please fill in all the fields.");
    }
  };

  const handleDelete = (index) => {
    const updatedData = PackageData.filter((item, idx) => idx !== index);
    setPackageData(updatedData);
    if (updatedData.length === 0) {
      setShowTable(false);
    }
  };

  const handleMultSelect = (obj) => {
    const tempArr = obj.map((ar) => ar.value);
    var str = "";
    tempArr.map((val, indx) => {
      if (indx <= tempArr.length - 2) {
        str = str + val + "|";
      }
      if (indx == tempArr.length - 1) {
        str = str + val;
      }
    });

    setVendorString(str);
  };

  return (
    <>
      <div className="package_pages_layout">
        <div className="package_page_heading">
          <h4> Add New Package </h4>
          <div className="admin_package_btns">
            <Link to="/admin/package">
              <span style={{ cursor: "pointer" }}>
                <div className="backbutton"></div>
              </span>
            </Link>
          </div>
        </div>
        <div className="add_package_details">
          <Form onSubmit={addNewPackage}>
            <Row>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Package Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Package Name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </Col>
            </Row>
            <Row>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Select Option</Form.Label>
                <Form.Select
                  name="checkedOption"
                  value={currentFormData.checkedOption}
                  onChange={(e) => handlePackageChange(e)}
                >
                  <option value="">Select Option</option>
                  <option value="CHECKED">Check Option</option>
                  <option value="CROSS">Remove Option</option>
                </Form.Select>
              </Col>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  name="text"
                  placeholder="Enter Description"
                  rows={2}
                  value={currentFormData.text}
                  onChange={(e) => handlePackageChange(e)}
                />
              </Col>
            </Row>
            <button className="add_package_des_btn" onClick={handleButtonClick}>
              Add Package Description
            </button>

            <div className={` ${showTable ? "d-block" : "d-none"} `}>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Selected Option</th>
                    <th>Description</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {PackageData.map((data, index) => (
                    <tr key={index}>
                      <td>{data.checkedOption}</td>
                      <td>{data.text}</td>
                      <td>
                        <button
                          className="show_table_delete_btn"
                          onClick={() => handleDelete(index)}
                        >
                          Delete Record
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            <Row>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Select Distribution</Form.Label>
                <Select
                  // onChange={(cl) => {
                  //   console.log(cl, "hiiiiiiii");
                  // }}

                  onChange={(cl) => handleMultSelect(cl)}
                  options={distribution}
                  placeholder="Select Distribution Options"
                  isMulti
                  closeMenuOnSelect={false}
                />
              </Col>
            </Row>
            <Row>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Number Press Release / Credits</Form.Label>
                <Form.Control
                  type="number"
                  name="pressReleaseCount"
                  value={formData.pressReleaseCount}
                  onChange={handleChange}
                  placeholder="Number Press Release"
                />
              </Col>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Price</Form.Label>
                <Form.Control
                  type="number"
                  name="price"
                  placeholder="Price"
                  value={formData.price}
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <Row>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>
                  Offline / Online Package [Show on frontend or not]
                </Form.Label>
                <Form.Select
                  as="select"
                  name="type"
                  value={formData.type}
                  onChange={handleChange}
                >
                  <option value="">
                    Please Select Offline / Online Package
                  </option>
                  <option value="ONLINE">online package</option>
                  <option value="OFFLINE">offline package</option>
                </Form.Select>
              </Col>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Package Validity</Form.Label>
                <Form.Control
                  type="number"
                  name="validity"
                  placeholder="Enter Package Validity like days 30/50"
                  value={formData.validity}
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <Row>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Select Badge</Form.Label>
                <Form.Select
                  name="badge"
                  value={formData.badge}
                  onChange={handleChange}
                >
                  <option value="">Select Badge</option>
                  <option value="5">5 CREDITS</option>
                  <option value="20">20 CREDITS</option>
                  <option value="50">50 CREDITS</option>
                </Form.Select>
              </Col>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Select Reseller/Normal Package</Form.Label>
                <Form.Select
                  as="select"
                  name="subType"
                  value={formData.subType}
                  onChange={handleChange}
                >
                  <option value="NORMAL">Normal Package</option>
                  <option value="RESELLER">Reseller Package</option>
                  <option value="CRYPTO">Crypto Package</option>
                  <option value="INDIAN">Indian PR Package</option>
                  <option value="WHITELABEL">White Label Package</option>
                </Form.Select>
              </Col>
            </Row>
            <button className="add_package_submit_btn" type="submit">
              Submit
            </button>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddPackage;
