import { useState } from "react";
import "./GalleryLayout.css";

export default function GridView({
  setSelectedPhoto,
  updateSelectedCheckboxes,
}) {
  const [checkedState, setCheckedState] = useState(Array(12).fill(false));

  const handleDivClick = (index) => {
    setCheckedState((prevCheckedState) => {
      const updatedCheckedState = prevCheckedState.map((checked, i) =>
        i === index ? !checked : checked
      );
      updateSelectedCheckboxes(updatedCheckedState);
  
      const selectedImages = updatedCheckedState.filter((isChecked) => isChecked);
      if (selectedImages.length === 1) {
        setSelectedPhoto(`/Image${index < 9 ? "0" : ""}${index + 1}.png`);
      } else if (selectedImages.length > 1) {
        setSelectedPhoto(`/Image${index < 9 ? "0" : ""}${index + 1}.png`);
      } else {
        setSelectedPhoto("");
      }
  
      return updatedCheckedState;
    });
  };
  
  const handlePhotoClick = (index) => {
    setSelectedPhoto(`/Image${index < 9 ? "0" : ""}${index + 1}.png`);
  };
  return (
    <>
      <div className="grid_view_layout">
        {checkedState.map((isChecked, index) => (
          <div
            key={index}
            className="grid_images"
            onClick={() => {
              handleDivClick(index);
              handlePhotoClick(index);
            }}
          >
            <img
              className={"mb-0"}
              src={`/Image${index < 9 ? "0" : ""}${index + 1}.png`}
              alt="zex pr wire Logo"
              width={200}
              height={200}
              priority
            />
            <input type="checkbox" checked={isChecked} />
          </div>
        ))}
      </div>
    </>
  );
}
