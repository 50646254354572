import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useState, useEffect } from "react";
import { showToast } from "../../Utils/index";
import axios from "axios";
import "./Profile.css";
import Instance from "../../Utils/Axios";

const Profile = () => {
  return (
    <>
      <div className="profile_pages_layout">
        <div className="profile_page_heading">
          <h4 className=""> Update Profile </h4>
          <div className="admin_profile_btns">
            <Link to="/user" className="">
              <span style={{ cursor: "pointer" }}>
                <div className="backbutton"></div>
              </span>
            </Link>
          </div>
        </div>
        <div className="add_profile_details">
          <Form
          //   onSubmit={addNewprofile}
          >
            <Row>
              <Col className=" col-6 custom_col_add_staff mb-4">
                <Form.Label>User Name</Form.Label>
                <Form.Control
                  type="text"
                  name="contactPerson"
                  //   value={formData.contactPerson}
                  //   onChange={handleChange}

                  required
                />
              </Col>
            </Row>

            <Row>
              <Col className="col-6 custom_col_add_staff mb-4">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  //value={formData.name}
                  //onChange={handleChange}
                />
              </Col>
            </Row>
            <Row>
              <Col className="col-6 custom_col_add_staff mb-4">
                <Form.Label>Confirm-Password</Form.Label>
                <Form.Control
                  type="text"
                  name="email"
                  //value={formData.email}
                  // onChange={handleChange}
                  required
                />
              </Col>
            </Row>

            <button className="add_profile_submit_btn" type="submit">
              Update Profile
            </button>
          </Form>
        </div>
      </div>
    </>
  );
};

export default Profile;
