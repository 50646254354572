import "./NetworkPages.css";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Link, useParams } from "react-router-dom";
import { Table } from "react-bootstrap";
import { useState, useEffect } from "react";
import Instance from "../../Utils/Axios";
import DataTable from "react-data-table-component";

const NetworkDetail = () => {
  const { networkId } = useParams();
  const [manageData, setManageData] = useState({
    networkName: "",
    publication: "",
    region: "",
    audience: "",
    status: "",
  });
  const [publicationData, setPublicationData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchStaffDetail = async () => {
      try {
        const token = localStorage.getItem("adminToken");
        const response = await Instance.post("/siteadmin/network/details", {
          adminToken: token,
          networkId: networkId,
        });
        console.log("network ID :", networkId);
        const fetchedData = response.data;
        const publicationNetwork = fetchedData.publication.map((pub) => ({
          ...pub,
          networkName: fetchedData.networkName,
        }));
        setManageData(fetchedData);
        setPublicationData(publicationNetwork);
        setError("");
      } catch (error) {
        setError("Failed to fetch staff details");

        console.error("Error fetching staff detail:", error);
      }
    };

    fetchStaffDetail();
  }, [networkId]);

  const columns = [
    {
      name: "SI.No.",
      selector: (row, index) => index + 1,
      width: "80px",
    },
    {
      name: "Publication Name",
      selector: (row) => row.publicationName,
    },
    {
      name: "Region / Language",
      selector: (row) => row.region,
    },
    {
      name: "Est. Audience",
      selector: (row) => row.audience,
    },
    {
      name: "Network name",
      selector: (row) => row.networkName,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <img src="/build/edit.svg" alt="edit" width={18} height={18} />
          <img src="/build/delete.svg" alt="delete" width={18} height={18} />
        </>
      ),
      width: "80px",
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredData = publicationData.filter((item) => {
    return (
      item.publicationName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.region.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.audience.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.networkName.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  return (
    <>
      <div className="admin_network_layout">
        <div className="network_page_heading">
          <h4>View Network</h4>
          <div className="admin_network_btns">
            <Link to={"/admin/network"}>
              <span style={{ cursor: "pointer" }}>
                <div className="backbutton"></div>
              </span>
            </Link>
          </div>
        </div>
        <div className="add_network_table">
          <Form>
            <Row>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Network ID</Form.Label>
                <Form.Control
                  type="text"
                  name="networkId"
                  value={manageData.networkId}
                />
              </Col>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Network Name</Form.Label>
                <Form.Control
                  type="text"
                  name="networkName"
                  value={manageData.networkName}
                />
              </Col>
            </Row>
            <Row>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Region / Language</Form.Label>
                <Form.Control
                  type="text"
                  name="region"
                  value={manageData.region}
                />
              </Col>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>No of Publications</Form.Label>
                <Form.Control
                  type="text"
                  name="networkName"
                  value={manageData.publicationNumber}
                />
              </Col>
            </Row>
            <Row>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Estimated Audience</Form.Label>
                <Form.Control
                  type="text"
                  name="audience"
                  value={manageData.audience}
                />
              </Col>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Network URL</Form.Label>
                <Form.Control
                  type="text"
                  name="networkUrl"
                  value={manageData.networkUrl}
                />
              </Col>
            </Row>
            <Row>
              <Col xs="6" className="custom_col_add_staff mb-4">
                <Form.Label>Status</Form.Label>
                <Form.Control
                  type="text"
                  name="status"
                  value={manageData.status}
                />
              </Col>
            </Row>
          </Form>
          <div>
            <div className="staff_search">
              <label>
                Search:
                <input
                  type="search"
                  placeholder=""
                  aria-controls="datatable"
                  onChange={handleSearchChange}
                ></input>
              </label>
            </div>
          </div>
          <DataTable
            columns={columns}
            data={filteredData}
            pagination
            highlightOnHover
            pointerOnHover
            customStyles={customStyles}
          />
          {/* <Table className="mb-0" striped bordered hover>
            <thead className="staff_table_head">
              <tr>
                <th>
                  <input type="checkbox" className="mt-1" />
                  <img
                    className="table_sort_img"
                    src="/build/sort.svg"
                    alt="sort"
                    width={10}
                    height={15}
                    priority
                  />
                </th>
                <th>
                  SI.No.
                  <img
                    className="table_sort_img"
                    src="/build/sort.svg"
                    alt="sort"
                    width={10}
                    height={15}
                    priority
                  />
                </th>
                <th>
                  Publication Name
                  <img
                    className="table_sort_img"
                    src="/build/sort.svg"
                    alt="sort"
                    width={10}
                    height={15}
                    priority
                  />
                </th>
                <th>
                  Region / Language
                  <img
                    className="table_sort_img"
                    src="/build/sort.svg"
                    alt="sort"
                    width={10}
                    height={15}
                    priority
                  />
                </th>
                <th>
                  Est. Audience
                  <img
                    className="table_sort_img"
                    src="/build/sort.svg"
                    alt="sort"
                    width={10}
                    height={15}
                    priority
                  />
                </th>
                <th>
                  Network Name
                  <img
                    className="table_sort_img"
                    src="/build/sort.svg"
                    alt="sort"
                    width={10}
                    height={15}
                    priority
                  />
                </th>
                <th>
                  Action
                  <img
                    className="table_sort_img"
                    src="/build/sort.svg"
                    alt="sort"
                    width={10}
                    height={15}
                    priority
                  />
                </th>
              </tr>
            </thead>
            <tbody className="staff_table_body">
              <tr>
                <td>
                  <input type="checkbox" className="mt-1" />
                </td>
                <td>{+1}</td>
                <td>{manageData?.publication?.publicationName}</td>
                <td>{manageData?.publication?.region}</td>
                <td>{manageData?.publication?.audience}</td>
                <td>{manageData?.publication?.networkName}</td>
                <td>
                  <img
                    src="/build/edit.svg"
                    alt="edit"
                    width={24}
                    height={24}
                    className="me-2"
                  />
                  <img
                    src="/build/delete.svg"
                    alt="delete"
                    width={24}
                    height={24}
                  />
                </td>
              </tr>
            </tbody>
          </Table> */}
        </div>
      </div>
    </>
  );
};
export default NetworkDetail;
