import Navbar from "../../components/NavbarProfile/NavbarProfile";
import Sidebar from "../../components/SidebarNew/Sidebar";
import VendorPage from "../../components/Vendor/VendorPage";
import Footer from "../../components/Layouts/Footer";
import "./Vendor.css";

const Vendor = () => {
  const activeSide = 26;
  return (
    <>
      <div className="row gx-0 admin_vendor_layout">
        <Sidebar activeSide={activeSide} />
        <div
          className={`main col d-flex flex-column`}
          style={{ overflow: "auto" }}
        >
          <Navbar />
          <div className="flex-grow-1">
            <VendorPage />
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Vendor;
