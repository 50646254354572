import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Form from "react-bootstrap/Form";

import Instance from "../../Utils/Axios";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { showToast } from "../../Utils/index";

const AddCountry = () => {
  const navigate = useNavigate();

  const [addcountry, setAddCountry] = useState([]);
  const [tempData, setTempData] = useState([]);
  const [error, setError] = useState("");
  const [formData, setFormData] = useState({
    countryCode: "",
    countryName: "",
    isdCode: "",
    gmtMinute: "",
    currency: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // const fetchCountryList = () => {
  //     const token = localStorage.getItem("adminToken");
  //     console.log("Fetched token from localStorage:", token);
  //     Instance.post("/siteadmin/common/countryAdd", {
  //       adminToken: token,
  //       countryCode:"",
  //     countryName: "",
  //     isdCode:"",
  //     gmtMinute:"",
  //     currency: ""
  //     })
  //     .then((response) => {
  //         setAddCountry(response?.data?.message);
  //       setTempData(response?.data?.message);
  //       setError("");
  //       console.log("check data", response?.data?.message);
  //     })
  //     .catch((error) => {
  //       setError("Failed to fetch the data");
  //       console.error("Error fetching country list:", error);
  //     });
  //   };

  const addNewCountry = (e) => {
    e.preventDefault();
    const token = localStorage.getItem("adminToken");

    Instance.post("/siteadmin/common/countryAdd", {
      adminToken: token,
      ...formData,
    })
      .then((response) => {
        if (response.data.resCode === "100") {
          showToast("Already a member", "error", "error");
          setError(response.data.resMessage);
          console.log("User added failed:", response.data);
        } else {
          console.log("User added successfully:", response.data);
          showToast("User added successfully", "success", "success");
          setError("");
          setTimeout(() => {
            navigate("/admin/country");
          }, 2000);
        }
      })
      .catch((error) => {
        showToast("Error", "error", "error");
        setError("Failed to add user");
        console.error("Error adding user:", error);
      });
  };
  return (
    <>
      <div className="user_manage_layout">
        <div className="page_heading ">
          <h4> Add New Country </h4>
          <Link to={"/admin/country"}>
            <span style={{ cursor: "pointer" }}>
              <div className="backbutton"></div>
            </span>
          </Link>
        </div>

        <div className="add_new_country">
          <Form onSubmit={addNewCountry}>
            <Row>
              <Row>
                <Col
                  xs="md-6 xl-6 lg-6"
                  className="custom_col_add_country mb-4 "
                >
                  <Form.Label>Country Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="countryName"
                    className=""
                    value={formData.countryName}
                    onChange={handleChange}
                    required
                  />
                </Col>
              </Row>

              <Row>
                <Col
                  xs="md-6 xl-6 lg-6"
                  className="custom_col_add_country mb-4"
                >
                  <Form.Label>Country Code</Form.Label>
                  <Form.Control
                    type="text"
                    name="countryCode"
                    value={formData.countryCode}
                    onChange={handleChange}
                    required
                  />
                </Col>
                <Col
                  xs="md-6 xl-6 lg-6"
                  className="custom_col_add_country mb-4"
                >
                  <Form.Label>ISD Code</Form.Label>
                  <Form.Control
                    type="text"
                    name="isdCode"
                    value={formData.isdCode}
                    onChange={handleChange}
                    required
                  />
                </Col>
                <Col
                  xs="md-6 xl-6 lg-6"
                  className="custom_col_add_country mb-4"
                >
                  <Form.Label>GMT Minite</Form.Label>
                  <Form.Control
                    type="text"
                    name="gmtMinute"
                    value={formData.gmtMinute}
                    onChange={handleChange}
                    required
                  />
                </Col>
                <Col
                  xs="md-6 xl-6 lg-6"
                  className="custom_col_add_country mb-4"
                >
                  <Form.Label>Currency</Form.Label>
                  <Form.Control
                    type="text"
                    name="currency"
                    value={formData.currency}
                    onChange={handleChange}
                    required
                  />
                </Col>
              </Row>
            </Row>

            <button
              className="add_country_submit_btn"
              // onClick={fetchCountryList}
              type="submit"
            >
              Submit
            </button>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddCountry;
