import React from "react";
import Navbar from "../../components/NavbarProfile/NavbarProfile";
import Sidebar from "../../components/SidebarNew/Sidebar";
import Footer from "../../components/Layouts/Footer";
import "./ProfileView.css";
import Profile from "../../components/NavbarProfile/Profile";

const ProfileView = () => {
  const activeEventKey = "20";
  const activeSide = 8;
  return (
    <>
      <div className="row gx-0 profile_view_content">
        <Sidebar activeSide={activeSide} activeEventKey={activeEventKey} />
        <div
          className={`main col d-flex flex-column`}
          style={{ overflow: "auto" }}
        >
          <Navbar />
          <div className="flex-grow-1">
            <Profile />
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default ProfileView;
