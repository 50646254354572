import React from "react";
import Navbar from "../../components/NavbarProfile/NavbarProfile";
import Sidebar from "../../components/SidebarNew/Sidebar";
import Footer from "../../components/Layouts/Footer";
import "./ActionPressRelease.css";
import PressReleaseAction from "../../components/EditorialManagment/PressReleaseAction";

const ActionPressRelease = () => {
  const activeSide = 7;
  const activeEventKey = "2";
  return (
    <>
      <div className="row gx-0 action_press_content">
        <Sidebar activeSide={activeSide} activeEventKey={activeEventKey} />
        <div
          className={`main col d-flex flex-column`}
          style={{ overflow: "auto" }}
        >
          <Navbar />
          <div className="flex-grow-1">
            <PressReleaseAction />
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default ActionPressRelease;
