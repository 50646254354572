import React from "react";
import "./Editorial.css";
import { Table, Pagination, Dropdown, DropdownButton } from "react-bootstrap";
import { useState, useEffect, useMemo } from "react";
import Instance from "../../Utils/Axios";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import EditorialRoom from "./EditorialRoom";

const PressReleaseGallery = () => {
  const [error, setError] = useState("");

  const [tempData, setTempData] = useState([]);
  const [accordionOpen, setAccordionOpen] = useState(false);
  const [galleryList, setGalleryList] = useState();
  const [filters, setFilters] = useState({
    status: "",
    name: "",
    documentUrl: "",
    galleryId: "",
    createdOn: "",
  });

  const columns = [
    {
      name: "Gallery Id",
      selector: (row) => row.galleryId,
      sortable: true,
      wrap: true,
    },
    {
      name: "Username",
      selector: (row) => row.name,
      sortable: true,
      wrap: true,
    },
    {
      name: "Image",
      cell: (row) => (
        <>
          <a href={`#`}>
            <img
              src={` ${row.documentUrl} `}
              alt="image"
              width={100}
              height={70}
              priority
              className="me-2 p-2"
            />
          </a>
        </>
      ),
      wrap: true,
    },
    {
      name: "Image Url",
      selector: (row) => row.documentUrl,
      sortable: true,
      wrap: true,
    },
    {
      name: "Date",
      selector: (row) => row.createdOn,
      sortable: true,
      wrap: true,
    },

    // {
    //   name: "Action",

    //   cell: (row) => (
    //     <>
    //       <a href={`#`} target="_blank">
    //         <img
    //           src="/build/delete.svg"
    //           alt="delete"
    //           width={24}
    //           height={24}
    //           priority
    //           className="me-2"
    //         />
    //       </a>
    //     </>
    //   ),
    // },
  ];

  // const data = [
  //   {
  //     No: "1",
  //     Name: "tushar",

  //     ImageUrl: "https://pr.zexprwire.com/images_data/40355182.png",
  //     Date: "2021-04-14",
  //   },
  //   {
  //     No: "2",
  //     Name: "tushar",

  //     ImageUrl: "https://pr.zexprwire.com/images_data/40355182.png",
  //     Date: "2021-04-14",
  //   },
  //   {
  //     No: "3",
  //     Name: "tushar",

  //     ImageUrl: "https://pr.zexprwire.com/images_data/40355182.png",
  //     Date: "2021-04-14",
  //   },
  //   {
  //     No: "4",
  //     Name: "tushar",

  //     ImageUrl: "https://pr.zexprwire.com/images_data/40355182.png",
  //     Date: "2021-04-14",
  //   },
  //   {
  //     No: "5",
  //     Name: "tushar",

  //     ImageUrl: "https://pr.zexprwire.com/images_data/40355182.png",
  //     Date: "2021-04-14",
  //   },
  // ];

  const fetchGalleryList = () => {
    const token = localStorage.getItem("adminToken");
    console.log("Fetched token from localStorage:", token);
    Instance.post("/siteadmin/gallery/getList", {
      adminToken: token,
      // userId: 1,
    })
      .then((response) => {
        setGalleryList(response?.data?.message);
        setTempData(response?.data?.message);
        setError("");
        console.log("check Gallery  data", response?.data?.message);
      })
      .catch((error) => {
        setError("Failed to fetch the data");
        console.error("Error fetching Gallery list:", error);
      });
  };
  useEffect(() => {
    fetchGalleryList();
  }, []);

  const filterData = (e) => {
    let filterText = e.target.value;
    if (!filterText) {
      setTempData([...galleryList]);
    } else {
      const filteredItems = galleryList.filter(
        (item) =>
          (item.name &&
            item.name.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.documentUrl &&
            item.documentUrl
              .toLowerCase()
              .includes(filterText.toLowerCase())) ||
          (item.galleryId &&
            item.galleryId.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.status &&
            item.status.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.createdOn &&
            item.createdOn.toLowerCase().includes(filterText.toLowerCase()))
      );
      setTempData([...filteredItems]);
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const applyFilters = () => {
    const token = localStorage.getItem("adminToken");
    Instance.post("/siteadmin/gallery/getList", {
      adminToken: token,
      status: filters.status,
      name: filters.name,
      documentUrl: filters.documentUrl,
      galleryId: filters.galleryId,
      createdOn: filters.createdOn,
    })
      .then((response) => {
        setTempData(response?.data?.message);
        setError("");
        console.log("Filtered data:", response?.data?.message);
      })
      .catch((error) => {
        setError("Failed to apply filters");
        console.error("Error applying filters:", error);
      });
  };

  const clearFilters = () => {
    const token = localStorage.getItem("adminToken");
    Instance.post("/siteadmin/gallery/getList", {
      adminToken: token,
      // status: filters.status,
      status: "",
      name: "",
      documentUrl: "",
      galleryId: "",
      createdOn: "",
    })
      .then((response) => {
        setFilters({
          status: "",
          name: "",
          documentUrl: "",
          galleryId: "",
          createdOn: "",
        });
        setGalleryList(response?.data?.message);
        setTempData(response?.data?.message);
        setError("");
        console.log("Cleared filters. Updated data:", response?.data?.message);
      })
      .catch((error) => {
        setError("Failed to clear filters");
        console.error("Error clearing filters:", error);
      });
  };

  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      setAccordionOpen(!accordionOpen)
    );

    return (
      <button
        type="button"
        className="admin_filter_btn"
        // style={{ backgroundColor: "pink" }}
        onClick={decoratedOnClick}
      >
        {children}
        {accordionOpen ? (
          <img
            src="/build/img/Icons/down_vector.svg"
            alt="zex pr wire Logo"
            width={16}
            height={16}
          />
        ) : (
          <img
            src="/build/img/Icons/down_vector.svg"
            alt="zex pr wire Logo"
            width={16}
            height={16}
            className="filter_up_arrow"
          />
        )}
      </button>
    );
  }

  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = ["galleryId", "name", "documentUrl", "createddOn"];

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  function downloadCSV(array) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = "gallery_press_release.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  return (
    <>
      <div className="admin_editorial_layout">
        <div className="page_heading">
          <h4>Manage Gallery </h4>
          <Link to="/admin/editorial">
            <span style={{ cursor: "pointer" }}>
              <div className="backbutton"></div>
            </span>
          </Link>
        </div>

        <Accordion className="mb-4">
          <Card>
            <Card.Header className="d-md-flex justify-content-between align-items-center">
              <div className="editorial_search">
                <label>
                  Search:
                  <input
                    type="search"
                    placeholder=""
                    aria-controls="datatable"
                    onChange={filterData}
                  ></input>
                </label>
              </div>
              <div className="d-md-flex">
                <button
                  onClick={(e) => downloadCSV(galleryList)}
                  className="btn btn-export mx-3"
                >
                  <img
                    src="/build/img/Icons/export.svg"
                    alt="zex pr wire Logo"
                    width={25}
                    height={25}
                  />
                </button>
                <CustomToggle as={Button} variant="link" eventKey="0">
                  Filters
                </CustomToggle>
              </div>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Form>
                  <div className="d-flex gap-4 flex-wrap">
                    <Form.Group
                      controlId="filterUsername"
                      className="editorial_filter_options"
                    >
                      <Form.Label>Username</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search Username"
                        name="name"
                        value={filters.name}
                        onChange={handleFilterChange}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filterImageURL"
                      className="editorial_filter_options"
                    >
                      <Form.Label>Image URL</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search Image URL"
                        name="documentUrl"
                        value={filters.documentUrl}
                        onChange={handleFilterChange}
                      ></Form.Control>
                    </Form.Group>

                    <Form.Group
                      controlId="filterGalleryID"
                      className="editorial_filter_options"
                    >
                      <Form.Label>Gallery Id</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Number"
                        name="galleryId"
                        value={filters.galleryId}
                        onChange={handleFilterChange}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filterGalleryID"
                      className="editorial_filter_options"
                    >
                      <Form.Label>Date</Form.Label>
                      <Form.Control
                        type="datetime-local"
                        id="datetime"
                        placeholder="Search Date"
                        name="createdOn"
                        value={filters.createdOn}
                        onChange={handleFilterChange}
                      ></Form.Control>
                    </Form.Group>
                  </div>
                  <div className="mt-3 d-flex gap-3">
                    <Button variant="secondary" onClick={clearFilters}>
                      Clear
                    </Button>
                    <Button variant="primary" onClick={applyFilters}>
                      Apply
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>

        <DataTable
          className="data-table-wrapper"
          columns={columns}
          data={tempData}
          pagination
          defaultSortFieldId={1}
          responsive={true}
          defaultSortAsc={true}
        //  actions={actionsMemo}
        />
      </div>
    </>
  );
};

export default PressReleaseGallery;
