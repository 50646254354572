import "./Staff.css";
import { Table, Pagination, Dropdown, DropdownButton } from "react-bootstrap";
import { useState, useEffect, useMemo } from "react";
import Instance from "../../Utils/Axios";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import { showToast } from "../../Utils/index";

const StaffManage = () => {
  const [staffList, setStaffList] = useState([]);
  const [error, setError] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [tempData, setTempData] = useState([]);
  const [filters, setFilters] = useState({
    status: "",
    type: "",
    userName: "",
    name: "",
    email: "",
  });
  const [accordionOpen, setAccordionOpen] = useState(false);

  const itemsPerPage = 10;

  const fetchStaffList = () => {
    const token = localStorage.getItem("adminToken");
    console.log("Fetched token from localStorage:", token);
    Instance.post("/siteadmin/staff/getList", {
      adminToken: token,
      status: "",
      email: "",
      userName: "",
      type: "",
      name: "",
    })
      .then((response) => {
        setStaffList(response?.data?.message);
        setTempData(response?.data?.message);
        setError("");
        console.log("check data", response?.data?.message);
      })
      .catch((error) => {
        setError("Failed to fetch the data");
        console.error("Error fetching staff list:", error);
      });
  };
  useEffect(() => {
    fetchStaffList();
  }, []);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const applyFilters = () => {
    const token = localStorage.getItem("adminToken");
    Instance.post("/siteadmin/staff/getList", {
      adminToken: token,
      status: filters.status,
      email: filters.email,
      userName: filters.userName,
      type: filters.type,
      name: filters.name,
    })
      .then((response) => {
        setTempData(response?.data?.message);
        setError("");
        console.log("Filtered data:", response?.data?.message);
      })
      .catch((error) => {
        setError("Failed to apply filters");
        console.error("Error applying filters:", error);
      });
  };

  const clearFilters = () => {
    const token = localStorage.getItem("adminToken");
    Instance.post("/siteadmin/staff/getList", {
      adminToken: token,
      status: "",
      email: "",
      userName: "",
      type: "",
      name: "",
    })
      .then((response) => {
        setFilters({ status: "", type: "", userName: "", name: "", email: "" });
        setStaffList(response?.data?.message);
        setTempData(response?.data?.message);
        setError("");
        console.log("Cleared filters. Updated data:", response?.data?.message);
      })
      .catch((error) => {
        setError("Failed to clear filters");
        console.error("Error clearing filters:", error);
      });
  };

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  const offset = currentPage * itemsPerPage;
  const currentItems = (staffList || []).slice(offset, offset + itemsPerPage);
  const pageCount = Math.max(
    Math.ceil((staffList || []).length / itemsPerPage),
    1
  );
  console.log(currentPage);

  const handleSubmit = async (status, id) => {
    // e.preventDefault();
    try {
      const token = localStorage.getItem("adminToken");
      const response = await Instance.post(`/siteadmin/staff/updateStaff`, {
        adminToken: token,
        staffId: id,
        status: status,
      });
      // console.log("Response from updateCoupon API:", response.data);
      if (response.data.resCode === "200") {
        fetchStaffList();
        showToast(" Status Updated Succesfully!", "success", "success");
        // setTimeout(() => {
        //   // navigate("/category_press_release");
        //   window.location.reload();
        // }, 1000);
      } else {
        setError(response.data.resMessage);
      }
    } catch (error) {
      setError("Failed to update staff details");
      console.error("Error updating staff details:", error);
    }
  };

  const handleChecked = (event, status, id) => {
    const confirmed = window.confirm(
      `Are you sure you want to ${status === "ACTIVE" ? "inactive" : "active"
      } status?`
    );

    if (status === "ACTIVE" && confirmed === true) {
      handleSubmit("INACTIVE", id);
    } else if (
      (status === "INACTIVE" || status === "DEACTIVE" || status === "") &&
      confirmed === true
    ) {
      handleSubmit("ACTIVE", id);
    }

    // else if (status === "DEACTIVE" && confirmed === true) {
    //   handleSubmit("ACTIVE", id);
    // }

    console.log("Switch value:", event.target.checked, status, id);
  };

  const columns = [
    {
      name: "User Name",
      selector: (row) => (
        <Link to={`/admin/staff/${row.id}`}> {row.userName}</Link>
      ),
      sortable: true,
      wrap: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      width: "180px",
      wrap: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      wrap: true,
    },
    {
      name: "Mobile No",
      selector: (row) => row.mobile,
      sortable: true,
      wrap: true,
    },
    {
      name: "	Last login",
      selector: (row) => row.lastLoginDate,
      sortable: true,
      wrap: true,
    },
    {
      name: "Staff Type",
      selector: (row) => row.type,
      sortable: true,
      wrap: true,
    },
    {
      name: "IP Adress",
      selector: (row) => row.ipAddress,
      sortable: true,
      wrap: true,
    },
    {
      name: "Active/Deactive",
      cell: (row) => (
        <Form>
          <Form.Check
            type="switch"
            id="custom-switch"
            checked={row?.status === "ACTIVE"}
            onChange={(e) => handleChecked(e, row.status, row.id)}
          />
        </Form>
      ),
      width: "160px",
    },
    {
      name: "Edit",
      //  selector: row => row.mobile,
      cell: (row) => (
        <>
          <a href={`/admin/staff/edit/${row.id}`} target="_blank">
            <img
              src="/build/edit.svg"
              alt="edit"
              width={24}
              height={24}
              priority
            />
          </a>
        </>
      ),
    },
  ];

  const filterData = (e) => {
    let filterText = e.target.value;
    if (!filterText) {
      setTempData([...staffList]);
    } else {
      const filteredItems = staffList.filter(
        (item) =>
          (item.userName &&
            item.userName.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.email &&
            item.email.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.id &&
            item.id.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.mobile &&
            item.mobile.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.status &&
            item.status.toLowerCase().includes(filterText.toLowerCase()))
      );
      setTempData([...filteredItems]);
    }
  };
  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = ["userName", "name", "email", "mobile", "lastLoginDate", "type", "ipAddress", "status"];

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  function downloadCSV(array) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = "Staff_List.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      setAccordionOpen(!accordionOpen)
    );
    return (
      <button
        type="button"
        className="admin_filter_btn"
        // style={{ backgroundColor: "pink" }}
        onClick={decoratedOnClick}
      >
        {children}
        {accordionOpen ? (
          <img
            src="/build/img/Icons/down_vector.svg"
            alt="zex pr wire Logo"
            width={16}
            height={16}
          />
        ) : (
          <img
            src="/build/img/Icons/down_vector.svg"
            alt="zex pr wire Logo"
            width={16}
            height={16}
            className="filter_up_arrow"
          />
        )}
      </button>
    );
  }

  // const Export = ({ onExport }) => ;
  // const actionsMemo = useMemo(() => <Export onExport={() => downloadCSV(staffList)} />, []);
  return (
    <>
      <div className="admin_staff_layout">
        <div className="page_heading">
          <h4> Manage Staff </h4>
          <Link to="/admin/staff/add">
            <button className="btn btn-new"> ADD NEW STAFF </button>
          </Link>
        </div>

        {/* <div className="staff_search" style={{ marginBottom: "20px" }}>
          <label>
            Search:
            <input
              type="search"
              placeholder=""
              aria-controls="datatable"
              onChange={filterData}
            ></input>
          </label>
          <button onClick={(e) => downloadCSV(staffList)}>Export</button>
        </div> */}

        <Accordion className="mb-4">
          <Card>
            <Card.Header className="d-md-flex justify-content-between align-items-center">
              <div className="staff_search">
                <label>
                  Search:
                  <input
                    type="search"
                    placeholder=""
                    aria-controls="datatable"
                    onChange={filterData}
                  ></input>
                </label>
              </div>
              <div className="d-md-flex">
                <button
                  onClick={(e) => downloadCSV(staffList)}
                  className="btn btn-export mx-3"
                >
                  <img
                    src="/build/img/Icons/export.svg"
                    alt="zex pr wire Logo"
                    width={25}
                    height={25}
                  />
                </button>
                <CustomToggle as={Button} variant="link" eventKey="0">
                  Filters
                </CustomToggle>
              </div>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Form>
                  <div className="d-flex gap-4 flex-wrap">
                    <Form.Group
                      controlId="filterStatus"
                      className="staff_filter_options"
                    >
                      <Form.Label>Status</Form.Label>
                      <Form.Control
                        as="select"
                        name="status"
                        value={filters.status}
                        onChange={handleFilterChange}
                      >
                        <option value="">All</option>
                        <option value="ACTIVE">ACTIVE</option>
                        <option value="INACTIVE">INACTIVE</option>
                      </Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filterType"
                      className="staff_filter_options"
                    >
                      <Form.Label>Type</Form.Label>
                      <Form.Control
                        as="select"
                        name="type"
                        value={filters.type}
                        onChange={handleFilterChange}
                      >
                        <option value="">All</option>
                        <option value="ADMIN">ADMIN</option>
                        <option value="STAFF">STAFF</option>
                      </Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filterUsername"
                      className="staff_filter_options"
                    >
                      <Form.Label>User Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search Username"
                        name="userName"
                        value={filters.userName}
                        onChange={handleFilterChange}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filterName"
                      className="staff_filter_options"
                    >
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search Name"
                        name="name"
                        value={filters.name}
                        onChange={handleFilterChange}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filterEmail"
                      className="staff_filter_options"
                    >
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search Email"
                        name="email"
                        value={filters.email}
                        onChange={handleFilterChange}
                      ></Form.Control>
                    </Form.Group>
                  </div>
                  <div className="mt-3 d-flex gap-3">
                    <Button variant="secondary" onClick={clearFilters}>
                      Clear
                    </Button>
                    <Button variant="primary" onClick={applyFilters}>
                      Apply
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>

        <DataTable
          columns={columns}
          data={tempData}
          pagination
          defaultSortFieldId={1}
          responsive={true}
          defaultSortAsc={true}
          className="staff_datatable data-table-wrapper"
        //  actions={actionsMemo}
        />
      </div>
    </>
  );
};

export default StaffManage;
