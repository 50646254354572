const EditorialRoom=[
    {
        ReleaseID:10460,
        Title:'Honorary',
        Date:'2024/07/04',
        Package:'Yahoo with Premium',
        User:'Managment Consultency',
        Status:'Pending',
    },
    {

         
        ReleaseID:10459,
        Title:'test pending mail is gone or n... ',
        Date:'2024/07/04',
        Package:'Yahoo Premium',
        User:'akshaytesttest',
        Status:'Pending',
    },
   
    {
        ReleaseID: 10458,
        Title:'ScottyTheAi',
        Date:'2024/07/04 10:07',
        Package:'Digital Journal',
        User:'DavidHempstein',
        Status:'Published',
    },
    
    {
        ReleaseID:10457,
        Title:'Citizens Commission on Human R',
        Date:'2024/07/04 10:00 ',
        Package:'Google News + International ',
        User:'JulianneBrinker',
        Status:'Published',
    },
    
    {
        ReleaseID:10456,
        Title:'Pazusoft Makes Pazu Spotify Co',
        Date:'2024/07/03 11:56',
        Package:'Digital Journal PR',
        User:'JeffLi',
        Status:'Published',
    },
    
    {
        ReleaseID: 10455 ,
        Title:'Pawfury Token Sale ',
        Date:'2024/07/04',
        Package:'Benzinga PR ',
        User:'JonasLiam ',
        Status:'Published',
    },
    
    {
        ReleaseID:10453,
        Title:'Dr. Ji Han',
        Date:'2024/07/04',
        Package:'2024/07/03 00:39 ',
        User:'JessicaSmith',
        Status:'Published',
    },
    
    {
        ReleaseID:10449,
        Title:'Lawrence Eta',
        Date:'2024/07/01 19:56',
        Package:'RS - Google, Int & Local Publications',
        User:'AvazonaMarketing',
        Status:'Published',
    },
]


export default EditorialRoom