import Navbar from "../../components/NavbarProfile/NavbarProfile";
import Sidebar from "../../components/SidebarNew/Sidebar";
import AddCoupon from "../../components/ManageCoupons/AddCoupons";
import Footer from "../../components/Layouts/Footer";
import "../ManageCoupons/Coupon.css";

const CouponCreate = () => {
  const activeSide = 28;
  const activeEventKey = "9";
  return (
    <>
      <div className="row gx-0 manage_coupon_layout">
        <Sidebar activeSide={activeSide} activeEventKey={activeEventKey} />
        <div
          className={`main col d-flex flex-column`}
          style={{ overflow: "auto" }}
        >
          <Navbar />
          <div className="flex-grow-1">
            <AddCoupon />
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default CouponCreate;
