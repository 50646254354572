import Navbar from "../../components/NavbarProfile/NavbarProfile";
import Sidebar from "../../components/SidebarNew/Sidebar";
import "./CountryAdd.css";
import AddCountry from "../../components/CountryManagment/AddCountry";
import Footer from "../../components/Layouts/Footer";

const CountryAdd = () => {
  const activeSide = 29;
  return (
    <>
      <div className="row gx-0 add_country_content">
        <Sidebar activeSide={activeSide} />
        <div
          className={`main col d-flex flex-column`}
          style={{ overflow: "auto" }}
        >
          <Navbar />
          <div className="flex-grow-1">
            <AddCountry />
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default CountryAdd;
