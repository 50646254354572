import Navbar from "../../components/NavbarProfile/NavbarProfile";
import Sidebar from "../../components/SidebarNew/Sidebar";
import NewsletterPage from "../../components/Newsletter/NewsletterPage";
import Footer from "../../components/Layouts/Footer";
import "./Newsletter.css";

const Newsletter = () => {
  const activeSide = 32;
  const activeEventKey = "12";
  return (
    <>
      <div className="row gx-0 admin_newsletter_content">
        <Sidebar activeSide={activeSide}  activeEventKey={activeEventKey} />
        <div
          className={`main col d-flex flex-column`}
          style={{ overflow: "auto" }}
        >
          <Navbar />
          <div className="flex-grow-1">
            <NewsletterPage />
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Newsletter;
