import Navbar from "../../components/NavbarProfile/NavbarProfile";
import Sidebar from "../../components/SidebarNew/Sidebar";
import AddPublication from "../../components/PublicationManagement/AddPublication";
import Footer from "../../components/Layouts/Footer";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const AddPublicationn = () => {
  const [showAddPublication, setShowAddPublication] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (showAddPublication) {
      navigate("/publication");
    }
  }, [showAddPublication, navigate]);

  const handlePublicationList = () => {
    setShowAddPublication(true);
  };

  const activeSide = 14;
  const activeEventKey = "3";

  return (
    <>
      <div className="row gx-0 admin_publication_layout">
        <Sidebar activeSide={activeSide} activeEventKey={activeEventKey} />
        <div
          className={`main col d-flex flex-column`}
          style={{ overflow: "auto" }}
        >
          <Navbar />
          <div className="flex-grow-1">
            <AddPublication publicationList={handlePublicationList} />
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default AddPublicationn;
