import { Table, Pagination, Dropdown } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import "../PRmanagerLayout/PRmanager.css";
import "./SubAccount.css";
import { useState } from "react";

export default function SubLayout() {
  const [active, setActive] = useState(0);

  const handleNewAccount = () => {
    setActive(1);
  };
  const handleAccount = () => {
    setActive(0);
  };
  return (
    <>
      <div className={`pr_secondary_nav`}>
        {active === 0 && (
          <button className="sub_edit_btn" onClick={handleNewAccount}>
            <img
              className={"sub_add_img mb-0"}
              src="/build/img/Icons/Vector.png"
              alt="zex pr wire Logo"
              width={13}
              height={13}
              priority
            />
            Add New
          </button>
        )}
        {active === 1 && (
          <button className="sub_back_btn" onClick={handleAccount}>
            <img
              className={"sub_back_img mb-0"}
              src="/build/img/Icons/Chevron.svg"
              alt="zex pr wire Logo"
              width={20}
              height={20}
              priority
            />
            Back
          </button>
        )}
        <img
          className={"pr_group_img"}
          src="/build/img/Group.png"
          alt="zex pr wire Logo"
          width={620}
          height={60}
          priority
        />
      </div>
      <div className="sub_layout">
        {active === 0 && (
          <div className="my_pr_tables">
            <Table hover className="mb-0">
              <thead className="pr_tables_head">
                <tr>
                  <th>COMPANY NAME</th>
                  <th>Contact PERSON</th>
                  <th>PHONE NUMBER</th>
                  <th>EMAIL</th>
                  <th>CREATED BY</th>
                  <th>ACTIONS</th>
                  <th></th>
                </tr>
              </thead>
              <tbody className="pr_tables_body">
                <tr>
                  <td>Acme Corporation</td>
                  <td>Darlene Robertson</td>
                  <td>(907) 555-0101</td>
                  <td>darlenerobertson@email</td>
                  <td>Jane Cooper</td>
                  <td>
                    {" "}
                    <img
                      className={"pr_add_img mb-0"}
                      src="/build/img/Icons/Edit.svg"
                      alt="zex pr wire Logo"
                      width={24}
                      height={24}
                      priority
                    />
                    <img
                      className={"pr_add_img mb-0"}
                      src="/build/img/Icons/Delete.svg"
                      alt="zex pr wire Logo"
                      width={24}
                      height={24}
                      priority
                    />
                  </td>
                  <td>
                    <div className="sub_disabled">Disabled </div>
                  </td>
                </tr>
                <tr>
                  <td>Wayne Enterprises</td>
                  <td>Cody Fisher</td>
                  <td>(316) 555-0116</td>
                  <td>codyfisher@email</td>
                  <td>Jane Cooper</td>
                  <td className="pr_note">
                    <img
                      className={"pr_add_img mb-0"}
                      src="/build/img/Icons/Edit.svg"
                      alt="zex pr wire Logo"
                      width={24}
                      height={24}
                      priority
                    />
                    <img
                      className={"pr_add_img mb-0"}
                      src="/build/img/Icons/Delete.svg"
                      alt="zex pr wire Logo"
                      width={24}
                      height={24}
                      priority
                    />
                  </td>
                  <td>
                    <div className="sub_enabled"> Enabled </div>
                  </td>
                </tr>
                <tr>
                  <td>Sterling Cooper</td>
                  <td>Guy Hawkins</td>
                  <td>(219) 555-0114</td>
                  <td>guyhawkins@email</td>
                  <td>Jane Cooper</td>
                  <td className="pr_note">
                    <img
                      className={"pr_add_img mb-0"}
                      src="/build/img/Icons/Edit.svg"
                      alt="zex pr wire Logo"
                      width={24}
                      height={24}
                      priority
                    />
                    <img
                      className={"pr_add_img mb-0"}
                      src="/build/img/Icons/Delete.svg"
                      alt="zex pr wire Logo"
                      width={24}
                      height={24}
                      priority
                    />
                  </td>
                  <td>
                    <div className="sub_disabled">Disabled </div>
                  </td>
                </tr>
              </tbody>
              <caption>
                <div className="pr_rows_table">
                  <div className="pr_dropdown">
                    <h4 className="mb-0">Rows per page</h4>
                    <Dropdown className="pr_rows_dropdown">
                      <Dropdown.Toggle variant="light" id="dropdown-basic">
                        10
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">1</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">2</Dropdown.Item>
                        <Dropdown.Item href="#/action-3">3</Dropdown.Item>
                        <Dropdown.Item href="#/action-3">4</Dropdown.Item>
                        <Dropdown.Item href="#/action-3">5</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="pr_pagination">
                    <Pagination className="mb-0">
                      <Pagination.Item>{1}</Pagination.Item>
                      <Pagination.Item>{2}</Pagination.Item>
                      <Pagination.Item>{3}</Pagination.Item>
                      <Pagination.Ellipsis />
                      <Pagination.Item>{10}</Pagination.Item>
                      <Pagination.Item>{11}</Pagination.Item>
                      <Pagination.Item>{12}</Pagination.Item>
                    </Pagination>
                  </div>
                  <div className="pr_next_page">
                    <h4 className="mb-0">Go to page</h4>
                    <img
                      className={"pr_next_img mb-0"}
                      src="/build/img/Select.svg"
                      alt="zex pr wire Logo"
                      width={76}
                      height={32}
                      priority
                    />
                  </div>
                </div>
              </caption>
            </Table>
          </div>
        )}

        {active === 1 && (
          <>
            <div className="pr_form_preview">
              <h4 className="sub_form_head mb-4">Add a new company profile</h4>
              <Row>
                <Col className="custom_col_sub_account mb-4">
                  <Form.Label>Company Name</Form.Label>
                  <Form.Control placeholder=" Enter text here" />
                </Col>
                <Col className="custom_col_sub_account mb-4">
                  <Form.Label>Contact Person Name</Form.Label>
                  <Form.Control placeholder="Enter text here" />
                </Col>
              </Row>
              <Row>
                <Col className="custom_col_sub_account mb-4">
                  <Form.Label>Email</Form.Label>
                  <Form.Control placeholder="Enter text here" />
                </Col>
                <Col className="custom_col_sub_account mb-4">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control placeholder="Enter text here" />
                </Col>
              </Row>
              <Row>
                <Col className="custom_col_sub_account mb-4">
                  <Form.Label>Company Website</Form.Label>
                  <Form.Control placeholder="Enter text here" />
                </Col>
                <Col className="custom_col_sub_account mb-4">
                  <Form.Label>Company Address</Form.Label>
                  <Form.Control placeholder="Enter text here" />
                </Col>
              </Row>
              <Row>
                <Col xs="3" className="custom_col_sub_account mb-4">
                  <Form.Label>City</Form.Label>
                  <Form.Control placeholder="Enter text here" />
                </Col>
                <Col xs="3" className="custom_col_sub_account mb-4">
                  <Form.Label>State</Form.Label>
                  <Form.Control placeholder="Enter text here" />
                </Col>
                <Col xs="6" className="custom_col_sub_account mb-4">
                  <Form.Label>Country</Form.Label>
                  <Form.Select>
                    <option>Select from dropdown</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                </Col>
              </Row>
              <p className="pr_form_p">
                Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                amet sint. Velit officia consequat duis enim velit mollit.
                Exercitation veniam consequat sunt nostrud amet.
              </p>
            </div>
            <div className="sub_account_buttons">
              <button className="sub_cancel_btn mb-4" onClick={handleAccount}>
                Cancel
              </button>
              <button className="sub_save_btn mb-4" onClick={handleAccount}>
                Save Changes
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
}
