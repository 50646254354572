import "./ManageCoupons.css";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import Button from "react-bootstrap/Button";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Instance from "../../Utils/Axios";
import { showToast } from "../../Utils/index";

const CouponsPage = () => {
  const [accordionOpen, setAccordionOpen] = useState(false);
  const [couponList, setCouponList] = useState([]);
  const [tempData, setTempData] = useState([]);
  const [error, setError] = useState("");

  const [filters, setFilters] = useState({
    status: "",
    couponId: "",
    code: "",
    discount: "",
    name: "",
  });

  const handleSubmit = async (status, id) => {
    // e.preventDefault();
    try {
      const token = localStorage.getItem("adminToken");
      const response = await Instance.post(`/siteadmin/coupons/update`, {
        adminToken: token,
        couponId: id,
        status: status,
      });
      // console.log("Response from updateCoupon API:", response.data);
      if (response.data.resCode === "200") {
        fetchCouponList();
        showToast(" Status Updated Succesfully!", "success", "success");
        // setTimeout(() => {
        //   // navigate("/category_press_release");
        //   window.location.reload();
        // }, 1000);
      } else {
        setError(response.data.resMessage);
      }
    } catch (error) {
      setError("Failed to update coupon details");
      console.error("Error updating coupon details:", error);
    }
  };

  const handleChecked = (event, status, id) => {
    const confirmed = window.confirm(
      `Are you sure you want to ${
        status === "ACTIVE" ? "inactive" : "active"
      } status?`
    );

    if (status === "ACTIVE" && confirmed === true) {
      handleSubmit("INACTIVE", id);
    } else if (
      (status === "INACTIVE" || status === "DEACTIVE" || status === "") &&
      confirmed === true
    ) {
      handleSubmit("ACTIVE", id);
    }

    // else if (status === "DEACTIVE" && confirmed === true) {
    //   handleSubmit("ACTIVE", id);
    // }

    console.log("Switch value:", event.target.checked, status, id);
  };

  const columns = [
    // {
    //   name: "SI.No",
    //   selector: (row, index) => parseInt(row.vendorId || index + 1),
    //   sortable: true,
    //   width: "100px",
    // },
    {
      name: "Coupon Id",
      selector: (row) => row.couponId,
      sortable: true,
      width: "200px",
      wrap: true,
    },
    {
      name: "Coupon Name",
      selector: (row) => (
        <a href={`/admin/coupons/view_history/${row.couponId}`} target="_blank">
          {" "}
          {row.name}
        </a>
      ),

      sortable: true,
      width: "200px",
      wrap: true,
    },
    {
      name: "Coupon Code",
      selector: (row) => row.code,
      sortable: true,
      wrap: true,
    },
    {
      name: "Discount",
      selector: (row) => row.discount,
      sortable: true,
      width: "120px",
      wrap: true,
    },
    {
      name: "Applicable to packages",
      selector: (row) =>
        row?.packageDetails?.map((val) => {
          return (
            <>
              <div>{val.name}</div>
            </>
          );
        }),
      sortable: true,
      wrap: true,
    },

    {
      name: "Edit Coupon",
      cell: (row) => (
        <a href={`/admin/coupons/edit/${row.couponId}`} target="_blank">
          <img
            src="/build/edit.svg"
            alt="edit"
            width={24}
            height={24}
            priority
          />
        </a>
      ),
    },
    {
      name: "Active/Deactive",
      cell: (row) => (
        <Form>
          <Form.Check
            type="switch"
            id="custom-switch"
            checked={row?.status === "ACTIVE"}
            onChange={(e) => handleChecked(e, row.status, row.couponId)}
          />
        </Form>
      ),
    },
  ];

  const fetchCouponList = () => {
    const token = localStorage.getItem("adminToken");
    console.log("Fetched token from localStorage:", token);
    Instance.post("/siteadmin/coupons/getList", {
      adminToken: token,
    })
      .then((response) => {
        setCouponList(response?.data?.message);
        setTempData(response?.data?.message);
        setError("");
        console.log("check data", response?.data?.message);
      })
      .catch((error) => {
        setError("Failed to fetch the data");
        console.error("Error fetching coupon list:", error);
      });
  };
  useEffect(() => {
    fetchCouponList();
  }, []);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const applyFilters = () => {
    const token = localStorage.getItem("adminToken");
    Instance.post("/siteadmin/coupons/getList", {
      adminToken: token,
      status: filters.status,
      couponId: filters.couponId,
      code: filters.code,
      discount: filters.discount,
      name: filters.name,
      status: filters.status,
    })
      .then((response) => {
        setTempData(response?.data?.message);
        setError("");
        console.log("Filtered data:", response?.data?.message);
      })
      .catch((error) => {
        setError("Failed to apply filters");
        console.error("Error applying filters:", error);
      });
  };

  const clearFilters = () => {
    const token = localStorage.getItem("adminToken");
    Instance.post("/siteadmin/coupons/getList", {
      adminToken: token,
      // status: filters.status,
    })
      .then((response) => {
        setFilters({
          couponId: "",
          code: "",
          discount: "",
          name: "",
          status: "",
        });
        setCouponList(response?.data?.message);
        setTempData(response?.data?.message);
        setError("");
        console.log("Cleared filters. Updated data:", response?.data?.message);
      })
      .catch((error) => {
        setError("Failed to clear filters");
        console.error("Error clearing filters:", error);
      });
  };

  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(couponList[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  function downloadCSV(array) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = "export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      setAccordionOpen(!accordionOpen)
    );
    return (
      <button
        type="button"
        className="admin_filter_btn"
        // style={{ backgroundColor: "pink" }}
        onClick={decoratedOnClick}
      >
        {children}
        {accordionOpen ? (
          <img
            src="/build/img/Icons/down_vector.svg"
            alt="zex pr wire Logo"
            width={16}
            height={16}
          />
        ) : (
          <img
            src="/build/img/Icons/down_vector.svg"
            alt="zex pr wire Logo"
            width={16}
            height={16}
            className="filter_up_arrow"
          />
        )}
      </button>
    );
  }

  const filterData = (e) => {
    let filterText = e.target.value;
    if (!filterText) {
      setTempData([...couponList]);
    } else {
      const filteredItems = couponList.filter(
        (item) =>
          (item.couponId &&
            item.couponId.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.code &&
            item.code.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.discount &&
            item.discount.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.name &&
            item.name.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.status &&
            item.status.toLowerCase().includes(filterText.toLowerCase()))
      );
      setTempData([...filteredItems]);
    }
  };

  return (
    <>
      <div className="coupon_pages_layout">
        <div className="coupon_page_heading">
          <h4> Manage Coupons </h4>
          <div className="admin_coupon_btns">
            <div className="search_coupon">
              <input
                placeholder="Search"
                type="search"
                aria-controls="datatable"
                onChange={filterData}
              />
              <img
                className={""}
                src="/build/img/search.svg"
                alt="zex pr wire Logo"
                width={24}
                height={24}
                priority
              />
            </div>
            <Link to="/admin/coupons/add">
              <button className="btn new_coupon_btn"> ADD NEW COUPONS </button>
            </Link>
          </div>
        </div>
        <Accordion className="mb-4">
          <Card>
            <Card.Header>
              <CustomToggle as={Button} variant="link" eventKey="0">
                Filter
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Form>
                  <div className="d-flex flex-wrap gap-3">
                    <Form.Group
                      controlId="filterStatus"
                      className="template_filter_options"
                    >
                      <Form.Label>Status</Form.Label>
                      <Form.Control
                        as="select"
                        name="status"
                        value={filters.status}
                        onChange={handleFilterChange}
                        className=""
                      >
                        <option value="">All</option>
                        <option value="ACTIVE">Active</option>
                        <option value="INACTIVE">Inactive</option>
                      </Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filterName"
                      className="staff_filter_options"
                    >
                      <Form.Label>Coupon Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search Coupon Name"
                        name="name"
                        value={filters.name}
                        onChange={handleFilterChange}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filterurl"
                      className="staff_filter_options"
                    >
                      <Form.Label>Coupon Code</Form.Label>
                      <Form.Control
                        type="text"
                        name="code"
                        value={filters.code}
                        onChange={handleFilterChange}
                        placeholder="Search Coupon Code"
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filterurl"
                      className="staff_filter_options"
                    >
                      <Form.Label>Discount</Form.Label>
                      <Form.Control
                        type="text"
                        name="discount"
                        value={filters.discount}
                        onChange={handleFilterChange}
                        placeholder="Search Coupon Code"
                      ></Form.Control>
                    </Form.Group>
                  </div>
                  <div className="mt-3 d-flex gap-3">
                    <Button
                      variant="secondary"
                      className="clear"
                      onClick={clearFilters}
                    >
                      Clear
                    </Button>
                    <Button
                      variant="primary"
                      className="apply"
                      onClick={applyFilters}
                    >
                      Apply
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <DataTable
          className="data-table-wrapper"
          columns={columns}
          //   data={row}
          data={tempData}
          pagination
          responsive={true}
          defaultSortFieldId={1}
          defaultSortAsc={true}
        />
      </div>
    </>
  );
};

export default CouponsPage;
