import "./NotificationLayout.css";
import { useState } from "react";

export default function NotificationLayout() {
  const [activeTab, setActiveTab] = useState(1);
  return (
    <>
      <div className="notification_layout">
        <div className="notification_tabs">
          <h4
            className={`all_tab ${activeTab === 1 ? "tab_active" : ""}`}
            onClick={() => setActiveTab(1)}
          >
            All
          </h4>
          <h4
            className={`read_tab ${activeTab === 2 ? "tab_active" : ""}`}
            onClick={() => setActiveTab(2)}
          >
            Read
          </h4>
          <h4
            className={`unread_tab ${activeTab === 3 ? "tab_active" : ""}`}
            onClick={() => setActiveTab(3)}
          >
            Unread
          </h4>
        </div>
        <div>
          <div class="notifications_list">
            <div class="notification">
              <div class="status_rejected"></div>
              <div class="content">
                <h3>PR ID: 7284 - PR Rejected</h3>
                <p>Content not according to the publications guidelines</p>
                <span>1 Hours Ago</span>
              </div>
            </div>
            <div class="notification">
              <div class="status_published"></div>
              <div class="content">
                <h3>PR ID: 7280 - Published</h3>
                <p>
                  ZEX PR WIRE Join Forces with Blockchain Life to be the
                  Official PR Partner for the 10th Edition of Blockchain Life
                  2023 in Dubai
                </p>
                <span>1 Hours Ago</span>
              </div>
            </div>
            <div class="notification">
              <div class="status_rejected"></div>
              <div class="content">
                <h3>PR ID: 7271 - PR Rejected</h3>
                <p>Content not according to the publications guidelines</p>
                <span>1 Hours Ago</span>
              </div>
            </div>
            <div class="notification">
              <div class="status_updated"></div>
              <div class="content">
                <h3>PR ID: 7255 - Report Updated</h3>
                <p>Make some small changes to submit again</p>
                <span>1 Hours Ago</span>
              </div>
            </div>
            <div class="notification">
              <div class="status_action"></div>
              <div class="content">
                <h3>PR ID: 7242 - Action Required</h3>
                <p>Make some small changes to submit again</p>
                <span>1 Hours Ago</span>
              </div>
            </div>
            <div class="notification">
              <div class="status_rejected"></div>
              <div class="content">
                <h3>PR ID: 7284 - PR Rejected</h3>
                <p>Content not according to the publications guidelines</p>
                <span>1 Hours Ago</span>
              </div>
            </div>
            <div class="notification">
              <div class="status_pending"></div>
              <div class="content">
                <h3>PR ID: 7210 - Pending</h3>
                <p>Make some small changes to submit again</p>
                <span>1 Hours Ago</span>
              </div>
            </div>
            <div class="notification">
              <div class="status_published"></div>
              <div class="content">
                <h3>PR ID: 7206 - Published</h3>
                <p>Make some small changes to submit again</p>
                <span>1 Hours Ago</span>
              </div>
            </div>
            <div class="notification">
              <div class="status_deleted"></div>
              <div class="content">
                <h3>PR ID: 7160 - Deleted</h3>
                <p>Make some small changes to submit again</p>
                <span>1 Hours Ago</span>
              </div>
            </div>
            <div class="notification">
              <div class="status_published"></div>
              <div class="content">
                <h3>PR ID: 7258 - Published</h3>
                <p>Make some small changes to submit again</p>
                <span>1 Hours Ago</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
