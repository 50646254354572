import "./SupportLayout.css";
import { useState } from "react";
import {
  Table,
  Button,
  Pagination,
  Dropdown,
  Modal,
  Accordion,
  Offcanvas,
  Form,
} from "react-bootstrap";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";

export default function SupportLayout({ onShowNewQuery }) {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedPackages, setSelectedPackages] = useState([]);
  const [selectedRead, setSelectedRead] = useState([]);
  const [filtersApplied, setFiltersApplied] = useState(0);
  const [packagesApplied, setPackagesApplied] = useState(0);
  const [readApplied, setReadApplied] = useState(0);
  const [show, setShow] = useState(false);
  const [showResolve, setshowResolve] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handlePackageChange = (newPackage, isChecked) => {
    setSelectedPackages((prevSelectedPackages) => {
      if (isChecked) {
        return [...prevSelectedPackages, newPackage];
      } else {
        return prevSelectedPackages.filter((pkg) => pkg !== newPackage);
      }
    });
  };

  const handleReadChange = (option, isChecked) => {
    setSelectedRead((prevSelectedRead) => {
      if (isChecked) {
        return [...prevSelectedRead, option];
      } else {
        return prevSelectedRead.filter((item) => item !== option);
      }
    });
  };  

  const calculateFiltersApplied = () => {
    let count = 0;
    if (selectedDate) count++;
    count += selectedPackages.length;
    count += selectedRead.length;
    setFiltersApplied(count);
  };
  const calculatePackagesApplied = () => {
    setPackagesApplied(selectedPackages.length);
  };
  const calculateReadApplied = () => {
    setReadApplied(selectedRead.length);
  };
  const handleApplyFilters = () => {
    calculateFiltersApplied();
    calculatePackagesApplied();
    calculateReadApplied();
    handleClose();
  };
  const handleClearFilters = () => {
    setFiltersApplied(0);
    setSelectedDate(null);
    setSelectedPackages([]);
    setPackagesApplied(0)
    setReadApplied(0)
  };

  const hadnleShowResolve = () => {
    setshowResolve(true);
  };
  const hadnleNotShowResolve = () => {
    setshowResolve(false);
  };

  return (
    <>
      <div className="support_layout">
        <div className="ticket_list">
          <div className="ticket_list_btns">
            <button className="add_new_query_btn" onClick={onShowNewQuery}>
              <img
                className="me-2"
                src="./build/img/Icons/Vector.png"
                alt="zex pr"
                height={15}
                width={15}
              />
              Add New Query
            </button>
            <button className="Support_filter_btn" onClick={handleShow}>
              Filter
              <img
                className="filter_img"
                src="/build/img/Icons/down_vector.svg"
                alt="zex pr wire Logo"
                width={16}
                height={16}
                priority
              />
            </button>
            <Offcanvas show={show} onHide={handleClose}>
              <Offcanvas.Header closeButton className="filter_offcanvas_header">
                <Offcanvas.Title className="filter_offcanvas_title">
                  {filtersApplied} filters applied
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body className="filter_offcanavas_body">
                <Accordion alwaysOpen flush>
                  <Accordion.Item eventKey="0" className="accordian_item">
                    <Accordion.Header className="filter_accordian">
                      Date Range
                    </Accordion.Header>
                    <Accordion.Body style={{ backgroundColor: "#FAFBFB" }}>
                      <div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateCalendar
                            value={selectedDate}
                            onChange={handleDateChange}
                          />
                        </LocalizationProvider>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1" className="accordian_item">
                    <Accordion.Header className="filter_accordian ">
                      <span>Status</span>
                      <span className="filter_accordian_text">{packagesApplied} selected </span>
                    </Accordion.Header>
                    <Accordion.Body
                      style={{ backgroundColor: "#FAFBFB" }}
                      className="filter_package_body pb-3"
                    >
                      <Form>
                        {["checkbox"].map((type) => (
                          <div key={`inline-${type}`} className="">
                            <Form.Check
                              label="All "
                              name="group1"
                              type={type}
                              id={`default-${type}`}
                              checked={selectedPackages.includes("All_status")}
                              onChange={(e) =>
                                handlePackageChange(
                                  "All_status",
                                  e.target.checked
                                )
                              }
                            />
                            <Form.Check
                              label="Open "
                              name="group1"
                              type={type}
                              id={`default-${type}-2`}
                              checked={selectedPackages.includes("Open")}
                              onChange={(e) =>
                                handlePackageChange("Open", e.target.checked)
                              }
                            />
                            <Form.Check
                              label="Closed "
                              name="group1"
                              type={type}
                              id={`default-${type}-3`}
                              checked={selectedPackages.includes("Closed")}
                              onChange={(e) =>
                                handlePackageChange("Closed", e.target.checked)
                              }
                            />
                          </div>
                        ))}
                      </Form>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2" className="accordian_item">
                    <Accordion.Header className="filter_accordian">
                      <span>Read/Unread</span>
                      <span className="filter_accordian_text_2">{readApplied} selected </span>
                    </Accordion.Header>
                    <Accordion.Body
                      style={{ backgroundColor: "#FAFBFB" }}
                      className="filter_package_body pb-3"
                    >
                      <Form>
                        {["checkbox"].map((type) => (
                          <div key={`inline-${type}`} className="">
                            <Form.Check
                              label="All "
                              name="group1"
                              type={type}
                              id={`default-${type}-4`}
                              checked={selectedRead.includes("All")}
                              onChange={(e) =>
                                handleReadChange("All", e.target.checked)
                              }
                            />
                            <Form.Check
                              label="Read "
                              name="group1"
                              type={type}
                              id={`default-${type}-5`}
                              checked={selectedRead.includes("Read")}
                              onChange={(e) =>
                                handleReadChange("Read", e.target.checked)
                              }
                            />
                            <Form.Check
                              label="Unread "
                              name="group1"
                              type={type}
                              id={`default-${type}-6`}
                              checked={selectedRead.includes("Unread")}
                              onChange={(e) =>
                                handleReadChange("Unread", e.target.checked)
                              }
                            />
                            <Form.Check
                              label="Read but not replied "
                              name="group1"
                              type={type}
                              id={`default-${type}-4`}
                              checked={selectedPackages.includes(
                                "Read but not replied"
                              )}
                              onChange={(e) =>
                                handleReadChange(
                                  "Read but not replied",
                                  e.target.checked
                                )
                              }
                            />
                          </div>
                        ))}
                      </Form>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3" className="accordian_item">
                    <Accordion.Header className="filter_accordian">
                      Not Replied
                    </Accordion.Header>
                    <Accordion.Body style={{ backgroundColor: "#FAFBFB" }}>
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                      In quia earum consequuntur est aspernatur. Tempora aperiam
                      magnam molestiae libero impedit magni labore nulla,
                      necessitatibus eum. Maiores consectetur ex commodi
                      quaerat!
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <div className="mt-4 mb-4 filter_btns">
                  <button
                    className="filter_clear_btn"
                    onClick={handleClearFilters}
                  >
                    Clear
                  </button>
                  <button
                    className="filter_apply_btn"
                    onClick={handleApplyFilters}
                  >
                    Apply
                  </button>
                </div>
              </Offcanvas.Body>
            </Offcanvas>
          </div>
          <div className="ticket_list_table">
            <Table hover className="mb-0">
              <thead className="support_tables_head">
                <tr className="support_tr">
                  <th>
                    SR NO
                    <img
                      className={"mb-0"}
                      src="/build/img/down_arrow.svg"
                      alt="zex pr wire Logo"
                      width={26}
                      height={18}
                      priority
                    />
                  </th>
                  <th>
                    TICKET NO
                    <img
                      className={"mb-0"}
                      src="/build/img/down_arrow.svg"
                      alt="zex pr wire Logo"
                      width={26}
                      height={18}
                      priority
                    />
                  </th>
                  <th>
                    QUERY SUB
                    <img
                      className={"mb-0"}
                      src="/build/img/down_arrow.svg"
                      alt="zex pr wire Logo"
                      width={26}
                      height={18}
                      priority
                    />
                  </th>
                  <th>TICKET RAISED ON</th>
                  <th>
                    STATUS
                    <img
                      className={"mb-0"}
                      src="/build/img/down_arrow.svg"
                      alt="zex pr wire Logo"
                      width={26}
                      height={18}
                      priority
                    />
                  </th>
                </tr>
              </thead>
              <tbody className="support_table_body">
                <tr>
                  <td>1</td>
                  <td>6065</td>
                  <td>Lorem Ipsum</td>
                  <td>Dec 18, 2020</td>
                  <td>
                    <span className="status_open">Open</span>
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>9462</td>
                  <td>Lorem Ipsum</td>
                  <td>Dec 18, 2020</td>
                  <td>
                    <span className="status_open">Open</span>
                  </td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>4846</td>
                  <td>Lorem Ipsum</td>
                  <td>Dec 18, 2020</td>
                  <td>
                    <span className="status_close">Closed</span>
                  </td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>6025</td>
                  <td>Lorem Ipsum</td>
                  <td>Dec 18, 2020</td>
                  <td>
                    <span className="status_close">Closed</span>
                  </td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>1784</td>
                  <td>Lorem Ipsum</td>
                  <td>Dec 18, 2020</td>
                  <td>
                    <span className="status_open">Open</span>
                  </td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>5045</td>
                  <td>Lorem Ipsum</td>
                  <td>Dec 18, 2020</td>
                  <td>
                    <span className="status_close">Closed</span>
                  </td>
                </tr>
                <tr>
                  <td>7</td>
                  <td>5560</td>
                  <td>Lorem Ipsum</td>
                  <td>Dec 18, 2020</td>
                  <td>
                    <span className="status_open">Open</span>
                  </td>
                </tr>
                <tr>
                  <td>8</td>
                  <td>4600</td>
                  <td>Lorem Ipsum</td>
                  <td>Dec 18, 2020</td>
                  <td>
                    <span className="status_close">Closed</span>
                  </td>
                </tr>
                <tr>
                  <td>9</td>
                  <td>3536</td>
                  <td>Lorem Ipsum</td>
                  <td>Dec 18, 2020</td>
                  <td>
                    <span className="status_open">Open</span>
                  </td>
                </tr>
                <tr>
                  <td>10</td>
                  <td>1577</td>
                  <td>Lorem Ipsum</td>
                  <td>Dec 18, 2020</td>
                  <td>
                    <span className="status_close">Closed</span>
                  </td>
                </tr>
              </tbody>
              <caption>
                <div className="support_table_footer">
                  <div className="support_page_no">
                    <h4>Rows per page</h4>
                    <Dropdown className="support_dropdown">
                      <Dropdown.Toggle variant="light" id="dropdown-basic">
                        10
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">1</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">2</Dropdown.Item>
                        <Dropdown.Item href="#/action-3">3</Dropdown.Item>
                        <Dropdown.Item href="#/action-3">4</Dropdown.Item>
                        <Dropdown.Item href="#/action-3">5</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="support_pagination">
                    <Pagination className="mb-0">
                      <Pagination.Item>{1}</Pagination.Item>
                      <Pagination.Item>{2}</Pagination.Item>
                      <Pagination.Item>{3}</Pagination.Item>
                      <Pagination.Ellipsis />
                      <Pagination.Item>{10}</Pagination.Item>
                      <Pagination.Item>{11}</Pagination.Item>
                      <Pagination.Item>{12}</Pagination.Item>
                    </Pagination>
                  </div>
                  <div className="support_pages">
                    <h4>Go to page</h4>
                    <img
                      className={"mb-0 payment_select_img"}
                      src="/build/img/Select.svg"
                      alt="zex pr wire Logo"
                      width={50}
                      height={32}
                      priority
                    />
                  </div>
                </div>
              </caption>
            </Table>
          </div>
        </div>
        <div className="ticket_chatbox">
          <div className="chatbox_title mb-2">
            <h4>Ticket No: 6065</h4>
            <p onClick={hadnleShowResolve}>Resolve</p>

            <Modal
              show={showResolve}
              onHide={hadnleNotShowResolve}
              centered
              className="custom_modal"
            >
              <Modal.Body>
                <div className="submit_modal_img">
                  <img
                    className={"submit_done_img"}
                    src="/build/Done.svg"
                    alt="zex pr wire Logo"
                    width={140}
                    height={140}
                    priority
                  />
                </div>
                <div>
                  <h5 className="submit_modal_head">Resolve Ticket</h5>
                  <p className="submit_modal_content">
                    Are your sure you want to resolve this ticket?
                  </p>
                </div>
                <div className="pr_modal_btn">
                  <Button
                    className="submit_cancel_btn"
                    variant="secondary"
                    onClick={hadnleNotShowResolve}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="submit_save_btn"
                    variant="primary"
                    onClick={hadnleNotShowResolve}
                  >
                    Resolve
                  </Button>
                </div>
              </Modal.Body>
            </Modal>
          </div>
          <p className="mb-2 ticket_date">Dec 18, 2020</p>
          <p className="mb-3 ticket_description">
            Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
            sint. Velit officia consequat duis enim velit mollit.
          </p>
          <div className="chatbox_chat">
            <div className="chat_content">
              <p className="admin_chat">
                The More Important the Work, the More Important the Rest
              </p>
              <p className="user_chat">
                Yo Reddit! What’s a small thing that anyone can do at nearly
                anytime to improve their mood and make them happier?
              </p>
              <p className="user_chat">
                The More Important the Work, the More Important the Rest
              </p>
              <p className="admin_chat">
                How to design a product that can grow itself 10x in year:
                <p>
                  Understanding color theory: the color wheel and finding
                  complementary colors
                </p>
              </p>
              <p className="user_chat">
                Any mechanical keyboard enthusiasts in design?
              </p>
              <p className="admin_chat">
                How to design a product that can grow itself 10x in year:
              </p>
              <p className="user_chat">
                Yo Reddit! What’s a small thing that anyone can do at nearly
                anytime to improve their mood and make them happier?
              </p>
            </div>
          </div>
          <div className="send_messages mt-3">
            <div className="support_chat_add">
              <img
                className={"support_attach_img"}
                src="/build/img/Icons/Paperclip.svg"
                alt="zex pr wire Logo"
                width={24}
                height={24}
                priority
              />
              <img
                className={"support_add_img"}
                src="/build/img/Icons/Add image.svg"
                alt="zex pr wire Logo"
                width={24}
                height={24}
                priority
              />
            </div>
            <input
              className="support_chat_input"
              type="text"
              placeholder="Type Here"
            />
            <button className="support_chat_send">
              <img
                className={"support_send_img"}
                src="/build/img/Icons/Send.png"
                alt="zex pr wire Logo"
                width={24}
                height={24}
                priority
              />
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
