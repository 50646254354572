import Navbar from "../../components/NavbarProfile/NavbarProfile";
import Sidebar from "../../components/SidebarNew/Sidebar";
import AddCompany from "../../components/ManageCompany/AddCompany";
import Footer from "../../components/Layouts/Footer";
import "../ManageCompany/Company.css";

const CompanyCreate = () => {
//   const activeSide = 27;
//   const activeEventKey = "8";
  return (
    <>
      <div className="row gx-0 manage_company_layout">
        <Sidebar />
        <div
          className={`main col d-flex flex-column`}
          style={{ overflow: "auto" }}
        >
          <Navbar />
          <div className="flex-grow-1">
            <AddCompany />
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default CompanyCreate;
