import React from "react";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useState, useEffect } from "react";
import { showToast } from "../../Utils/index";
import Select from "react-select";
import axios from "axios";
import DataTable from "react-data-table-component";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Instance from "../../Utils/Axios";
import "./UserPrEdit.css";

const UserPrEdit = () => {
  const [accordionOpen, setAccordionOpen] = useState(false);
  const [packageList, setPackageList] = useState([]);
  const [prHistory, setPrHistory] = useState([]);
  const [tempData, setTempData] = useState([]);
  const [error, setError] = useState("");
  const [data, setData] = useState([]);
  const { userId } = useParams();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    increasePR: "",
    price: "",
    paymentId: "",
    validity: "",
    packageId: "",
    userId: userId,
  });

  const [filters, setFilters] = useState({
    userId: userId,
    packageName: "",
    increasePR: "",
    totalPR: "",
    price: "",
    createdOn: "",
    originalValidity: "",
    adminAddedValidity: "",
    reason: "",
    userName: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const updateIncreasePr = (e) => {
    e.preventDefault();
    window.confirm(formData);
    console.log(formData);

    formData.packageId = data[0]?.value;

    const token = localStorage.getItem("adminToken");

    Instance.post("/siteadmin/user/increasepr", {
      adminToken: token,
      ...formData,
    })
      .then((response) => {
        if (response.data.resCode === "100") {
          showToast("Already a member", "error", "error");
          setError(response.data.resMessage);
          console.log("Increase PR update  failed:", response.data);
        } else {
          // console.log("Increase PR updated successfully:", response.data);
          showToast("Increase PR update successfully", "success", "success");
          setError("");
          setTimeout(() => {
            navigate("/admin/user");
          }, 2000);
        }
      })
      .catch((error) => {
        showToast("Error", "error", "error");
        setError("Failed to Update Increase PR ");
        console.error("Error Update Increase PR :", error);
      });
  };

  const fetchPackageList = () => {
    const token = localStorage.getItem("adminToken");
    console.log("Fetched token from localStorage:", token);
    Instance.post("/siteadmin/user/directPRList", {
      adminToken: token,
      userId: userId,
    })
      .then((response) => {
        setPrHistory(response?.data?.message);
        setTempData(response?.data?.message);
        setError("");
        const arr = [];
        response?.data?.message.map((val) => {
          arr.push({
            label: val.packageName,
            value: val.packageId,
            price: val.price,
            validity: val.originalValidity,
            pressReleaseCount: val.pressReleaseCount,
            limit: val.prLimit,
          });
        });

        setPackageList(arr);

        // console.log("check Package  data", response?.data?.message);
      })
      .catch((error) => {
        setError("Failed to fetch the  package data");
        console.error("Error fetching package list:", error);
      });
  };
  useEffect(() => {
    fetchPackageList();
  }, []);

  const columns = [
    {
      name: "Package Name",
      selector: (row) => row.packageName,
      sortable: true,
      wrap: true,
      width: "200px",
    },
    {
      name: "Increase PR",
      selector: (row) => row.increasePR,
      sortable: true,
      wrap: true,
      width: "140px",
    },
    {
      name: "Total PR",
      selector: (row) => row.totalPR,
      sortable: true,
      wrap: true,
      // width: "160px",
    },
    {
      name: "PR Price",
      selector: (row) => row.price,
      sortable: true,
      wrap: true,
      width: "140px",
    },
    {
      name: "Date & Time",
      selector: (row) => row.createdOn,
      sortable: true,
      wrap: true,
      width: "160px",
    },
    {
      name: "Original Validity",
      selector: (row) => row.originalValidity,
      sortable: true,
      wrap: true,
      // width: "160px",
    },
    {
      name: `Admin Added  Validity`,
      cell: (row) => row.adminAddedValidity,
      // width: "100px",
      wrap: true,
      // width: "200px",
    },
    {
      name: "Reason",
      cell: (row) => row.reason,
      // width: "200px",
      wrap: true,
    },
    {
      name: "Who's Increased PR",
      cell: (row) => row.userName,
      //  width: "160px",
      wrap: true,
    },
  ];

  const filterData = (e) => {
    let filterText = e.target.value;
    if (!filterText) {
      setTempData([...prHistory]);
    } else {
      const filteredItems = prHistory.filter(
        (item) =>
          (item.packageName &&
            item.packageName
              .toLowerCase()
              .includes(filterText.toLowerCase())) ||
          (item.increasePR &&
            item.increasePR.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.totalPR &&
            item.totalPR.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.price &&
            item.price.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.createdOn &&
            item.createdOn.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.originalValidity &&
            item.originalValidity
              .toLowerCase()
              .includes(filterText.toLowerCase())) ||
          (item.adminAddedValidity &&
            item.adminAddedValidity
              .toLowerCase()
              .includes(filterText.toLowerCase())) ||
          (item.reason &&
            item.reason.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.userName &&
            item.userName.toLowerCase().includes(filterText.toLowerCase()))
      );
      setTempData([...filteredItems]);
    }
  };
  const handleFilterChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };

  const applyFilters = () => {
    const token = localStorage.getItem("adminToken");
    Instance.post("/siteadmin/user/directPRList", {
      adminToken: token,
      userId: userId,
      packageName: filters.packageName,
      increasePR: filters.increasePR,
      totalPR: filters.totalPR,
      price: filters.price,
      createdOn: filters.createdOn,
      originalValidity: filters.originalValidity,
      adminAddedValidity: filters.adminAddedValidity,
      reason: filters.reason,
      userName: filters.userName,
    })
      .then((response) => {
        setTempData(response.data.message);
        setError("");
        console.log("Filtered PR History data:", response?.data?.message);
      })
      .catch((error) => {
        setError("failed to apply filters");
        console.error("Error applying filter", error);
      });
  };

  const clearFilters = () => {
    const token = localStorage.getItem("adminToken");
    Instance.post("/siteadmin/user/directPRList", {
      adminToken: token,
      userId: userId,
      packageName: "",
      increasePR: "",
      totalPR: "",
      price: "",
      createdOn: "",
      originalValidity: "",
      adminAddedValidity: "",
      reason: "",
      userName: "",
    })
      .then((response) => {
        setFilters({
          userId: userId,
          packageName: "",
          increasePR: "",
          totalPR: "",
          price: "",
          createdOn: "",
          originalValidity: "",
          adminAddedValidity: "",
          reason: "",
          userName: "",
        });
        setTempData(response.data.message);
        setPrHistory(response.data.message);
        setError("");
      })
      .catch((error) => {
        setError("Failed to clear filters");
        console.error("error clearing the filters", error);
      });
  };

  // console.log(data);

  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      setAccordionOpen(!accordionOpen)
    );
    return (
      <button
        type="button"
        className="admin_filter_btn"
        // style={{ backgroundColor: "pink" }}
        onClick={decoratedOnClick}
      >
        {children}
        {accordionOpen ? (
          <img
            src="/build/img/Icons/down_vector.svg"
            alt="zex pr wire Logo"
            width={16}
            height={16}
          />
        ) : (
          <img
            src="/build/img/Icons/down_vector.svg"
            alt="zex pr wire Logo"
            width={16}
            height={16}
            className="filter_up_arrow"
          />
        )}
      </button>
    );
  }
  return (
    <>
      <div className="userPr_pages_layout">
        <div className="userPr_page_heading">
          <h4 className=""> Edit Increase PR </h4>
          <div className="admin_userPr_btns">
            <Link to="/admin/user" className="">
              <span style={{ cursor: "pointer" }}>
                <div className="backbutton"></div>
              </span>
            </Link>
          </div>
        </div>
        <div className="add_userPr_details">
          <Form onSubmit={updateIncreasePr}>
            <Row>
              <Col className=" custom_col_add_staff mb-4">
                <Form.Label>Select Package</Form.Label>
                <Select
                  options={packageList}
                  placeholder="Select Distribution Options"
                  isMulti
                  closeMenuOnSelect={true}
                  //   value={selectedVendor}
                  //   onChange={(cl) => handleMultSelect(cl)}

                  onChange={(e) => {
                    setData(e);
                  }}
                />
              </Col>
            </Row>

            {data?.map((val) => {
              return (
                <>
                  <Row className="mb-5">
                    <Col className="col-12 pb-4">
                      <div className="user_content">
                        Insert PR For {val.packageName}
                      </div>
                      <div className="user_content2">
                        Package Price is $ {val.price}{" "}
                      </div>
                      <div className="user_content2">
                        Package PR Limit is $ {val.limit}{" "}
                      </div>
                      <Form.Control
                        type="text"
                        className="input_tag"
                        placeholder="Insert PR"
                        name="increasePR"
                        value={formData.increasePR}
                        onChange={handleChange}
                      />
                    </Col>
                    <Col className="col-12 pb-4">
                      <div className="user_content mb-3">
                        Insert Price For {val.label}
                      </div>
                      <div>
                        <Form.Control
                          type="text"
                          className="input_tag"
                          placeholder="Insert Price"
                          name="price"
                          value={formData.price}
                          onChange={handleChange}
                        />
                      </div>
                    </Col>
                    <Col className="col-12 pb-4">
                      <div className="user_content mb-3">
                        Insert Reason / Transaction ID For {val.label}
                      </div>
                      <div>
                        <Form.Control
                          type="text"
                          className="input_tag"
                          placeholder="Insert Reason / Transaction ID"
                          name="paymentId"
                          value={formData.paymentId}
                          onChange={handleChange}
                        />
                      </div>
                    </Col>

                    <hr className="mt-3"></hr>
                    <Col className="col-12 pb-4">
                      <div className="user_content mb-3">
                        Enter Package Validity {val.label}
                      </div>
                      <div>
                        <Form.Control
                          type="text"
                          className="input_tag"
                          placeholder="Enter Package Validity like days 30/50"
                          value={formData.validity}
                          name="validity"
                          onChange={handleChange}
                        />
                      </div>
                    </Col>
                    <hr className="mt-3"></hr>
                  </Row>
                </>
              );
            })}

            <button className="add_userPr_submit_btn" type="submit">
              Update PR
            </button>
          </Form>
        </div>
      </div>

      <div className="user_data1 text-center py-3">
        <img
          src="/build/history.png"
          alt="history"
          style={{ height: "22px", width: "22px" }}
        />
        &nbsp; PR History{" "}
      </div>

      <div className="userPr_pages_layout">
        <Accordion className="mb-4">
          <Card>
            <Card.Header className="d-md-flex justify-content-between align-items-center">
              <div className="staff_search">
                <label>
                  Search:
                  <input
                    type="search"
                    placeholder=""
                    aria-controls="datatable"
                    onChange={filterData}
                  ></input>
                </label>
              </div>
              <div className="d-md-flex">
                <CustomToggle as={Button} variant="link" eventKey="0">
                  Filters
                </CustomToggle>
              </div>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Form>
                  <div className="d-flex gap-4 flex-wrap">
                    <Form.Group
                      controlId="filterName"
                      className="staff_filter_options"
                    >
                      <Form.Label>Package Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search Package Name"
                        name="packageName"
                        value={filters.packageName}
                        onChange={handleFilterChange}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filterprice"
                      className="staff_filter_options"
                    >
                      <Form.Label>Increase PR</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search Increase PR"
                        name="increasePR"
                        value={filters.increasePR}
                        onChange={handleFilterChange}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filterRelease"
                      className="staff_filter_options"
                    >
                      <Form.Label>Total PR</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search Total PR"
                        name="totalPR"
                        value={filters.totalPR}
                        onChange={handleFilterChange}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filterType"
                      className="staff_filter_options"
                    >
                      <Form.Label>PR Price</Form.Label>
                      <Form.Control
                        type="number"
                        name="price"
                        value={filters.price}
                        onChange={handleFilterChange}
                        placeholder="Search PR Price"
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filterSubtype"
                      className="staff_filter_options"
                    >
                      <Form.Label>Date & Time</Form.Label>
                      <Form.Control
                        type="datetime-local"
                        id="datetime"
                        name="createdOn"
                        value={filters.createdOn}
                        onChange={handleFilterChange}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filterSubtype"
                      className="staff_filter_options"
                    >
                      <Form.Label>Original Validity</Form.Label>
                      <Form.Control
                        type="number"
                        name="originalValidity"
                        value={filters.originalValidity}
                        onChange={handleFilterChange}
                        placeholder="Search Original Validity"
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filterSubtype"
                      className="staff_filter_options"
                    >
                      <Form.Label>Admin Added Validity</Form.Label>
                      <Form.Control
                        type="number"
                        name="adminAddedValidity"
                        value={filters.adminAddedValidity}
                        onChange={handleFilterChange}
                        placeholder="Search Admin Added Validity"
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filterSubtype"
                      className="staff_filter_options"
                    >
                      <Form.Label>Reason</Form.Label>
                      <Form.Control
                        type="text"
                        name="reason"
                        value={filters.reason}
                        onChange={handleFilterChange}
                        placeholder="Search Reason "
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filterSubtype"
                      className="staff_filter_options"
                    >
                      <Form.Label>Who's Increased PR</Form.Label>
                      <Form.Control
                        type="text"
                        name="userName"
                        value={filters.userName}
                        onChange={handleFilterChange}
                        placeholder="Search Who's Increased PR"
                      ></Form.Control>
                    </Form.Group>
                  </div>
                  <div className="mt-3 d-flex gap-3">
                    <Button variant="secondary" onClick={clearFilters}>
                      Clear
                    </Button>
                    <Button variant="primary" onClick={applyFilters}>
                      Apply
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>

        <DataTable
          className="data-table-wrapper"
          columns={columns}
          data={tempData}
          pagination
          defaultSortFieldId={1}
          responsive={true}
          defaultSortAsc={true}
        />
      </div>
    </>
  );
};

export default UserPrEdit;
