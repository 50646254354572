import Navbar from "../../components/NavbarProfile/NavbarProfile";
import Sidebar from "../../components/SidebarNew/Sidebar";
import StaffManage from "../../components/StaffManagement/StaffManage";
import Footer from "../../components/Layouts/Footer";
import "./SM.css";

const StaffManagement = () => {
  const activeSide = 25;
  return (
    <>
      <div className="row gx-0 staff_manage_layout">
        <Sidebar activeSide={activeSide} />
        <div
          className={`main col d-flex flex-column`}
          style={{ overflow: "auto" }}
        >
          <Navbar />
          <div className="flex-grow-1">
            <StaffManage />
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default StaffManagement;
