"use client";
import Sidebar from "../../components/SidebarNew/Sidebar";
import Navbar from "../../components/NavbarProfile/NavbarProfile";
import SecondaryNavbar from "../../components/SecondaryNav/SecondaryNavbar";
import "./Profile.css";

export default function Profile() {
  return (
    <>
      <div className="row gx-0 profile_bar">
        <Sidebar />
        <div className={`main col`}>
          <Navbar name={"Profile"} />
          <SecondaryNavbar />
        </div>
      </div>
    </>
  );
}
