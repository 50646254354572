import "./VendorLayout.css";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Instance from "../../Utils/Axios";

const VendorDetails = () => {
  const [vendorHistory, setVendorHistory] = useState([]);
  const [vendor, setVendor] = useState([]);
  const [error, setError] = useState("");
  const { vendorId } = useParams();

  const [data, setData] = useState([
    {
      addCredit: "",
      name: "",
      user: "",
      createdOn: "",
      publicationNumber: "",
      availableCredit: "",
      creditLimits: "",
    },
  ]);

  const columns = [
    {
      name: "Vendor Name",
      selector: (row) => row.name,
      sortable: true,
      wrap: true,
    },
    {
      name: "No Of Publication",
      selector: (row) => row.publicationNumber,
      sortable: true,
      wrap: true,
    },
    {
      name: "Credits Limit",
      selector: (row) => row.creditLimits,
      sortable: true,
      wrap: true,
    },
    {
      name: "Available Credits",
      selector: (row) => row.availableCredit,
      sortable: true,
      wrap: true,
    },
  ];

  const columns2 = [
    {
      name: "Vendor Name",
      selector: (row) => row.name,
      sortable: true,
      wrap: true,
    },
    {
      name: "Add Credits",
      selector: (row) => row.addCredit,
      sortable: true,
      wrap: true,
    },
    {
      name: "	Who's Add Credits",
      selector: (row) => row.user,
      sortable: true,
      wrap: true,
    },
    {
      name: "Date & Time",
      selector: (row) => row.createdOn,
      sortable: true,
      wrap: true,
    },
  ];

  const fetchVendorDetails = () => {
    const token = localStorage.getItem("adminToken");
    Instance.post("/siteadmin/vendor/details", {
      adminToken: token,
      vendorId: vendorId,
    })
      .then((response) => {
        setVendor(response?.data?.message);
        data[0].publicationNumber = response?.data?.message?.publicationNumber;
        data[0].availableCredit = response?.data?.message?.availableCredit;
        data[0].creditLimits = response?.data?.message?.credits;
        setError("");
      })
      .catch((error) => {
        setError("Cannot fetch Vendor Details");
        console.error("cannot fetch vendor details", error);
      });
  };
  useEffect(() => {
    fetchVendorDetails();
  }, [vendorId]);
  const fetchVendorHistory = async () => {
    try {
      const token = localStorage.getItem("adminToken");
      const response = await Instance.post("/siteadmin/vendor/history", {
        adminToken: token,
        id: vendorId,
      });
      console.log("Response:", response?.data?.message);
      if (response.data) {
        setVendorHistory(response?.data?.message);
        data[0].name = response?.data?.message[0]?.name;
        data[0].user = response?.data?.message[0]?.user;
        data[0].addCredit = response?.data?.message[0]?.credits;
        data[0].createdOn = response?.data?.message[0]?.createdOn;
      } else {
        setError("Unexpected data format received");
      }
      setError("");
    } catch (error) {
      setError("Cannot fetch Vendor history");
      console.error("Cannot fetch vendor history", error);
    }
  };
  useEffect(() => {
    fetchVendorHistory();
  }, [vendorId]);

  // const mergeData = () => {

  //   // const data = [...vendor, ...vendorHistory];

  // };

  // console.log("------------------", [...[vendor], ...vendorHistory]);

  return (
    <>
      <div className="vendor_pages_layout">
        <div className="vendor_page_heading">
          <h4> View Vendor History </h4>
          <div className="admin_vendor_btns">
            <Link to="/admin/vendor">
              <span style={{ cursor: "pointer" }}>
                <div className="backbutton"></div>
              </span>
            </Link>
          </div>
        </div>
        <div className="vendor_history_table mb-4">
          <h4>Vendor History</h4>
          <DataTable
            className="data-table-wrapper"
            columns={columns}
            data={data}
            responsive={true}
            defaultSortFieldId={1}
          />
        </div>
        <div className="vendor_history_table">
          <h4>Update Credits History</h4>
          <DataTable
            className="data-table-wrapper"
            columns={columns2}
            data={data}
            responsive={true}
            defaultSortFieldId={1}
          />
        </div>
      </div>
    </>
  );
};

export default VendorDetails;
