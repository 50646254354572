import "./ReportLayout.css";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useState, useEffect } from "react";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Instance from "../../Utils/Axios";
import error from "../../Utils/Icons/error";
import { showToast } from "../../Utils";

const ReportPage = () => {
  const [reportData, setReportData] = useState([])
  const [accordionOpen, setAccordionOpen] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("adminToken")
    const getReportsList = () => {
      Instance.post("/siteadmin/pressrelease/list", {
        adminToken: token,
        status: "PUBLISHED"
      })
        .then((response) => {
          setReportData(response?.data?.message);
        })
        .catch((error) => {
          console.error("Error fetching the reports list", error)
        })
    }
    getReportsList();
  }, [])

  const handleCreate = (id) => {
    const token = localStorage.getItem("adminToken")
    Instance.post("/siteadmin/pressrelease/list", {
      adminToken: token,
      prId: id
    })
      .then((response) => {
        if (response.data.resCode === "200") {
          showToast("Request for report is created", "success", "success")
        } else {
          showToast(response.data.message, "error", "error")
        }
      })
      .catch((error) => {
        showToast("Unable to create any request right now", "error", "error")
      })
  }

  const columns = [
    {
      name: "SI.No",
      selector: (row, index) => parseInt(index + 1),
      // sortable: true,
      wrap: true,
    },
    {
      name: "PR ID",
      selector: (row) => row.id,
      sortable: true,
      wrap: true,
    },
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
      wrap: true,
    },
    {
      name: "Package",
      selector: (row) => row.packageName,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      wrap: true,
    },
    {
      name: "Action",
      cell: (row) => (
        // <Link to={`/admin/report/add/${row.id}`}>
        <div className="report_create_btn" onClick={() => handleCreate(row.id)} style={{ cursor: "pointer" }}>Create</div>
        // </Link>
      ),
    },
  ];

  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      setAccordionOpen(!accordionOpen)
    );
    return (
      <button
        type="button"
        className="admin_filter_btn"
        // style={{ backgroundColor: "pink" }}
        onClick={decoratedOnClick}
      >
        {children}
        {accordionOpen ? (
          <img
            src="/build/img/Icons/down_vector.svg"
            alt="zex pr wire Logo"
            width={16}
            height={16}
          />
        ) : (
          <img
            src="/build/img/Icons/down_vector.svg"
            alt="zex pr wire Logo"
            width={16}
            height={16}
            className="filter_up_arrow"
          />
        )}
      </button>
    );
  }
  return (
    <>
      <div className="report_pages_layout">
        <div className="report_page_heading">
          <h4> Report Center </h4>
        </div>
        <Accordion className="mb-4">
          <Card>
            <Card.Header className="d-md-flex justify-content-between align-items-center">
              <div className="staff_search">
                <label>
                  Search:
                  <input
                    type="search"
                    placeholder=""
                    aria-controls="datatable"
                  // onChange={filterData}
                  ></input>
                </label>
              </div>
              <div className="d-md-flex">
                <CustomToggle as={Button} variant="link" eventKey="0">
                  Filters
                </CustomToggle>
              </div>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Form>
                  <div className="d-flex gap-4 flex-wrap">
                    <Form.Group
                      controlId="filterStatus"
                      className="staff_filter_options"
                    >
                      <Form.Label>Status</Form.Label>
                      <Form.Control
                        as="select"
                        name="status"
                      // value={filters.status}
                      // onChange={handleFilterChange}
                      >
                        <option value="">All</option>
                        <option value="ACTIVE">ACTIVE</option>
                        <option value="INACTIVE">INACTIVE</option>
                      </Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filterTitle"
                      className="staff_filter_options"
                    >
                      <Form.Label>Title</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search Title"
                        name="title"
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filterPackage"
                      className="staff_filter_options"
                    >
                      <Form.Label>Package</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search Package"
                        name="Package"
                      ></Form.Control>
                    </Form.Group>
                  </div>
                  <div className="mt-3 d-flex gap-3">
                    <Button variant="secondary" /*onClick={clearFilters}*/>
                      Clear
                    </Button>
                    <Button variant="primary" /*onClick={applyFilters} */>
                      Apply
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <DataTable
          className="data-table-wrapper"
          columns={columns}
          //   data={tempData}
          data={reportData}
          pagination
          defaultSortFieldId={1}
          responsive={true}
          defaultSortAsc={true}
        />
      </div>
    </>
  );
};

export default ReportPage;
