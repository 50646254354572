import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "../Pages/Login/Page";
import Signup from "../Pages/SignUp/Page";
import Forgot from "../Pages/ForgotPassword/page";
import NewPassword from "../Pages/NewPassword/Page";
import Dashboard from "../Pages/Dashboard/Page";
import Profile from "../Pages/Profile/Page";
import PRmanager from "../Pages/PRmanager/Page";
import SubmitPR from "../Pages/SubmitPr/Page";
import SubAccount from "../Pages/SubAccount/Page";
import Gallery from "../Pages/Gallery/Page";
import Support from "../Pages/Support/Page";
import Contact from "../Pages/Contact/Page";
import Notification from "../Pages/Notification/Page";
import StaffManagement from "../Pages/StaffManage/Page";
import StaffCreate from "../Pages/StaffCreate/Page";
import StaffEdit from "../Pages/StaffEdit/Page";
import StaffHistory from "../Pages/StaffHistory/Page";
import UserManagement from "../Pages/UserManage/Page";
import Networks from "../Pages/Networks/Page";
import NetworkCreate from "../Pages/NetworkCreate/Page";
import NetworkEdit from "../Pages/NetworkEdit/Page";
import NetworkManage from "../Pages/NetworkManage/Page";
import Template from "../Pages/Templates/Page";
import TemplateEdit from "../Pages/TemplatesEdit/Page";
import TemplateCreate from "../Pages/TemplateCreate/Page";
import ProtectedRoute from "../Utils/ProtectedRoutes";
import PublicationManagement from "../Pages/Publication/Page";
import Addpublicationn from "../Pages/Addpublicationn/Page";
import PublicationEdit from "../Pages/PublicationEdit/Page";
import UserAdd from "../Pages/UserAdd/Page";
import UserEdit from "../Pages/UserEdit/Page";
import UserDelete from "../Pages/UserDelete/Page";
import AddUser from "../components/UserManagement/AddUser";
import CountryManage from "../Pages/CountryManage/Page";
import Vendor from "../Pages/Vendor/Page";
import VendorCreate from "../Pages/VendorCreate/Page";
import VendorEdit from "../Pages/VendorEdit/Page";
import VendorHistory from "../Pages/VendorHistory/Page";
import CountryAdd from "../Pages/CountryAdd/Page";
import CountryEdit from "../Pages/CountryEdit/Page";
import ManageCompany from "../Pages/ManageCompany/Page";
import CompanyCreate from "../Pages/ManageCompanyCreate/Page";
import CompanyEdit from "../Pages/ManageCompanyEdit/Page";
import Package from "../Pages/Package/Page";
import PackageCreate from "../Pages/PackageCreate/Page";
import PackageSequence from "../Pages/PackageSequence/Page";
import PackageUpdate from "../Pages/PackageUpdate/Page";
import PackageHistory from "../Pages/PackageHistory/Page";
import OnlinePackage from "../Pages/OnlinePackage/Page";
import OfflinePackage from "../Pages/OfflinePackage/Page";
import ResellerPackage from "../Pages/ResellerPackage/Page";
import CryptoPackage from "../Pages/CryptoPackage/Page";
import CryptoSequence from "../Pages/CryptoSequence/Page";
import ResellerSequence from "../Pages/ResellerSequence/Page";
import IndianPackage from "../Pages/IndianPackage/Page";
import WhiteLabelPackage from "../Pages/WhiteLabelPackage/Page";
import IndianSequence from "../Pages/IndianSequence/Page";
import WhiteLabelSequence from "../Pages/WhiteLabelSequence/Page";
import ManageCoupon from "../Pages/ManageCoupons/Page";
import CouponCreate from "../Pages/ManageCouponCreate/Page";
import CouponEdit from "../Pages/ManageCouponEdit/Page";
import ManagePayment from "../Pages/Payments/Page";
import PaymentUserCreate from "../Pages/PaymentsCreate/Page";
import PaymentHistory from "../Pages/PaymentsHistory/Page";
import PaymentHistoryDetail from "../Pages/PaymentsHistoryDetail/Page";
import Newsletter from "../Pages/Newsletter/Page";
import Report from "../Pages/Report/Page";
import ReportCreate from "../Pages/ReportCreate/Page";
import EditorialManage from "../Pages/EditorialManage/page";
import AddPressRelease from "../Pages/AddPressRelease/page";
import EditPressRelease from "../Pages/EditPressRelease/Page";
import ViewPressRelease from "../Pages/ViewPressRelease/Page";
import AllPressRelease from "../Pages/AllPressRelease/Page";
import DraftPressRelease from "../Pages/DraftPressRelease/Page";
import PendingPressRelease from "../Pages/PendingPressRelease/Page";
import ViewPendingPressRelease from "../Pages/ViewPendingPressRelease/Page";
import ActionPressRelease from "../Pages/ActionPressRelease/Page";
import PublishPressRelease from "../Pages/PublishPressRelease/Page";
import UploadPublishPressRelease from "../Pages/UploadPublishPressRelease/Page";
import RejectedPressRelease from "../Pages/RejectedPressRelease/Page";
import CategoryPressRelease from "../Pages/CategoryPressRelease/Page";
import AffiliateCenter from "../Pages/AffiliateCenter/Page";
import ApplicationCenter from "../Pages/ApplicationCenterView/Page";
import AffiliateUser from "../Pages/AffiliateUsers/Page";
import ViewUser from "../Pages/AffiliateUserView/Page";
import AffiliatePayment from "../Pages/AffiliatePayment/Page";
import AffiliateSource from "../Pages/AffiliateSource/Page";
import AffiliateSourceCreate from "../Pages/AffiliateSourceCreate/Page";
import Bank from "../Pages/AffiliateBank/Page";
import BankCreate from "../Pages/AffiliateBankCreate/Page";
import EditCategoryPressRelease from "../Pages/EditCategoryPressRelease/Page";
import AddCatoryPressRelease from "../Pages/AddCategoryPressRelease/Page";
import GalleryPressRelease from "../Pages/GalleryPressRelease/Page";
import DeletePressRelease from "../Pages/DeletePressRelease/Page";
import SupportManagement from "../Pages/SupportManagement/Page";
import LoginLogManage from "../Pages/LoginLogManage/Page";
import InvoiceManagement from "../Pages/InvoiceManagement/Page";
import EditInvoice from "../Pages/EditInvoice/Page";
import Invoice from "../Pages/Invoice/Page";
import UploadInvoice from "../Pages/UploadInvoice/Page";
import Analytics from "../Pages/Analytics/Page";
import AnalyticsPR from "../Pages/AnalyticsPR/Page";
import AnalyticsUser from "../Pages/AnalyticsUser/Page";
import AnalyticsUserView from "../Pages/AnalyticsUserView/Page";
import AnalyticsReport from "../Pages/AnalyticsReport/Page";
import PreviewPressRelease from "../Pages/PreviewPressRelease/Page";
import HistoryCoupon from "../Pages/HistoryCoupon/Page";
import SalesAnalytics from "../Pages/SalesAnalytics/Page";
import ProfileView from "../Pages/ProfileView/Page";
import PrUSerEdit from "../Pages/PrUserEdit/Page";
import PrUserHistory from "../Pages/PrUserHistory/Page";
import ChatSupport from "../Pages/ChatSupport/Page";
import HistoryUser from "../Pages/HistoryUser/Page";
import UserDecreasePr from "../Pages/UserDecreasePr/Page";

export default function AllRoutes() {
  return (
    <Router>
      <Routes>
        <Route path="" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgot" element={<Forgot />} />
        <Route path="/newpassword" element={<NewPassword />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/pr-manager" element={<PRmanager />} />
        <Route path="/submit-pr" element={<SubmitPR />} />
        <Route path="/sub-account" element={<SubAccount />} />
        <Route path="/gallery" element={<Gallery />} />{" "}
        <Route path="/support" element={<Support />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/notification" element={<Notification />} />
        <Route
          path="/admin/staff"
          element={
            <ProtectedRoute>
              <StaffManagement />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/staff/add"
          element={
            <ProtectedRoute>
              <StaffCreate />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/staff/edit/:staffId"
          element={
            <ProtectedRoute>
              <StaffEdit />{" "}
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/staff/:staffId"
          element={
            <ProtectedRoute>
              <StaffHistory />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/user"
          element={
            <ProtectedRoute>
              <UserManagement />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/user_delete"
          element={
            <ProtectedRoute>
              <UserDelete />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/user/edit_pr_view/:userId"
          element={
            <ProtectedRoute>
              <PrUSerEdit />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/user/editPr_history_view/:userId"
          element={
            <ProtectedRoute>
              <PrUserHistory />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/user/history/:userId"
          element={
            <ProtectedRoute>
              <HistoryUser />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/user/decreasePr/:id/:userId"
          element={
            <ProtectedRoute>
              <UserDecreasePr />
            </ProtectedRoute>
          }
        />
        <Route path="/admin/user/add" element={<UserAdd />} />
        <Route path="/admin/edit_user/:email" element={<UserEdit />} />
        <Route path="/admin/country" element={<CountryManage />} />
        <Route path="/admin/country/add" element={<CountryAdd />} />
        <Route
          path="/admin/country/edit/:countryId"
          element={<CountryEdit />}
        />
        <Route
          path="/admin/network"
          element={
            <ProtectedRoute>
              <Networks />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/network/add"
          element={
            <ProtectedRoute>
              <NetworkCreate />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/network/edit/:networkId"
          element={
            <ProtectedRoute>
              <NetworkEdit />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/network/:networkId"
          element={
            <ProtectedRoute>
              <NetworkManage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/publication"
          element={
            <ProtectedRoute>
              <PublicationManagement />{" "}
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/publication/addnewpublication"
          element={
            <ProtectedRoute>
              <Addpublicationn />{" "}
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/publication/edit/:publicationId"
          element={
            <ProtectedRoute>
              <PublicationEdit />{" "}
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/template"
          element={
            <ProtectedRoute>
              <Template />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/template/add"
          element={
            <ProtectedRoute>
              <TemplateCreate />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/template/edit/:templateId"
          element={
            <ProtectedRoute>
              <TemplateEdit />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/vendor"
          element={
            <ProtectedRoute>
              <Vendor />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/vendor/add"
          element={
            <ProtectedRoute>
              <VendorCreate />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/vendor/edit/:vendorId"
          element={
            <ProtectedRoute>
              <VendorEdit />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/vendor/history/:vendorId"
          element={
            <ProtectedRoute>
              <VendorHistory />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/company"
          element={
            <ProtectedRoute>
              <ManageCompany />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/company/add"
          element={
            <ProtectedRoute>
              <CompanyCreate />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/company/edit/:companyId"
          element={
            <ProtectedRoute>
              <CompanyEdit />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/package"
          element={
            <ProtectedRoute>
              <Package />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/package/add"
          element={
            <ProtectedRoute>
              <PackageCreate />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/package/sequence"
          element={
            <ProtectedRoute>
              <PackageSequence />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/package/edit/:packageId"
          element={
            <ProtectedRoute>
              <PackageUpdate />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/package/history/:packageId"
          element={
            <ProtectedRoute>
              <PackageHistory />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/online_package"
          element={
            <ProtectedRoute>
              <OnlinePackage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/offline_package"
          element={
            <ProtectedRoute>
              <OfflinePackage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/reseller_package"
          element={
            <ProtectedRoute>
              <ResellerPackage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/crypto_package"
          element={
            <ProtectedRoute>
              <CryptoPackage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/crypto_package/sequence"
          element={
            <ProtectedRoute>
              <CryptoSequence />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/reseller_package/sequence"
          element={
            <ProtectedRoute>
              <ResellerSequence />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/indian_package"
          element={
            <ProtectedRoute>
              <IndianPackage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/whitelabel_package"
          element={
            <ProtectedRoute>
              <WhiteLabelPackage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/indian_package/sequence"
          element={
            <ProtectedRoute>
              <IndianSequence />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/whitelabel_package/sequence"
          element={
            <ProtectedRoute>
              <WhiteLabelSequence />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/coupons"
          element={
            <ProtectedRoute>
              <ManageCoupon />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/coupons/add"
          element={
            <ProtectedRoute>
              <CouponCreate />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/coupons/edit/:couponId"
          element={
            <ProtectedRoute>
              <CouponEdit />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/coupons/view_history/:couponId"
          element={
            <ProtectedRoute>
              <HistoryCoupon />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/payments"
          element={
            <ProtectedRoute>
              <ManagePayment />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/payments/add_user"
          element={
            <ProtectedRoute>
              <PaymentUserCreate />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/payments_history"
          element={
            <ProtectedRoute>
              <PaymentHistory />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/payments_history/:id"
          element={
            <ProtectedRoute>
              <PaymentHistoryDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/newsletter"
          element={
            <ProtectedRoute>
              <Newsletter />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/report"
          element={
            <ProtectedRoute>
              <Report />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/report/add/:reportId"
          element={
            <ProtectedRoute>
              <ReportCreate />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/application_center"
          element={
            <ProtectedRoute>
              <AffiliateCenter />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/application_center/:editorialId"
          element={
            <ProtectedRoute>
              <ApplicationCenter />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/affiliate_user"
          element={
            <ProtectedRoute>
              <AffiliateUser />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/affiliate_user/:id"
          element={
            <ProtectedRoute>
              <ViewUser />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/affiliate_payment"
          element={
            <ProtectedRoute>
              <AffiliatePayment />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/affiliate_sources"
          element={
            <ProtectedRoute>
              <AffiliateSource />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/affiliate_sources/add"
          element={
            <ProtectedRoute>
              <AffiliateSourceCreate />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/affiliate_bank"
          element={
            <ProtectedRoute>
              <Bank />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/affiliate_bank/add"
          element={
            <ProtectedRoute>
              <BankCreate />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/analytics"
          element={
            <ProtectedRoute>
              <Analytics />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/PR"
          element={
            <ProtectedRoute>
              <AnalyticsPR />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/PR"
          element={
            <ProtectedRoute>
              <AnalyticsPR />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/analytics_user"
          element={
            <ProtectedRoute>
              <AnalyticsUser />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/view_user_history/:userId"
          element={
            <ProtectedRoute>
              <AnalyticsUserView />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/analytics_report"
          element={
            <ProtectedRoute>
              <AnalyticsReport />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/analytics_sales"
          element={
            <ProtectedRoute>
              <SalesAnalytics />
            </ProtectedRoute>
          }
        />
        <Route path="/admin/editorial" element={<EditorialManage />} />
        <Route
          path="/admin/editorial/add_press_release"
          element={<AddPressRelease />}
        />
        <Route
          path="/admin/editorial/edit_press_release/:id"
          element={<EditPressRelease />}
        />
        <Route
          path="/admin/editorial/view_press_release/:id"
          element={<ViewPressRelease />}
        />
        <Route
          path="/admin/editorial/all_press_release"
          element={<AllPressRelease />}
        />
        <Route
          path="/admin/editorial/draft_press_release"
          element={<DraftPressRelease />}
        />
        <Route
          path="/admin/editorial/pending_press_release"
          element={<PendingPressRelease />}
        />
        <Route
          path="/admin/editorial/view_pending_press_release/:id"
          element={<ViewPendingPressRelease />}
        />
        <Route
          path="/admin/editorial/action_press_release"
          element={<ActionPressRelease />}
        />
        <Route
          path="/admin/editorial/publish_press_release"
          element={<PublishPressRelease />}
        />
        <Route
          path="/admin/editorial/publish/upload_press_release"
          element={<UploadPublishPressRelease />}
        />
        <Route
          path="/admin/editorial/rejected_press_release"
          element={<RejectedPressRelease />}
        />
        <Route
          path="/admin/editorial/category_press_release"
          element={<CategoryPressRelease />}
        />
        <Route
          path="/admin/editorial/category_press_release/edit/:categoryId"
          element={<EditCategoryPressRelease />}
        />
        <Route
          path="/admin/editorial/category/add_press_release"
          element={<AddCatoryPressRelease />}
        />
        <Route
          path="/admin/editorial/gallery_press_release"
          element={<GalleryPressRelease />}
        />
        <Route
          path="/admin/editorial/delete_press_release"
          element={<DeletePressRelease />}
        />
        <Route
          path="/admin/editorial/preview_press_release"
          element={<PreviewPressRelease />}
        />
        <Route path="/admin/manage_support" element={<SupportManagement />} />
        <Route
          path="/admin/support/view_chat/:supportId"
          element={<ChatSupport />}
        />
        <Route path="/admin/loginlog" element={<LoginLogManage />} />
        <Route
          path="/admin/manage_invoice_company"
          element={<InvoiceManagement />}
        />
        <Route
          path="/admin/manage_invoice_company/edit/:id"
          element={<EditInvoice />}
        />
        <Route path="/admin/manage_invoice" element={<Invoice />} />
        <Route
          path="/admin/manage_invoice/upload"
          element={<UploadInvoice />}
        />
        <Route path="/view_profile" element={<ProfileView />} />
      </Routes>
    </Router>
  );
}
