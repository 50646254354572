import "./PackageLayout.css";
import { Link, useNavigate } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import { useState, useEffect } from "react";
import Instance from "../../Utils/Axios";
import { showToast } from "../../Utils/index";

const SetSequenceReseller = () => {
  const [packageList, setPackageList] = useState([]);
  const [selectedSequence, setSelectedSequence] = useState("1");
  const [selectedPackageId, setSelectedPackageId] = useState("");

  const navigate = useNavigate()

  const fetchPackageDetails = () => {
    const token = localStorage.getItem("adminToken");
    Instance.post("/siteadmin/package/getList", {
      adminToken: token,
      status: "active",
      subType: "RESELLER"
    })
      .then((response) => {
        const pkg = response.data.message;
        setPackageList(pkg);
        if (pkg.length > 0) {
          setSelectedPackageId(pkg[0].packageId);
        }
      })
      .catch((error) => {
        console.error("Error fetching the package details ", error);
      });
  };
  useEffect(() => {
    fetchPackageDetails();
  }, []);

  const handleUpdate = () => {
    const token = localStorage.getItem("adminToken");
    Instance.post("/siteadmin/package/update", {
      adminToken: token,
      sequence: selectedSequence,
      packageId: selectedPackageId,
    })
      .then((response) => {
        if (response.data.resCode === "200") {
          showToast("Updated Succesfully!", "success", "success");
          setTimeout(() => {
            navigate("/admin/package");
          }, 1000);
        }
      })
      .catch((error) => {
        console.error("Error updating package details:", error);
        showToast("Error updating the details", "error", "error");
      });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    handleUpdate();
  };
  return (
    <>
      <div className="package_pages_layout">
        <div className="package_page_heading">
          <h4> Set sequence Reseller </h4>
          <div className="admin_package_btns">
            <Link to="/admin/reseller_package">
              <span style={{ cursor: "pointer" }}>
                <div className="backbutton"></div>
              </span>
            </Link>
          </div>
        </div>
        <div className="add_package_details">
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Sequence no</Form.Label>
                <Form.Select name="sequence" value={selectedSequence}
                  onChange={(e) => setSelectedSequence(e.target.value)}>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                  <option value="14">14</option>
                  <option value="15">15</option>
                  <option value="16">16</option>
                  <option value="17">17</option>
                  <option value="18">18</option>
                  <option value="19">19</option>
                  <option value="20">20</option>
                  <option value="21">21</option>
                  <option value="22">22</option>
                  <option value="23">23</option>
                  <option value="24">24</option>
                </Form.Select>
              </Col>
            </Row>
            <Row>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Package Name</Form.Label>
                <Form.Select name="name" value={selectedPackageId}
                  onChange={(e) => setSelectedPackageId(e.target.value)} >
                  {packageList.map((list) => (
                    <option key={list.packageId} value={list.packageId}>
                      {list.name}
                    </option>
                  ))}

                </Form.Select>
              </Col>
            </Row>

            <div>
              <Table responsive bordered hover>
                <thead>
                  <tr>
                    <th width="80%">Package Name</th>
                    <th> Sequence No</th>
                  </tr>
                </thead>
                <tbody>
                  {packageList.map((list, index) => (
                    <tr key={index}>
                      <td>   {list.name}</td>
                      <td>{list.sequence}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            <button className="add_package_submit_btn" type="submit">
              Submit
            </button>
          </Form>
        </div>
      </div>
    </>
  );
};

export default SetSequenceReseller;
