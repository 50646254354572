import Navbar from "../../components/NavbarProfile/NavbarProfile";
import Sidebar from "../../components/SidebarNew/Sidebar";
import PublicationManage from "../../components/PublicationManagement/PublicationManage";
import Footer from "../../components/Layouts/Footer";
import "./Page.css";
const PublicationManagement = () => {
  const activeSide = 14;
  const activeEventKey = "3";
  // const [showAddPublication, setShowAddPublication] = useState(false);

  // const handleNewPublication = () => {
  //   setShowAddPublication(true);
  // };
  // const handlePublicationList = () => {
  //   setShowAddPublication(false);
  // };
  return (
    <>
      <div className="row gx-0 admin_publication_layout">
        <Sidebar activeSide={activeSide} activeEventKey={activeEventKey} />
        <div
          className={`main col d-flex flex-column`}
          style={{ overflow: "auto" }}
        >
          <Navbar />
          <div className="flex-grow-1">
            <PublicationManage />
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default PublicationManagement;
