import React from "react";
import Navbar from "../../components/NavbarProfile/NavbarProfile";
import Sidebar from "../../components/SidebarNew/Sidebar";
import Footer from "../../components/Layouts/Footer";
import "./AddPressRelease.css";
import PressReleaseAdd from "../../components/EditorialManagment/PressReleaseAdd";

const AddPressRelease = () => {
  const activeSide = 3;
  const activeEventKey = "2";
  return (
    <>
      <div className="row gx-0 add_press_content ">
        <Sidebar activeSide={activeSide} activeEventKey={activeEventKey} />
        <div
          className={`main col d-flex flex-column`}
          style={{ overflow: "auto" }}
        >
          <Navbar />
          <div className="flex-grow-1">
            <PressReleaseAdd />
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default AddPressRelease;
