import "./AffiliateLayout.css";
import { useState } from "react";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import DataTable from "react-data-table-component";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

const ViewUserPage = () => {
  const [activeCategory, setActiveCategory] = useState(1);
  const [showActive, setShowActive] = useState(false);
  const [showBanned, setShowBanned] = useState(false);
  const [showBank, setshowBank] = useState(false);

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const [showDatePicker, setShowDatePicker] = useState(false);

  const formatDate = (date) => {
    return date.toLocaleDateString("en-US", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  const formattedDateRange = `${formatDate(
    dateRange[0].startDate
  )} - ${formatDate(dateRange[0].endDate)}`;

  const handleCloseActive = () => setShowActive(false);
  const handleShowActive = () => setShowActive(true);

  const handleCloseBanned = () => setShowBanned(false);
  const handleShowBanned = () => setShowBanned(true);

  const hadnleShowBank = () => {
    setshowBank(true);
  };
  const hadnleNotShowBank = () => {
    setshowBank(false);
  };

  const columns = [
    {
      name: "SI.No",
      selector: (row) => parseInt(row.id),
      sortable: true,
      width: "100px",
      wrap: true,
    },
    {
      name: "Coupon Code Name",
      selector: (row) => row.name,
      sortable: true,
      wrap: true,
    },
    {
      name: "NO. of time used",
      selector: (row) => row.used,
      sortable: true,
      wrap: true,
    },
    {
      name: "Commission Earned",
      selector: (row) => row.commission,
      sortable: true,
      wrap: true,
    },
  ];
  const columns2 = [
    {
      name: "SI.No",
      selector: (row) => parseInt(row.id),
      sortable: true,
      width: "100px",
      wrap: true,
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true,
      wrap: true,
    },
    {
      name: "Payment Method",
      selector: (row) => row.method,
      sortable: true,
      wrap: true,
    },
    {
      name: "Date of Request",
      selector: (row) => row.date,
      sortable: true,
      wrap: true,
    },
    {
      name: "Payment",
      selector: (row) => row.payment,
      sortable: true,
      wrap: true,
    },
    {
      name: "Action",
      selector: (row) => row.action,
      sortable: true,
      wrap: true,
    },
  ];

  return (
    <>
      <div className="affiliate_pages_layout">
        <div className="affiliate_page_heading">
          <h4> Manage User</h4>
          <Link to="/admin/affiliate_user">
            <span style={{ cursor: "pointer" }}>
              <div className="backbutton"></div>
            </span>
          </Link>
        </div>
        <div className="view_users_categories" style={{ overflow: "auto" }}>
          <p
            className={activeCategory === 1 ? "active_category" : ""}
            onClick={() => {
              setActiveCategory(1);
            }}
          >
            Affiliate Coupon Codes
          </p>
          <p
            className={activeCategory === 2 ? "active_category" : ""}
            onClick={() => {
              setActiveCategory(2);
            }}
          >
            Profile Details
          </p>
          <p
            className={activeCategory === 3 ? "active_category" : ""}
            onClick={() => {
              setActiveCategory(3);
            }}
          >
            Wallet
          </p>
        </div>

        {activeCategory === 1 ? (
          <>
            <div className="view_application_detail">
              <Row className="view_user_cards g-3">
                <Col className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                  <div className="view_card_one">
                    <p>Name: Prerna Arora</p>
                    <p>Email: prerna@zexprwire.com</p>
                  </div>
                </Col>
                <Col className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12">
                  <div className="view_card_two">
                    <img
                      src="/build/img/Newspaper.svg"
                      alt="icon"
                      width={48}
                      height={48}
                    />
                    <h4>0</h4>
                    <p>Coupons Created</p>
                  </div>
                </Col>
                <Col className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12">
                  {" "}
                  <div className="view_card_three">
                    <img
                      src="/build/img/Package.svg"
                      alt="icon"
                      width={48}
                      height={48}
                    />
                    <h4>$0</h4>
                    <p>Earnings</p>
                  </div>
                </Col>
                <Col className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12">
                  {" "}
                  <div className="view_card_three">
                    <img
                      src="/build/img/Package.svg"
                      alt="icon"
                      width={48}
                      height={48}
                    />
                    <h4>$0</h4>
                    <p>Withdrawn</p>
                  </div>
                </Col>
                <Col className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12">
                  {" "}
                  <div className="view_card_three">
                    <img
                      src="/build/img/Package.svg"
                      alt="icon"
                      width={48}
                      height={48}
                    />
                    <h4>$0</h4>
                    <p>Pending</p>
                  </div>
                </Col>
              </Row>
              <div className="staff_search mt-5 mb-4">
                <label>
                  Search:
                  <input
                    type="search"
                    placeholder=""
                    aria-controls="datatable"
                    // onChange={filterData}
                  ></input>
                </label>
              </div>
              <DataTable
                columns={columns}
                //   data={tempData}
                data={[{}]}
                pagination
                defaultSortFieldId={1}
                responsive={true}
                defaultSortAsc={true}
                className="application_datatable data-table-wrapper"
              />
              {showDatePicker ? (
                <DateRangePicker
                  ranges={dateRange}
                  onChange={(ranges) => setDateRange([ranges.selection])}
                  onClose={() => setShowDatePicker(false)}
                />
              ) : (
                <div
                  style={{ textDecoration: "underline" }}
                  onClick={() => setShowDatePicker(true)}
                >
                  {formattedDateRange}
                </div>
              )}
            </div>
          </>
        ) : activeCategory === 2 ? (
          <>
            <div className="view_application_detail">
              <h4 className="view_profile_title">User Details</h4>
              <Form>
                <Row>
                  <Col className="custom_col_add_staff mb-4">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="firstname"
                      //   value={formData.title}
                      //   onChange={handleChange}
                    />
                  </Col>
                  <Col className="custom_col_add_staff mb-4">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="lastname"
                      //   value={formData.name}
                      //   onChange={handleChange}
                      required
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="custom_col_add_staff mb-4">
                    <Form.Label>Email Id</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      //   value={formData.userName}
                      //   onChange={handleChange}
                      required
                    />
                  </Col>
                  <Col className="custom_col_add_staff mb-4">
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                      type="number"
                      name="mobile"
                      //   value={formData.mobile}
                      //   onChange={handleChange}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="custom_col_add_staff mb-4">
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      type="text"
                      name="address"
                      //   value={formData.email}
                      //   onChange={handleChange}
                      required
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs="3" className="custom_col_add_staff mb-4">
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      type="text"
                      name="city"
                      //   value={formData.email}
                      //   onChange={handleChange}
                      required
                    />
                  </Col>
                  <Col xs="3" className="custom_col_add_staff mb-4">
                    <Form.Label>State</Form.Label>
                    <Form.Control
                      type="text"
                      name="state"
                      //   value={formData.email}
                      //   onChange={handleChange}
                      required
                    />
                  </Col>
                  <Col xs="6" className="custom_col_add_staff mb-4">
                    <Form.Label>Country</Form.Label>
                    <Form.Control
                      type="text"
                      name="country"
                      //   value={formData.email}
                      //   onChange={handleChange}
                      required
                    />
                  </Col>
                </Row>

                <Row>
                  <Col className="custom_col_add_staff mb-4">
                    <Form.Label>How You Are going to Promote</Form.Label>
                    <Form.Control
                      type="text"
                      name="how"
                      placeholder="An idea of the strategy and platforms where the affiliate member will promote the coupon codes."
                      //   value={formData.password}
                      //   onChange={handleChange}
                      required
                    />
                  </Col>
                </Row>
                <p>Date of account creation : 04/21/2022</p>
                <p>Date of affiliate application : 03/11/2024</p>
                <p>
                  User Status :<span className="success_text"> Active </span>
                </p>
                <p>
                  User Banned/Unbanned Status :
                  <span className="success_text">Unbanned</span>
                </p>
              </Form>
            </div>
            <div className="d-flex justify-content-center gap-5">
              <button className="view_action_btn" onClick={handleShowActive}>
                Active/Deactive
              </button>
              <Modal show={showActive} onHide={handleCloseActive}>
                <Modal.Body className="view_modal_body">
                  <div className="modal_warning">
                    <span className="warning_line">
                      <span className="warning_dot"></span>
                    </span>
                  </div>
                  <h4 className="view_modal_title">Are you sure?</h4>
                  <p className="view_modal_text">
                    Are you sure you want to deactive the user
                  </p>
                  <div className="d-flex justify-content-end gap-4">
                    <Button
                      variant="secondary"
                      className="ps-4 pe-4"
                      onClick={handleCloseActive}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="danger"
                      className="ps-4 pe-4"
                      onClick={handleCloseActive}
                    >
                      OK
                    </Button>
                  </div>
                </Modal.Body>
              </Modal>
              <button className="view_action_btn" onClick={handleShowBanned}>
                Banned/Unbanned
              </button>

              <Modal show={showBanned} onHide={handleCloseBanned}>
                <Modal.Body className="view_modal_body">
                  <div className="modal_warning">
                    <span className="warning_line">
                      <span className="warning_dot"></span>
                    </span>
                  </div>
                  <h4 className="view_modal_title">Are you sure?</h4>
                  <p className="view_modal_text">
                    Are you sure you want to unbanned the user
                  </p>
                  <div className="d-flex justify-content-end gap-4">
                    <Button
                      variant="secondary"
                      className="ps-4 pe-4"
                      onClick={handleCloseBanned}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="danger"
                      className="ps-4 pe-4"
                      onClick={handleCloseBanned}
                    >
                      OK
                    </Button>
                  </div>
                </Modal.Body>
              </Modal>
              <button className="view_action_btn">Edit/Save</button>
            </div>
          </>
        ) : (
          <div>
            <div className="view_application_detail">
              <Row className="g-3">
                <Col className="  col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                  <div className="wallet_balance p-2 pb-3">
                    <img
                      src="/build/Balance.svg"
                      alt="zex pr"
                      width={48}
                      height={48}
                    />
                    <h4>$0</h4>
                    <p>Balance</p>
                  </div>
                </Col>
                <Col className="  col-xl-9 col-lg-9 col-md-9 col-sm-9 col-12">
                  <div className=" wallet_withdraw pb-5">
                    <h4>Withdraw Amount </h4>
                    <input
                      type="text"
                      placeholder="Enter amount here"
                      className="withdraw_input"
                    />

                    <div>
                      <button className="withdraw_btn">Withdraw</button>
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="mt-4">
                <p className="wallet_withdraw_title">Withdraw to</p>
                <Row className="wallet_withdraw_to row">
                  <Col
                    className="withdraw_account col-xl-4 col-lg-4 col-sm-6 col-12 "
                    onClick={hadnleShowBank}
                  >
                    <div className="d-flex align-items-center">
                      <img
                        src="/build/Add_bank.svg"
                        alt="zex pr"
                        width={75}
                        height={75}
                      />
                    </div>
                    <div className="pt-2">
                      <p className="withdraw_account_name">
                        Add New Bank Account
                      </p>
                      <p className="withdraw_account_type">
                        Savings or Current Account
                      </p>
                    </div>
                  </Col>

                  <Modal
                    show={showBank}
                    onHide={hadnleNotShowBank}
                    centered
                    className="custom_modal_wallet"
                  >
                    <Modal.Body className="p-0 wallet_modal_body">
                      <div>
                        <h5 className="wallet_modal_head">Add Bank Account</h5>
                        <div className="wallet_modal_detail">
                          <div className="wallet_modal_box1">
                            <Row>
                              <Col className="custom_col_wallet mb-2-lg">
                                <Form.Label>Country</Form.Label>
                                <Form.Select>
                                  <option>United States</option>
                                  <option>1</option>
                                  <option>2</option>
                                  <option>3</option>
                                </Form.Select>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="custom_col_wallet mb-2-lg">
                                <Form.Label>Choose Bank</Form.Label>
                                <Form.Select>
                                  <option>Select from dropdown</option>
                                  <option>1</option>
                                  <option>2</option>
                                  <option>3</option>
                                </Form.Select>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="custom_col_wallet mb-2-lg">
                                <Form.Label>Address</Form.Label>
                                <Form.Control placeholder="optional" />
                              </Col>
                            </Row>
                          </div>
                          <div className="wallet_modal_box2">
                            <Row>
                              <Col className="custom_col_wallet mb-1">
                                <Form.Label>Card type</Form.Label>
                                <Form.Select>
                                  <option>Select from dropdown</option>
                                  <option>1</option>
                                  <option>2</option>
                                  <option>3</option>
                                </Form.Select>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="custom_col_wallet mb-1">
                                <Form.Label>Card Number</Form.Label>
                                <Form.Control placeholder="1234 xxxx xxxx 7890" />
                              </Col>
                            </Row>
                            <Row>
                              <Col className="custom_col_wallet mb-1">
                                <Form.Label>Expiry Date</Form.Label>
                                <Form.Control placeholder="2/6/2024" />
                              </Col>
                              <Col className="custom_col_wallet mb-1">
                                <Form.Label>CVV</Form.Label>
                                <Form.Control placeholder="***" />
                              </Col>
                            </Row>
                            <Row>
                              <Col className="custom_col_wallet mb-1">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control placeholder="Sam" />
                              </Col>
                              <Col className="custom_col_wallet mb-1">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control placeholder="Maxwell" />
                              </Col>
                            </Row>
                            <p className="mb-1">Save Card Details?</p>
                            <div className="d-flex align-items-center">
                              <input
                                type="checkbox"
                                className="me-1 affiliate_checkbox_design"
                              />
                              <span className="me-3">Yes</span>
                              <input
                                type="checkbox"
                                className="me-1 affiliate_checkbox_design"
                              />
                              <span>No</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="pr_modal_btn">
                        <Button
                          className="wallet_cancel_btn"
                          onClick={hadnleNotShowBank}
                        >
                          Cancel
                        </Button>
                        <Button
                          className="wallet_save_btn"
                          onClick={hadnleNotShowBank}
                        >
                          Save
                        </Button>
                      </div>
                    </Modal.Body>
                  </Modal>

                  <Col className="withdraw_account col-xl-4 col-lg-4 col-sm-6 col-12">
                    <div className="d-flex align-items-center">
                      <img
                        src="/build/Paypal.svg"
                        alt="zex pr"
                        width={75}
                        height={75}
                      />
                    </div>
                    <div className="pt-2   ">
                      <p className="withdraw_account_name">Paypal</p>
                      <p className="withdraw_account_type">Paypal account</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <h2 className="mt-5">Withdrawal requests</h2>
            <div className="staff_search mt-5 mb-4">
              <label>
                Search:
                <input
                  type="search"
                  placeholder=""
                  aria-controls="datatable"
                  // onChange={filterData}
                ></input>
              </label>
            </div>
            <DataTable
              columns={columns2}
              //   data={tempData}
              data={[{}]}
              pagination
              defaultSortFieldId={1}
              responsive={true}
              defaultSortAsc={true}
              className="application_datatable data-table-wrapper"
            />
          </div>
        )}
      </div>
    </>
  );
};

export default ViewUserPage;
