import "./TemplateLayout.css";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Table } from "react-bootstrap";
import Instance from "../../Utils/Axios";
import { showToast } from "../../Utils/index";
import DataTable from "react-data-table-component";

const AddTemplate = () => {
  const [templateName, setTemplateName] = useState("");
  const [templateDesc, setTemplateDesc] = useState("");
  const [publicationIds, setPublicationIds] = useState([]);
  const [publicationData, setPublicationData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleAddTemplate = () => {
    if (!templateName.length || !templateDesc) {
      showToast("The Input fields are empty", "error", "error");
      return;
    }
    const token = localStorage.getItem("adminToken");
    const selectedPublications = publicationIds.join("|");
    Instance.post("/siteadmin/template/create", {
      adminToken: token,
      templateName: templateName,
      templateDesc: templateDesc,
      publicationIds: selectedPublications,
    })
      .then((response) => {
        console.log("Template added successfully:", response.data);
        if (response.data.resCode === "200") {
          showToast("Template added successfully", "success", "success");
          setError("");
          setTimeout(() => {
            navigate("/admin/template");
          }, 2000);
        } else {
          showToast("Error adding template", "error", "error");
          setError(response.data.resMessage);
        }
      })
      .catch((error) => {
        console.error("Error adding template:", error);
        alert("Error adding template");
      });
  };

  useEffect(() => {
    const publicationData = () => {
      const token = localStorage.getItem("adminToken");
      Instance.post("/siteadmin/publication/getList", {
        adminToken: token,
        status: "active",
      })
        .then((response) => {
          setPublicationData(response.data.message);
          console.log("publication data in template", response.data.message);
        })
        .catch((error) => {
          console.error("error fetch publication data", error);
        });
    };
    publicationData();
  }, []);

  const handleCheckboxChange = (publicationId) => {
    setPublicationIds((prevSelected) => {
      if (prevSelected.includes(publicationId)) {
        return prevSelected.filter((id) => id !== publicationId);
      } else {
        return [...prevSelected, publicationId];
      }
    });
  };

  const filteredData = publicationData.filter((publication) => {
    const publicationName = publication.publicationName
      ? publication.publicationName.toLowerCase()
      : "";
    const region = publication.region ? publication.region.toLowerCase() : "";
    const audience = publication.audience ? publication.audience.toLowerCase() : "";
    const publicationUrl = publication.publicationUrl ? publication.publicationUrl.toLowerCase() : "";
    const networkName = publication.networkName
      ? publication.networkName.toLowerCase()
      : "";

    return (
      publicationName.includes(searchTerm.toLowerCase()) ||
      region.includes(searchTerm.toLowerCase()) ||
      audience.includes(searchTerm.toLowerCase()) ||
      publicationUrl.includes(searchTerm.toLowerCase()) ||
      networkName.includes(searchTerm.toLowerCase())
    );
  });

  const columns = [
    {
      name: "",
      selector: (row) => (
        <input
          type="checkbox"
          className="mt-1"
          onChange={() => handleCheckboxChange(row.publicationId)}
          checked={publicationIds.includes(row.publicationId)}
        />
      ),
      sortable: false,
      width: "40px",
      wrap: true,
    },
    {
      name: "SI.No.",
      selector: (row) => row.publicationId,
      sortable: true,
      width: "90px",
      wrap: true,
    },
    {
      name: "Publication Name",
      selector: (row) => row.publicationName,
      sortable: true,
      width: "180px",
      wrap: true,
    },
    {
      name: "Region / Language",
      selector: (row) => row.region,
      sortable: true,
      width: "180px",
      wrap: true,
    },
    {
      name: "Est. Audience",
      selector: (row) => row.audience,
      sortable: true,
      width: "160px",
      wrap: true,
    },
    {
      name: "Published URL",
      selector: (row) => row.publicationUrl,
      sortable: true,
      width: "160px",
      wrap: true,
    },
    {
      name: "Network name",
      selector: (row) => row.networkName,
      sortable: true,
      width: "160px",
      wrap: true,
    },
    // {
    //   name: "Action",
    //   selector: (row) => row.action,
    //   sortable: false,
    //   width: "90px",
    //   wrap: true,
    // },
  ];
  const data = [];

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
  };

  return (
    <>
      <div className="template_pages_layout">
        <div className="template_page_heading">
          <h4> Templates </h4>
          <div className="admin_template_btns">
            <div className="search_template_add">
              <input placeholder="Search Network / Publication" type="search" />
              <img
                className={""}
                src="/build/img/search.svg"
                alt="zex pr wire Logo"
                width={24}
                height={24}
                priority
              />
            </div>
            <button className="new_template_btn" onClick={handleAddTemplate}>
              Save
            </button>
          </div>
        </div>
        <div className="add_template_details">
          <div className="d-flex justify-content-between flex-wrap flex-column flex-lg-row">
            <div className="add_template_info">
              <p>Template Name</p>
              <input
                type="text"
                value={templateName}
                onChange={(e) => setTemplateName(e.target.value)}
                required
              />
            </div>
            <div className="add_template_info">
              <p>Template Description</p>
              <input
                type="text"
                value={templateDesc}
                onChange={(e) => setTemplateDesc(e.target.value)}
              />
            </div>
            {/* <div className="add_template_info" >
              <p>Publication IDs</p>
              <input
                type="text"
                value={publicationIds}
                onChange={(e) => setPublicationIds(e.target.value)}
                
              />
            </div> */}
          </div>
          <div className="d-md-flex justify-content-between">
            <div className="template_add_search">
              <label>
                Search:
                <input
                  type="search"
                  placeholder=""
                  aria-controls="datatable"
                  className="search_temp"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                ></input>
              </label>
            </div>
            <div className="new_template_btns">
              <button>
                <img
                  src="/build/up.svg"
                  alt="up"
                  width={14}
                  height={24}
                  className="template_action_btn"
                />
              </button>
              <button>
                <img
                  src="/build/down.svg"
                  alt="down"
                  width={14}
                  height={24}
                  className="template_action_btn"
                />
              </button>
              <button>
                <img
                  src="/build/trashcan.svg"
                  alt="delete"
                  width={14}
                  height={24}
                  className="template_action_btn"
                />
              </button>
            </div>
          </div>
          <div className="admin_template_table data-table-wrapper">
            <DataTable
              columns={columns}
              data={filteredData}
              defaultSortFieldId={1}
              pagination
              highlightOnHover
              customStyles={customStyles}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddTemplate;
