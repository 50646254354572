import "./PackageLayout.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import Select from "react-select";
import { useEffect, useState } from "react";
import Instance from "../../Utils/Axios";
import { showToast } from "../../Utils/index";

const PackageEdit = () => {
  const [showTable, setShowTable] = useState(true);
  const navigate = useNavigate();
  const [formData, setFormData] = useState([]);
  const [tempData, setTempData] = useState([]);
  const [distribution, setDistribution] = useState([]);
  const [vendorString, setVendorString] = useState("");
  const [vendordata, setVendorData] = useState("");
  const [vendorName, setVendorName] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState([]);

  const [currentFormData, setCurrentFormData] = useState({
    checkedOption: "",
    text: "",
    detailId: "",

    //  createdOn: "",

    // description: "",

    // packageId: "",

    // sequence: "",

    // status: "",
  });
  const { packageId } = useParams();

  const fetchVendorList = () => {
    const token = localStorage.getItem("adminToken");
    console.log("Fetched token from localStorage:", token);
    Instance.post("/siteadmin/vendor/getList", {
      adminToken: token,
      status: "",
      // vendorId: "",
      // credits: "",
      // status: "",
      // url: "",
      // name: "",
      // createdOn: "",
    })
      .then((response) => {
        var tempArr = [];
        response?.data?.message.map((dist) =>
          tempArr.push({
            label: dist.name,
            value: dist.vendorId,
          })
        );
        setDistribution(tempArr);

        setError("");
        console.log("check  vendor data", response?.data?.message);

        // var nameArr = [];
        // response?.data?.message.map((val) => {
        //   if (vendordata.includes(val.vendorId)) {
        //     nameArr.push(val.name);
        //   }
        // });
        // console.log("my array is ", nameArr);
        // setVendorName(nameArr);
      })
      .catch((error) => {
        setError("Failed to fetch the data");
        console.error("Error fetching vendor list:", error);
      });
  };
  useEffect(() => {
    fetchVendorList();
  }, []);

  // const options = [];

  // const data = tempData.map((val) => {
  //   options.push({
  //     value: val.vendorId,
  //     label: val.name,
  //   });
  // });

  const [PackageData, setPackageData] = useState({
    packageId: packageId,
    name: "",
    vendorId: "",
    price: "",
    pressReleaseCount: "",
    badge: "",
    type: "",
    subType: "",
    validity: "",
    description: [
      {
        checkedOption: "",

        createdOn: "",

        description: "",

        detailId: "",

        packageId: "",

        sequence: "",

        status: "",
      },
    ],
  });
  const [error, setError] = useState("");

  const fetchPackageDetails = async () => {
    try {
      const token = localStorage.getItem("adminToken");
      const response = await Instance.post(`/siteadmin/package/details`, {
        adminToken: token,
        packageId: packageId,
      });
      console.log("Response from  package detail  API:", response.data);

      //   pressReleaseCount: response.data.pressReleaseCount,
      //   status: response.data.status,
      //   description: [
      //     {
      //       checkedOption: response.data.description.checkedOption,
      //       packageId: response.data.description.packageId,
      //       detailId: response.data.description.detailId,
      //       sequence: response.data.description.sequence,
      //       status: response.data.description.status,
      //       createdOn: response.data.description.createdOn,
      //       description: response.data.description.description,
      //     },
      //   ],
      //   packageName: response.data.packageName,
      //   vendorId: response.data.vendorId,
      //   validity: response.data.validity,
      //   subType: response.data.subType,
      //   badge: response.data.badge,
      //   price: response.data.price,
      //   type: response.data.type,
      //   createdOn: response.data.createdOn,
      // });

      // console.log(
      //   "this is the package name : ",
      //   response.data.message[0].packageName
      // );
      setPackageData({
        packageId: packageId,
        name: response.data.message.packageName,
        vendorId: response.data.message.vendorId,
        price: response.data.message.price,
        pressReleaseCount: response.data.message.pressReleaseCount,
        badge: response.data.message.badge,
        type: response.data.message.type,
        subType: response.data.message.subType,
        validity: response.data.message.validity,
        description: response.data.message.description,
      });
      const arr = [];
      response.data.message.description?.map((val) => {
        arr.push({
          checkedOption: val.checkedOption,
          text: val.description,
          detailId: val.detailId,
        });
      });

      setFormData(arr);
      console.log("formdata :", formData);
      var vendor = (response?.data?.message?.vendorId).split("|");
      var mainArr = [];
      distribution.map((ven) => {
        if (vendor.includes(ven.value)) {
          mainArr.push(ven);
        }
      });

      setSelectedVendor(mainArr);

      setError("");
    } catch (error) {
      setError("Failed to fetch edit details");
      console.error("Error fetching package details for edit:", error);
    }
  };

  useEffect(() => {
    fetchPackageDetails();
  }, [distribution]);

  const handleChange = (e) => {
    setPackageData({
      ...PackageData,
      [e.target.name]: e.target.value,
    });
  };

  if (!PackageData) {
    return <p>Loading</p>;
  }

  const handleDescriptionChange = (e) => {
    const { name, value } = e.target;
    setCurrentFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleButtonClick = (e) => {
    e.preventDefault();
    const {
      checkedOption,
      // createdOn,
      text,
      detailId,
      // packageId,
      // sequence,
      // status,
    } = currentFormData;
    if (checkedOption && text) {
      setFormData((prevData) => [
        ...prevData,
        {
          checkedOption,

          text,
          detailId,
        },
      ]);
      setShowTable(true);
    } else {
      showToast("Please fill in all the fields.");
    }
  };

  const handleDelete = (index) => {
    const updatedData = formData.filter((item, idx) => idx !== index);
    setFormData(updatedData);
    if (updatedData.length === 0) {
      setShowTable(false);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    // PackageData.description = formData;
    try {
      const token = localStorage.getItem("adminToken");
      const response = await Instance.post(`/siteadmin/package/update`, {
        adminToken: token,
        packageId: packageId,
        name: PackageData.name,
        vendorId: vendorString,
        price: PackageData.price,
        pressReleaseCount: PackageData.pressReleaseCount,
        badge: PackageData.badge,
        type: PackageData.type,
        subType: PackageData.subType,
        validity: PackageData.validity,
        description: formData,
        // description: PackageData.description,
      });
      console.log("Response from updatePackage API:", response.data);
      if (response.data.resCode === "200") {
        showToast("Updated Succesfully!", "success", "success");
        setTimeout(() => {
          navigate("/admin/package");
        }, 1000);
      } else {
        setError(response.data.resMessage);
      }
    } catch (error) {
      setError("Failed to update package details");
      console.error("Error updating package details:", error);
    }
  };

  const handleMultSelect = (obj) => {
    setSelectedVendor(obj);
    const tempArr = obj.map((ar) => ar.value);
    var str = "";
    tempArr.map((val, indx) => {
      if (indx <= tempArr.length - 2) {
        str = str + val + "|";
      }
      if (indx === tempArr.length - 1) {
        str = str + val;
      }
    });
    setVendorString(str);
    console.log(str, "sssssssssssssss");
  };

  // console.log("aaaaaaa", selectedVendor);
  return (
    <>
      <div className="package_pages_layout">
        <div className="package_page_heading">
          <h4 className="addpack"> Edit Package </h4>
          <div className="admin_package_btns">
            <Link to="/admin/package">
              <span style={{ cursor: "pointer" }}>
                <div className="backbutton"></div>
              </span>
            </Link>
          </div>
        </div>
        <div className="add_package_details">
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Package Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Package Name"
                  value={PackageData.name}
                  onChange={handleChange}
                  required
                />
              </Col>
            </Row>
            <Row>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Select Option</Form.Label>
                <Form.Select
                  name="checkedOption"
                  value={currentFormData.checkedOption}
                  onChange={handleDescriptionChange}
                >
                  <option value="">Select Option</option>
                  <option value="CHECKED">Check Option</option>
                  <option value="CROSS">Remove Option</option>
                </Form.Select>
              </Col>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  name="text"
                  placeholder="Enter Description"
                  rows={2}
                  value={currentFormData.text}
                  onChange={handleDescriptionChange}
                />
              </Col>
            </Row>
            <button className="add_package_des_btn" onClick={handleButtonClick}>
              Add Package Description
            </button>

            <div className={` ${showTable ? "d-block" : "d-none"} `}>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Selected Option</th>
                    <th>Description</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {formData.map((data, index) => (
                    <tr key={index}>
                      <td>{data.checkedOption}</td>
                      <td>{data.text}</td>
                      <td>
                        <button
                          className="show_table_delete_btn"
                          onClick={() => handleDelete(index)}
                        >
                          Delete Record
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            <Row>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Select Distribution</Form.Label>
                <Select
                  options={distribution}
                  placeholder="Select Distribution Options"
                  isMulti
                  closeMenuOnSelect={false}
                  value={selectedVendor}
                  onChange={(cl) => handleMultSelect(cl)}
                  // onChange={(e) => {
                  //   console.log(e);
                  // }}
                />
              </Col>
            </Row>
            <Row>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Price</Form.Label>
                <Form.Control
                  type="number"
                  name="price"
                  value={PackageData.price}
                  onChange={handleChange}
                />
              </Col>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Number Press Release</Form.Label>
                <Form.Control
                  type="number"
                  name="pressReleaseCount"
                  value={PackageData.pressReleaseCount}
                  onChange={handleChange}
                  // placeholder="Number Press Release"
                />
              </Col>
            </Row>
            <Row>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>
                  Offline / Online Package [Show on frontend or not]
                </Form.Label>
                <Form.Select
                  as="select"
                  name="type"
                  value={PackageData.type}
                  onChange={handleChange}
                >
                  <option value="">
                    Please Select Offline / Online Package
                  </option>
                  <option value="ONLINE">Online Package</option>
                  <option value="OFFLINE">Offline Package</option>
                </Form.Select>
              </Col>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Package Validity</Form.Label>
                <Form.Control
                  type="number"
                  name="validity"
                  value={PackageData.validity}
                  onChange={handleChange}

                  // placeholder="Enter Package Validity like days 30/50"
                />
              </Col>
            </Row>
            <Row>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Select Badge</Form.Label>
                <Form.Select
                  as="select"
                  name="badge"
                  value={PackageData.badge}
                  onChange={handleChange}
                >
                  <option value="">Select Badge</option>
                  <option value="5">5 CREDITS</option>
                  <option value="20">20 CREDITS</option>
                  <option value="50">50 CREDITS</option>
                </Form.Select>
              </Col>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Select Reseller/Normal Package</Form.Label>
                <Form.Select
                  as="select"
                  name="subType"
                  value={PackageData.subType}
                  onChange={handleChange}
                >
                  <option value="NORMAL">Normal Package</option>
                  <option value="RESELLER">Reseller Package</option>
                  <option value="CRYPTO">Crypto Package</option>
                  <option value="INDIAN">Indian PR Package</option>
                  <option value="WHITELABEL"> White Label Package</option>
                </Form.Select>
              </Col>
            </Row>
            <button className="add_package_submit_btn" type="submit">
              Update Package
            </button>
          </Form>
        </div>
      </div>
    </>
  );
};

export default PackageEdit;
