import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import Instance from "../../Utils/Axios";

const CouponHistory = () => {
  const { couponId } = useParams();
  const [couponList, setCouponList] = useState([]);
  const [error, setError] = useState("");
  const columns = [
    {
      name: "Coupon Code",
      selector: (row) => row.code,
      sortable: true,
      width: "130px",
      wrap: true,
    },
    {
      name: "Discount",
      selector: (row) => row.discount,
      wrap: true,

      sortable: true,
      width: "120px",
    },

    {
      name: "Applicable to Packages",
      selector: (row) =>
        row?.packageDetails?.map((val) => {
          return (
            <>
              <div>{val.name}</div>
            </>
          );
        }),
      sortable: true,
      width: "180px",
      wrap: true,
    },
    {
      name: "Applicable to users",
      selector: (row) =>
        row?.userDetails?.map((val) => {
          return (
            <>
              {" "}
              <div>{val.name}</div>{" "}
            </>
          );
        }),
      sortable: true,
      width: "180px",
      wrap: true,
    },
    {
      name: `Maximum Number of times the 
      coupon can be used`,
      cell: (row) => row.usageLimit,
      width: "160px",
      wrap: true,
    },
    {
      name: "How many times the coupon used",
      cell: (row) => row.usedCount,
      width: "160px",
      wrap: true,
    },
    {
      name: "Start Date MM/DD/YYYY",
      cell: (row) => row.startDate,
      width: "130px",
      wrap: true,
    },
    {
      name: "End Date MM/DD/YYYY",
      cell: (row) => row.expiryDate,
      width: "130px",
      wrap: true,
    },
  ];

  const columns2 = [
    {
      name: `Maximum Number of times the 
      coupon can be used`,
      cell: (row) => row?.usageLimit,
      //   width: "160px",
      wrap: true,
    },
    {
      name: "How many times the coupon is used",
      cell: (row) => row.usedCount,
      //   width: "160px",
      wrap: true,
    },
    {
      name: "How many times still the coupon can be used",
      cell: (row) => row.remainingUsage,
      //   width: "130px",
      wrap: true,
    },
  ];

  useEffect(() => {
    const fetchCouponDetails = async () => {
      try {
        const token = localStorage.getItem("adminToken");
        const response = await Instance.post(`/siteadmin/coupons/details`, {
          adminToken: token,
          couponId: couponId,
        });
        console.log("Response from Company API:", response.data);
        setCouponList([response?.data?.message]);
        setError("");
      } catch (error) {
        setError("Failed to fetch company details");
        console.error("Error fetching company details for edit:", error);
      }
    };

    fetchCouponDetails();
  }, [couponId]);

  return (
    <>
      <div className="coupon_pages_layout">
        <div className="coupon_page_heading">
          <h4> View Coupon History </h4>
          <div className="admin_coupon_btns">
            <Link to="/admin/coupons">
              <span style={{ cursor: "pointer" }}>
                <div className="backbutton"></div>
              </span>
            </Link>
          </div>
        </div>

        <div className="mb-3 mt-5">Coupon History</div>
        <DataTable
          className="data-table-wrapper"
          columns={columns}
          data={couponList}
          //   pagination
          responsive={true}
          defaultSortFieldId={1}
          defaultSortAsc={true}
        />
      </div>
      <div className="coupon_pages_layout">
        <DataTable
          className="data-table-wrapper"
          columns={columns2}
          data={couponList}
          //   pagination
          responsive={true}
          defaultSortFieldId={1}
          defaultSortAsc={true}
        />
      </div>
    </>
  );
};

export default CouponHistory;
