import FormLayout from "./FormLayout";
import "./ProfileLayout.css";

export default function MyProfile() {
  return (
    <>
      <div className="profile_container">
        <div className="rectangle_one"></div>
        <img
          className={"blank_photo"}
          src="/Profile_Pic.svg"
          alt="zex pr wire Logo"
          width={154}
          height={154}
          priority
        />
      </div>
      <div className="content_container">
        <FormLayout />
      </div>
    </>
  );
}
