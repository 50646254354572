import React from "react";
import Navbar from "../../components/NavbarProfile/NavbarProfile";
import Sidebar from "../../components/SidebarNew/Sidebar";
import Footer from "../../components/Layouts/Footer";
import "./RejectedPressRelease.css";
import PressReleaseRejected from "../../components/EditorialManagment/PressReleaseRejected";

const RejectedPressRelease = () => {
  const activeEventKey = "2";
  const activeSide = 9;
  return (
    <>
      <div className="row gx-0 Rejected_press_content">
        <Sidebar activeSide={activeSide} activeEventKey={activeEventKey} />
        <div
          className={`main col d-flex flex-column`}
          style={{ overflow: "auto" }}
        >
          <Navbar />
          <div className="flex-grow-1">
            <PressReleaseRejected />
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default RejectedPressRelease;
