import { Link, useParams } from "react-router-dom";
import "./PackageLayout.css";
import DataTable from "react-data-table-component";
import { useState } from "react";
import Instance from "../../Utils/Axios";

const PackageDetail = () => {
  const [error, setError] = useState("");
  const { packageId } = useParams();
  const [packageData, setPackageData] = useState([]);
  const [tempData, setTempData] = useState([]);

  const [packageUser, setPackageUser] = useState([]);
  const [userData, setUserData] = useState([]);

  const [prCount, setPrCount] = useState(null);
  const [userCount, setUserCount] = useState(null);

  console.log(packageId);
  const columns = [
    {
      name: "Package Name",
      selector: (row) => row.packageName,
      sortable: true,
      wrap: true,
    },
    {
      name: "Price",
      selector: (row) => row.price,
      sortable: true,
      wrap: true,
    },
    {
      name: "Press Limit",
      selector: (row) => row.pressReleaseCount,
      sortable: true,
      wrap: true,
    },
    {
      name: "Package Validity",
      selector: (row) => row.validity,
      sortable: true,
      wrap: true,
    },
    {
      name: "Distribution Name",
      selector: (row) => row.vendorName,
      sortable: true,
      wrap: true,
    },
    {
      name: "	which offer applicable",
      selector: (row) => row.offer,
      sortable: true,
      wrap: true,
    },
  ];
  const columns2 = [
    {
      name: "PR Title",
      selector: (row) => row.prTitle,
      sortable: true,
      width: "80%",
      wrap: true,
    },
    {
      name: "PR Status",
      selector: (row) => row.prStatus,
      sortable: true,
      wrap: true,
    },
  ];
  const columns3 = [
    {
      name: "User Name",
      selector: (row) => row.UserName,
      sortable: true,
      width: "80%",
      wrap: true,
    },
    {
      name: "left Credits",
      selector: (row) => row.leftCredit,
      sortable: true,
      wrap: true,
    },
  ];

  const fetchPackageHistory = async () => {
    try {
      const token = localStorage.getItem("adminToken");
      const response = await Instance.post(`/siteadmin/package/history`, {
        adminToken: token,
        id: packageId,
      });
      // console.log("Response from  package History  API:", response.data);
      setPackageData(response?.data?.message?.packageUsedPR);
      setTempData(response?.data?.message?.packageUsedPR);
      setPackageUser(response?.data?.message?.packageUsedUser);
      setUserData(response?.data?.message?.packageUsedUser);
      setPrCount(response?.data?.packageUsedPRCount);
      setUserCount(response?.data?.packageUsedUserCount);

      setError("");
    } catch (error) {
      setError("Failed to fetch edit details");
      console.error("Error fetching package details for edit:", error);
    }
  };

  useState(() => {
    fetchPackageHistory();
  }, []);

  const filterData = (e) => {
    let filterText = e.target.value;
    if (!filterText) {
      setTempData([...packageData]);
    } else {
      const filteredItems = packageData.filter(
        (item) =>
          // (item.packageName &&
          //   item.packageName
          //     .toLowerCase()
          //     .includes(filterText.toLowerCase())) ||
          // (item.pressReleaseCount &&
          //   item.pressReleaseCount
          //     .toLowerCase()
          //     .includes(filterText.toLowerCase())) ||
          // (item.price &&
          //   item.price.toLowerCase().includes(filterText.toLowerCase())) ||
          // (item.validity &&
          //   item.validity.toLowerCase().includes(filterText.toLowerCase())) ||
          // (item.vendorName &&
          //   item.vendorName.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.prTitle &&
            item.prTitle.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.prStatus &&
            item.prStatus.toLowerCase().includes(filterText.toLowerCase()))
      );
      setTempData([...filteredItems]);
    }
  };
  const handleFilter = (e) => {
    let filterText = e.target.value;
    if (!filterText) {
      setUserData([...packageUser]);
    } else {
      const filteredItems = packageUser.filter(
        (item) =>
          (item.UserName &&
            item.UserName.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.leftCredit &&
            item.leftCredit.toLowerCase().includes(filterText.toLowerCase()))
      );
      setUserData([...filteredItems]);
    }
  };

  return (
    <>
      <div className="package_pages_layout">
        <div className="package_page_heading">
          <h4> View Package History </h4>
          <div className="admin_package_btns">
            <div className="package_detail_status">Active</div>
            <Link to="/admin/package">
              <span style={{ cursor: "pointer" }}>
                <div className="backbutton"></div>
              </span>
            </Link>
          </div>
        </div>
        <div className="package_history_box">
          <h4 className="package_history_text">Package Information</h4>
          <DataTable
            className="data-table-wrapper"
            columns={columns}
            data={packageData}
            responsive={true}
            defaultSortAsc={true}
          />
        </div>
        <div className="package_history_box">
          <div className="d-flex justify-content-between mb-2">
            <h4 className="package_history_text">
              Number of PR use this package. :- {prCount}
            </h4>
            <div className="staff_search">
              <label>
                Search:
                <input
                  type="search"
                  placeholder=""
                  aria-controls="datatable"
                  onChange={filterData}
                ></input>
              </label>
            </div>
          </div>
          <DataTable
            className="data-table-wrapper"
            columns={columns2}
            data={tempData}
            responsive={true}
            defaultSortAsc={true}
          />
        </div>
        <div className="package_history_box">
          <div className="d-flex justify-content-between mb-2">
            <h4 className="package_history_text">
              Total user use this package :- {userCount}
            </h4>
            <div className="staff_search">
              <label>
                Search:
                <input
                  type="search"
                  placeholder=""
                  aria-controls="datatable"
                  onChange={handleFilter}
                ></input>
              </label>
            </div>
          </div>
          <DataTable
            className="data-table-wrapper"
            columns={columns3}
            data={userData}
            responsive={true}
            defaultSortAsc={true}
          />
        </div>
      </div>
    </>
  );
};
export default PackageDetail;
