import "./AffiliateLayout.css";
import { Link } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

const AddSource = () => {
  return (
    <>
      <div className="affiliate_pages_layout">
        <div className="affiliate_page_heading">
          <h4> Add / Edit Source </h4>
          <Link to="/admin/affiliate_sources">
            <span style={{ cursor: "pointer" }}>
              <div className="backbutton"></div>
            </span>
          </Link>
        </div>
        <div className="view_application_detail">
          <Form>
            <Row>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Source Name</Form.Label>
                <Form.Control
                  type="text"
                  name="sourceName"
                  //   value={formData.title}
                  //   onChange={handleChange}
                />
              </Col>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Domain Name</Form.Label>
                <Form.Control
                  type="text"
                  name="domainName"
                  //   value={formData.name}
                  //   onChange={handleChange}
                  required
                />
              </Col>
            </Row>
            <div className="d-flex justify-content-center gap-5">
              <button className="view_action_btn">Cancel</button>
              <button className="view_action_btn">Add</button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddSource;
