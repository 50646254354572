import "./CompanyLayout.css";
import { Link, useNavigate } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useState, useEffect } from "react";
import { showToast } from "../../Utils/index";
import Instance from "../../Utils/Axios";
import axios from "axios";

const AddCompany = () => {
  const navigate = useNavigate();

  const [error, setError] = useState("");
  const [formData, setFormData] = useState({
    contactPerson: "",
    name: "",
    email: "",
    mobile: "",
    website: "",
    address: "",
    state: "",
    country: "",
    city: "",
    createdBy: "",
  });
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [userList, setUserList] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  //     console.log("Fetched token from localStorage:", token);
  //     Instance.post("/siteadmin/common/countryAdd", {
  //       adminToken: token,
  //       countryCode:"",
  //     countryName: "",
  //     isdCode:"",
  //     gmtMinute:"",
  //     currency: ""
  //     })
  //     .then((response) => {
  //         setAddCountry(response?.data?.message);
  //       setTempData(response?.data?.message);
  //       setError("");
  //       console.log("check data", response?.data?.message);
  //     })
  //     .catch((error) => {
  //       setError("Failed to fetch the data");
  //       console.error("Error fetching country list:", error);
  //     });
  //   };

  const addNewCompany = (e) => {
    e.preventDefault();
    const token = localStorage.getItem("adminToken");

    Instance.post("/siteadmin/user/company/create", {
      adminToken: token,
      ...formData,
    })
      .then((response) => {
        if (response.data.resCode === "100") {
          showToast("Already a member", "error", "error");
          setError(response.data.resMessage);
          console.log("Company added failed:", response.data);
        } else {
          console.log("Company added successfully:", response.data);
          showToast("Company added successfully", "success", "success");
          setError("");
          setTimeout(() => {
            navigate("/admin/company");
          }, 2000);
        }
      })
      .catch((error) => {
        showToast("Error", "error", "error");
        setError("Failed to add company");
        console.error("Error adding Company:", error);
      });
  };

  const fetchCountryList = () => {
    const token = localStorage.getItem("adminToken");
    Instance.post("/siteadmin/common/countryList", {
      adminToken: token,
      countryName: "",
      countryCode: "",
      isdCode: "",
      currency: "",
    })
      .then((response) => {
        setCountryList(response?.data?.message || []);
        setError("");
      })
      .catch((error) => {
        setError("Failed to fetch the data");
        console.error("Error fetching country list:", error);
      });
  };

  const fetchUserId = () => {
    const token = localStorage.getItem("adminToken");
    Instance.post("/siteadmin/user/list", {
      adminToken: token,
      status: "",
      fromDate: "",
      toDate: "",
      email: "",
      userId: "",
      country: "",
      state: "",
      city: "",
      mobileNumber: "",
      parentId: "",
    })
      .then((response) => {
        setUserList(response?.data?.message || []);
        setError("");
      })
      .catch((error) => {
        setError("Failed to fetch the data");
        console.error("Error fetching user list:", error);
      });
  };

  const fetchStateList = () => {
    const token = localStorage.getItem("adminToken");
    Instance.post("/siteadmin/common/getStates", {
      adminToken: token,
      code: "IN",
    })
      .then((response) => {
        setStateList(response?.data?.message || []);
        setError("");
      })
      .catch((error) => {
        setError("Failed to fetch the data");
        console.error("Error fetching state list:", error);
      });
  };

  useEffect(() => {
    fetchCountryList();
    fetchUserId();
    fetchStateList();
  }, []);

  return (
    <>
      <div className="company_pages_layout">
        <div className="company_page_heading">
          <h4 className=""> Add New Company </h4>
          <div className="admin_company_btns">
            <Link to="/admin/company" className="">
              <span style={{ cursor: "pointer" }}>
                <div className="backbutton"></div>
              </span>
            </Link>
          </div>
        </div>
        <div className="add_company_details">
          <Form onSubmit={addNewCompany}>
            <Row>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Contact Person Name</Form.Label>
                <Form.Control
                  type="text"
                  name="contactPerson"
                  value={formData.contactPerson}
                  onChange={handleChange}
                  required
                />
              </Col>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Company Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <Row>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="text"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </Col>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  type="text"
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <Row>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Company Website</Form.Label>
                <Form.Control
                  type="text"
                  name="website"
                  value={formData.website}
                  onChange={handleChange}
                  required
                />
              </Col>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Company Address</Form.Label>
                <Form.Control
                  type="text"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <Row>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>State</Form.Label>
                <Form.Select
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                >
                  <option value="">Select State</option>
                  {stateList.map((state) => (
                    <option key={state.id} value={state.value}>
                      {state.value}
                    </option>
                  ))}
                </Form.Select>
              </Col>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Country</Form.Label>
                <Form.Select
                  name="country"
                  value={formData.country}
                  onChange={handleChange}
                >
                  <option value="">Select Country</option>
                  {countryList.map((country) => (
                    <option key={country.countryId} value={country.countryName}>
                      {country.countryName}
                    </option>
                  ))}
                </Form.Select>
              </Col>
            </Row>
            <Row>
              <Col xs="6" className="custom_col_add_staff mb-4">
                <Form.Label>Users</Form.Label>
                <Form.Select
                  name="userId"
                  value={formData.userId}
                  onChange={handleChange}
                >
                  <option value="">Select User</option>
                  {userList.map((user) => (
                    <option key={user.userId} value={user.userId}>
                      {user.firstName} {user.lastName}
                    </option>
                  ))}
                </Form.Select>
              </Col>
            </Row>
            <button className="add_company_submit_btn" type="submit">
              Submit
            </button>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddCompany;
