import "./AnalyticsLayout.css";
import { Link, useParams } from "react-router-dom";
import { Table } from "react-bootstrap";
import DataTable from "react-data-table-component";

const UserHistory = () => {
  const columns1 = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      // width: "360px",
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
      sortable: true,
      // width: "110px",
    },
    {
      name: "Date of Signup (DOJ)",
      selector: (row) => row.date,
      sortable: true,
      // width: "190px",
    },
    {
      name: "Last Login",
      selector: (row) => row.last,
      sortable: true,
      // width: "130px",
    },
    {
      name: "Spending Amount",
      selector: (row) => row.spending,
      sortable: true,
      // width: "190px",
    },
  ];
  const columns = [
    {
      name: "Package Name",
      selector: (row) => row.name,
      sortable: true,
      // width: "360px",
    },
    {
      name: "Price",
      selector: (row) => row.price,
      sortable: true,
    },
    {
      name: "PR limit",
      selector: (row) => row.pr,
      sortable: true,
      // width: "110px",
    },
    {
      name: "How paid (reason)",
      selector: (row) => row.reason,
      sortable: true,
      // width: "190px",
    },
    {
      name: "Date Time",
      selector: (row) => row.times,
      sortable: true,
      // width: "130px",
    },
    {
      name: "Remaining Credits",
      selector: (row) => row.credits,
      sortable: true,
      // width: "190px",
    },
    {
      name: "Expiry Dates",
      selector: (row) => row.date,
      sortable: true,
      // width: "180px",
    },
  ];

  const columns2 = [
    {
      name: "Package Name",
      selector: (row) => row.name,
      sortable: true,
      width: "360px",
    },
    {
      name: "Price",
      selector: (row) => row.price,
      sortable: true,
    },
    {
      name: "PR limit",
      selector: (row) => row.pr,
      sortable: true,
      width: "110px",
    },
    {
      name: "Date Time",
      selector: (row) => row.times,
      sortable: true,
      width: "130px",
    },
    {
      name: "Remaining Credits",
      selector: (row) => row.credits,
      sortable: true,
      width: "190px",
    },
    {
      name: "Decrease PR",
      selector: (row) => row.decrease,
      sortable: true,
      width: "140px",
    },
    {
      name: "Decrease History",
      selector: (row) => row.history,
      sortable: true,
      width: "190px",
    },
  ];

  const columns3 = [
    {
      name: "Release Id",
      selector: (row) => row.release,
      sortable: true,
    },
    {
      name: "PR Status",
      selector: (row) => row.status,
      sortable: true,
    },
    {
      name: "Name of the release",
      selector: (row) => row.release,
      sortable: true,
      width: "360px",
    },
    {
      name: "Package",
      selector: (row) => row.package,
      sortable: true,
    },
    {
      name: "Date & Time",
      selector: (row) => row.credits,
      sortable: true,
    },
  ];
  return (
    <>
      <div className="affiliate_pages_layout">
        <div className="affiliate_page_heading">
          <h4>View User History</h4>
          <Link to={"/user"}>
            <span style={{ cursor: "pointer" }}>
              <div className="backbutton"></div>
            </span>
          </Link>
        </div>
        <div className="table_box">
          <h4>User History</h4>

          <div className="admin_analytics_table">
            <DataTable
              columns={columns1}
              //   data={tempData}
              data={[{}]}
              pagination
              defaultSortFieldId={1}
              responsive={true}
              defaultSortAsc={true}
              className="application_datatable mt-4"
            />
          </div>
        </div>
        <div className="table_box">
          <h4>Payment History</h4>
          <div className="staff_search mt-4">
            <label>
              Search:
              <input
                type="search"
                placeholder=""
                aria-controls="datatable"
                // onChange={filterData}
              ></input>
            </label>
          </div>
          <DataTable
            columns={columns}
            //   data={tempData}
            data={[{}]}
            pagination
            defaultSortFieldId={1}
            responsive={true}
            defaultSortAsc={true}
            className="application_datatable mt-4"
          />
        </div>
        <div className="table_box">
          <h4>Decrease PR</h4>
          <div className="staff_search mt-4">
            <label>
              Search:
              <input
                type="search"
                placeholder=""
                aria-controls="datatable"
                // onChange={filterData}
              ></input>
            </label>
          </div>
          <DataTable
            columns={columns2}
            //   data={tempData}
            data={[{}]}
            pagination
            defaultSortFieldId={1}
            responsive={true}
            defaultSortAsc={true}
            className="application_datatable mt-4"
          />
        </div>
        <div className="table_box">
          <h4>Press Release History</h4>
          <div className="staff_search mt-4">
            <label>
              Search:
              <input
                type="search"
                placeholder=""
                aria-controls="datatable"
                // onChange={filterData}
              ></input>
            </label>
          </div>
          <DataTable
            columns={columns3}
            //   data={tempData}
            data={[{}]}
            pagination
            defaultSortFieldId={1}
            responsive={true}
            defaultSortAsc={true}
            className="application_datatable mt-4"
          />
        </div>
      </div>
    </>
  );
};

export default UserHistory;
