import { useState, useRef, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Instance from "../../Utils/Axios";
import { showToast } from "../../Utils/index";
import { useParams, Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "./Publication.css";
import axios from "axios";

const PublicationUpdate = () => {
  const { publicationId } = useParams();
  const fileInputRef = useRef(null);
  const fileInputRefOne = useRef(null);
  const [tempData, setTempData] = useState([]);
  const [networkList, setNetworkList] = useState([]);
  const [fileData, setFile] = useState(null);
  const [dataUpdate, setDataUpdate] = useState({
    status: "",
    publicationName: "",
    publicationUrl: "",
    networkName: "",
    region: "",
    audience: "",
    networkId: "0",
    logo: "",
    premium: 0,
  });

  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPublicationDetails = async () => {
      try {
        const token = localStorage.getItem("adminToken");
        const response = await Instance.post(`/siteadmin/publication/details`, {
          adminToken: token,
          publicationId: publicationId,
        });
        console.log("Response from pulicationdetails API:", response.data);

        setDataUpdate({
          publicationName: response.data.publicationName,
          publicationUrl: response.data.publicationUrl,
          networkId: response.data.networkId,
          networkName: response.data.networkName,
          region: response.data.region,
          audience: response.data.audience,
          status: response.data.status,
          premium: response.data.premium,
          logo: response.data.logo,
        });
        setError("");
      } catch (error) {
        setError("Failed to fetch publication details");
        console.error("Error fetching publication details for edit:", error);
      }
    };

    const fetchNetworkList = async () => {
      const token = localStorage.getItem("adminToken");
      console.log("Fetched token from localStorage:", token);

      if (!token) {
        setError("Admin token is missing");
        console.error("Admin token is missing");
        return;
      }

      try {
        const response = await Instance.post("/siteadmin/network/getList", {
          adminToken: token,
          networkName: "",
          networkId: "0",
        });

        console.log("API response:", response);

        if (response.data && response.data.message) {
          setNetworkList(response.data.message);
          setTempData(response.data.message);
          setError("");
        } else {
          setError("No network data found");
          console.log("No network data found");
        }
      } catch (error) {
        setError("Failed to fetch the data");
        console.error(
          "Error fetching network list:",
          error.response ? error.response.data : error.message
        );
      }
    };

    // const fetchImage = async (documentId) => {
    //   try {
    //     const response = await axios.get(
    //       `https://pradmin.webandapi.com//siteadmin/upload/document/${documentId}`,
    //       {
    //         responseType: "blob",
    //       }
    //     );

    //     // Create a URL for the image blob
    //     const logo = URL.createObjectURL(response.data);

    //     setDataUpdate((prevData) => ({
    //       ...prevData,
    //       logo,
    //     }));
    //   } catch (error) {
    //     setError("Failed to fetch the image");
    //     console.error(
    //       "Error fetching image:",
    //       error.response ? error.response.data : error.message
    //     );
    //   }
    // };

    fetchPublicationDetails()
      .then(() => {
        fetchNetworkList();
        // fetchImage();
      })
      .catch((error) => {
        // Handle error
      });

    // fetchPublicationDetails();
  }, [publicationId]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setDataUpdate((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleFileUploadClick = () => {
    fileInputRefOne.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setDataUpdate((prevData) => ({
      ...prevData,
      logo: file,
    }));
  };

  const handleFileChangeLogo = (event) => {
    const token = localStorage.getItem("adminToken");
    const file = event.target.files[0];
    setFile(file);
    //alert('h');
    const formDataToSubmit = new FormData();
    formDataToSubmit.append("adminToken", token);
    formDataToSubmit.append("fileUpload", file);

    axios
      .post(
        "https://pradmin.webandapi.com//siteadmin/upload/document",
        formDataToSubmit,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        if (response?.data?.resCode == "200") {
          setDataUpdate((prevData) => ({
            ...prevData,
            logo: response.data.documentUrl,
          }));
        }
      })
      .catch((error) => {
        setError("Failed to fetch the data");
        console.error("Error fetching network list:", error);
      });
    console.log(file);
  };

  const updatePublication = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("adminToken");

      const formDataToSubmit = new FormData();
      formDataToSubmit.append("adminToken", token);
      formDataToSubmit.append("publicationId", publicationId);
      formDataToSubmit.append("publicationName", dataUpdate.publicationName);
      formDataToSubmit.append("publicationUrl", dataUpdate.publicationUrl);
      formDataToSubmit.append("networkId", dataUpdate.networkId);
      formDataToSubmit.append("networkName", dataUpdate.networkName);
      formDataToSubmit.append("region", dataUpdate.region);
      formDataToSubmit.append("audience", dataUpdate.audience);
      formDataToSubmit.append("status", dataUpdate.status);
      formDataToSubmit.append("premium", dataUpdate.premium ? 1 : 0);
      formDataToSubmit.append("logo", dataUpdate.logo);
      // if (dataUpdate.logo instanceof File) {
      //   formDataToSubmit.append("logo", dataUpdate.logo);
      // }

      const response = await Instance.post(
        "/siteadmin/publication/update",
        formDataToSubmit
      );

      console.log("Response from update API:", response.data);

      if (response.data.resCode === "200") {
        showToast("Publication updated successfully", "success", "success");
        setTimeout(() => {
          navigate("/admin/publication");
        }, 2000);
        setError("");
      } else {
        showToast("Error updating publication", "error", "error");
        setError(response.data.resMessage || "Error updating publication");
        console.log("Publication update failed:", response.data);
      }
    } catch (error) {
      showToast("Error", "error", "error");
      setError("Failed to update publication");
      console.error("Error updating publication:", error);
    }
  };

  return (
    <>
      <div className="admin_network_layout">
        <div className="network_page_heading">
          <h4 className="pub_heading">Edit Publication</h4>
          <div className="admin_network_btns">
            <Link to={"/admin/publication"}>
              <span style={{ cursor: "pointer" }}>
                <div className="backbutton"></div>
              </span>
            </Link>
          </div>
        </div>
        <div className="add_new_staff">
          <Form onSubmit={updatePublication}>
            <Row>
              <Tabs
                defaultActiveKey="Individual"
                transition={false}
                id="noanim-tab-example"
                className="mb-3"
              >
                <Tab
                  eventKey="Individual"
                  title="Individual"
                  style={{ marginTop: "15px" }}
                >
                  <Row>
                    <Col className="custom_col_add_staff mb-4">
                      <Form.Label>Publication Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="publicationName"
                        value={dataUpdate.publicationName}
                        onChange={handleChange}
                        required
                      />
                    </Col>
                    <Col className="custom_col_add_staff mb-4">
                      <Form.Label>Publication URL</Form.Label>
                      <Form.Control
                        type="text"
                        name="publicationUrl"
                        value={dataUpdate.publicationUrl}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="custom_col_add_staff mb-4">
                      <Form.Label>Region / Language</Form.Label>
                      <Form.Control
                        type="text"
                        name="region"
                        value={dataUpdate.region}
                        onChange={handleChange}
                      />
                    </Col>
                    <Col className="custom_col_add_staff mb-4">
                      <Form.Label>Estimated Audience</Form.Label>
                      <Form.Control
                        type="text"
                        name="audience"
                        value={dataUpdate.audience}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="custom_col_add_staff mb-4">
                      <Form.Label>Network Name</Form.Label>
                      <Form.Select
                        aria-label="Please select"
                        name="networkId"
                        value={dataUpdate.networkId}
                        onChange={handleChange}
                      >
                        <option value="0">Please select</option>
                        {networkList.map((network) => (
                          <option
                            key={network.networkId}
                            value={network.networkId}
                          >
                            {network.networkName}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>

                    <Col xs="6" className="custom_col_add_staff mb-4">
                      <Form.Label>Publications Logo</Form.Label>
                      <div className="d-flex gap-4">
                        <Form.Control
                          type="text"
                          value={dataUpdate.logo || ""}
                          // value={dataUpdate.logo && typeof dataUpdate.logo === 'object' ? dataUpdate.logo.name : dataUpdate.logo}
                          readOnly
                        />

                        <button
                          className="network_upload_btn"
                          type="button"
                          onClick={handleFileUploadClick}
                        >
                          <img
                            src="/build/upload.svg"
                            alt="upload"
                            width={20}
                            height={20}
                          />
                        </button>
                        <input
                          type="file"
                          ref={fileInputRefOne}
                          style={{ display: "none" }}
                          accept=".png, .jpg, .jpeg"
                          onChange={handleFileChangeLogo}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="6" className="custom_col_add_staff mb-4">
                      <Form.Label>Status</Form.Label>
                      <Form.Select
                        aria-label="Please select"
                        name="status"
                        value={dataUpdate.status}
                        onChange={handleChange}
                      >
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                      <Form.Check
                        type="checkbox"
                        label="Premium Publication"
                        name="premium"
                        checked={!!dataUpdate.premium}
                        onChange={(e) =>
                          setDataUpdate((prevData) => ({
                            ...prevData,
                            premium: e.target.checked ? 1 : 0,
                          }))
                        }
                      />
                    </Form.Group>
                  </Row>
                </Tab>
              </Tabs>
            </Row>
            <Button className="add_staff_submit_btn" type="submit">
              Update
            </Button>
          </Form>
          {error && <p className="error_message">{error}</p>}
        </div>
      </div>
    </>
  );
};

export default PublicationUpdate;
