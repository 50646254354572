import React from "react";
import { Col, FormGroup, Row } from "react-bootstrap";
import { Link, useParams, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { useState, useEffect } from "react";
import Instance from "../../Utils/Axios";
import { showToast } from "../../Utils/index";

const PressReleaseEditCategory = () => {
  const [category, setCategory] = useState([]);
  const { categoryId } = useParams();
  const [error, setError] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategoryDetails = () => {
      const token = localStorage.getItem("adminToken");
      Instance.post("/siteadmin/category/details", {
        adminToken: token,
        categoryId: categoryId,
      })
        .then((response) => {
          setCategory(response.data.message[0]);
          setError("");
        })
        .catch((error) => {
          setError("Cannot fetch Category Details");
          console.error("cannot fetch Category details", error);
        });
    };
    fetchCategoryDetails();
  }, [categoryId]);

  const handleChange = (e) => {
    setCategory({
      ...category,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const token = localStorage.getItem("adminToken");
    Instance.post("/siteadmin/category/update", {
      adminToken: token,
      categoryId: categoryId,
      status: category.status,
      name: category.name,
    })
      .then((response) => {
        if (response.data.resCode === "200") {
          showToast("Updated Succesfully!", "success", "success");
          setTimeout(() => {
            navigate("/admin/editorial/category_press_release");
          }, 1000);
        } else {
          setError(response.data.resMessage);
        }
      })
      .catch((error) => {
        setError("Failed to update category details");
        console.error("Error updating category details:", error);
      });
    if (!category) {
      return <p>Loading</p>;
    }
  };
  return (
    <>
      <div className="admin_editorial_layout">
        <div className="page_heading">
          <h4>Edit Category</h4>
          <Link to="/admin/editorial/category_press_release">
            <span style={{ cursor: "pointer" }}>
              <div className="backbutton"></div>
            </span>
          </Link>
        </div>
        <div className="add_new_editorial">
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col className="col-10 custom_col_add_editorial mb-4">
                <Form.Label>Category Name</Form.Label>
                <Form.Control
                  type="text"
                  class="form-control"
                  name="name"
                  value={category.name}
                  onChange={handleChange}
                  required
                />
              </Col>
              <Col className="col-10 custom_col_add_editorial mb-4">
                <Form.Label>Status</Form.Label>
                <Form.Select
                  class="form-control"
                  name="status"
                  value={category.status}
                  onChange={handleChange}
                >
                  <option value="">Select Status</option>
                  <option value="ACTIVE">Active</option>
                  <option value="INACTIVE">Inactive</option>
                </Form.Select>
              </Col>
            </Row>

            <Row className="pb-5 ">
              <Col className=" ">
                <button
                  className="add_editorial_submit_btn"
                  type="submit"
                  // style={{ width: "300px" }}
                >
                  Update Category
                </button>
              </Col>
            </Row>
          </Form>
          {/* {error && <p className="error_message">{error}</p>} */}
        </div>
      </div>
    </>
  );
};
export default PressReleaseEditCategory;
