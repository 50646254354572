import "./ReportLayout.css";
import { Link, useNavigate } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import { useState, useEffect } from "react";
import Select from "react-select";
import Instance from "../../Utils/Axios";
import { showToast } from "../../Utils/index";
import DataTable from "react-data-table-component";

const AddReport = () => {
  const [templateData, setTemplateData] = useState([])

  useEffect(() => {
    const token = localStorage.getItem("adminToken");
    const getTemplateList = () => {
      Instance.post("/siteadmin/template/getList", {
        adminToken: token,
        status: "active",
      })
        .then((response) => {
          setTemplateData(response?.data?.message);
        })
        .catch((error) => {
          console.error("error fetching the template data", error);
        });
    };
    getTemplateList()
  }, []
  )
  const columns = [
    {
      name: <input type="checkbox" className="mt-1" />,
      selector: (row) =>
        /*<input type="checkbox" className="mt-1" />*/ row.input,
      sortable: false,
      width: "60px",
      wrap: true,
    },
    {
      name: "SI.No.",
      selector: (row) => row.id,
      sortable: true,
      width: "100px",
      wrap: true,
    },
    {
      name: "Publication Name",
      selector: (row) => row.publicationName,
      sortable: true,
      wrap: true,
    },
    {
      name: "Region / Language",
      selector: (row) => row.region,
      sortable: true,
      wrap: true,
    },
    {
      name: "Est. Audience",
      selector: (row) => row.audience,
      sortable: true,
      wrap: true,
    },
    {
      name: "Published URL",
      selector: (row) => row.publishedUrl,
      sortable: true,
      wrap: true,
    },
    {
      name: "Network name",
      selector: (row) => row.networkName,
      sortable: true,
      wrap: true,
    },
    {
      name: "Action",
      selector: (row) => row.action,
      sortable: false,
      wrap: true,
    },
  ];
  return (
    <>
      <div className="report_pages_layout">
        <div className="report_page_heading">
          <h4> Report Center </h4>
          <div className="admin_report_btns">
            <Link to="/admin/report">
              <span style={{ cursor: "pointer" }}>
                <div className="backbutton"></div>
              </span>
            </Link>
          </div>
        </div>
        <div className="add_report_details">
          <h4 className="report_detail_text">
            <span>Title : </span> ScottyTheAi Going Multi-chain, in Efforts to
            Complete the Blockchain Takeover.
          </h4>
          <h4 className="report_detail_text">
            <span>Package : </span> Digital Journal PR + G News Syndication +
            350 Links
          </h4>
          <ul className="add_report_process">
            <li className="active_step">
              <div>Step 1</div>
              Fetch Data
            </li>
            <li>
              <div>Step 2</div>
              Manual Insertion
            </li>
            <li>
              <div>Step 3</div>
              Analyze Risk
            </li>
            <li>
              <div>Step 4</div>
              Preview
            </li>
          </ul>
          <Row className="mb-4">
            <Col xs="4">
              <Form.Label>Select Template</Form.Label>
              <Form.Select as="select" name="template">
                <option value="">Please Select Template</option>
                {templateData.map((list) => (
                  <option value={list.templateId}>{list.templateName}</option>
                ))}

              </Form.Select>
            </Col>
            <Col xs="2" className="d-flex align-items-end">
              <button className="report_apply_btn">Apply </button>
            </Col>
            <Col xs="4">
              <Form.Label>Insert Post URL</Form.Label>
              <Form.Control type="text" name="post" />
            </Col>
            <Col xs="2" className="d-flex align-items-end">
              <button className="report_apply_btn">Fetch Data </button>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col xs="9">
              <Form.Label>Insert Sub-Address</Form.Label>
              <Form.Control type="text" name="address" />
            </Col>
            <Col xs="3" className="d-flex align-items-end">
              <button className="report_apply_btn">Fetch Data </button>
            </Col>
          </Row>
          <div className="report_post_box">
            <h4 className="report_detail_text">
              <span>Title : </span> Now you san See No Waste No Hunger posters
              in New York Cityand London
            </h4>
            <Row className="mb-3">
              <Col xs="9">
                <Form.Label>Post URL</Form.Label>
                <Form.Control type="text" name="url" />
              </Col>
              <Col xs="3">
                <Form.Label>Post ID</Form.Label>
                <Form.Control type="text" name="ID" />
              </Col>
            </Row>
            <div className="report_post_btns">
              <button className="report_apply_btn">Refresh </button>
              <button className="report_apply_btn">Fetch Data </button>
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <div className="report_add_search">
              <label>
                Search:
                <input
                  type="search"
                  placeholder=""
                  aria-controls="datatable"
                ></input>
              </label>
            </div>
            <div className="new_report_btns">
              <button>
                <img src="/build/up.svg" alt="up" width={14} height={24} />
              </button>
              <button>
                <img src="/build/down.svg" alt="down" width={14} height={24} />
              </button>
              <button>
                <img
                  src="/build/trashcan.svg"
                  alt="delete"
                  width={14}
                  height={24}
                />
              </button>
            </div>
          </div>
          <DataTable
            className="data-table-wrapper"
            columns={columns}
            data={[{}]}
            defaultSortFieldId={1}
            pagination
            highlightOnHover
          />
          <div className="d-flex justify-content-center gap-3 mt-3">
            <button className="report_create_btn">Previous </button>
            <button className="report_create_btn">Save Draft </button>
            <button className="report_create_btn">Next </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddReport;
