import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./ManageInvoice.css";
import Instance from "../../Utils/Axios";

import { Accordion, Button, Card, Form } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useAccordionButton } from "react-bootstrap/AccordionButton";

const InvoiceMainManagement = () => {
  const [accordionOpen, setAccordionOpen] = useState(false);
  const [error, setError] = useState("");
  const [invoiceList, setInvoiceList] = useState([]);
  const [tempData, setTempData] = useState([]);
  const [filters, setFilters] = useState({
    invoiceNumber: "",
    user: "",
    date: "",
  });

  const columns = [
    // {
    //   name: "SI.No",
    //   selector: (row) => row.No,
    //   sortable: true,
    // },
    {
      name: "Invoice No",
      selector: (row) => row.invoiceNumber,
      sortable: true,
      wrap: true,
    },

    {
      name: "User",
      selector: (row) => row.user,
      sortable: true,
      wrap: true,
    },
    {
      name: "Date",
      selector: (row) => row.date,
      sortable: true,
      wrap: true,
    },
    {
      name: "Download Invoice",
      //  selector: row => row.mobile,
      cell: (row) => (
        <>
          <img
            src="/build/download_icon.png"
            alt="download"
            width={24}
            height={24}
            priority
            className="me-2   "
            onClick={() => handleDownloadPDF(row.invoiceNumber)}
            style={{ cursor: "pointer" }}
          />
        </>
      ),
    },

    {
      name: "Upload Invoice",
      //  selector: row => row.mobile,
      cell: (row) => (
        <>
          <a href={`/admin/manage_invoice/upload`}>
            <img
              src="/build/upload_icon1.png"
              alt="upload"
              width={24}
              height={24}
              priority
              className="me-2  border-box"
            />
          </a>
        </>
      ),
    },
  ];
  const fetchInvoiceList = () => {
    const token = localStorage.getItem("adminToken");
    Instance.post("/siteadmin/invoice/getInvoice", {
      adminToken: token,
    })
      .then((response) => {
        setInvoiceList(response?.data?.message);
        setTempData(response?.data?.message);
        setError("");
        // console.log("check data", response?.data?.message);
      })
      .catch((error) => {
        setError("Failed to fetch the data");
        console.error("Error fetching Invoice list:", error);
      });
  };
  useEffect(() => {
    fetchInvoiceList();
  }, []);

  const handleDownloadPDF = (invoiceNumber) => {
    const token = localStorage.getItem("adminToken");

    Instance.post("/siteadmin/invoice/invoicePDF", {
      adminToken: token,
      invoiceNumber: invoiceNumber,
    })
      .then((response) => {
        const invoicePdf = response.data;
        const link = document.createElement("a");
        link.href = invoicePdf;
        link.download = `Invoice_${invoiceNumber}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error("Error downloading PDF:", error);
      });
  };

  const filterData = (e) => {
    let filterText = e.target.value;
    // console.log(filterText);

    if (!filterText) {
      setTempData([...invoiceList]);
    } else {
      const filteredItems = invoiceList.filter(
        (item) =>
          (item.user &&
            item.user.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.date &&
            item.date.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.invoiceNumber &&
            item.invoiceNumber.toLowerCase().includes(filterText.toLowerCase()))
      );
      setTempData([...filteredItems]);
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const applyFilters = () => {
    const token = localStorage.getItem("adminToken");
    Instance.post("/siteadmin/invoice/getInvoice", {
      adminToken: token,

      invoiceNumber: filters.invoiceNumber,
      user: filters.user,
      date: filters.date,
    })
      .then((response) => {
        setTempData(response?.data?.message);
        setError("");
        console.log("Filtered data:", response?.data?.message);
      })
      .catch((error) => {
        setError("Failed to apply filters");
        console.error("Error applying filters:", error);
      });
  };

  const clearFilters = () => {
    const token = localStorage.getItem("adminToken");
    Instance.post("/siteadmin/invoice/getInvoice", {
      adminToken: token,
      // status: filters.status,
      invoiceNumber: "",
      user: "",
      date: "",
    })
      .then((response) => {
        setFilters({
          invoiceNumber: "",
          user: "",
          date: "",
        });
        setInvoiceList(response?.data?.message);
        setTempData(response?.data?.message);
        setError("");
        console.log("Cleared filters. Updated data:", response?.data?.message);
      })
      .catch((error) => {
        setError("Failed to clear filters");
        console.error("Error clearing filters:", error);
      });
  };

  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      setAccordionOpen(!accordionOpen)
    );

    return (
      <button
        type="button"
        className="admin_filter_btn"
        // style={{ backgroundColor: "pink" }}
        onClick={decoratedOnClick}
      >
        {children}
        {accordionOpen ? (
          <img
            src="/build/img/Icons/down_vector.svg"
            alt="zex pr wire Logo"
            width={16}
            height={16}
          />
        ) : (
          <img
            src="/build/img/Icons/down_vector.svg"
            alt="zex pr wire Logo"
            width={16}
            height={16}
            className="filter_up_arrow"
          />
        )}
      </button>
    );
  }

  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(invoiceList[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  function downloadCSV(array) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = "export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  return (
    <>
      <div className="admin_invoice_layout">
        <div className="page_heading">
          <h4>Manage Invoice</h4>

          <Link to="#">
            <span style={{ cursor: "pointer" }}>
              <div className="backbutton"></div>
            </span>
          </Link>
        </div>

        <Accordion className="my-4">
          <Card>
            <Card.Header className="d-md-flex justify-content-between align-items-center">
              <div className="invoice_search">
                <label>
                  Search:
                  <input
                    type="search"
                    placeholder=""
                    aria-controls="datatable"
                    onChange={filterData}
                  ></input>
                </label>
              </div>
              <div className="d-md-flex">
                <button
                  onClick={(e) => downloadCSV(invoiceList)}
                  className="btn btn-export mx-3"
                >
                  <img
                    src="/build/img/Icons/export.svg"
                    alt="zex pr wire Logo"
                    width={25}
                    height={25}
                  />
                </button>
                <CustomToggle as={Button} variant="link" eventKey="0">
                  Filters
                </CustomToggle>
              </div>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Form>
                  <div className="d-flex gap-4 flex-wrap">
                    <Form.Group
                      controlId="filterUsername"
                      className="invoice_filter_options"
                    >
                      <Form.Label>User</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search User"
                        name="user"
                        value={filters.user}
                        onChange={handleFilterChange}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filterInvoiceNo"
                      className="invoice_filter_options"
                    >
                      <Form.Label>Invoice No</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search Invoice No"
                        name="invoiceNumber"
                        value={filters.invoiceNumber}
                        onChange={handleFilterChange}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filterDate"
                      className="invoice_filter_options"
                    >
                      <Form.Label>Date</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search Date"
                        name="date"
                        value={filters.date}
                        onChange={handleFilterChange}
                      ></Form.Control>
                    </Form.Group>

                    {/* <Form.Group
                      controlId="filterSINo"
                      className="invoice_filter_options"
                    >
                      <Form.Label>SI.No</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search SI.No"
                        name="Search SI.No"
                        // value={filters.email}
                        // onChange={handleFilterChange}
                      ></Form.Control>
                    </Form.Group> */}
                  </div>
                  <div className="mt-3 d-flex gap-3">
                    <Button variant="secondary" onClick={clearFilters}>
                      Clear
                    </Button>
                    <Button variant="primary" onClick={applyFilters}>
                      Apply
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>

        <DataTable
          className="data-table-wrapper"
          columns={columns}
          data={tempData}
          pagination
          defaultSortFieldId={1}
          responsive={true}
          defaultSortAsc={true}
        />
      </div>
    </>
  );
};

export default InvoiceMainManagement;
