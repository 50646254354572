import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import "./SupportLayout.css";

export default function NewTicket({ onShowTickets }) {
  return (
    <>
      <div className="new_ticket_layout">
        <Row>
          <Col className="custom_col_new_ticket mb-4">
            <Form.Label>Contact Name</Form.Label>
            <Form.Control placeholder=" Enter text here" />
          </Col>
        </Row>
        <Row>
          <Col xs="6" className="custom_col_new_ticket mb-4">
            <Form.Label>Email</Form.Label>
            <Form.Control placeholder="Enter text here" />
          </Col>
          <Col xs="2" className="custom_col_new_ticket mb-4">
            <Form.Label>Country Code</Form.Label>
            <Form.Control placeholder="IND | +91" />
          </Col>
          <Col xs="4" className="custom_col_new_ticket mb-4">
            <Form.Label>Phone Number</Form.Label>
            <Form.Control placeholder="Enter text here" />
          </Col>
        </Row>
        <Row>
          <Col className="custom_col_new_ticket mb-4">
            <Form.Label>Subject</Form.Label>
            <Form.Control placeholder="Enter text here" />
          </Col>
        </Row>
        <Row>
          <Col className="custom_col_new_ticket mb-4">
            <Form.Label>How may we help you?</Form.Label>
            <Form.Select>
              <option>Select from dropdown</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </Form.Select>
          </Col>
        </Row>
        <Row>
          <Col className="custom_col_new_ticket mb-4">
            <Form.Label>Description</Form.Label>
            <Form.Control as="textarea" rows={4} placeholder="Comment Box" />
          </Col>
        </Row>
        <div className="ticket_account_buttons">
          <button className="ticket_cancel_btn mb-4" onClick={onShowTickets}>
            Cancel
          </button>
          <button className="ticket_save_btn mb-4" onClick={onShowTickets}>
            Submit
          </button>
        </div>
      </div>
    </>
  );
}
