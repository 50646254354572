import "./NetworkPages.css";
import { Table } from "react-bootstrap";
import { useState, useEffect } from "react";
import Instance from "../../Utils/Axios";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import Modal from "react-bootstrap/Modal";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import { showToast } from "../../Utils/index";

const NetworkPage = () => {
  const [networkList, setNetworkList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [filteredTemplates, setFilteredTemplates] = useState([]);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [filters, setFilters] = useState({ status: "", audience: "" });
  const [accordionOpen, setAccordionOpen] = useState(false);
  const [selectedNetworkId, setSelectedNetworkId] = useState(null);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (networkId) => {
    setSelectedNetworkId(networkId);
    setShow(true);
  };

  const fetchNetworkList = () => {
    const token = localStorage.getItem("adminToken");
    console.log("Fetched token from localStorage:", token);
    Instance.post("/siteadmin/network/getList", {
      adminToken: token,
      status: "",
    })
      .then((response) => {
        setNetworkList(response?.data?.message);
        setFilteredTemplates(response?.data?.message);
        setLoading(false);
        setError("");
        console.log("Network data", response?.data?.message);
      })
      .catch((error) => {
        setLoading(false);
        setError("Failed to fetch the data");
        console.error("Error fetching network list:", error);
      });
  };

  useEffect(() => {
    fetchNetworkList();
  }, []);

  const deleteNetwork = () => {
    const updatedList = networkList.filter(
      (item) => item.networkId !== selectedNetworkId
    );
    setNetworkList(updatedList);
    setFilteredTemplates(updatedList);
    handleClose();
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  // const applyFilters = () => {
  //   let filteredData = [...networkList];

  //   filteredData = filteredData.filter((item) => {
  //     return filters.status ? item.status === filters.status : true;
  //   });

  //   if (filters.audience) {
  //     const [min, max] = filters.audience.split("-").map(Number);
  //     filteredData = filteredData.filter((item) => {
  //       const audience = parseInt(item.audience);
  //       return audience >= min && audience <= max;
  //     });
  //   }

  //   setFilteredTemplates(filteredData);
  // };
  const applyFilters = () => {
    const token = localStorage.getItem("adminToken");

    Instance.post("/siteadmin/network/getList", {
      adminToken: token,
      status: filters.status,
      audience: filters.audience,
    })
      .then((response) => {
        let filteredData = response?.data?.message || [];

        if (filters.audience) {
          const [min, max] = filters.audience.split("-").map(Number);
          filteredData = filteredData.filter((item) => {
            const audience = parseInt(item.audience, 10);
            return audience >= min && audience <= max;
          });
        }

        setFilteredTemplates(filteredData);
        setError();
        console.log("Filtered template data:", filteredData);
      })
      .catch((error) => {
        setError("Failed to apply filters");
        console.error("Error applying filter", error);
      });
  };

  const clearFilters = () => {
    const token = localStorage.getItem("adminToken");
    Instance.post("/siteadmin/network/getList", {
      adminToken: token,
      status: "",
      audience: "",
    })
      .then((response) => {
        setFilters({ status: "", audience: "" });
        setFilteredTemplates(response?.data?.message);
        setNetworkList(response?.data?.message);
        setError("");
      })
      .catch((error) => {
        setError("Failed to clear filters");
        console.error("Error clearing the filters", error);
      });
  };

  const handleAllChecked = (event) => {
    const checked = event.target.checked;
    setIsAllChecked(checked);
    setNetworkList((prevList) =>
      prevList.map((item) => ({ ...item, isChecked: checked }))
    );
    console.log("All checked:", checked);
    console.log("Network List:", networkList);
  };
  const handleCheckboxChange = (id) => {
    setNetworkList((prevList) =>
      prevList.map((item) =>
        item.networkId === id ? { ...item, isChecked: !item.isChecked } : item
      )
    );
    console.log("Checkbox changed for ID:", id);
    console.log("Network List:", networkList);
  };

  const handleSubmit = async (status, id) => {
    // e.preventDefault();
    try {
      const token = localStorage.getItem("adminToken");
      const response = await Instance.post(`/siteadmin/network/update`, {
        adminToken: token,
        networkId: id,
        status: status,
      });
      // console.log("Response from updateCoupon API:", response.data);
      if (response.data.resCode === "200") {
        fetchNetworkList();
        showToast(" Status Updated Succesfully!", "success", "success");
        // setTimeout(() => {
        //   // navigate("/category_press_release");
        //   window.location.reload();
        // }, 1000);
      } else {
        setError(response.data.resMessage);
      }
    } catch (error) {
      setError("Failed to update network details");
      console.error("Error updating network details:", error);
    }
  };

  const handleChecked = (event, status, id) => {
    const confirmed = window.confirm(
      `Are you sure you want to ${status === "active" ? "inactive" : "active"
      } status?`
    );

    if (status === "active" && confirmed === true) {
      handleSubmit("inactive", id);
    } else if (
      (status === "inactive" || status === "deactive" || status === "") &&
      confirmed === true
    ) {
      handleSubmit("active", id);
    }

    // else if (status === "DEACTIVE" && confirmed === true) {
    //   handleSubmit("ACTIVE", id);
    // }

    console.log("Switch value:", event.target.checked, status, id);
  };

  const columns = [
    {
      name: (
        <input
          type="checkbox"
          checked={isAllChecked}
          onChange={handleAllChecked}
          width={28}
          height={28}
        />
      ),
      cell: (row) => (
        <input
          type="checkbox"
          checked={row.isChecked}
          onChange={() => handleCheckboxChange(row.networkId)}
          width={28}
          height={28}
        />
      ),
      width: "50px",
    },
    {
      name: "SI.No.",
      // selector: (row, index) => index + 1,
      selector: (row) => parseInt(row.networkId),
      sortable: true,
      width: "90px",
      wrap: true,
    },
    {
      name: "Network Name",
      selector: (row) => row.networkName,
      sortable: true,
      width: "160px",
      wrap: true,
    },
    {
      name: "Region / Language",
      selector: (row) => row.region,
      sortable: true,
      width: "180px",
      wrap: true,
    },
    {
      name: "Est. Audience",
      selector: (row) => row.audience,
      sortable: true,
      width: "160px",
      wrap: true,
    },
    {
      name: "No. of Publications",
      selector: (row) => row.numOfPublication,
      sortable: true,
      width: "200px",
      wrap: true,
    },
    {
      name: "Status",
      cell: (row) => (
        <Form>
          <Form.Check
            type="switch"
            id="custom-switch"
            checked={row?.status === "active"}
            onChange={(e) => handleChecked(e, row.status, row.networkId)}
          />
        </Form>
      ),
      width: "120px",
      wrap: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <span>
          <Link to={`/admin/network/${row.networkId}`}>
            <img
              src="/build/watch.svg"
              alt="watch"
              width={24}
              height={24}
              className="me-2"
            />
          </Link>
          <a href={`/admin/network/edit/${row.networkId}`} target="_blank">
            <img
              src="/build/edit.svg"
              alt="edit"
              width={24}
              height={24}
              className="me-2"
            />
          </a>
        </span>
      ),
      width: "120px",
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        // fontSize: "18px",
      },
    },
  };

  const handleSearch = (e) => {
    let filterText = e.target.value.toLowerCase();
    const filteredItems = networkList.filter(
      (item) =>
        item.networkName.toLowerCase().includes(filterText) ||
        item.networkId.toLowerCase().includes(filterText) ||
        item.region.toLowerCase().includes(filterText) ||
        item.audience.toLowerCase().includes(filterText)
    );
    setFilteredTemplates(filteredItems);
  };

  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      setAccordionOpen(!accordionOpen)
    );

    return (
      <button
        type="button"
        className="admin_network_filter_btn"
        // style={{ backgroundColor: "pink" }}
        onClick={decoratedOnClick}
      >
        {children}
        {accordionOpen ? (
          <img
            src="/build/img/Icons/down_vector.svg"
            alt="zex pr wire Logo"
            width={16}
            height={16}
          />
        ) : (
          <img
            src="/build/img/Icons/down_vector.svg"
            alt="zex pr wire Logo"
            width={16}
            height={16}
            className="filter_up_arrow"
          />
        )}
      </button>
    );
  }

  // const handlePageClick = (event) => {
  //   setCurrentPage(event.selected);
  // };

  // const offset = currentPage * itemsPerPage;
  // const currentItems = networkList.slice(offset, offset + itemsPerPage);
  // const pageCount = Math.max(Math.ceil(networkList.length / itemsPerPage), 1);
  return (
    <>
      <div className="admin_network_layout">
        <div className="network_page_heading">
          <h4> Networks </h4>
          <div className="admin_network_btns">
            <div className="search_network">
              <input
                placeholder="Search"
                type="search"
                aria-controls="datatable"
                onChange={handleSearch}
              />
              <img
                className={""}
                src="/build/img/search.svg"
                alt="zex pr wire Logo"
                width={24}
                height={24}
                priority
              />
            </div>
            <Link to="/admin/network/add">
              <button className="new_network_btn"> ADD NEW </button>
            </Link>
          </div>
        </div>
        <div className="network_page_details">
          <div className="admin_network_table">
            <Accordion className="mb-4">
              <Card>
                <Card.Header>
                  <CustomToggle as={Button} variant="link" eventKey="0">
                    Filter
                  </CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <Form>
                      <div className="d-flex gap-4">
                        <Form.Group
                          controlId="filterStatus"
                          className="network_filter_options"
                        >
                          <Form.Label>Status</Form.Label>
                          <Form.Control
                            as="select"
                            name="status"
                            value={filters.status}
                            onChange={handleFilterChange}
                            className=""
                          >
                            <option value="">All</option>
                            <option value="active">ACTIVE</option>
                            <option value="inactive">INACTIVE</option>
                          </Form.Control>
                        </Form.Group>
                        <Form.Group
                          controlId="filterAudience"
                          className="template_filter_options"
                        >
                          <Form.Label>Audience</Form.Label>
                          <Form.Control
                            as="select"
                            name="audience"
                            value={filters.audience}
                            onChange={handleFilterChange}
                            className=""
                          >
                            <option value="">All Audience</option>
                            <option value="0-10">0-10</option>q
                            <option value="11-20">11-20</option>
                            <option value="21-30">21-30</option>
                            <option value="31-40">31-40</option>
                            <option value="40-1000">More than 40</option>
                          </Form.Control>
                        </Form.Group>
                      </div>
                      <div className="mt-3 d-flex gap-3">
                        <Button variant="secondary" onClick={clearFilters}>
                          Clear
                        </Button>
                        <Button variant="primary" onClick={applyFilters}>
                          Apply
                        </Button>
                      </div>
                    </Form>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>

            <DataTable
              className="data-table-wrapper"
              columns={columns}
              data={filteredTemplates}
              pagination
              progressPending={loading}
              persistTableHead
              responsive
              highlightOnHover
              noHeader
              striped
              customStyles={customStyles}
            />
            <Modal show={show} onHide={handleClose}>
              <Modal.Body>
                <div className="custom_template_modal_body">
                  <h5>Are you sure?</h5>
                  <p>Are you sure! You want to delete?</p>
                  <div className="d-flex justify-content-end gap-4">
                    <Button variant="secondary" onClick={handleClose}>
                      Cancel
                    </Button>
                    <Button
                      variant="danger"
                      onClick={deleteNetwork}
                      style={{ cursor: "pointer" }}
                    >
                      Ok
                    </Button>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
            {/* <Table className="mb-0" striped bordered hover>
              <thead className="staff_table_head">
                <tr>
                  <th>
                    <input type="checkbox" className="mt-1" />
                    <img
                      className="table_sort_img"
                      src="/build/sort.svg"
                      alt="sort"
                      width={10}
                      height={15}
                      priority
                    />
                  </th>
                  <th>
                    SI.No.
                    <img
                      className="table_sort_img"
                      src="/build/sort.svg"
                      alt="sort"
                      width={10}
                      height={15}
                      priority
                    />
                  </th>
                  <th>
                    Network Name
                    <img
                      className="table_sort_img"
                      src="/build/sort.svg"
                      alt="sort"
                      width={10}
                      height={15}
                      priority
                    />
                  </th>
                  <th>
                    Region / Language
                    <img
                      className="table_sort_img"
                      src="/build/sort.svg"
                      alt="sort"
                      width={10}
                      height={15}
                      priority
                    />
                  </th>
                  <th>
                    Est. Audience
                    <img
                      className="table_sort_img"
                      src="/build/sort.svg"
                      alt="sort"
                      width={10}
                      height={15}
                      priority
                    />
                  </th>
                  <th>
                    No. of Publications
                    <img
                      className="table_sort_img"
                      src="/build/sort.svg"
                      alt="sort"
                      width={10}
                      height={15}
                      priority
                    />
                  </th>
                  <th>
                    Action
                    <img
                      className="table_sort_img"
                      src="/build/sort.svg"
                      alt="sort"
                      width={10}
                      height={15}
                      priority
                    />
                  </th>
                </tr>
              </thead>
              <tbody className="staff_table_body">
                {networkList.map((network, index) => (
                  <tr key={network.networkId}>
                    <td>
                      <input type="checkbox" width={28} height={28} />{" "}
                    </td>
                    <td>{index + 1}</td>
                    <td>{network.networkName}</td>
                    <td>{network.region}</td>
                    <td>{network.audience}</td>
                    <td>{network.publication}</td>
                    <td>
                      <Link to={`/network/${network.networkId}`}>
                        <img
                          src="/build/watch.svg"
                          alt="watch"
                          width={24}
                          height={24}
                          className="me-2"
                        />
                      </Link>
                      <Link to={`/network/edit/${network.networkId}`}>
                        <img
                          src="/build/edit.svg"
                          alt="edit"
                          width={24}
                          height={24}
                          className="me-2"
                        />
                      </Link>
                      <img
                        src="/build/delete.svg"
                        alt="delete"
                        width={24}
                        height={24}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table> */}
          </div>
        </div>
        {/* <div className="d-flex justify-content-between">
          <p>
            Showing {offset + 1} to {offset + currentItems.length} of{" "}
            {networkList.length} entries
          </p>
          <div>
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              previousClassName={"custom_previous_network"}
              nextClassName={"custom_next_network"}
              pageClassName={"custom_page_network"}
              containerClassName={"pagination_network"}
              activeClassName={"active_network_page"}
            />
          </div>
        </div> */}
      </div>
    </>
  );
};
export default NetworkPage;
