import Navbar from "../../components/NavbarProfile/NavbarProfile";
import Sidebar from "../../components/SidebarNew/Sidebar";
import PublicationUpdate from "../../components/PublicationManagement/PublicationUpdate";
import Footer from "../../components/Layouts/Footer";

const StaffEdit = () => {
  const activeSide = 14;
  const activeEventKey = "3";
  return (
    <>
      <div className="row gx-0 staff_edit_layout">
        <Sidebar activeSide={activeSide}  activeEventKey={activeEventKey} />
        <div
          className={`main col d-flex flex-column`}
          style={{ overflow: "auto" }}
        >
          <Navbar />
          <div className="flex-grow-1">
            <PublicationUpdate />
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default StaffEdit;
