import "./CompanyLayout.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { showToast } from "../../Utils/index";
import Instance from "../../Utils/Axios";
import { useEffect, useState } from "react";

const CompanyUpdate = () => {
  const { companyId } = useParams();
  const navigate = useNavigate();
  const [company, setCompany] = useState({
    companyId: companyId,
    userId: "",
    name: "",
    address: "",
    contactPerson: "",
    mobile: "",
    email: "",
    website: "",
    state: "",
    country: "",
    city: "",
    createdBy: "Test",
    status: "INACTIVE",
  });
  const [error, setError] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      try {
        const token = localStorage.getItem("adminToken");
        const response = await Instance.post(
          `/siteadmin/user/company/details`,
          {
            adminToken: token,
            companyId: companyId,
          }
        );
        console.log("Response from Company API:", response.data);
        setCompany({
          companyId: companyId,
          userId: response?.data?.message?.userId,
          contactPerson: response?.data?.message?.contactPerson,
          companyName: response?.data?.message?.name,
          email: response?.data?.message?.email,
          mobile: response?.data?.message?.mobile,
          website: response?.data?.message?.website,
          address: response?.data?.message?.address,
          state: response?.data?.message?.state,
          country: response?.data?.message?.country,
          status: response?.data?.message?.status,
        });
        setError("");
      } catch (error) {
        setError("Failed to fetch company details");
        console.error("Error fetching company details for edit:", error);
      }
    };

    fetchCompanyDetails();
  }, [companyId]);

  const handleChange = (e) => {
    setCompany({
      ...company,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("adminToken");
      const response = await Instance.post(`/siteadmin/user/company/update`, {
        adminToken: token,
        ...company,
      });
      console.log("Response from updateCompany API:", response.data);
      if (response.data.resCode === "200") {
        showToast("Updated Succesfully!", "success", "success");
        setTimeout(() => {
          navigate("/admin/company");
        }, 1000);
      } else {
        setError(response.data.resMessage);
      }
    } catch (error) {
      setError("Failed to update company details");
      console.error("Error updating company details:", error);
    }
  };

  const fetchCountryList = () => {
    const token = localStorage.getItem("adminToken");
    Instance.post("/siteadmin/common/countryList", {
      adminToken: token,
      countryName: "",
      countryCode: "",
      isdCode: "",
      currency: "",
    })
      .then((response) => {
        setCountryList(response?.data?.message || []);
        setError("");
      })
      .catch((error) => {
        setError("Failed to fetch the data");
        console.error("Error fetching country list:", error);
      });
  };

  const fetchUserId = () => {
    const token = localStorage.getItem("adminToken");
    Instance.post("/siteadmin/user/list", {
      adminToken: token,
      status: "",
      fromDate: "",
      toDate: "",
      email: "",
      userId: "",
      country: "",
      state: "",
      city: "",
      mobileNumber: "",
      parentId: "",
    })
      .then((response) => {
        setUserList(response?.data?.message || []);
        setError("");
      })
      .catch((error) => {
        setError("Failed to fetch the data");
        console.error("Error fetching user list:", error);
      });
  };

  const fetchStateList = () => {
    const token = localStorage.getItem("adminToken");
    Instance.post("/siteadmin/common/getStates", {
      adminToken: token,
      code: "IN",
    })
      .then((response) => {
        setStateList(response?.data?.message || []);
        setError("");
      })
      .catch((error) => {
        setError("Failed to fetch the data");
        console.error("Error fetching state list:", error);
      });
  };

  useEffect(() => {
    fetchCountryList();
    fetchUserId();
    fetchStateList();
  }, []);

  if (!company) {
    return <p>Loading</p>;
  }

  return (
    <>
      <div className="company_pages_layout">
        <div className="company_page_heading">
          <h4> Edit Company </h4>
          <div className="admin_company_btns">
            <Link to="/admin/company">
              <span style={{ cursor: "pointer" }}>
                <div className="backbutton"></div>
              </span>
            </Link>
          </div>
        </div>
        <div className="add_company_details">
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Contact Person Name</Form.Label>
                <Form.Control
                  type="text"
                  name="contactPerson"
                  value={company.contactPerson}
                  onChange={handleChange}
                  required
                />
              </Col>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Company Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={company.companyName}
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <Row>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="text"
                  name="email"
                  value={company.email}
                  onChange={handleChange}
                  required
                />
              </Col>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  type="text"
                  name="mobile"
                  value={company.mobile}
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <Row>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Company Website</Form.Label>
                <Form.Control
                  type="text"
                  name="website"
                  value={company.website}
                  onChange={handleChange}
                  required
                />
              </Col>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Company Address</Form.Label>
                <Form.Control
                  type="text"
                  name="address"
                  value={company.address}
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <Row>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>State</Form.Label>
                <Form.Select
                  name="state"
                  value={company.state}
                  onChange={handleChange}
                >
                  <option value="">Select State</option>
                  {stateList.map((state) => (
                    <option key={state.id} value={state.value}>
                      {state.value}
                    </option>
                  ))}
                </Form.Select>
              </Col>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Country</Form.Label>
                <Form.Select
                  name="country"
                  value={company.country}
                  onChange={handleChange}
                >
                  <option value="">Please select</option>
                  {countryList.map((country) => (
                    <option key={country.countryId} value={country.countryName}>
                      {country.countryName}
                    </option>
                  ))}
                </Form.Select>
              </Col>
            </Row>
            <Row>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>User Id</Form.Label>
                <Form.Select
                  name="userId"
                  value={company.userId}
                  onChange={handleChange}
                >
                  <option value="">Select User</option>
                  {userList.map((user) => (
                    <option key={user.userId} value={user.userId}>
                      {user.firstName} {user.lastName}
                    </option>
                  ))}
                </Form.Select>
              </Col>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Status</Form.Label>
                <Form.Select
                  name="status"
                  value={company.status}
                  onChange={handleChange}
                  required
                >
                  <option value="ACTIVE">Active</option>
                  <option value="INACTIVE">Inactive</option>
                </Form.Select>
              </Col>
            </Row>
            <button className="add_company_submit_btn" type="submit">
              Update Company
            </button>
          </Form>
        </div>
      </div>
    </>
  );
};

export default CompanyUpdate;
