import React, { useState } from "react";
import { Accordion, Button, Card, Form } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import "../AnalyticsLayout.css";

const Package = () => {
  const [supportList, setSupportList] = useState();
  const [id, setId] = useState(1);
  const [accordionOpen, setAccordionOpen] = useState(false);

  const columns = [
    {
      name: "SR.No",
      selector: (row) => parseInt(row.No),
      sortable: true,
      wrap: true,
    },

    {
      name: "Item Name",
      selector: (row) => row.Name,
      sortable: true,
      wrap: true,
    },

    {
      name: "Quantity Sold",
      selector: (row) => row.quantity,
      sortable: true,
      wrap: true,
    },
    {
      name: "Total Amount",
      selector: (row) => row.amount,
      sortable: true,
      wrap: true,
    },
    {
      name: "Average Price",
      selector: (row) => row.Price,
      sortable: true,
      wrap: true,
    },
    {
      name: "View Details",
      selector: (row) => row.Details,
      wrap: true,
    },
  ];

  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(supportList[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  function downloadCSV(array) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = "export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      setAccordionOpen(!accordionOpen)
    );
    return (
      <button
        type="button"
        className="admin_filter_btn"
        // style={{ backgroundColor: "pink" }}
        onClick={decoratedOnClick}
      >
        {children}
        {accordionOpen ? (
          <img
            src="/build/img/Icons/down_vector.svg"
            alt="zex pr wire Logo"
            width={16}
            height={16}
          />
        ) : (
          <img
            src="/build/img/Icons/down_vector.svg"
            alt="zex pr wire Logo"
            width={16}
            height={16}
            className="filter_up_arrow"
          />
        )}
      </button>
    );
  }

  const data = [
    {
      No: " 1",
      Name: "Etrendystock PR",
      quantity: "1",
      amount: "$39",
      Price: "$39",

      Details: "View",
    },
  ];
  return (
    <>
      <Accordion className="my-4">
        <Card>
          <Card.Header className="d-md-flex justify-content-between align-items-center">
            <div className="support_search">
              <label>
                Search:
                <input
                  type="search"
                  placeholder=""
                  aria-controls="datatable"
                  // onChange={handleFilter}
                ></input>
              </label>
            </div>
            <div className="d-md-flex">
              <button
                // onClick={(e) => downloadCSV(supportList)}
                className="btn btn-export mx-3"
              >
                <img
                  src="/build/img/Icons/export.svg"
                  alt="zex pr wire Logo"
                  width={25}
                  height={25}
                />
              </button>
              <CustomToggle as={Button} variant="link" eventKey="0">
                Filters
              </CustomToggle>
            </div>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <Form>
                <div className="d-flex gap-4 flex-wrap">
                  <Form.Group
                    controlId="filterStatus"
                    className="support_filter_options"
                  >
                    <Form.Label>View Details</Form.Label>
                    <Form.Control
                      as="select"
                      name="status"
                      //  value={filters.status}
                      // onChange={handleFilterChange}
                    >
                      <option value="Cleared">View</option>
                      <option value="Pending">Pending</option>
                    </Form.Control>
                  </Form.Group>

                  <Form.Group
                    controlId="filtername"
                    className="support_filter_options"
                  >
                    <Form.Label>Item Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Search Item Name"
                      name="Name"
                      // value={filters.userName}
                      //  onChange={handleFilterChange}
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group
                    controlId="filterUsername"
                    className="support_filter_options"
                  >
                    <Form.Label>Quantity Sold</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Search Quantity Sold"
                      name="quantity"
                      // value={filters.userName}
                      //  onChange={handleFilterChange}
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group
                    controlId="filterUsername"
                    className="support_filter_options"
                  >
                    <Form.Label>Total Amount</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Search Total Amount"
                      name="amount"
                      // value={filters.ReleaseId}
                      // onChange={handleFilterChange}
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group
                    controlId="filterName"
                    className="support_filter_options"
                  >
                    <Form.Label>Average Price</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Search Price"
                      name="Price"
                      // value={filters.name}
                      // onChange={handleFilterChange}
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group
                    controlId="filterUsername"
                    className="support_filter_options"
                  >
                    <Form.Label>SR.No</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Search SR No"
                      name="no"
                      // value={filters.ReleaseId}
                      // onChange={handleFilterChange}
                    ></Form.Control>
                  </Form.Group>
                </div>
                <div className="mt-3 d-flex gap-3">
                  <Button
                    variant="secondary"
                    // onClick={clearFilters}
                  >
                    Clear
                  </Button>
                  <Button
                    variant="primary"
                    // onClick={applyFilters}
                  >
                    Apply
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>

      <DataTable
        className="data-table-wrapper"
        columns={columns}
        data={data}
        pagination
        defaultSortFieldId={1}
        responsive={true}
        defaultSortAsc={true}
      />
    </>
  );
};

export default Package;
