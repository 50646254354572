import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import Form from "react-bootstrap/Form";

import Instance from "../../Utils/Axios";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { showToast } from "../../Utils/index";

const EditCountry = () => {
  const { countryId } = useParams();
  const navigate = useNavigate();
  const [country, setCountry] = useState({
    countryId: countryId,
    countryName: "",
    countryCode: "",
    isdCode: "",
    gmtMinute: "",
    currency: "",
  });
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchCountryDetails = async () => {
      try {
        const token = localStorage.getItem("adminToken");
        const response = await Instance.post(
          `/siteadmin/common/countryDetails`,
          {
            adminToken: token,
            countryId: countryId,
            // countryName:"",
            // countryCode:"",
            // isdCode:"",
            // gmtMinute:"",
            // currency:""
          }
        );
        console.log("Response from Country API:", response.data);
        setCountry({
          countryId: response.data.countryId,
          countryName: response.data.countryName,
          countryCode: response.data.countryCode,
          isdCode: response.data.isdCode,
          gmtMinute: response.data.gmtMinute,
          currency: response.data.currency,
        });
        setError("");
      } catch (error) {
        setError("Failed to fetch country details");
        console.error("Error fetching country details for edit:", error);
      }
    };

    fetchCountryDetails();
  }, [countryId]);

  const handleChange = (e) => {
    setCountry({
      ...country,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("adminToken");
      const response = await Instance.post(`/siteadmin/common/countryUpdate`, {
        countryId: countryId,
        countryName: country.countryName,
        countryCode: country.countryCode,
        isdCode: country.isdCode,
        gmtMinute: country.gmtMinute,
        currency: country.currency,
        adminToken: token,
      });
      console.log("Response from updateCountry API:", response.data);
      if (response.data.resCode === "200") {
        showToast("Updated Succesfully!", "success", "success");
        setTimeout(() => {
          navigate("/admin/country");
        }, 1000);
      } else {
        setError(response.data.resMessage);
      }
    } catch (error) {
      setError("Failed to update country details");
      console.error("Error updating country details:", error);
    }
  };

  if (!country) {
    return <p>Loading</p>;
  }

  return (
    <>
      <div className="user_manage_layout">
        <div className="page_heading ">
          <h4> Edit Country </h4>
          <Link to={"/admin/country"}>
            <span style={{ cursor: "pointer" }}>
              <div className="backbutton"></div>
            </span>
          </Link>
        </div>

        <div className="add_new_country">
          <Form onSubmit={handleSubmit}>
            <Row>
              <Row>
                <Col
                  xs="md-6 xl-6 lg-6"
                  className="custom_col_add_country mb-4 "
                >
                  <Form.Label>Country Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="countryName"
                    className=""
                    value={country.countryName}
                    onChange={handleChange}
                    required
                  />
                </Col>
                <Col
                  xs="md-6 xl-6 lg-6"
                  className="custom_col_add_country mb-4 "
                >
                  <Form.Label>Country ID</Form.Label>
                  <Form.Control
                    type="text"
                    name="countryId"
                    className=""
                    value={country.countryId}
                    onChange={handleChange}
                    required
                  />
                </Col>
              </Row>

              <Row>
                <Col
                  xs="md-6 xl-6 lg-6"
                  className="custom_col_add_country mb-4"
                >
                  <Form.Label>Country Code</Form.Label>
                  <Form.Control
                    type="text"
                    name="countryCode"
                    value={country.countryCode}
                    onChange={handleChange}
                    required
                  />
                </Col>
                <Col
                  xs="md-6 xl-6 lg-6"
                  className="custom_col_add_country mb-4"
                >
                  <Form.Label>ISD Code</Form.Label>
                  <Form.Control
                    type="text"
                    name="isdCode"
                    value={country.isdCode}
                    onChange={handleChange}
                    required
                  />
                </Col>
                <Col
                  xs="md-6 xl-6 lg-6"
                  className="custom_col_add_country mb-4"
                >
                  <Form.Label>GMT Minite</Form.Label>
                  <Form.Control
                    type="text"
                    name="gmtMinute"
                    value={country.gmtMinute}
                    onChange={handleChange}
                    required
                  />
                </Col>
                <Col
                  xs="md-6 xl-6 lg-6"
                  className="custom_col_add_country mb-4"
                >
                  <Form.Label>Currency</Form.Label>
                  <Form.Control
                    type="text"
                    name="currency"
                    value={country.currency}
                    onChange={handleChange}
                    required
                  />
                </Col>
              </Row>
            </Row>

            <button
              className="add_country_submit_btn"
              // onClick={fetchCountryList}
              type="submit"
            >
              Submit
            </button>
          </Form>
        </div>
      </div>
    </>
  );
};

export default EditCountry;
