"use client";
import "../../Pages/Login/Login.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Instance from "../../Utils/Axios";

export default function Header() {
  const [headerData, setHeaderData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    Instance.get("/site/header")
      .then((response) => {
        setHeaderData(response.data);
        setLoading(false);
        console.log(response.data.logo);
      })
      .catch((error) => {
        setError("Error fetching header data");
        setLoading(false);
        console.error("Error fetching header data:", error);
      });
  }, []);

  if (loading) return <div>Loading Header</div>;
  if (error) return <div>{error}</div>;

  if (!headerData || !headerData.logo) {
    console.log("No logo found or headerData is null:", headerData);
    return <div>No logo found</div>;
  }

  return (
    <Navbar className="header_nav">
      <Container className="header_container">
        <div className="my-2">
          <img
            className="logo"
            src={`https://pradmin.webandapi.com/${headerData.logo}`}
            alt="zex pr wire Logo"
            width={147}
            height={67}
            onClick={() => {
              navigate("/");
            }}
          />
        </div>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto nav_items_container_login">
            {headerData.module.map((item, index) => {
              if (item.label === "SIGN UP") {
                return (
                  <Link key={index} to={item.rUrl} className="outline_btn">
                    {item.label}
                  </Link>
                );
              } else if (item.label === "SIGN IN") {
                return (
                  <Link key={index} to={item.rUrl} className="fill_btn">
                    {item.label}
                  </Link>
                );
              } else if (item.suboptions && item.suboptions.length > 0) {
                return (
                  <NavDropdown
                    key={index}
                    title={item.label}
                    id={`nav-dropdown-${index}`}
                  >
                    {item.suboptions.map((subitem, subindex) => (
                      <NavDropdown.Item key={subindex} href={subitem.rUrl}>
                        {subitem.label}
                      </NavDropdown.Item>
                    ))}
                  </NavDropdown>
                );
              } else {
                return (
                  <Nav.Link key={index} href={item.rUrl}>
                    {item.label}
                  </Nav.Link>
                );
              }
            })}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
