import "./PRmanager.css";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Modal, Button } from "react-bootstrap";
import { useState } from "react";

export default function PRpreview({ onShowPRLayout }) {
  const [navshow, setnavshow] = useState(0);
  const [showSubmit, setshowSubmit] = useState(false);

  const handleNavShow = () => {
    setnavshow(1);
  };
  const handleNotNavShow = () => {
    setnavshow(0);
  };

  const hadnleShowSubmit = () => {
    setshowSubmit(true);
  };
  const hadnleNotShowSubmit = () => {
    setshowSubmit(false);
  };
  return (
    <>
      <div className={`pr_secondary_nav  ${navshow === 1 ? "" : "d-none"} `}>
        <button className="pr_edit_btn" onClick={handleNotNavShow}>
          <img
            className={"pr_edit_img"}
            src="/build/img/Edit.svg"
            alt="zex pr wire Logo"
            width={14}
            height={14}
            priority
          />
          Edit
        </button>
        <img
          className={"pr_group_img"}
          src="/build/img/Group.png"
          alt="zex pr wire Logo"
          width={620}
          height={60}
          priority
        />
      </div>
      <div className="pr_form_preview">
        <Form className="pr_form_text">
          <Form.Group className="mb-4">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter text here"
              className="submit_form_control"
            />
          </Form.Group>
          <Row>
            <Col className="custom_col_submit mb-4">
              <Form.Label>Time of Release</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter text here"
                className="submit_form_control"
              />
            </Col>
            <Col className="custom_col_submit mb-4">
              <Form.Label>Package</Form.Label>
              <Form.Select className="submit_form_select">
                <option>Select from dropdown</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </Form.Select>
            </Col>
          </Row>
          <Form.Group className="mb-4">
            <Form.Label>PR Body</Form.Label>
            <Form.Control
              as="textarea"
              rows={6}
              placeholder="Enter text here"
              className="submit_form_control"
            />
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>Insert Image</Form.Label>
            <Form.Select className="submit_form_select">
              <option>Select from dropdown</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>Select Company</Form.Label>
            <Form.Select className="submit_form_select">
              <option>Select from dropdown</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </Form.Select>
          </Form.Group>
          <h4 className="pr_or">OR</h4>
          <Row>
            <Col className="custom_col_submit mb-4">
              <Form.Label>Company Name</Form.Label>
              <Form.Control
                placeholder=" Enter text here"
                className="submit_form_control"
              />
            </Col>
            <Col className="custom_col_submit mb-4">
              <Form.Label>Contact Person Name</Form.Label>
              <Form.Control
                placeholder="Enter text here"
                className="submit_form_control"
              />
            </Col>
          </Row>
          <Row>
            <Col className="custom_col_submit mb-4">
              <Form.Label>Email</Form.Label>
              <Form.Control
                placeholder="Enter text here"
                className="submit_form_control"
              />
            </Col>
            <Col className="custom_col_submit mb-4">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                placeholder="Enter text here"
                className="submit_form_control"
              />
            </Col>
          </Row>
          <Row>
            <Col className="custom_col_submit mb-4">
              <Form.Label>Company Website</Form.Label>
              <Form.Control
                placeholder="Enter text here"
                className="submit_form_control"
              />
            </Col>
            <Col className="custom_col_submit mb-4">
              <Form.Label>Company Address</Form.Label>
              <Form.Control
                placeholder="Enter text here"
                className="submit_form_control"
              />
            </Col>
          </Row>
          <Row>
            <Col xs="3" className="custom_col_submit mb-4">
              <Form.Label>City</Form.Label>
              <Form.Control
                placeholder="Enter text here"
                className="submit_form_control"
              />
            </Col>
            <Col xs="3" className="custom_col_submit mb-4">
              <Form.Label>State</Form.Label>
              <Form.Control
                placeholder="Enter text here"
                className="submit_form_control"
              />
            </Col>
            <Col xs="6" className="custom_col_submit mb-4">
              <Form.Label>Country</Form.Label>
              <Form.Select className="submit_form_select">
                <option>Select from dropdown</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </Form.Select>
            </Col>
          </Row>
          <Form.Group className="mb-4">
            <Form.Label>Select Category</Form.Label>
            <Form.Select className="submit_form_select">
              <option>Select from dropdown</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </Form.Select>
          </Form.Group>
          <p className="pr_form_p">
            Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
            sint. Velit officia consequat duis enim velit mollit. Exercitation
            veniam consequat sunt nostrud amet.
          </p>
        </Form>
      </div>
      <div className="pr_form_btns">
        {navshow === 0 && (
          <>
            <button className="pr_form_save_btn" onClick={onShowPRLayout}>
              Save as Draft
            </button>
            <button className="pr_form_next_btn" onClick={handleNavShow}>
              Next
            </button>
          </>
        )}
        {navshow === 1 && (
          <>
            <button className="pr_form_save_btn" onClick={onShowPRLayout}>
              Save as Draft
            </button>
            <button className="pr_form_submit_btn" onClick={hadnleShowSubmit}>
              Submit PR
            </button>
          </>
        )}
      </div>
      <Modal
        show={showSubmit}
        onHide={hadnleNotShowSubmit}
        centered
        className="custom_modal"
      >
        <Modal.Body>
          <div className="submit_modal_img">
            <img
              className={"submit_done_img"}
              src="/Done.svg"
              alt="zex pr wire Logo"
              width={140}
              height={140}
              priority
            />
          </div>
          <div>
            <h5 className="submit_modal_head">Submit PR</h5>
            <p className="submit_modal_content">
              Are your sure you want to submit this Press Release?
            </p>
          </div>
          <div className="pr_modal_btn">
            <Button
              className="submit_cancel_btn"
              variant="secondary"
              onClick={hadnleNotShowSubmit}
            >
              Cancel
            </Button>
            <Button
              className="submit_save_btn"
              variant="primary"
              onClick={onShowPRLayout}
            >
              Submit
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
