import React, { useState } from "react";
import { Col, FormGroup, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { showToast } from "../../Utils";

const InvoiceUpload = () => {
  const [file, setFile] = useState(null);
  const [error, setError] = useState("");

  const handleFileChangeLogo = (event) => {
    const file = event.target.files[0];
    setFile(file);
    console.log(file);
  };

  const submitImage = () => {
    if (!file) {
      showToast("Please Select Image", "error", "error");
    } else {
      const formDataToSubmit = new FormData();
      const token = localStorage.getItem("adminToken");
      formDataToSubmit.append("adminToken", token);
      formDataToSubmit.append("fileUpload", file);

      axios
        .post(
          "https://pradmin.webandapi.com/siteadmin/upload/document",
          formDataToSubmit,
          {
            headers: {
              "content-type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          if (response?.data?.resCode === "200") {
            showToast("Invoice Uploded Successfully!", "success", "success");
          }
        })
        .catch((error) => {
          setError("Failed to upload the file. Please try again.");
          console.error(
            "Error fetching network list:",
            error?.response?.data || error.message
          );
        });
    }
  };

  return (
    <>
      <div className="admin_editorial_layout">
        <div className="page_heading">
          <h4>Upload Invoice</h4>
          <Link to="/admin/manage_invoice">
            <span style={{ cursor: "pointer" }}>
              <div className="backbutton"></div>
            </span>
          </Link>
        </div>
        <div className="add_new_editorial">
          <Form onSubmit={(e) => e.preventDefault()}>
            <Row>
              <Col className="col-10 custom_col_add_editorial mb-4">
                <Form.Group>
                  <Form.Label>Upload Invoice</Form.Label>
                  <Form.Control
                    type="file"
                    className="form-control"
                    id="inputGroupFile04"
                    aria-describedby="inputGroupFileAddon04"
                    aria-label="Upload"
                    onChange={handleFileChangeLogo}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="pb-5">
              <Col>
                <button
                  type="button"
                  className="add_editorial_submit_btn"
                  onClick={submitImage}
                >
                  Upload Invoice
                </button>
              </Col>
            </Row>
          </Form>
          {error && <p className="error_message">{error}</p>}
        </div>
      </div>
    </>
  );
};

export default InvoiceUpload;
