import "./NetworkPages.css";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useState, useRef } from "react";
import Instance from "../../Utils/Axios";
import { showToast } from "../../Utils/index";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

const AddNetwork = () => {
  const fileInputRef = useRef(null);
  const logoInputRef = useRef(null);

  const fileImageRef = useRef(null);
  const [file, setFile] = useState(null);
  const [logoUrl, setLogoUrl] = useState(null);
  const [uploadFile, setUploadFile] = useState(null);
  const [uploadUrl, setUploadUrl] = useState(null);

  const [formData, setFormData] = useState({
    networkName: "",
    networkUrl: "",
    region: "",
    audience: "",
    logo: "",
    bulkPublicationsUrl: "",
  });
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleLogoUploadClick = () => {
    logoInputRef.current.click();
  };

  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     bulkPublicationsUrl: file,
  //   }));
  // };

  const handleUploadChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      setUploadFile(file);
      console.log(`Selected file: ${file.name}`);
    }

    if (!file) {
      showToast("Please Select Image", "error", "error");
    } else {
      const formDataToSubmit = new FormData();
      const token = localStorage.getItem("adminToken");
      formDataToSubmit.append("adminToken", token);
      formDataToSubmit.append("fileUpload", file);

      axios
        .post(
          "https://pradmin.webandapi.com//siteadmin/upload/document",
          formDataToSubmit,
          {
            headers: {
              "content-type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          if (response?.data?.resCode == "200") {
            // handleAddGallery(response?.data?.documentUrl);

            console.log("file url : ", response?.data?.documentUrl);
            setUploadUrl(response?.data?.documentUrl);
            // showToast("logo Inserted !", "success", "success");
          }
        })
        .catch((error) => {
          setError("Failed to fetch the data");
          console.error("Error fetching network list:", error);
        });
    }
  };

  const handleLogoChange = (event) => {
    const file = event.target.files[0];
    console.log(file.name);
    setFormData((prevData) => ({
      ...prevData,
      logo: file,
    }));
  };

  const handleImageClick = () => {
    // Trigger click on the hidden file input
    fileImageRef.current.click();
    // console.log(fileInputRef.current);
  };

  const handleImageChange = (event) => {
    // Handle file change here (e.g., upload file, display filename, etc.)
    const file = event.target.files[0];

    if (file) {
      setFile(file.name);
      console.log(`Selected file: ${file.name}`);
    }

    if (!file) {
      showToast("Please Select Image", "error", "error");
    } else {
      const formDataToSubmit = new FormData();
      const token = localStorage.getItem("adminToken");
      formDataToSubmit.append("adminToken", token);
      formDataToSubmit.append("fileUpload", file);

      axios
        .post(
          "https://pradmin.webandapi.com//siteadmin/upload/document",
          formDataToSubmit,
          {
            headers: {
              "content-type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          if (response?.data?.resCode == "200") {
            // handleAddGallery(response?.data?.documentUrl);

            console.log("file url : ", response?.data?.documentUrl);
            setLogoUrl(response?.data?.documentUrl);
            // showToast("logo Inserted !", "success", "success");
          }
        })
        .catch((error) => {
          setError("Failed to fetch the data");
          console.error("Error fetching network list:", error);
        });
    }
  };

  const addNewNetwork = (e) => {
    e.preventDefault();
    const token = localStorage.getItem("adminToken");

    const formDataToSubmit = new FormData();
    formDataToSubmit.append("adminToken", token);
    formDataToSubmit.append("networkName", formData.networkName);
    formDataToSubmit.append("networkUrl", formData.networkUrl);
    formDataToSubmit.append("region", formData.region);
    formDataToSubmit.append("audience", formData.audience);
    // formDataToSubmit.append("logo", formData.logo);
    formDataToSubmit.append("logo", logoUrl);
    // formDataToSubmit.append(
    //   "bulkPublicationsUrl",
    //   formData.bulkPublicationsUrl
    // );
    formDataToSubmit.append("bulkPublicationsUrl", uploadUrl);

    Instance.post("/siteadmin/network/create", formDataToSubmit)
      .then((response) => {
        if (response.data.resCode === "200") {
          showToast("Network added successfully", "success", "success");
          setError("");
          setTimeout(() => {
            navigate("/admin/network");
          }, 2000);
        } else {
          showToast("Error adding network", "error", "error");
          setError(response.data.resMessage);
          console.log("Network creation failed:", response.data);
        }
      })
      .catch((error) => {
        showToast("Error", "error", "error");
        setError("Failed to add network");
        console.error("Error adding network:", error);
      });
  };

  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(array[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  function downloadCSV(array) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = "export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  return (
    <>
      <div className="admin_network_layout">
        <div className="network_page_heading">
          <h4> Add New Report Network </h4>
          <div className="admin_network_btns">
            <Link to="/admin/network">
              <span style={{ cursor: "pointer" }}>
                <div className="backbutton"></div>
              </span>
            </Link>
          </div>
        </div>
        <div className="add_network_table">
          <Form onSubmit={addNewNetwork}>
            <Row>
              <Col xs="6" className="custom_col_add_staff mb-4">
                <Form.Label>Network Name</Form.Label>
                <Form.Control
                  type="text"
                  name="networkName"
                  value={formData.networkName}
                  onChange={handleChange}
                  required
                />
              </Col>
              <Col xs="6" className="custom_col_add_staff mb-4">
                <Form.Label>Network URL</Form.Label>
                <Form.Control
                  type="text"
                  name="networkUrl"
                  value={formData.networkUrl}
                  onChange={handleChange}
                  required
                />
              </Col>
            </Row>
            <Row>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Region / Language</Form.Label>
                <Form.Control
                  type="text"
                  name="region"
                  value={formData.region}
                  onChange={handleChange}
                  required
                />
              </Col>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Estimated Audience</Form.Label>
                <Form.Control
                  type="text"
                  name="audience"
                  value={formData.audience}
                  onChange={handleChange}
                  required
                />
              </Col>
            </Row>
            <Row>
              <Col xs="6" className="custom_col_add_staff mb-4">
                <Form.Label>Upload Network CSV</Form.Label>
                <div className="d-flex gap-4">
                  <Form.Control
                    type="text"
                    // value={
                    //   formData.bulkPublicationsUrl
                    //     ? formData.bulkPublicationsUrl.name
                    //     : ""
                    // }
                    value={uploadFile?.name}
                    readOnly
                  />
                  <button
                    className="network_upload_btn"
                    type="button"
                    onClick={handleFileUploadClick}
                  >
                    <img
                      src="/build/upload.svg"
                      alt="upload"
                      width={20}
                      height={20}
                    />
                  </button>
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    accept=".csv"
                    // onChange={handleFileChange}
                    onChange={handleUploadChange}
                  />
                </div>
                <p
                  className="network_table_dwonload_text"
                  onClick={(e) => downloadCSV([0])}
                  // onClick={(e) => downloadCSV(uploadFile)}
                >
                  Download Sample CSV
                </p>
              </Col>
              <Col xs="6" className="custom_col_add_staff mb-4">
                <Form.Label>Logo</Form.Label>
                <div className="d-flex gap-4">
                  <Form.Control
                    type="text"
                    // value={formData.logo ? formData.logo.name : ""}
                    value={file}
                    readOnly
                  />
                  <button
                    className="network_upload_btn"
                    type="button"
                    // onClick={handleLogoUploadClick}
                    onClick={handleImageClick}
                  >
                    <img
                      src="/build/upload.svg"
                      alt="upload"
                      width={20}
                      height={20}
                    />
                  </button>
                  <input
                    type="file"
                    // ref={logoInputRef}
                    ref={fileImageRef}
                    style={{ display: "none" }}
                    accept="image/*"
                    // onChange={handleLogoChange}
                    onChange={handleImageChange}
                  />
                </div>
              </Col>
            </Row>
            <div>
              <button className="add_network_add_btn" type="submit">
                Add
              </button>
            </div>
          </Form>
          {error && <p className="error_message">{error}</p>}
        </div>
      </div>
    </>
  );
};

export default AddNetwork;
