import "./GalleryLayout.css";
import { Table, Dropdown, Pagination } from "react-bootstrap";
import { useState } from "react";

export default function ListView({
  setSelectedPhoto,
  updateSelectedCheckboxes,
}) {
  const [checkedState, setCheckedState] = useState(Array(10).fill(false));

  const images = [
    { name: "Big Ben", date: "Jun 24, 2008" },
    { name: "Place du Capitole", date: "Jul 11, 2015" },
    { name: "Statu de la libérté", date: "Aug 28, 2004" },
    { name: "Tour Eiffel", date: "Mar 25, 2006" },
    { name: "Tour Eiffel", date: "Apr 09, 2011" },
    { name: "Place du Vigan", date: "Mar 22, 2013" },
    { name: "La sainte victoire", date: "Nov 22, 2006" },
    { name: "Arc de triomphe", date: "Feb 28, 2004" },
    { name: "La Croisette", date: "Feb 10, 2018" },
    { name: "La Croisette", date: "Nov 16, 2016" },
  ];

  const handleCheckboxClick = (index) => {
    setCheckedState((prevCheckedState) => {
      const updatedCheckedState = prevCheckedState.map((checked, i) =>
        i === index ? !checked : checked
      );
      updateSelectedCheckboxes(updatedCheckedState);
      
      const selectedImages = images.filter(
        (image, i) => updatedCheckedState[i]
      );
      if (selectedImages.length === 1){
        const selectedIndex = images.findIndex(
          (image, i) => updatedCheckedState[i]
        );
        setSelectedPhoto(`/Image${index < 9 ? "0" : ""}${index + 1}.png`);
      }else if (selectedImages.length > 1){
        setSelectedPhoto(`/Image${index < 9 ? "0" : ""}${index + 1}.png`);
      }else{
        setSelectedPhoto("");
      }
      return updatedCheckedState;
    });
  };;
  const handlePhotoClick = (imageName) => {
    setSelectedPhoto(`/Images/${imageName}.png`);
  };
  return (
    <>
      <div className="gallery_table">
        <Table hover className="mb-0">
          <thead className="gallery_tables_head">
            <tr className="gallery_tr">
              <th className="gallery_table_th1">
                <input type="checkbox" className="gallery_sr_checkbox" />
                SR NO
                <img
                  className={"mb-0"}
                  src="/build/img/down_arrow.svg"
                  alt="zex pr wire Logo"
                  width={26}
                  height={18}
                  priority
                />
              </th>
              <th className="gallery_table_th2">
                IMAGE NAME
                <img
                  className={"mb-0"}
                  src="/build/img/down_arrow.svg"
                  alt="zex pr wire Logo"
                  width={26}
                  height={18}
                  priority
                />
              </th>
              <th className="gallery_table_th3">
                DATE UPLOADED
                <img
                  className={"mb-0"}
                  src="/build/img/down_arrow.svg"
                  alt="zex pr wire Logo"
                  width={26}
                  height={18}
                  priority
                />
              </th>
            </tr>
          </thead>
          <tbody className="gallery_table_body">
            {images.map((image, index) => (
              <tr key={index} onClick={() => handleCheckboxClick(index)}>
                <td className="gallery_table_td1">
                  <input
                    type="checkbox"
                    className="gallery_sr_checkbox"
                    checked={checkedState[index]}
                    onChange={() => handleCheckboxClick(index)}
                  />
                  {index + 1}
                </td>
                <td onClick={() => handlePhotoClick(`image${index + 1}`)}>
                  {image.name}
                </td>
                <td>{image.date}</td>
              </tr>
            ))}
          </tbody>
          <caption>
            <div className="payment_table_footer">
              <div className="payment_page_no">
                <h4>Rows per page</h4>
                <Dropdown className="payment_dropdown">
                  <Dropdown.Toggle variant="light" id="dropdown-basic">
                    10
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">1</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">2</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">3</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">4</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">5</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="payment_pagination">
                <Pagination className="mb-0">
                  <Pagination.Item>{1}</Pagination.Item>
                  <Pagination.Item>{2}</Pagination.Item>
                  <Pagination.Item>{3}</Pagination.Item>
                  <Pagination.Ellipsis />
                  <Pagination.Item>{10}</Pagination.Item>
                  <Pagination.Item>{11}</Pagination.Item>
                  <Pagination.Item>{12}</Pagination.Item>
                </Pagination>
              </div>
              <div className="payment_pages">
                <h4>Go to page</h4>
                <img
                  className={"mb-0 payment_select_img"}
                  src="/build/img/Select.svg"
                  alt="zex pr wire Logo"
                  width={50}
                  height={32}
                  priority
                />
              </div>
            </div>
          </caption>
        </Table>
      </div>
    </>
  );
}
