import Navbar from "../../components/NavbarProfile/NavbarProfile";
import Sidebar from "../../components/SidebarNew/Sidebar";
import AddUser from "../../components/UserManagement/AddUser";
import Footer from "../../components/Layouts/Footer";
import "./UserAdd.css";

const UserAdd = () => {
  const activeSide = 1;
  const activeEventKey = "1";
  return (
    <>
      <div className="row gx-0 user_manage_content">
        <Sidebar activeSide={activeSide} activeEventKey={activeEventKey} />
        <div
          className={`main col d-flex flex-column`}
          style={{ overflow: "auto" }}
        >
          <Navbar />
          <div className="flex-grow-1">
            <AddUser />
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default UserAdd;
