import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useState, useEffect, useRef } from "react";
import Instance from "../../Utils/Axios";
import { useNavigate } from "react-router-dom";
import { showToast } from "../../Utils/index";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "./Publication.css";
import axios from "axios";

const AddPublication = ({ publicationList }) => {
  const fileInputRef = useRef(null);
  const fileInputRefOne = useRef(null);
  const fileInputRefTwoo = useRef(null);
  const fileInputRefThree = useRef(null);
  const [networkList, setNetworkList] = useState([]);
  const [tempData, setTempData] = useState([]);
  const [error, setError] = useState("");
  const [fileData, setFile] = useState(null);
  const [fileDataBulk, setFileBulk] = useState(null);
  const [fileDataSeperate, setFileSeperate] = useState(null);
  const [selectedImage, setSelectedImage] = useState("");
  const [bulkPublicationsUrl, setBulkPublicationsUrl] = useState("");
  const [seperatePublication, setSeperatePublication] = useState("");
  const [activeKey, setActiveKey] = useState("Individual");
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    publicationName: "",
    publicationUrl: "",
    region: "",
    audience: "",
    networkId: "",
    logo: "",
    premium: "",
  });

  const sampleFile = [
    {
      publicationName: "",
      publicationUrl: "",
      region: "",
      audience: "",
      logo: "",
      premium: "",
    },
  ];

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: files ? files[0] : value,
    }));
  };

  const fetchNetworkList = () => {
    const token = localStorage.getItem("adminToken");
    Instance.post("/siteadmin/network/getList", {
      adminToken: token,
      networkName: "",
      networkId: "0",
    })
      .then((response) => {
        setNetworkList(response?.data?.message);
        setTempData(response?.data?.message);
        setError("");
      })
      .catch((error) => {
        setError("Failed to fetch the data");
        console.error("Error fetching network list:", error);
      });
  };

  useEffect(() => {
    fetchNetworkList();
  }, []);

  const addNewPublication = (e) => {
    e.preventDefault();
    const token = localStorage.getItem("adminToken");

    // const data = new FormData();
    // data.append("adminToken", token);
    // for (const key in formData) {
    //   data.append(key, formData[key]);
    // }

    // if (fileDataBulk) {
    //   data.append("bulkPublicationsUrl", fileDataBulk);
    // }

    Instance.post("/siteadmin/publication/create", {
      adminToken: token,
      ...formData,
      logo: selectedImage,
      bulkPublicationsUrl: bulkPublicationsUrl || seperatePublication,
    })
      .then((response) => {
        if (response.data.resCode === "100") {
          showToast("Already a member", "error", "error");
          setError(response.data.resMessage);
          console.log("Publication creation failed:", response.data);
        } else {
          console.log("Publication created successfully:", response.data);
          showToast("Publication created successfully", "success", "success");
          setError("");
          setTimeout(() => {
            // publicationList();
            navigate("/admin/publication");
          }, 2000);
        }
      })
      .catch((error) => {
        showToast("Error", "error", "error");
        setError("Failed to register Publication");
        console.error("Error registering Publication:", error);
      });
  };

  const handleButtonClick = () => {
    document.getElementById("fileInput").click();
  };

  const handleFileUploadClick = () => {
    fileInputRefOne.current.click();
  };

  const handleFileUploadClickbulk = (e) => {
    console.log(fileInputRefTwoo.current.click(), "=====");
    fileInputRefTwoo.current.click();
  };

  const handleFileUploadClickseperate = () => {
    fileInputRefThree.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFormData((prevData) => ({
      ...prevData,
      networkUploadCsv: file,
    }));
  };

  const handleFileChangeLogo = (event) => {
    const token = localStorage.getItem("adminToken");
    const file = event.target.files[0];

    console.log(file.name);
    setFile(file);
    //alert('h');
    const formDataToSubmit = new FormData();
    formDataToSubmit.append("adminToken", token);
    formDataToSubmit.append("fileUpload", file);

    axios
      .post(
        "https://pradmin.webandapi.com//siteadmin/upload/document",
        formDataToSubmit,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        if (response?.data?.resCode === "200") {
          setSelectedImage(response?.data?.documentUrl);
          console.log("upload image api ", response?.data);
        }
      })
      .catch((error) => {
        setError("Failed to fetch the data");
        console.error("Error fetching network list:", error);
      });
  };

  const handleFileChangeLogoBulk = (event) => {
    const token = localStorage.getItem("adminToken");
    const filebulk = event.target.files[0];

    console.log(filebulk);

    const formDataToSubmit = new FormData();
    formDataToSubmit.append("adminToken", token);
    formDataToSubmit.append("fileUpload", filebulk);

    axios
      .post(
        "https://pradmin.webandapi.com//siteadmin/upload/document",
        formDataToSubmit,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        if (response?.data?.resCode === "200") {
          console.log(response?.data, "000000000000");
          const documentUrl = response.data.documentUrl;
          setBulkPublicationsUrl(documentUrl);
          setFileBulk(filebulk);
        } else {
          console.error(
            "Error during bulk upload: ",
            response?.data?.resMessage
          );
          showToast(
            response?.data?.resMessage || "Upload failed",
            "error",
            "error"
          );
        }
      })
      .catch((error) => {
        setError("Failed to fetch the data");
        console.error("Error fetching network list:", error);
      });
    console.log(filebulk);
  };

  const handleFileChangeLogoSeperate = (event) => {
    const token = localStorage.getItem("adminToken");
    const fileseperate = event.target.files[0];

    console.log(fileseperate.name);
    setFileSeperate(fileseperate);
    //alert('h');
    const formDataToSubmit = new FormData();
    formDataToSubmit.append("adminToken", token);
    formDataToSubmit.append("fileUpload", fileseperate);

    axios
      .post(
        "https://pradmin.webandapi.com//siteadmin/upload/document",
        formDataToSubmit,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        if (response?.data?.resCode === "200") {
          const documentUrl = response.data.documentUrl;
          setSeperatePublication(documentUrl);
        }
      })
      .catch((error) => {
        setError("Failed to fetch the data");
        console.error("Error fetching network list:", error);
      });
    console.log(fileseperate);
  };

  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(sampleFile[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  function downloadCSV(array) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = "publicationFile.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  return (
    <>
      <div className="admin_staff_layout">
        <div className="page_heading">
          <h4>Add Publication</h4>
          <Link to={"/admin/publication"}>
            <span style={{ cursor: "pointer" }}>
              <div className="backbutton"></div>
            </span>
          </Link>
        </div>
        <div className="add_new_staff">
          <Form onSubmit={addNewPublication}>
            <Row>
              <Tabs
                activeKey={activeKey}
                onSelect={setActiveKey}
                transition={false}
                id="noanim-tab-example"
                className="mb-3"
              >
                <Tab
                  eventKey="Individual"
                  title="Individual"
                  style={{ marginTop: "15px" }}
                >
                  <Row>
                    <Col className="custom_col_add_staff mb-4">
                      <Form.Label>Publication Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="publicationName"
                        value={formData.publicationName}
                        onChange={handleChange}
                        required={activeKey === "Individual"}
                      />
                    </Col>
                    <Col className="custom_col_add_staff mb-4">
                      <Form.Label>Publication URL</Form.Label>
                      <Form.Control
                        type="text"
                        name="publicationUrl"
                        value={formData.publicationUrl}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="custom_col_add_staff mb-4">
                      <Form.Label>Region / Language</Form.Label>
                      <Form.Control
                        type="text"
                        name="region"
                        value={formData.region}
                        onChange={handleChange}
                      />
                    </Col>
                    <Col className="custom_col_add_staff mb-4">
                      <Form.Label>Estimated Audience</Form.Label>
                      <Form.Control
                        type="text"
                        name="audience"
                        value={formData.audience}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="custom_col_add_staff mb-4">
                      <Form.Label>Network Name</Form.Label>
                      <Form.Select
                        aria-label="Please select"
                        name="networkId"
                        value={formData.networkId}
                        onChange={handleChange}
                      >
                        <option value="0">Please select</option>
                        {networkList.map((network) => (
                          <option
                            key={network.networkId}
                            value={network.networkId}
                          >
                            {network.networkName}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>

                    <Col xs="6" className="custom_col_add_staff mb-4">
                      <Form.Label>Publications Logo</Form.Label>
                      <div className="d-flex gap-4">
                        <Form.Control
                          type="text"
                          value={fileData ? fileData.name : ""}
                          readOnly
                        />
                        <button
                          className="network_upload_btn"
                          type="button"
                          onClick={handleFileUploadClick}
                        >
                          <img
                            src="/build/upload.svg"
                            alt="upload"
                            width={20}
                            height={20}
                          />
                        </button>
                        <input
                          type="file"
                          ref={fileInputRefOne}
                          style={{ display: "none" }}
                          accept=".png, .jpg, .jpeg"
                          onChange={handleFileChangeLogo}
                        />
                      </div>
                      {/* <p className="network_table_text">Download Sample CSV</p> */}
                    </Col>
                  </Row>
                  <Row>
                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                      <Form.Check
                        type="checkbox"
                        label="Premium Publication"
                        onClick={(e) => {
                          setFormData((prevData) => ({
                            ...prevData,
                            ["premium"]: !formData.premium ? 1 : 0,
                          }));
                        }}
                      />
                    </Form.Group>
                  </Row>
                </Tab>
                <Tab eventKey="Bulk" title="Bulk" style={{ marginTop: "15px" }}>
                  <Row>
                    <Col className="custom_col_add_staff mb-4">
                      <Form.Label>Network Name</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="networkName"
                        value={formData.networkName}
                        onChange={handleChange}
                      >
                        <option value="">Please Select Network</option>
                        {networkList.map((network) => (
                          <option
                            key={network.networkId}
                            value={network.networkId}
                          >
                            {network.networkName}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>

                    <Col xs="6" className="custom_col_add_staff mb-4">
                      <Form.Label>Upload Publications CSV</Form.Label>
                      <div className="d-flex gap-4">
                        <Form.Control
                          type="text"
                          value={fileDataBulk ? fileDataBulk.name : ""}
                          readOnly
                        />
                        <button
                          className="network_upload_btn"
                          type="button"
                          onClick={() => fileInputRefTwoo.current.click()}
                        >
                          <img
                            src="/build/upload.svg"
                            alt="upload"
                            width={20}
                            height={20}
                          />
                        </button>
                        <input
                          type="file"
                          ref={fileInputRefTwoo}
                          name="bulkPublicationsUrl"
                          style={{ display: "none" }}
                          accept=".csv, .png"
                          onChange={handleFileChangeLogoBulk}
                        />
                      </div>
                      <p
                        className="network_table_text"
                        onClick={(e) => downloadCSV(sampleFile)}
                      >
                        Download Sample CSV
                      </p>
                    </Col>
                  </Row>
                </Tab>
                <Tab
                  eventKey="Seperate Publication"
                  title="Seperate Publication"
                  style={{ marginTop: "15px" }}
                >
                  <Row>
                    <Col xs="6" className="custom_col_add_staff mb-4">
                      <Form.Label>Upload Publications CSV</Form.Label>
                      <div className="d-flex gap-4">
                        <Form.Control
                          type="text"
                          value={fileDataSeperate ? fileDataSeperate.name : ""}
                          readOnly
                        />
                        <button
                          className="network_upload_btn"
                          type="button"
                          onClick={handleFileUploadClickseperate}
                        >
                          <img
                            src="/build/upload.svg"
                            alt="upload"
                            width={20}
                            height={20}
                          />
                        </button>
                        <input
                          type="file"
                          ref={fileInputRefThree}
                          style={{ display: "none" }}
                          accept=".csv"
                          onChange={handleFileChangeLogoSeperate}
                        />
                      </div>
                      <p
                        className="network_table_text"
                        onClick={(e) => downloadCSV(sampleFile)}
                      >
                        Download Sample CSV
                      </p>
                    </Col>
                  </Row>
                </Tab>
              </Tabs>
            </Row>
            <Button className="add_staff_submit_btn" type="submit">
              Submit
            </Button>
          </Form>
          {error && <p className="error_message">{error}</p>}
        </div>
      </div>
    </>
  );
};

export default AddPublication;
