import "./AffiliateLayout.css";
import { Link } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

const AddBank = () => {
  return (
    <>
      <div className="affiliate_pages_layout">
        <div className="affiliate_page_heading">
          <h4> Add Bank </h4>
          <Link to="/admin/affiliate_bank">
            <span style={{ cursor: "pointer" }}>
              <div className="backbutton"></div>
            </span>
          </Link>
        </div>
        <div className="view_application_detail">
          <Form>
            <Row>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Bank Name</Form.Label>
                <Form.Control
                  type="text"
                  name="BankName"
                  //   value={formData.title}
                  //   onChange={handleChange}
                />
              </Col>
              <Col className="custom_col_add_staff mb-4">
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label>Icon</Form.Label>
                  <Form.Control type="file" />
                </Form.Group>
              </Col>
            </Row>
            <div className="d-flex justify-content-start gap-5">
              <button className="view_action_btn">Add Bank</button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddBank;
