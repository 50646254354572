import "./Staff.css";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Instance from "../../Utils/Axios";
import { Link } from "react-router-dom";
import { showToast } from "../../Utils/index";

const StaffUpdate = () => {
  const { staffId } = useParams();
  const navigate = useNavigate();
  const [staff, setStaff] = useState({
    title: "",
    name: "",
    userName: "",
    email: "",
    status: "",
    type: "",
    mobile: "",
    password: "",
  });
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchStaffDetails = async () => {
      try {
        const token = localStorage.getItem("adminToken");
        const response = await Instance.post(`/siteadmin/staff/getStaff`, {
          adminToken: token,
          staffId: staffId,
          status: "ACTIVE",
          title: "",
          name: "",
          mobile: "",
          type: "",
          password: "",
          type: "",
        });
        console.log("Response from getStaff API:", response.data);
        setStaff({
          title: response.data.title,
          name: response.data.name,
          userName: response.data.userName,
          email: response.data.email,
          status: response.data.status,
          type: response.data.type,
          mobile: response.data.mobile,
          password: response.data.password,
        });
        setError("");
      } catch (error) {
        setError("Failed to fetch staff details");
        console.error("Error fetching staff details for edit:", error);
      }
    };

    fetchStaffDetails();
  }, [staffId]);

  const handleChange = (e) => {
    setStaff({
      ...staff,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("adminToken");
      const response = await Instance.post(`/siteadmin/staff/updateStaff`, {
        adminToken: token,
        staffId: staffId,
        status: staff.status,
        title: staff.title,
        name: staff.name,
        mobile: staff.mobile,
        password: staff.password,
        type: staff.type,
      });
      console.log("Response from updateStaff API:", response.data);
      if (response.data.resCode === "200") {
        showToast("Updated Succesfully!", "success", "success");
        setTimeout(() => {
          navigate("/admin/staff");
        }, 1000);
      } else {
        setError(response.data.resMessage);
      }
    } catch (error) {
      setError("Failed to update staff details");
      console.error("Error updating staff details:", error);
    }
  };

  if (!staff) {
    return <p>Loading</p>;
  }

  return (
    <>
      <div className="admin_staff_layout">
        <div className="page_heading">
          <h4>Edit Staff</h4>
          <Link to={"/admin/staff"}>
            <span style={{ cursor: "pointer" }}>
              <div className="backbutton"></div>
            </span>
          </Link>
        </div>
        <div className="add_new_staff">
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  as="select"
                  name="title"
                  value={staff.title}
                  onChange={handleChange}
                >
                  <option value="">Select Title</option>
                  <option value="Mr">Mr</option>
                  <option value="Mrs">Mrs</option>
                </Form.Control>
              </Col>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={staff.name}
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <Row>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>User Name</Form.Label>
                <Form.Control
                  type="text"
                  name="userName"
                  value={staff.userName}
                  onChange={handleChange}
                  readOnly
                />
              </Col>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  readOnly
                  value={staff.email}
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <Row>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Status</Form.Label>
                <Form.Control
                  as="select"
                  name="status"
                  value={staff.status}
                  onChange={handleChange}
                >
                  <option value="ACTIVE">Active</option>
                  <option value="INACTIVE">Inactive</option>
                </Form.Control>
              </Col>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Type</Form.Label>
                <Form.Control
                  as="select"
                  name="type"
                  value={staff.type}
                  onChange={handleChange}
                >
                  <option value="STAFF">Staff</option>
                  <option value="ADMIN">Admin</option>
                </Form.Control>
              </Col>
            </Row>
            <Row>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  type="number"
                  name="mobile"
                  value={staff.mobile}
                  onChange={handleChange}
                />
              </Col>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  value={staff.password}
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <button className="add_staff_submit_btn" type="submit">
              Update Staff
            </button>
          </Form>
          {error && <p className="error">{error}</p>}
        </div>
      </div>
    </>
  );
};

export default StaffUpdate;
