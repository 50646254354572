import React, { useEffect, useState } from "react";
import { Col, FormGroup, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Instance from "../../Utils/Axios";
import { showToast } from "../../Utils";

const InvoiceEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    id: id,
    name: "",
    email: "",
    address: "",
    gst: "",
  });
  const [error, setError] = useState("");
  const [invoiceList, setInvoiceList] = useState([]);

  const fetchInvoiceDetails = async () => {
    try {
      const token = localStorage.getItem("adminToken");
      const response = await Instance.post(`/siteadmin/invoice/detail`, {
        adminToken: token,
        id: id,
      });
      // console.log("Response from Invoice Detail API:", response?.data?.message);
      setFormData({
        id: id,
        name: response?.data?.message?.name,
        email: response?.data?.message?.email,
        address: response?.data?.message?.address,
        gst: response?.data?.message?.gst,
      });

      setError("");
    } catch (error) {
      setError("Failed to fetch Invoice details");
      console.error("Error fetching Invoice details for edit:", error);
    }
  };
  useEffect(() => {
    fetchInvoiceDetails();
  }, [id]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("adminToken");
      const response = await Instance.post(`/siteadmin/invoice/updateDetail`, {
        adminToken: token,
        ...formData,
      });
      // console.log("Response from updateInvoivce API:", response.data);
      if (response.data.resCode === "200") {
        showToast("Updated Succesfully!", "success", "success");
        setTimeout(() => {
          navigate("/admin/manage_invoice_company");
        }, 1000);
      } else {
        setError(response.data.resMessage);
      }
    } catch (error) {
      setError("Failed to update Invoice details");
      console.error("Error updating Invoice details:", error);
    }
  };
  return (
    <>
      <div className="admin_editorial_layout">
        <div className="page_heading">
          <h4>Edit Invoice company</h4>
          <Link to="/admin/manage_invoice_company">
            <span style={{ cursor: "pointer" }}>
              <div className="backbutton"></div>
            </span>
          </Link>
        </div>
        <div className="add_new_editorial">
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col className="col-6 custom_col_add_editorial mb-4">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  class="form-control"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="APSA Enterprises Pvt Ltd"
                />
              </Col>
              <Col className="col-6 custom_col_add_editorial mb-4">
                <Form.Label>Eamil</Form.Label>
                <Form.Control
                  type="text"
                  class="form-control"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="info@zexprwire.com"
                />
              </Col>
            </Row>
            <Row>
              <Col className="col-6 custom_col_add_editorial mb-4">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={6}
                  placeholder=" E-44/3, Ground Floor, OKHLA
                Industrial Area Phase - 2
                New Delhi, Delhi
                India 110020"
                  className="p-2"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                />
              </Col>
              <Col className="col-6 custom_col_add_editorial mb-4">
                <Form.Label>GST</Form.Label>
                <Form.Control
                  type="text"
                  class="form-control"
                  name="gst"
                  value={formData.gst}
                  onChange={handleChange}
                  placeholder="07AATCA5400G1ZR"
                />
              </Col>
            </Row>

            <Row className="pb-5 ">
              <Col className=" ">
                <button
                  className="add_editorial_submit_btn"
                  type="submit"
                  //style={{ width: "200px" }}
                >
                  Update Company
                </button>
              </Col>
            </Row>
          </Form>
          {/* {error && <p className="error_message">{error}</p>} */}
        </div>
      </div>
    </>
  );
};
export default InvoiceEdit;
