import "./AffiliateLayout.css";
import { Link } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";

const ViewApplication = () => {
  const [showDeny, setShowDeny] = useState(false);
  const [showPending, setShowPending] = useState(false);

  const handleCloseDeny = () => setShowDeny(false);
  const handleShowDeny = () => setShowDeny(true);

  const handleClosePending = () => setShowPending(false);
  const handleShowPending = () => setShowPending(true);
  return (
    <>
      <div className="affiliate_pages_layout">
        <div className="affiliate_page_heading">
          <h4> Application Center </h4>
          <Link to="/admin/application_center">
            <span style={{ cursor: "pointer" }}>
              <div className="backbutton"></div>
            </span>
          </Link>
        </div>
        <div className="view_application_detail">
          <Form>
            <Row>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  name="firstname"
                  //   value={formData.title}
                  //   onChange={handleChange}
                />
              </Col>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  name="lastname"
                  //   value={formData.name}
                  //   onChange={handleChange}
                  required
                />
              </Col>
            </Row>
            <Row>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Email Id</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  //   value={formData.userName}
                  //   onChange={handleChange}
                  required
                />
              </Col>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  type="number"
                  name="mobile"
                  //   value={formData.mobile}
                  //   onChange={handleChange}
                />
              </Col>
            </Row>
            <Row>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  type="text"
                  name="address"
                  //   value={formData.email}
                  //   onChange={handleChange}
                  required
                />
              </Col>
            </Row>
            <Row>
              <Col xs="3" className="custom_col_add_staff mb-4">
                <Form.Label>City</Form.Label>
                <Form.Control
                  type="text"
                  name="city"
                  //   value={formData.email}
                  //   onChange={handleChange}
                  required
                />
              </Col>
              <Col xs="3" className="custom_col_add_staff mb-4">
                <Form.Label>State</Form.Label>
                <Form.Control
                  type="text"
                  name="state"
                  //   value={formData.email}
                  //   onChange={handleChange}
                  required
                />
              </Col>
              <Col xs="6" className="custom_col_add_staff mb-4">
                <Form.Label>Country</Form.Label>
                <Form.Control
                  type="text"
                  name="country"
                  //   value={formData.email}
                  //   onChange={handleChange}
                  required
                />
              </Col>
            </Row>

            <Row>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>How You Are going to Promote</Form.Label>
                <Form.Control
                  type="text"
                  name="how"
                  placeholder="An idea of the strategy and platforms where the affiliate member will promote the coupon codes."
                  //   value={formData.password}
                  //   onChange={handleChange}
                  required
                />
              </Col>
            </Row>
            <p>Date of account creation : 04/21/2022</p>
            <p>Date of affiliate application : 03/11/2024</p>
            <p>Current Status : Approve</p>
          </Form>
        </div>
        <div className="d-flex justify-content-center gap-5">
          <button className="view_action_btn" onClick={handleShowDeny}>
            Deny
          </button>
          <Modal show={showDeny} onHide={handleCloseDeny}>
            <Modal.Body className="view_modal_body">
              <div className="modal_warning">
                <span className="warning_line">
                  <span className="warning_dot"></span>
                </span>
              </div>
              <h4 className="view_modal_title">Are you sure?</h4>
              <p className="view_modal_text">
                Are you sure you want to change the status?
              </p>
              <div className="d-flex justify-content-end gap-4">
                <Button
                  variant="secondary"
                  className="ps-4 pe-4"
                  onClick={handleCloseDeny}
                >
                  Cancel
                </Button>
                <Button
                  variant="danger"
                  className="ps-4 pe-4"
                  onClick={handleCloseDeny}
                >
                  OK
                </Button>
              </div>
            </Modal.Body>
          </Modal>
          <button className="view_action_btn" onClick={handleShowPending}>
            Pending
          </button>

          <Modal show={showPending} onHide={handleClosePending}>
            <Modal.Body className="view_modal_body">
              <div className="modal_warning">
                <span className="warning_line">
                  <span className="warning_dot"></span>
                </span>
              </div>
              <h4 className="view_modal_title">Are you sure?</h4>
              <p className="view_modal_text">
                Are you sure you want to change the status?
              </p>
              <div className="d-flex justify-content-end gap-4">
                <Button
                  variant="secondary"
                  className="ps-4 pe-4"
                  onClick={handleClosePending}
                >
                  Cancel
                </Button>
                <Button
                  variant="danger"
                  className="ps-4 pe-4"
                  onClick={handleClosePending}
                >
                  OK
                </Button>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </>
  );
};
export default ViewApplication;
