import React from "react";
import Navbar from "../../components/NavbarProfile/NavbarProfile";
import Sidebar from "../../components/SidebarNew/Sidebar";
import Footer from "../../components/Layouts/Footer";
import "./CategoryPressRelease.css";
import PressReleaseCategory from "../../components/EditorialManagment/PressReleaseCategory";

const CategoryPressRelease = () => {
  const activeEventKey = "2";
  const activeSide = 11;
  return (
    <>
      <div className="row gx-0 category_press_content">
        <Sidebar activeSide={activeSide} activeEventKey={activeEventKey} />
        <div
          className={`main col d-flex flex-column `}
          style={{ overflow: "auto" }}
        >
          <Navbar />
          <div className="flex-grow-1">
            <PressReleaseCategory />
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default CategoryPressRelease;
