import React from "react";
import "./Editorial.css";
import { Table, Pagination, Dropdown, DropdownButton } from "react-bootstrap";
import { useState, useEffect } from "react";
import Instance from "../../Utils/Axios";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import { showToast } from "../../Utils/index";

const PressReleaseCategory = () => {
  const [categoryList, setCategoryList] = useState([]);
  const [error, setError] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [filteredCategory, setFilteredCategory] = useState([]);
  const [accordionOpen, setAccordionOpen] = useState(false);
  const [filters, setFilters] = useState([]);

  // const [packageList, setPackageList] = useState([]);
  // const [filteredPackage, setFilteredPackage] = useState([]);

  const fetchCategoryList = () => {
    const token = localStorage.getItem("adminToken");
    Instance.post("/siteadmin/category/getList", {
      adminToken: token,
      status: "",
    })
      .then((response) => {
        setCategoryList(response.data.message);
        setFilteredCategory(response.data.message);
        setError("");
      })
      .catch((error) => {
        setError("Error fetching the data");
        console.error("error fetching the category data", error);
      });
  };
  useEffect(() => {
    fetchCategoryList();
  }, []);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const applyFilters = () => {
    const token = localStorage.getItem("adminToken");
    Instance.post("/siteadmin/category/getList", {
      adminToken: token,
      status: filters.status,
      name: filters.name,
    })
      .then((response) => {
        setFilteredCategory(response.data.message);
        setError("");
        console.log("Filtered template data:", response?.data?.message);
      })
      .catch((error) => {
        setError("failed to apply filters");
        console.error("Error applying filter", error);
      });
  };

  const clearFilters = () => {
    const token = localStorage.getItem("adminToken");
    Instance.post("/siteadmin/category/getList", {
      adminToken: token,
      status: "",
    })
      .then((response) => {
        setFilters({ status: "", name: "" });
        setFilteredCategory(response.data.message);
        setCategoryList(response.data.message);
        setError("");
      })
      .catch((error) => {
        setError("Failed to clear filters");
        console.error("error clearing the filters", error);
      });
  };
  const handleToggleStatus = (row) => {
    const confirmed = window.confirm(
      `Are you sure you want to ${
        row.status === "ACTIVE" ? "deactivate" : "activate"
      } distribution?`
    );
    const updatedCategoryList = categoryList.map((category) =>
      category.categoryId === row.categoryId
        ? {
            ...category,
            status: category.status === "ACTIVE" ? "INACTIVE" : "ACTIVE",
          }
        : category
    );

    setCategoryList(updatedCategoryList);
    setFilteredCategory(updatedCategoryList);
  };

  const handleSubmit = async (status, id) => {
    // e.preventDefault();
    try {
      const token = localStorage.getItem("adminToken");
      const response = await Instance.post(`/siteadmin/category/update`, {
        adminToken: token,
        categoryId: id,
        status: status,
      });
      // console.log("Response from updateCoupon API:", response.data);
      if (response.data.resCode === "200") {
        fetchCategoryList();
        showToast(" Status Updated Succesfully!", "success", "success");
        // setTimeout(() => {
        //   // navigate("/category_press_release");
        //   window.location.reload();
        // }, 1000);
      } else {
        setError(response.data.resMessage);
      }
    } catch (error) {
      setError("Failed to update category details");
      console.error("Error updating category details:", error);
    }
  };

  const handleChecked = (event, status, id) => {
    const confirmed = window.confirm(
      `Are you sure you want to ${
        status === "ACTIVE" ? "inactive" : "active"
      } status?`
    );

    if (status === "ACTIVE" && confirmed === true) {
      handleSubmit("INACTIVE", id);
    } else if (
      (status === "INACTIVE" || status === "DEACTIVE" || status === "") &&
      confirmed === true
    ) {
      handleSubmit("ACTIVE", id);
    }

    // else if (status === "DEACTIVE" && confirmed === true) {
    //   handleSubmit("ACTIVE", id);
    // }

    console.log("Switch value:", event.target.checked, status, id);
  };
  const columns = [
    {
      name: "Category Name",
      selector: (row) => row.name,
      sortable: true,
      // width: "400px",
      wrap: true,
    },
    {
      name: "Edit Category",
      cell: (row) => (
        <>
          <a
            href={`/admin/editorial/category_press_release/edit/${row.categoryId} `}
            target="_blank"
          >
            <img
              src="/build/edit.svg"
              alt="edit"
              width={24}
              height={24}
              priority
              className="me-2"
            />
          </a>
        </>
      ),
      wrap: true,
      // width: "400px",
    },

    {
      name: "Active / Deactive",
      cell: (row) => (
        <Form>
          <Form.Check
            type="switch"
            id="custom-switch"
            checked={row?.status === "ACTIVE"}
            onChange={(e) => handleChecked(e, row.status, row.categoryId)}
          />
        </Form>
      ),
      wrap: true,
      // width: "400px",
    },
  ];

  const filterData = (e) => {
    let filterText = e.target.value;
    if (!filterText) {
      setFilteredCategory([...categoryList]);
    } else {
      const filteredItems = categoryList.filter(
        (item) =>
          (item.status &&
            item.status.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.name &&
            item.name.toLowerCase().includes(filterText.toLowerCase()))
      );
      setFilteredCategory([...filteredItems]);
    }
  };

  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = ["name"];

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  function downloadCSV(array) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = "category_press_release.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      setAccordionOpen(!accordionOpen)
    );
    return (
      <button
        type="button"
        className="admin_filter_btn"
        // style={{ backgroundColor: "pink" }}
        onClick={decoratedOnClick}
      >
        {children}
        {accordionOpen ? (
          <img
            src="/build/img/Icons/down_vector.svg"
            alt="zex pr wire Logo"
            width={16}
            height={16}
          />
        ) : (
          <img
            src="/build/img/Icons/down_vector.svg"
            alt="zex pr wire Logo"
            width={16}
            height={16}
            className="filter_up_arrow"
          />
        )}
      </button>
    );
  }

  return (
    <>
      <div className="admin_editorial_layout">
        <div className="page_heading">
          <h4>Manage Category </h4>
          <Link to="/admin/editorial/category/add_press_release">
            <button className="btn btn-new"> ADD NEW CATEGORY</button>
          </Link>
        </div>

        <Accordion className="mb-4">
          <Card>
            <Card.Header className="d-md-flex justify-content-between align-items-center">
              <div className="editorial_search">
                <label>
                  Search:
                  <input
                    type="search"
                    placeholder=""
                    aria-controls="datatable"
                    onChange={filterData}
                  ></input>
                </label>
              </div>
              <div className="d-md-flex">
                <button
                  // onClick={(e) => downloadCSV(editorialList)}
                  className="btn btn-export mx-3"
                >
                  <img
                    src="/build/img/Icons/export.svg"
                    alt="zex pr wire Logo"
                    width={25}
                    height={25}
                  />
                </button>
                <CustomToggle as={Button} variant="link" eventKey="0">
                  Filters
                </CustomToggle>
              </div>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Form>
                  <div className="d-flex gap-4 flex-wrap">
                    <Form.Group
                      controlId="filterStatus"
                      className="editorial_filter_options"
                    >
                      <Form.Label>Status</Form.Label>
                      <Form.Control
                        as="select"
                        name="status"
                        value={filters.status}
                        onChange={handleFilterChange}
                      >
                        <option value="">Select Status</option>
                        <option value="ACTIVE">Active</option>
                        <option value="INACTIVE">Inactive</option>
                      </Form.Control>
                    </Form.Group>

                    <Form.Group
                      controlId="filterName"
                      className="editorial_filter_options"
                    >
                      <Form.Label>Category Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search Category Name"
                        name="name"
                        value={filters.name}
                        onChange={handleFilterChange}
                      ></Form.Control>
                    </Form.Group>
                  </div>
                  <div className="mt-3 d-flex gap-3">
                    <Button variant="secondary" onClick={clearFilters}>
                      Clear
                    </Button>
                    <Button variant="primary" onClick={applyFilters}>
                      Apply
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>

        <DataTable
          className="data-table-wrapper"
          columns={columns}
          data={filteredCategory}
          pagination
          defaultSortFieldId={1}
          responsive={true}
          defaultSortAsc={true}
          //  actions={actionsMemo}
        />
      </div>
    </>
  );
};

export default PressReleaseCategory;
