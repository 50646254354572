import "./Staff.css";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useState } from "react";
import Instance from "../../Utils/Axios";
import { Link, useNavigate } from "react-router-dom";
import { showToast } from "../../Utils/index";

const AddStaff = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    name: "",
    userName: "",
    type: "",
    title: "",
    status: "",
    mobile: "",
    password: "",
  });
  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const addNewStaff = (e) => {
    e.preventDefault();
    const token = localStorage.getItem("adminToken");

    Instance.post("/siteadmin/staff/registerStaff", {
      adminToken: token,
      ...formData,
    })
      .then((response) => {
        if (response.data.resCode === "100") {
          showToast("Already a member", "error", "error");
          setError(response.data.resMessage);
          console.log("Staff registration failed:", response.data);
        } else {
          console.log("Staff registered successfully:", response.data);
          showToast("Staff registered successfully", "success", "success");
          setError("");
          setTimeout(() => {
            navigate("/admin/staff");
          }, 2000);
        }
      })
      .catch((error) => {
        showToast("Error", "error", "error");
        setError("Failed to register staff");
        console.error("Error registering staff:", error);
      });
  };

  return (
    <>
      <div className="admin_staff_layout">
        <div className="page_heading">
          <h4>Add New Staff</h4>
          <Link to="/admin/staff">
            <span style={{ cursor: "pointer" }}>
              <div className="backbutton"></div>
            </span>
          </Link>
        </div>
        <div className="add_new_staff">
          <Form onSubmit={addNewStaff}>
            <Row>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  as="select"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                >
                  <option value="">Select Title</option>
                  <option value="Mr">Mr</option>
                  <option value="Mrs">Mrs</option>
                </Form.Control>
              </Col>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </Col>
            </Row>
            <Row>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>User Name</Form.Label>
                <Form.Control
                  type="text"
                  name="userName"
                  value={formData.userName}
                  onChange={handleChange}
                  required
                />
              </Col>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  type="number"
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <Row>
              <Col xs="6" className="custom_col_add_staff mb-4">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </Col>
            </Row>
            <Row>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Status</Form.Label>
                <Form.Control
                  as="select"
                  name="status"
                  value={formData.status}
                  onChange={handleChange}
                >
                  <option value="">Select Status</option>
                  <option value="ACTIVE">Active</option>
                  <option value="INACTIVE">Inactive</option>
                </Form.Control>
              </Col>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Type</Form.Label>
                <Form.Control
                  as="select"
                  name="type"
                  value={formData.type}
                  onChange={handleChange}
                >
                  <option value="">Select Type</option>
                  <option value="STAFF">Staff</option>
                  <option value="ADMIN">Admin</option>
                </Form.Control>
              </Col>
            </Row>
            <Row>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                />
              </Col>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Confirm-Password</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  value={formData.confirmpassword}
                  onChange={handleChange}
                  required
                />
              </Col>
            </Row>
            <button className="add_staff_submit_btn" type="submit">
              Submit
            </button>
          </Form>
          {error && <p className="error_message">{error}</p>}
        </div>
      </div>
    </>
  );
};

export default AddStaff;
